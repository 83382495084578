import { useForm } from "./useForm";
import "./Form.css";

const initialForm = {
  firstname: "",
  lastname: "",
  email: "",
  identification: "",
  phone: "",
  observations: "",
};

const validationsForm = (form) => {
  let errors = {};
  let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
  let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
  let regexPhone =
    /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/;
  let regexObservations = /^.{1,255}$/;
  let regexIdentification = /^[0-9]+$/;

  if (!form.firstname.trim()) {
    errors.firstname = "Es necesario escribir un 'Nombre'";
  } else if (!regexName.test(form.firstname.trim())) {
    errors.firtsname = "Este campo solo acepta letras y espacios en blanco";
  }
  if (!form.lastname.trim()) {
    errors.lastname = "Es necesario escribir un 'Apellido'";
  } else if (!regexName.test(form.lastname.trim())) {
    errors.lastname = "Este campo solo acepta letras y espacios en blanco";
  }
  if (!form.email.trim()) {
    errors.email = "Es necesario escribir un 'E-mail'";
  } else if (!regexEmail.test(form.email.trim())) {
    errors.email =
      "El campo es incorrecto. Solo se aceptan direcciones de mail";
  }
  if (!form.identification.trim()) {
    errors.identification =
      "Es necesario escribir un 'Número de identificación'";
  } else if (!regexIdentification.test(form.identification.trim())) {
    errors.identification = "Este campo solo acepta números";
  }
  if (!form.phone.trim()) {
    errors.phone = "Es necesario escribir un 'Numero de teléfono'";
  } else if (!regexPhone.test(form.phone.trim())) {
    errors.phone = "Debe ingresar un número de teléfono correcto";
  }
  if (!form.observations.trim()) {
    errors.observations = "Es necesario escribir una 'Observación'";
  } else if (!regexObservations.test(form.observations.trim())) {
    errors.observations = "Este campo no debe exceder los 255 caracteres";
  }

  return errors;
};

export const ContactForm = () => {
  const {
    form,
    errors,
    /* loading,
    response, */
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm(initialForm, validationsForm);

  return (
    <>
      <form className="contactForm" onSubmit={handleSubmit}>
        <label className="labelForm" htmlFor="firstname">
          NOMBRE:{" "}
        </label>
        <input
          type="text"
          id="firstname"
          name="firstname"
          placeholder="Escribe tu nombre"
          onBlur={handleBlur}
          value={form.firstname}
          onChange={handleChange}
          required
        />
        {errors.firstname && <p className="pForm">{errors.firstname}</p>}
        <label className="labelForm" htmlFor="lastname">
          APELLIDO:{" "}
        </label>
        <input
          type="text"
          id="lastname"
          name="lastname"
          placeholder="Escribe tu apellido"
          onBlur={handleBlur}
          onChange={handleChange}
          value={form.lastname}
          required
        />
        {errors.lastname && <p className="pForm">{errors.lastname}</p>}
        <label className="labelForm" htmlFor="email">
          E-MAIL:{" "}
        </label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Escribe tu dirección de mail"
          onBlur={handleBlur}
          onChange={handleChange}
          value={form.email}
          required
        />
        {errors.email && <p className="pForm">{errors.email}</p>}
        <label className="labelForm" htmlFor="identification">
          N° DOCUMENTO:{" "}
        </label>
        <input
          type="number"
          id="identification"
          name="identification"
          placeholder="Número de identificación"
          onBlur={handleBlur}
          onChange={handleChange}
          value={form.identification}
          required
        />
        {errors.identification && <p className="pForm">{errors.identification}</p>}
        <label className="labelForm" htmlFor="phone">
          TELÉFONO:{" "}
        </label>
        <input
          type="tel"
          id="phone"
          name="phone"
          placeholder="Teléfono"
          onBlur={handleBlur}
          onChange={handleChange}
          value={form.phone}
          required
        />
        {errors.phone && <p className="pForm">{errors.phone}</p>}
        <label className="labelForm" htmlFor="observations">
          OBSERVACIONES:{" "}
        </label>
        <textarea
          className="textareaForm"
          name="observations"
          id="observations"
          cols="30"
          rows="10"
          placeholder="Escribe tus observaciones"
          onBlur={handleBlur}
          onChange={handleChange}
          value={form.observations}
          required
        ></textarea>
        {errors.observations && <p className="pForm">{errors.observations}</p>}
        <input type="submit" value="Enviar" />
      </form>
    </>
  );
};
