import { GET_SITES } from "../../utils/constantes";

const getAllSites = (payload) => {
  return {
    type: GET_SITES,
    payload,
  };
};

export default getAllSites;
