import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import style from "./Backoffice-NewPreparations.module.css";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { Editor } from "@tinymce/tinymce-react";
import { BackofficeModal } from "../Backoffice-Modal/Backoffice-Modal";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import openModal from "../../../../actions/backoffice-actions/openModal";
import { useNavigate, useParams } from "react-router-dom";
import { filterSearchBo } from "../../../../actions";
import cleanSearch from "../../../../actions/backoffice-actions/cleanSearch";
import { ImageOutlined } from "@mui/icons-material";
import Select from 'react-select';
import { useLocation } from "react-router-dom";

import {
  useData,
  useDataById,
  useAddData,
  useEditData,
} from "../../../../hooks/api";

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = error => reject(error);
  });
};

export const BackofficeNewPreparations = () => {

  const [specialties, setSpecialties] = useState("");
  const [laboratories, setLaboratories] = useState("");
  const [filter, setFilter] = useState("");
  const [save, setSave] = useState(false);
  const [modal, setModal] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [preparation, setPreparation] = useState("");

  const backofficeReducer = useSelector((state) => state.backofficeReducer);
  const modalImage = backofficeReducer.setModal;
  const search = backofficeReducer.search;

  const [valueLab, setValueLab] = useState({
    name: "",
    laboratories: [],
    fast: false,
    content: "",
    date: new Date(),
  });
  const [valueSpecialty, setvalueSpecialty] = useState({
    name: "",
    specialties: search,
    content: "",
    file: [],
    date: new Date(),
  });

  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation(); // Para acceder al pathname actual
  const { area, id } = useParams();
  const [type, setType] = useState(area || ""); // Usa `area` o un valor por defecto
  useEffect(() => {
    if (!type) {
      const pathArray = location.pathname.split('/');
      const extractedType = pathArray[3]; // El `type` es la cuarta parte de la URL
      setType(extractedType);
    }
  }, [location, type]);
  const { data: specialtiesData } = useData("specialtiesList");
  const { data: laboratoriesData } = useData("laboratorios");
  const { data: preparationData } = useDataById(type, id);
  const addDataMutation = useAddData(type);
  const editDataMutation = useEditData(type);

  useEffect(() => {
    setSpecialties(specialtiesData?.sort() || []);
    setLaboratories(laboratoriesData?.sort() || []);
  }, [specialtiesData, laboratoriesData]);


  useEffect(() => {
    if (preparationData) {
      setPreparation({ ...preparationData, date: new Date() });
    }
  }, [preparationData]);

  useEffect(() => setDirty(false), []);

  const getByQuery = (query) => {
    if (!query) {
      console.error('getByQuery recibió un valor undefined');
      return null;
    }
  
    if (type === "preparationsLabs" || area === "preparationsLabs") {
      const results = laboratories?.filter((lab) => lab.id === query);
      return results?.[0] || null;
    } else {
      const results = specialties?.filter((specialty) => specialty.id === query);
      return results?.[0] || null;
    }
  };
  
  const addPreparation = async () => {
    if (!id) {
      let input = type === "preparationsLabs" ? valueLab : valueSpecialty;
      addDataMutation.mutate(input, {
        onSuccess: () => {
          navigate(`/backoffice/preparaciones?type=${type}`);
        },
      });
    }
  };

  const handleChangeText = (e) => {
    if (id) {
      setPreparation({
        ...preparation,
        [e.target.name]: e.target.value,
      });
    } else {
      if (type === "preparationsLabs") {
        setValueLab({
          ...valueLab,
          [e.target.name]: e.target.value,
        });
      } else {
        setvalueSpecialty({
          ...valueSpecialty,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  const handleFast = (e) => {
    e.preventDefault();
    if (id) {
      setPreparation({ ...preparation, fast: !preparation.fast });
    } else {
      setValueLab({ ...valueLab, fast: !valueLab.fast });
    }
  };

  const handleCleanFile = (e, name) => {
    e.preventDefault();
    if (id) {
      setPreparation({
        ...preparation,
        file: preparation.file.filter((e) => e.name !== name),
      });
    } else {
      setvalueSpecialty({
        ...valueSpecialty,
        file: valueSpecialty?.file.filter((file) => file.name !== name),
      });
    }
  };

  const handleDocuments = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const base64File = await fileToBase64(file);
    if (id) {
      setPreparation({
        ...preparation,
        file: [...preparation.file, { file: base64File, name: file.name }],
      });
    } else {
      setvalueSpecialty({
        ...valueSpecialty,
        file: [...valueSpecialty.file, { file: base64File, name: file.name }],
      });
    }
  };

  const handleMarkdown = (e) => {
    e.preventDefault();
    setModal(true);
  };

  // Corregir handleSelectSpecialties para actualizar el estado correctamente
  const handleSelectSpecialties = (selectedOptions) => {
    const selectedValues = selectedOptions.map(option => option.value);

    if (id) {
      setPreparation((prev) => ({
        ...prev,
        specialties: selectedValues // actualiza con todas las especialidades seleccionadas
      }));
    } else {
      setvalueSpecialty((prev) => ({
        ...prev,
        specialties: selectedValues // actualiza con todas las especialidades seleccionadas
      }));
    }
  };


  // Corregir handleDesSelectSpecialty para eliminar correctamente
  const handleDesSelectSpecialty = (e, specialtyToRemove) => {
    e.preventDefault();

    if (id) {
      setPreparation({
        ...preparation,
        specialties: preparation.specialties.filter((specialty) => specialty !== specialtyToRemove),
      });
    } else {
      setvalueSpecialty({
        ...valueSpecialty,
        specialties: valueSpecialty.specialties.filter((specialty) => specialty !== specialtyToRemove),
      });
    }
  };


  const handleSelectLabs = (selectedOptions) => {
    const selectedValues = selectedOptions.map(option => option.value);

    if (id) {
      setPreparation((prev) => ({
        ...prev,
        laboratories: selectedValues,  // Establece los laboratorios seleccionados
      }));
    } else {
      setValueLab((prev) => ({
        ...prev,
        laboratories: selectedValues,  // Establece los laboratorios seleccionados
      }));
    }
  };



  const handleDesSelectLabs = (e, l) => {
    e.preventDefault();
    if (l !== "default") {
      if (id) {
        setPreparation({
          ...preparation,
          laboratories: preparation.laboratories.filter((el) => el !== l),
        });
      } else {
        setValueLab({
          ...valueLab,
          laboratories: valueLab.laboratories.filter((el) => el !== l),
        });
      }
    }
  };

  const handleModal = () => {
    dispatch(openModal());
  };

  const handleClose = (e) => {
    e.preventDefault();
    setFilter("");
    setModal(false);
  };

  const handleConfirm = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      setDirty(false);
      setSave(true);
      editorRef.current.setDirty(false);
      if (id) {
        setPreparation({ ...preparation, content: content });
      } else {
        if (type === "preparationsSpecialties") {
          setvalueSpecialty({ ...valueSpecialty, content: content });
        } else {
          setValueLab({ ...valueLab, content: content });
        }
      }
    }
  };

  const edit = async () => {
    editDataMutation.mutate(preparation, {
      onSuccess: () => {
        navigate(`/backoffice/preparaciones?type=${area}`);
      },
    });
  };

  const handleConfirmPreparation = (e) => {
    e.preventDefault(); // Prevent the form from submitting and reloading the page

    if (id) {
      edit();
    } else {
      addPreparation();
    }
    setSave(false);
  };

  const handleConfirmMarkdown = () => {
    setModal(false);
  };

  const handleSelectType = (e) => {
    setType(e.target.value);
    navigate(`/backoffice/preparaciones/crear?type=${e.target.value}`);
  };

  const open = (e) => {
    e.preventDefault();
    document.getElementById("file").click();
  };
  const adultsOrKids = (param) => {
    console.log("Received param in adultsOrKids:", param); // Añade este log
    let filters = id
      ? preparation?.specialties?.filter((e) => e !== "Diagnóstico por imágenes")
      : valueSpecialty?.specialties?.filter((e) => e !== "Diagnóstico por imágenes");

    // Asegura que filters no sea undefined o vacío
    if (!filters || filters.length === 0) {
      console.log("No filters found for", param); // Añade este log
      return "";
    }

    // Filtra el objeto completo basado en el ID
    let adults = specialties?.filter((e) => e.id === param);


    // Verifica si 'adults' contiene un objeto
    if (adults && adults.length > 0 && adults[0]) {
      if (adults[0].adults === "false" && adults[0].kids === "false") {
        return "";
      } else if (adults[0].adults === "true") {
        return "Adulto";
      } else if (adults[0].kids === "true") {
        return "Pediátrico";
      }
    }

    return ""; // Si no encontramos un valor válido
  };

  useEffect(() => {
    if (id && area === "preparationsSpecialties") {
      setPreparation({
        ...preparation,
        specialties: search,
      });
    }
    if (valueSpecialty) {
      setvalueSpecialty({
        ...valueSpecialty,
        specialties: search,
      });
    }
  }, [search]);

  useEffect(() => {
    return () => {
      dispatch(cleanSearch());
    };
  }, []);

  return (
    <div className={style.container}>
      {!id ? (
        <div className={style.selectContainer}>
          <div>
            <label htmlFor="">Tipo de preparación :</label>
            <select
              className={style.selectType}
              onChange={(e) => handleSelectType(e)}
              name="type"
              id=""
            >
              <option value="default">-</option>
              <option value="preparationsLabs">Laboratorio</option>
              <option value="preparationsSpecialties">Especialidad</option>
            </select>
          </div>
        </div>
      ) : null}
      {type === "preparationsLabs" ||
        type === "preparationsSpecialties" ||
        id ? (
        <>
          <form className={style.form} action="">
            <div className={style.title}>
              <label className={style.titleLabel} htmlFor="">
                {type === "preparationsLabs" ? "Preparación" : "Estudio"}
              </label>
              <input
                className={style.titleInput}
                type="text"
                placeholder={id ? preparation && preparation?.name : "Nombre"}
                value={
                  id
                    ? preparation.name
                    : area === "preparationsLabs" || type === "preparationsLabs"
                      ? valueLab.name
                      : valueSpecialty.name
                }
                name="name"
                onChange={(e) => handleChangeText(e)}
              />
            </div>
            {area === "preparationsSpecialties" ||
              type === "preparationsSpecialties" ? (
              <div className={style.specialty}>
                <label htmlFor="" className={style.specialtyLabel}>
                  Especialidades relacionadas
                </label>
                <Select
                  isMulti
                  options={(specialties || []).map(specialty => ({ value: specialty.id, label: specialty.name }))}
                  onChange={handleSelectSpecialties}
                  value={(preparation.specialties || valueSpecialty.specialties || []).map(specialty => ({
                    value: specialty,
                    label: getByQuery(specialty)?.name || 'Especialidad no encontrada',
                  }))}
                  className={style.selectArea}
                  placeholder="Seleccionar especialidades..."
                />

                <div>
                  <div>
                    {id
                      ? preparation.specialties &&
                      preparation.specialties
                        .filter(Boolean) // Filtra cualquier valor undefined
                        .map((r, index) => {
                          if (!r) {
                            return null;
                          }
                          return (
                            <div key={index} className={style.spanContainer}>
                              <CancelRoundedIcon
                                onClick={(e) => handleDesSelectSpecialty(e, r)}
                                className={style.cancel}
                              />
                              <span>{`${getByQuery(r)?.name || 'Especialidad no encontrada'} (${adultsOrKids(r)})`}</span>
                            </div>
                          );
                        })
                      : valueSpecialty.specialties &&
                      valueSpecialty.specialties
                        .filter(Boolean) // Filtra cualquier valor undefined
                        .map((r, index) => {
                          if (!r) {
                            return null;
                          }
                          return (
                            <div key={index} className={style.spanContainer}>
                              <CancelRoundedIcon
                                onClick={(e) => handleDesSelectSpecialty(e, r)}
                                className={style.cancel}
                              />
                              <span>{`${getByQuery(r)?.name || 'Especialidad no encontrada'} (${getByQuery(r)?.adults === 'true' ? 'Adultos' : 'Pediatricos'})`}</span>
                            </div>
                          );
                        })}
                  </div>



                </div>
              </div>
            ) : null}
            {area === "preparationsLabs" || type === "preparationsLabs" ? (
              <div className={style.labs}>
                <label htmlFor="" className={style.labsLabel}>
                  Laboratorios donde se realiza
                </label>
                <div>
                  <Select
                    isMulti
                    options={(laboratories || []).map(lab => ({ value: lab.id, label: lab.name }))}
                    onChange={handleSelectLabs}
                    value={(preparation.laboratories || valueLab.laboratories || []).map(lab => ({
                      value: lab,
                      label: getByQuery(lab)?.name || 'Laboratorio no encontrado',
                    }))}
                    className={style.selectArea}
                    placeholder="Seleccionar laboratorios..."
                  />

                  <div>
                    {id
                      ? (preparation.laboratories || []).map((l, index) => (
                        <div key={index} className={style.spanContainer}>
                          <CancelRoundedIcon
                            onClick={(e) => handleDesSelectLabs(e, l)}
                            className={style.cancel}
                          />
                          <span>{getByQuery(l)?.name}</span>
                        </div>
                      ))
                      : (valueLab.laboratories || []).map((l, index) => (
                        <div key={index} className={style.spanContainer}>
                          <CancelRoundedIcon
                            onClick={(e) => handleDesSelectLabs(e, l)}
                            className={style.cancel}
                          />
                          <span>{getByQuery(l)?.name}</span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ) : null}
            {area === "preparationsLabs" || type === "preparationsLabs" ? (
              <div className={style.ayuno}>
                <label className={style.ayunoLabel} htmlFor="">
                  Información adicional
                </label>
                <div className={style.ayunoContainerInput}>
                  <label htmlFor="" /* className={style.switch} */>
                    <button
                      className={style.fastButton}
                      onClick={(e) => handleFast(e)}
                    >
                      Ayuno
                    </button>
                  </label>
                  {id ? (
                    preparation.fast === false ? (
                      <CancelPresentationIcon
                        style={{ color: "red", width: "50px", height: "50px" }}
                      />
                    ) : (
                      <CheckBoxIcon
                        style={{ color: "teal", width: "50px", height: "50px" }}
                      />
                    )
                  ) : valueLab.fast === false ? (
                    <CancelPresentationIcon
                      style={{ color: "red", width: "50px", height: "50px" }}
                    />
                  ) : (
                    <CheckBoxIcon
                      style={{ color: "teal", width: "50px", height: "50px" }}
                    />
                  )}
                </div>
              </div>
            ) : null}
            {area === "preparationsSpecialties" ||
              type === "preparationsSpecialties" ? (
              <>
                <div className={style.indications}>
                  <label className={style.indicationsLabel} htmlFor="">
                    Documentos
                  </label>
                  <div className={style.indicationsContainerButton}>
                    <input
                      type="file"
                      onChange={handleDocuments}
                      name=""
                      id="file"
                      style={{ display: "none" }}
                    />
                    <PictureAsPdfIcon
                      className={style.file}
                      onClick={(e) => open(e)}
                    />
                  </div>
                  <div className={style.cancelContainer}>
                    {id
                      ? preparation &&
                      preparation.file?.map((file, index) => (
                        <div key={index} className={style.cancelFiles}>
                          <CancelRoundedIcon
                            onClick={(e) => handleCleanFile(e, file.name)}
                            className={style.cancel}
                          />
                          <span>{file.name}</span>
                        </div>
                      ))
                      : valueSpecialty &&
                      valueSpecialty.file?.map((file, index) => (
                        <div key={index}>
                          <CancelRoundedIcon
                            key={index}
                            onClick={(e) => handleCleanFile(e, file.name)}
                            className={style.cancel}
                          />
                          <span>{file.name}</span>
                        </div>
                      ))}
                  </div>
                </div>
              </>
            ) : null}
            <div className={style.indications}>
              <label className={style.indicationsLabel} htmlFor="">
                Descripción de indicaciones
              </label>
              <div className={style.indicationsContainerButton}>
                <button
                  onClick={(e) => {
                    handleMarkdown(e);
                  }}
                  className={style.indicationsButton}
                >
                  Indicaciones
                </button>
              </div>
            </div>
            <div className={style.sendContainer}>
              <button
                onClick={(e) => handleConfirmPreparation(e)} // Pass event 'e'
                className={style.sendButton}
              >
                {id ? "Modificar" : "Crear"}
              </button>

            </div>
          </form>
          {modal ? (
            <article className={style.modal}>
              <section className={style.modalContainer}>
                <span>{filter.name}</span>
                <div>
                  <button className="open-modal" onClick={() => handleModal()}>
                    <ImageOutlined width="40" height="40" />
                  </button>
                </div>
                <div
                  className={
                    modalImage
                      ? "modalImageContainer"
                      : "modalImageContainerOff"
                  }
                >
                  <BackofficeModal />
                </div>
                <form className="form-markdown">
                  <Editor
                    apiKey="slz93nsspb367zfebawu3ptagqrlbuyxfora657iog3xugbm"
                    textareaName="description"
                    initialValue={id ? preparation?.content : valueLab?.content}
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    onDirty={() => setDirty(true)}
                    init={{
                      selector: "textarea.tinymce",
                      theme: "silver",
                      height: 400,
                      plugins: [
                        "advlist autolink link image lists charmap preview hr anchor pagebreak",
                        "searchreplace wordcount visualblocks code visualchars nonbreaking",
                        "save table contextmenu directionality paste textcolor ",
                      ],
                      toolbar:
                        "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media| print preview fullpage | forecolor backcolor",
                      image_advtab: true,
                    }}
                  />
                </form>
              </section>
              <div className={style.containerButtonGroup}>
                <button
                  className="markdown-button"
                  onClick={(e) => handleClose(e)}
                >
                  Cancelar
                </button>
                <button
                  className="modified-button"
                  onClick={() => handleConfirm()}
                  disabled={!dirty}
                >
                  Modificar
                </button>
                <button
                  className={save ? "saveButton" : "saveDisabled"}
                  disabled={save ? false : true}
                  onClick={() => handleConfirmMarkdown()}
                >
                  Guardar
                </button>
              </div>
            </article>
          ) : null}
        </>
      ) : null}
    </div>
  );
};
