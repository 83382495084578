import { useEffect } from 'react';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
// import awsExports from '../../../aws-exports';
import { useNavigate } from 'react-router-dom';
import './AuthStyles.css';

// Amplify.configure(awsExports);

const formFields = {
  signIn: {
    username: {
      label: 'Nombre de usuario',
      placeholder: 'Ingrese su nombre de usuario',
    },
    password: {
      label: 'Contraseña',
      placeholder: 'Ingrese su contraseña',
    }
  }
};

export default function BackofficeLogin() {
  let navigate = useNavigate();

  useEffect(() => {
    const checkUser = async () => {
      try {
        const user = await Amplify.Auth.currentAuthenticatedUser();
        if (user) {
          navigate('/backoffice');
        }
      } catch (error) {
        // No user signed in, no action needed
      }
    };

    checkUser();
  }, [navigate]);

  const components = {
    SignIn: {
      Header() {
        return (
          <div className="auth-header">
            <img src="/logo192.png" alt="Logo" className="auth-logo" />
            <h2>Iniciar sesión</h2>
          </div>
        );
      },
      Footer() {
        return null; // Ocultar el pie de página que contiene el enlace "Forgot your password"
      },
      FormFields() {
        const { fields } = useAuthenticator();
        return (
          <>
            {fields.username}
            {fields.password}
          </>
        );
      },
      SubmitButton() {
        return (
          <button type="submit" className="amplify-button amplify-field-group__control amplify-button--primary amplify-button--fullwidth">
            Ingresar
          </button>
        );
      }
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-card">
        <Authenticator formFields={formFields} hideSignUp={true} components={components}>
          {({ user }) => {
            if (user) {
              navigate('/backoffice');
            }
            return null;
          }}
        </Authenticator>
      </div>
    </div>
  );
}
