import backgroundPrueba from "./backgroundBannerNews.svg";
import backgroundInstitucional from "../../assets/institucional.jpg";
import backgroundDocencia from "../../assets/docencia.png";
import frame from "./Frame.svg";
import imagenPrueba from "./imagenPrueba.svg";
import style from "./BannerNews.module.css";

const BannerNews = ({ area, icon, background }) => {
  return (
    <div className={style.container__main}>
      <img
        src={
          background
            ? background
            : area === "Actividades académicas"
            ? backgroundDocencia
            : area === "Programa de buena salud" ||
              area === "Actividades para la comunidad"
            ? backgroundPrueba
            : area === "Novedades institucionales"
            ? backgroundInstitucional 
            : null
        }
        alt="Background"
        className={style.imagen__background}
      />
      <div className={style.container__content}>
        <div className={style.container__frame1}>
          <img src={frame} alt="Frame" />
        </div>
        <div className={style.container__title_image}>
          <h3>
            <img src={icon ? icon : imagenPrueba} alt="Imagen de Prueba" />
            {area == "Programa de buena salud" ? "Programa de Buena Salud" : area}
          </h3>
        </div>
        <div className={style.container__frame2}>
          <img src={frame} alt="Frame" />
        </div>
      </div>
    </div>
  );
};

export default BannerNews;
