import { useEffect, useState } from "react";
import chevronOrdenar from "./chevron ordenar.svg";
import { useDispatch } from "react-redux";
import { sectionShow } from "../../actions/index";
import { useLocation } from "react-router-dom";
import CardNoticias from "../CardNoticias/CardNoticias";
import flechaBidireccion from "./va y viene.svg";
import Pagination from "./Pagination";
import style from "./ContenidoCentralNews.module.css";
import { getAnalytics, logEvent } from "firebase/analytics";

let newsPerPage = 5;

const ContenidoCentralNews = ({ data, area }) => {
  let [currentPage, setCurrentPage] = useState(1);
  let [orderShow, setOrderShow] = useState(true);
  let dispatch = useDispatch();
  const location = useLocation();

  //Firebase Analytics
  //-----------------------------------------------------//
  const analytics = getAnalytics();
  logEvent(analytics, "page_view", {
    firebase_screen: `Página visitada: ${location?.pathname}`,
    firebase_screen_class: `Página visitada`,
  });
  //-----------------------------------------------------//

  let indexOfLastNews = currentPage * newsPerPage;
  let indexOfFirstNews = indexOfLastNews - newsPerPage;
  let currentNews = data?.slice(indexOfFirstNews, indexOfLastNews);

  const openSections = () => {
    dispatch(sectionShow(true));
  };

  const orderFecha = (order) => {
    if (order) {
      setOrderShow(false);
      data?.sort((a, b) => {
        if (a.date > b.date) return 1;
        if (a.date < b.date) return -1;
        return 0;
      });
    } else {
      setOrderShow(true);
      data?.sort((a, b) => {
        if (a.date < b.date) return 1;
        if (a.date > b.date) return -1;
        return 0;
      });
    }
  };
  let paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // const establecerPaginas = () => {
  //   let indexOfLastNews = currentPage * newsPerPage;
  //   let indexOfFirstNews = indexOfLastNews - newsPerPage;
  //   return data?.slice(indexOfFirstNews, indexOfLastNews);
  // };

  useEffect(() => {
    setCurrentPage(1);
  }, [data, area]);

  // useEffect(() => {
  //   setCurrentNews(establecerPaginas());
  // }, [data, currentNews, orderShow]);

  return (
    <div className={style.container__main}>
      <div className={style.container__content}>
        <div className={style.container__title_select}>
          <h3>
            {area === "Programa de buena salud"
              ? "Hablemos de salud"
              : area === "Actividades para la comunidad"
              ? "Agenda CURF"
              : area === "Novedades institucionales"
              ? "Historial"
              : ""}
          </h3>
          <div className={style.container__ordenar_chevron}>
            {orderShow ? (
              <span
                onClick={() => {
                  orderFecha(true);
                }}
                className={style.order}
              >
                Más recientes primero
                <img
                  src={chevronOrdenar}
                  alt="chevron"
                  className={style.chevron_up}
                />
              </span>
            ) : (
              <span
                onClick={() => {
                  orderFecha(false);
                }}
                className={style.order}
              >
                Más antiguos primero
                <img
                  src={chevronOrdenar}
                  alt="chevron"
                  className={style.chevron_down}
                />
              </span>
            )}
          </div>
          <img
            src={flechaBidireccion}
            alt="Flecha bidireccional"
            className={style.flecha_bidireccional}
            onClick={() => openSections()}
          />
        </div>
        <div className={style.container__noticias}>
          {currentNews?.map((card, index) => {
            return <CardNoticias key={index} data={card} />;
          })}
        </div>
        <div className={style.container__paginado}>
          {data?.length > newsPerPage ? (
            <Pagination
              newsPerPage={newsPerPage}
              totalNews={data.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ContenidoCentralNews;
