import { Header } from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { NavBar } from "../../components/NavBar/NavBar";
import style from "./Template.module.css";

const Template = ({ body }) => {
  return (
    <div className={style.container__main}>
      <nav className="navBar__Home">
        <Header />
        <NavBar />
      </nav>
      <div className="distancia" /* style={{ marginTop: "154px" }} */></div>
      {body}
      <Footer />
    </div>
  );
};

export default Template;
