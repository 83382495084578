import style from "./Telemedicine.module.css";
import { Header } from "../Header/Header";
import { NavBar } from "../NavBar/NavBar";
import logo from "./logo.svg";
import img1 from "./tele1.png";
import img2 from "./tele2.png";
import img3 from "./tele3.png";
import banner from "../../assets/telemedicina.jpg";
import Footer from "../Footer/Footer";
import calendario from "./calendario.svg";
import escudo from "./escudo.svg";
import reloj from "./reloj.svg";
import arrow from "./arrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import setSection from "../../actions/setSection";
import setContent from "../../actions/setContent";

export const Telemedicine = ({ sections }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { section } = useParams();

  // const defaultItem =
  //   sections && sections?.filter((section) => section.name === "default");
  // const information =
  //   sections &&
  //   sections?.filter((section) => section.name === "Información general");
  // const waitingRoom =
  //   sections &&
  //   sections?.filter((section) => section.name === "Sala de espera");
  const coverage =
    sections && sections?.filter((section) => section.name === "Cobertura");
  // const turns =
  //   sections &&
  //   sections?.filter((section) => section.name === "Gestión de turnos");

  const handleSection = (value, content, tag) => {
    dispatch(setSection(value));
    dispatch(setContent(content));
    navigate(`/general/telemedicina/${tag}`);
  };
  return (
    <>
      {section === "Cobertura" ? (
        <div>
          {/* <button
            className={
              section
                ? information[0]?.tag === section
                  ? style.buttonSelect
                  : style.buttonSection
                : style.buttonSection
            }
            role="link"
            onClick={() =>
              handleSection(
                information[0]?.name,
                information[0]?.content,
                information[0]?.tag
              )
            }
          >
            {information[0]?.name}
          </button> */}
          {/* <a
            style={{ textDecoration: "none" }}
            href={"https://curf.aiphag.com/index.php/login"}
          >
            <button
              className={
                section
                  ? waitingRoom[0]?.tag === section
                    ? style.buttonSelect
                    : style.buttonSection
                  : style.buttonSection
              }
            >
              {waitingRoom[0]?.name}
            </button>
          </a> */}
          <button
            className={
              section
                ? coverage[0]?.tag === section
                  ? style.buttonSelect
                  : style.buttonSection
                : style.buttonSection
            }
            role="link"
            onClick={() =>
              handleSection(
                coverage[0]?.name,
                coverage[0]?.content,
                coverage[0]?.tag
              )
            }
          >
            {coverage[0]?.name}
          </button>
          {/* <a
            style={{ textDecoration: "none" }}
            href={"http://turnos.clinicareinafabiola.com.ar/"}
          >
            <button
              className={
                section
                  ? turns[0]?.tag === section
                    ? style.buttonSelect
                    : style.buttonSection
                  : style.buttonSection
              }
            >
              {turns[0]?.name}
            </button>
          </a> */}
        </div>
      ) : (
        <div className={style.container}>
          <nav className="navBar__Home">
            <Header />
            <NavBar />
          </nav>
          <div className="distancia"></div>
          <div className={style.banner}>
            <div
              className={style.bannerImage}
              style={{ backgroundImage: `url(${banner})` }}
            ></div>
            <button onClick={() => navigate("/")} className={style.goHomeBtn}>
              <img src={arrow} alt="flecha" />
              Volver
            </button>
            <div className={style.bannerCont}>
              <img className={style.bannerImg} src={logo} alt="logo" />
              <h2 className={style.bannerTitle}>Telemedicina</h2>
            </div>
          </div>
          <div className={style.containerGallery}>
            <div className={style.gallery}>
              <a
                className={style.containerImg}
                href="https://curf.aiphag.com/index.php/login"
                target="_blank"
              >
                <div className={style.containerImg}>
                  <img src={img1} alt="img" />
                  <div className={style.filtro}>
                    <img className={style.logo} src={reloj} alt="logo" />
                    <h3 className={style.sectionTitle}>Sala de espera</h3>
                    <p className={style.desc}>
                      Ingresá aquí para acceder a tu turno
                    </p>
                  </div>
                </div>
              </a>
              <div
                className={style.containerImg}
                role="link"
                onClick={() => navigate("/general/telemedicina/Cobertura")}
              >
                <img src={img2} alt="img" />
                <div className={style.filtro}>
                  <img className={style.logo} src={escudo} alt="logo" />
                  <h3 className={style.sectionTitle}>Cobertura</h3>
                  <p className={style.desc}>
                    Consultá aquí las obras sociales o prepagas convenidas
                  </p>
                </div>
              </div>
              <a
                className={style.containerImg}
                href="http://turnos.curf.com.ar"
                target="_blank"
              >
                <div className={style.containerImg}>
                  <img src={img3} alt="img" />
                  <div className={style.filtro}>
                    <img className={style.logo} src={calendario} alt="logo" />
                    <h3 className={style.sectionTitle}>Turnero online</h3>
                    <p className={style.desc}>
                      Gestioná tu turno para consultas presenciales o por
                      Telemedicina
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className={style.pantalla}>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};
