import { BannerPatient } from "../BannerPatient/BannerPatient";
import style from "./PatientGuide.module.css";
import arrow from "../../assets/arrow.svg";
import { GuideCard } from "../GuideCard/GuideCard";
import { Link } from "react-router-dom";
import { useData } from "../../hooks/api";

export const PatientGuide = () => {
  const { data: guides } = useData("guiaPaciente");
  return (
    <div>
      <BannerPatient />
      <div className={style.body}>
        <div className={style.arrowContainer}>
          <img className={style.arrow} src={arrow} alt="" />
          <Link className={style.goToHome} to="/">
            Volver
          </Link>
        </div>
        <article className={style.containerCards}>
          {window.innerWidth < 608 ? (
            <div className={style.arrowContainerResponsive}>
              <div>
                <img className={style.arrow} src={arrow} alt="" />
                <Link className={style.goToHome} to="/">
                  Volver
                </Link>
              </div>
            </div>
          ) : null}
          <section className={style.sectionCards}>
            {guides &&
              guides
                ?.sort((a, b) =>
                  a.date > b.date ? 1 : b.date > a.date ? -1 : 0
                )
                .map((guide, index) => (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/guiaDelPaciente/${guide?.name}/${guide?.sections?.sort((a, b) =>
                      a.date > b.date ? 1 : b.date > a.date ? -1 : 0
                    )[0]?.id}`}
                    key={index}
                  >
                    <GuideCard
                      key={index}
                      name={guide?.name}
                      resume={guide?.resume}
                      image={guide?.img}
                      icon={guide?.icon}
                      isLinked={true} // Indica que no debe generar un enlace adicional
                    />
                  </Link>
                ))}
          </section>
        </article>
      </div>
    </div>
  );
};
