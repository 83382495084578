import { useEffect } from "react";
import { useState } from "react";
import bannerSedes from "../../assets/nuestro equipo.jpg";
import Footer from "../Footer/Footer";
import Banner from "../Banner/Banner";
import Instalaciones from "../Instalaciones/Instalaciones.jsx";
import { Header } from "../Header/Header";
import { NavBar } from "../NavBar/NavBar";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Galeria from "../Galeria/Galeria";
import flecha from "./felcha izquierda.svg";
import sections from "./sections.svg";
import barrio from "./barrio.svg";
import edificio from "./Edificio.svg";
import close from "./close.svg";
import logoSedes from "./logoSedes.svg";
import style from "./Sedes.module.css";
import Equipamiento from "../Equipamiento/Equipamiento";
import { useData } from "../../hooks/api";

function Sedes() {
  const navigate = useNavigate();
  let params = useParams();
  const [showSections, setShowSections] = useState(false);
  const { data: sites, isLoading, error } = useData("sites");
  const sede = params.name;
  const gralPaz = sites?.filter((site) => site.barrio === "General Paz");
  const jardin = sites?.filter((site) => site.barrio === "Jardín Espinosa");
  const pueyrredon = sites?.filter((site) => site.barrio === "Pueyrredón");
  const selectSede = sites?.filter((site) => site.nombre === sede) || [];

  const openSections = () => {
    setShowSections(true);
  };

  const closeSections = () => {
    setShowSections(false);
  };

  const { search } = useLocation();
  const infraestructura = new URLSearchParams(search).get("infraestructura");

  function createMarkup() {
    return { __html: selectSede.length > 0 ? selectSede[0]?.content : "" };
  }

  function dataMap() {
    return { __html: selectSede.length > 0 ? selectSede[0]?.map : "" };
  }

  useEffect(() => {
    setShowSections(false);
  }, [sede]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error al cargar los datos de las sedes</div>;
  }

  return (
    <div className={style.container}>
      <nav className="navBar__Home">
        <Header />
        <NavBar />
      </nav>
      <div className="distancia"></div>
      <Banner
        title={infraestructura ? "Infraestructura" : `Sedes`}
        iconProp={logoSedes}
        image={bannerSedes}
      />
      <div className={style.container__flecha_home}>
        <div
          className={style.container__flecha_home_content}
          onClick={() => navigate(-1)}
        >
          <img src={flecha} alt="Flecha" className={style.flecha__home} />
          <span>Volver</span>
        </div>
      </div>
      <div className={style.father}>
        <div
          className={`${style.columnaizq__container_main} ${!showSections ? style.columnaizq__noshow : ""
            }`}
        >
          <h3 className={style.columnaizq__title}>Secciones</h3>
          <img
            src={close}
            className={style.close}
            onClick={closeSections}
            alt="close"
          />
          <div className={style.columnaizq__container_sites}>
            <div className={style.columnaizq__container_barrio}>
              <img className={style.barrio} src={barrio} alt="barrio" />
              <h4 className={style.name_barrio}>Barrio General Paz</h4>
            </div>
            <div className={style.division}></div>
            {gralPaz
              ?.sort((a, b) =>
                a.index > b.index ? 1 : b.index > a.index ? -1 : 0
              )
              ?.map((r) => (
                <Link to={`/sedes/${r.nombre}`} key={r.nombre}>
                  <button
                    className={
                      sede === r.nombre ? style.select : style.columnaizq__btn
                    }
                  >
                    {r.nombre}
                  </button>
                </Link>
              ))}
          </div>
          <div className={style.columnaizq__container_sites}>
            <div className={style.columnaizq__container_barrio}>
              <img className={style.barrio} src={barrio} alt="barrio" />
              <h4 className={style.name_barrio}>Pueyrredón</h4>
            </div>
            <div className={style.division}></div>
            {pueyrredon
              ?.sort((a, b) =>
                a.index > b.index ? 1 : b.index > a.index ? -1 : 0
              )
              ?.map((r) => (
                <Link to={`/sedes/${r.nombre}`} key={r.nombre}>
                  <button
                    className={
                      sede === r.nombre ? style.select : style.columnaizq__btn
                    }
                  >
                    {r.nombre}
                  </button>
                </Link>
              ))}
          </div>
          <div className={style.columnaizq__container_sites}>
            <div className={style.columnaizq__container_barrio}>
              <img className={style.barrio} src={barrio} alt="barrio" />
              <h4 className={style.name_barrio}>Barrio Jardín Espinosa</h4>
            </div>
            <div className={style.division}></div>
            {jardin?.map((r) => (
              <Link to={`/sedes/${r.nombre}`} key={r.nombre}>
                <button
                  className={
                    sede === r.nombre ? style.select : style.columnaizq__btn
                  }
                >
                  {r.nombre}
                </button>
              </Link>
            ))}
          </div>
          <div className={style.columnaizq__container_sites}>
            <div className={style.columnaizq__container_barrio}>
              <img className={style.edificio} src={edificio} alt="edificio" />
              <h4 className={style.name_barrio}>Infraestructura</h4>
            </div>
            <div className={style.division}></div>
            <Link to={`/sedes/Instalaciones`}>
              <button
                className={
                  sede === "Instalaciones"
                    ? style.select
                    : style.columnaizq__btn
                }
              >
                Instalaciones
              </button>
            </Link>
            <Link to={`/sedes/Equipamiento`}>
              <button
                className={
                  sede === "Equipamiento" ? style.select : style.columnaizq__btn
                }
              >
                Equipamiento tecnológico
              </button>
            </Link>
          </div>
        </div>
        <div className={style.columnader__container_main}>
          {sede === "Instalaciones" ? (
            <div className={style.columnader__container_content}>
              <Instalaciones />
            </div>
          ) : sede === "Equipamiento" ? (
            <div className={style.columnader__container_content}>
              <Equipamiento />
            </div>
          ) : (
            <div className={style.columnader__container_content}>
              <img
                src={sections}
                alt="Section"
                className={style.sections__button}
                onClick={openSections}
              />
              <div>
                <img
                  className={style.columnader__banner}
                  src={selectSede.length > 0 ? selectSede[0]?.img : sites?.[0]?.img}
                  alt={selectSede.length > 0 ? selectSede[0]?.nombre : sites?.[0]?.nombre}
                />
                <h2 className={style.columnader__banner_name}>
                  {selectSede.length > 0 ? selectSede[0]?.nombre : sites?.[0]?.nombre}
                </h2>
              </div>
              <h1 className={style.columnader__title}>
                Días y horarios de atención
              </h1>
              <div className={style.division2}></div>
              <p className={style.columnader__txt}>
                {selectSede.length > 0 ? selectSede[0]?.diasHorarios : sites?.[0]?.diasHorarios}
              </p>
              <h1 className={style.columnader__title}>Ubicación</h1>
              <div className={style.division2}></div>
              <h3 className={style.columnader__subtitulo}>Dirección</h3>
              <h4 className={style.columnader__info}>
                {selectSede.length > 0 ? `${selectSede[0]?.domicilio} - ${selectSede[0]?.provincia}` : `${sites?.[0]?.domicilio} - ${sites?.[0]?.provincia}`}
              </h4>

              <div dangerouslySetInnerHTML={dataMap()} />

              {selectSede.length > 0 && selectSede[0]?.content ? (
                <>
                  <h1 className={style.columnader__title}>Sobre esta sede</h1>
                  <div className={style.division2}></div>
                  <div
                    className={style.p}
                    dangerouslySetInnerHTML={createMarkup()}
                  />
                </>
              ) : null}
              {selectSede.length > 0 && selectSede[0]?.galeria?.length > 0 ? (
                <Galeria images={selectSede[0].galeria} />
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Sedes;
