/* eslint-disable react/no-unknown-property */
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import style from "./Backoffice-NewSpecialties.module.css";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import CheckIcon from "@mui/icons-material/Check";
import { useAddData, useData } from "../../../../hooks/api";
import { fileToBase64 } from "../../../../utils/files";

export const BackofficeNewSpecialties = () => {
  const [input, setInput] = useState({
    name: "",
    adults: "false",
    kids: "false",
    sites: "",
    sections: "",
  });
  const [inputLab, setInputLab] = useState({
    name: "",
    sites: "",
    sections: "",
  });
  const [inputUni, setInputUni] = useState({
    name: "",
    sites: "",
    sections: "",
    principal: "false",
  });

  const [img, setImg] = useState();

  const navigate = useNavigate();
  const { area } = useParams();

  const { data: sites } = useData("sites");
  const { data: sections } = useData("sections");

  let dbQuery = area;

  if (dbQuery === "especialidades") {
    dbQuery = "specialtiesList";
  } else {
    dbQuery = area;
  }
  const addDocMutation = useAddData(dbQuery);

  const handleUpload = (e) => {
    uploadImageAsPromise({
      img: e.target.files[0],
      name: e.target.files[0]?.name,
    });
  };

  const uploadImageAsPromise = async (uploadFile) => {
    const { img, name } = uploadFile;
    setImg(URL.createObjectURL(img));
    const base64File = await fileToBase64(img);
    const formData = {
      fileContent: base64File,
      fileName: name,
    };
    area === "especialidades"
      ? setInput({ ...input, ...formData })
      : area === "laboratorios"
      ? setInputLab({ ...inputLab, ...formData })
      : setInputUni({ ...inputUni, ...formData });
  };

  const handleChange = (e) => {
    switch (area) {
      case "especialidades":
        setInput({ ...input, [e.target.name]: e.target.value });
        break;
      case "laboratorios":
        setInputLab({ ...inputLab, [e.target.name]: e.target.value });
        break;
      case "unidadesAsistenciales":
        setInputUni({ ...inputUni, [e.target.name]: e.target.value });
        break;
      default:
        break;
    }
  };

  const handleCheckSites = (e) => {
    let nombre = e.target.getAttribute("data-nombre");
    let id = e.target.getAttribute("data-idsite");
    let pais = e.target.getAttribute("data-pais");
    let provincia = e.target.getAttribute("data-provincia");
    let barrio = e.target.getAttribute("data-barrio");
    let domicilio = e.target.getAttribute("data-direccion");
    let tel = e.target.getAttribute("data-tel");
    let content = e.target.getAttribute("data-content");
    let img = e.target.getAttribute("data-img");
    let galeria = e.target.getAttribute("data-galeria");
    let diasHorarios = e.target.getAttribute("data-dias");
    let mail = e.target.getAttribute("data-mail");

    const objectValue = {
      nombre,
      id,
      pais,
      provincia,
      barrio,
      domicilio,
      tel,
      img,
      galeria,
      content,
      diasHorarios,
      mail,
    };

    if (e.target.checked === true) {
      area === "especialidades"
        ? setInput({
            ...input,
            sites: [...input.sites, objectValue],
          })
        : area === "laboratorios"
        ? setInputLab({
            ...inputLab,
            sites: [...inputLab.sites, objectValue],
          })
        : setInputUni({
            ...inputUni,
            sites: [...inputUni.sites, objectValue],
          });
    }
    if (e.target.checked === false) {
      area === "especialidades"
        ? setInput({
            ...input,
            sites: input.sites.filter((e) => e.nombre !== objectValue.nombre),
          })
        : area === "laboratorios"
        ? setInputLab({
            ...inputLab,
            sites: [...inputLab.sites, objectValue],
          })
        : setInputUni({
            ...inputUni,
            sites: [...inputUni.sites, objectValue],
          });
    }
  };

  const handleCheckSections = (e) => {
    let name = e.target.getAttribute("data-name");
    let id = e.target.getAttribute("data-id");
    let content = e.target.getAttribute("data-content");
    let index = e.target.getAttribute("data-index");

    const objectValue = { name, id, content, index };

    if (e.target.checked === true) {
      area === "especialidades"
        ? setInput({
            ...input,
            sections: [...input.sections, objectValue],
          })
        : area === "laboratorios"
        ? setInputLab({
            ...inputLab,
            sections: [...inputLab.sections, objectValue],
          })
        : setInputUni({
            ...inputUni,
            sections: [...inputUni.sections, objectValue],
          });
    }
    if (e.target.checked === false) {
      area === "especialidades"
        ? setInput({
            ...input,
            sections: input.sections.filter((e) => e.name !== objectValue.name),
          })
        : area === "laboratorios"
        ? setInputLab({
            ...inputLab,
            sections: inputLab.sections.filter(
              (e) => e.name !== objectValue.name
            ),
          })
        : setInputUni({
            ...inputUni,
            sections: inputUni.sections.filter(
              (e) => e.name !== objectValue.name
            ),
          });
    }
  };

  const upperCase = (str) => {
    let modified = str.charAt(0).toUpperCase() + str.slice(1);
    area === "especialidades"
      ? setInput({
          ...input,
          name: modified,
        })
      : area === "laboratorios"
      ? setInputLab({
          ...inputLab,
          name: modified,
        })
      : setInputUni({
          ...inputUni,
          name: modified,
        });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addDocMutation.mutate(
      area === "especialidades"
        ? input
        : area === "laboratorios"
        ? inputLab
        : inputUni,
      {
        onSuccess: (data) => {
          navigate(`/backoffice/${area}/editar/${data.id}`);
        },
      }
    );
  };

  useEffect(() => {
    area === "especialidades"
      ? upperCase(input.name)
      : area === "laboratorios"
      ? upperCase(inputLab.name)
      : upperCase(inputUni.name);
  }, [input.sections, inputLab.sections, inputUni.sections]);

  const open = () => {
    document.getElementById("file").click();
  };

  return (
    <div className={style.container}>
      <div>
        <div className={style.titleContainer}>
          <label className={style.title}>
            {area === "especialidades"
              ? "Nueva especialidad"
              : area === "laboratorios"
              ? "Nuevo laboratorio"
              : "Nueva unidad asistencial"}
          </label>
        </div>
        <form className={style.formSpecialties} action="">
          <label className={style.headersLabel} htmlFor="name">
            Nombre
          </label>
          <input
            id="name"
            name="name"
            value={
              area === "especialidades"
                ? input.name
                : area === "laboratorios"
                ? inputLab.name
                : inputUni.name
            }
            placeholder="Nombre de la especialidad"
            required
            type="text"
            className={style.inputName}
            onChange={(e) =>
              area === "especialidades"
                ? setInput({ ...input, name: e.target.value })
                : area === "laboratorios"
                ? setInputLab({ ...inputLab, name: e.target.value })
                : setInputUni({ ...inputUni, name: e.target.value })
            }
          />
          {area === "especialidades" || area === "unidadesAsistenciales" ? (
            <>
              <label className={style.headersLabel}>Tipo</label>
              <div
                className={
                  area === "unidadesAsistenciales"
                    ? style.principalProp
                    : style.adultsOrKids
                }
              >
                <div
                  className={
                    area === "unidadesAsistenciales"
                      ? style.adultsOrkidsOff
                      : null
                  }
                >
                  <input
                    id="adults"
                    name="adults"
                    value={input.adults === "false" ? true : false}
                    type="checkbox"
                    onChange={(e) => handleChange(e)}
                    disabled={input.kids === "true" ? true : false}
                  />
                  <label style={{ marginLeft: "10px" }} htmlFor="adults">
                    Adultos
                  </label>
                </div>
                <div>
                  <input
                    className={style.inputUni}
                    id="kids"
                    name={
                      area === "unidadesAsistenciales" ? "principal" : "kids"
                    }
                    value={
                      area === "unidadesAsistenciales"
                        ? inputUni.principal === "false"
                          ? true
                          : false
                        : input.kids === "false"
                        ? true
                        : false
                    }
                    type="checkbox"
                    onChange={(e) => handleChange(e)}
                    disabled={input.adults === "true" ? true : false}
                  />
                  <label className={style.uniOrKids} htmlFor="adults">
                    {area === "unidadesAsistenciales"
                      ? "Unidad Principal"
                      : "Niños"}
                  </label>
                </div>
              </div>
            </>
          ) : null}
          <label className={style.headersLabel}>Imagen</label>
          <div className={style.imageSpecialtyContainer}>
            {area === "especialidades" ? (
              input.img ? (
                <CheckIcon className={style.checkImage} />
              ) : (
                <ImageSearchIcon
                  className={style.searchImage}
                  onClick={() => open()}
                />
              )
            ) : area === "laboratorios" ? (
              inputLab.img ? (
                <CheckIcon className={style.checkImage} />
              ) : (
                <ImageSearchIcon
                  className={style.searchImage}
                  onClick={() => open()}
                />
              )
            ) : inputUni.img ? (
              <CheckIcon className={style.checkImage} />
            ) : (
              <ImageSearchIcon
                className={style.searchImage}
                onClick={() => open()}
              />
            )}
            <input
              onChange={handleUpload}
              className={style.uploadImgSpecialty}
              type="file"
              id="file"
            />
            <img className={style.imageHeaderSpecialty} src={img} alt="" />
          </div>
          <div>
            <label className={style.headersLabel} htmlFor="sites">
              Sedes
            </label>
            <div className={style.siteContainer}>
              <div>
                {sites &&
                  sites.map((site, index) => (
                    <div key={index} className={style.siteMap}>
                      <input
                        type="checkbox"
                        className={style.inputDisplayFlex}
                        id={`${site.name}`}
                        onChange={(e) => handleCheckSites(e)}
                        name="sites"
                        datanombre={site.nombre}
                        dataidsite={site.id}
                        datapais={site.pais}
                        dataprovincia={site.provincia}
                        databarrio={site.barrio}
                        datadireccion={site.domicilio}
                        datadias={site.diasHorarios}
                        datatel={site.tel}
                        datagaleria={site.galeria}
                        dataimg={site.img}
                        datacontent={site.content}
                        datamail={site.mail}
                        value={site}
                      />
                      <label htmlFor={`${site.name}`}>{site.nombre}</label>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <label className={style.headersLabel} htmlFor="section">
            Secciones base
          </label>
          <div className={style.sectionsContainer}>
            <div>
              {sections &&
                sections.map((sec, index) => (
                  <div key={index} className={style.siteMap}>
                    <input
                      type="checkbox"
                      className={style.inputDisplay}
                      id={`${sec.name}`}
                      onChange={(e) => handleCheckSections(e)}
                      name={`${sec.name}`}
                      value={sec}
                      dataname={sec.name}
                      dataid={sec.id}
                      datacontent={sec.content}
                      dataindex={sec.index}
                    />
                    <label htmlFor={`${sec.name}`}>{sec.name}</label>
                  </div>
                ))}
            </div>
          </div>
          <div className={style.nextButtonContainer}>
            <button
              className={style.backButton}
              type="submit"
              onClick={() => navigate(`/backoffice/${area}`)}
            >
              Atras
            </button>
            {area == "especialidades" ? (
              input.kids === "false" &&
              input.adults === "false" ? null : input.name === "" ? null : (
                <button
                  disabled={
                    area === "especialidades"
                      ? input.sections === ""
                        ? true
                        : false
                      : area === "laboratorios"
                      ? inputLab.sections === ""
                        ? true
                        : false
                      : inputUni.sections === ""
                      ? true
                      : false
                  }
                  className={
                    area === "especialidades"
                      ? input.sections === ""
                        ? style.disabledNextButton
                        : style.nextButton
                      : area === "laboratorios"
                      ? inputLab.sections === ""
                        ? style.disabledNextButton
                        : style.nextButton
                      : inputUni.sections === ""
                      ? style.disabledNextButton
                      : style.nextButton
                  }
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                >
                  Siguiente
                </button>
              )
            ) : area == "laboratorios" ? (
              inputLab.name === "" ? null : (
                <button
                  disabled={
                    area === "especialidades"
                      ? input.sections === ""
                        ? true
                        : false
                      : area === "laboratorios"
                      ? inputLab.sections === ""
                        ? true
                        : false
                      : inputUni.sections === ""
                      ? true
                      : false
                  }
                  className={
                    area === "especialidades"
                      ? input.sections === ""
                        ? style.disabledNextButton
                        : style.nextButton
                      : area === "laboratorios"
                      ? inputLab.sections === ""
                        ? style.disabledNextButton
                        : style.nextButton
                      : inputUni.sections === ""
                      ? style.disabledNextButton
                      : style.nextButton
                  }
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                >
                  Siguiente
                </button>
              )
            ) : inputUni.name === "" ? null : (
              <button
                disabled={
                  area === "especialidades"
                    ? input.sections === ""
                      ? true
                      : false
                    : area === "laboratorios"
                    ? inputLab.sections === ""
                      ? true
                      : false
                    : inputUni.sections === ""
                    ? true
                    : false
                }
                className={
                  area === "especialidades"
                    ? input.sections === ""
                      ? style.disabledNextButton
                      : style.nextButton
                    : area === "laboratorios"
                    ? inputLab.sections === ""
                      ? style.disabledNextButton
                      : style.nextButton
                    : inputUni.sections === ""
                    ? style.disabledNextButton
                    : style.nextButton
                }
                type="submit"
                onClick={(e) => handleSubmit(e)}
              >
                Siguiente
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
