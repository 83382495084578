import { useState, useEffect } from "react";
import { Slide, Slideshow } from "../Slideshow/Slideshow";
import { useData } from "../../hooks/api";
import "./Statistics.css";

const Statistics = () => {
  const [estadisticas, setEstadisticas] = useState([]);

  const { data, error, isLoading } = useData("estadisticas");

  useEffect(() => {
    if (data) {
      setEstadisticas(data);
    }
  }, [data]);

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Error al cargar las estadísticas: {error.message}</div>;
  }

  return (
    <div className="container__statistics_main">
      <div className="container__statistics_content">
        <Slideshow>
          {estadisticas?.map((elem, index) => (
            <Slide key={index}>
              <div className="container__statistics_individual">
                <img
                  src={elem.img && elem.img}
                  alt={elem.name}
                  className="statistics__image"
                />
                <span>{elem.value}</span>
                <p>{elem.name}</p>
              </div>
              <div className="statistics__line"></div>
            </Slide>
          ))}
        </Slideshow>
        <div className="statistics__block"></div>
      </div>
    </div>
  );
};

export default Statistics;
