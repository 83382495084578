import institucional from "./Institucional.svg";
import curf from "./curf.svg";
import prof from "./prof.svg";
import rrhh from "./rrhh.svg";
import burbuja from "./burbuja.svg";
import esp from "./esp.svg";
import gineco from "./gineco.svg";
import guardiaadultos from "./guardiaadultos.svg";
import guardiap from "./guardiap.svg";
import guia from "./guia.svg";
import info from "./info.svg";
import tele from "./tele.svg";
import suero from "./suero.svg";
import miportal from "./miportal.svg";
import arrow from "./arrow.svg";
import close from "./close.svg";
import sedes from "./sedes.svg";
import cel from "./cel.svg";
import { useDispatch, useSelector } from "react-redux";
import { burgerMenu, setCategory } from "../../actions";
import "./MenuMovil.css";
import { Link } from "react-router-dom";
import setLocation from "../../actions/setLocation";

function MenuMovil() {
  let dispatch = useDispatch();
  let menu = useSelector((state) => state.rootReducer.burgerMenu);

  const handleMenu = () => {
    dispatch(burgerMenu("false"));
  };
  const handleCategory = (r) => {
    dispatch(setCategory(r));
    dispatch(burgerMenu("false"));
  };
  const handleSelect = (type) => {
    if (type) {
      dispatch(setLocation("mega"));
    }
    dispatch(burgerMenu("false"));
  };
  return (
    <div className={menu === "true" ? "father" : "father false"}>
      <div className="header">
        <h3 className="titleMenu">Menú</h3>
        <button
          className={menu === "true" ? "close" : "close none"}
          onClick={handleMenu}
        >
          <img src={close} alt="*" />
        </button>
      </div>
      <div className="categories">
        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                Nosotros
                <img className="arrow" src={arrow} alt="*" />
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <div className="cont">
                  <img className="logo" src={institucional} alt="*" />
                  <p className="titleOption">INSTITUCIONAL</p>
                </div>
                <div className="division" />
                <div className="contOpt">
                  <Link
                    to="/general/institucional/Historia"
                    className="opt"
                    onClick={handleSelect}
                  >
                    Quiénes somos - Historia
                  </Link>
                  <Link
                    to="/general/institucional/Mision"
                    className="opt"
                    onClick={handleSelect}
                  >
                    Misión , visión y valores
                  </Link>
                  <Link
                    to="/general/equipo/Autoridades"
                    className="opt"
                    onClick={handleSelect}
                  >
                    Nuestro equipo
                  </Link>
                  <Link
                    to="/sedes/Instalaciones?infraestructura=true"
                    className="opt"
                    onClick={handleSelect}
                  >
                    Infraestructura
                  </Link>
                  <Link
                    to="/noticias/Novedades%20institucionales"
                    className="opt"
                    onClick={handleSelect}
                  >
                    Novedades institucionales
                  </Link>
                </div>
                <div className="cont">
                  <img className="logo" src={prof} alt="*" />
                  <p className="titleOption">PROFESIONALES</p>
                </div>
                <div className="division" />
                <div className="contOpt">
                  <Link
                    to="/general/docencia/Informacion%20general"
                    className="opt"
                    onClick={handleSelect}
                  >
                    Docencia
                  </Link>
                  <Link
                    to="/general/comite/"
                    className="opt"
                    onClick={handleSelect}
                  >
                    Comités
                  </Link>
                  <Link
                    to="/noticias/Publicaciones%20académicas"
                    className="opt"
                    onClick={handleSelect}
                  >
                    Publicaciones académicas
                  </Link>
                </div>
                <div className="cont">
                  <img className="logo" src={rrhh} alt="*" />
                  <p className="titleOption">RECURSOS HUMANOS</p>
                </div>
                <div className="division" />
                <div className="contOpt">
                  <Link to="/trabajaConNosotros" onClick={handleSelect}>
                    <span className="opt">Trabajá con nosotros</span>
                  </Link>
                </div>
                <div className="cont">
                  <img className="logo" src={curf} alt="*" />
                  <p className="titleOption">COMUNIDAD CURF</p>
                </div>
                <div className="division" />
                <div className="contOpt">
                  <a href="https://comunidadcurf.clinicareinafabiola.com.ar/index.php/login/index"
                      target="_blank"
                      rel="noreferrer" className="opt">
                    Acceso exclusivo para el personal de la institución
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                Pacientes
                <img className="arrow" src={arrow} alt="*" />
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <div className="cont">
                  <img className="logo" src={info} alt="*" />
                  <p className="titleOption">INFORMACION GENERAL</p>
                </div>
                <div className="division" />
                <div className="contOpt">
                  <Link
                    to="/general/coberturas/Prepagas"
                    className="opt"
                    onClick={handleSelect}
                  >
                    Coberturas
                  </Link>
                  <Link
                    to="/noticias/Programa%20de%20buena%20salud"
                    className="opt"
                    onClick={handleSelect}
                  >
                    Programa de Buena Salud
                  </Link>
                  <Link
                    to="/noticias/Actividades%20para%20la%20comunidad"
                    className="opt"
                    onClick={handleSelect}
                  >
                    Actividades para la comunidad
                  </Link>
                  <Link
                    className="opt"
                    onClick={handleSelect}
                    to="/general/sugerencias/Agradecimientos"
                  >
                    Sugerencias y agradecimientos
                  </Link>
                </div>
                <div className="cont">
                  <img className="logo" src={esp} alt="*" />
                  <p className="titleOption">ESPECIALIDADES Y PROFESIONALES</p>
                </div>
                <div className="division" />
                <div className="contOpt">
                  <Link
                    to="/search/especialidades?sec=Adultos"
                    className="opt"
                    onClick={() => handleCategory("Adultos")}
                  >
                    Adultos
                  </Link>
                  <Link
                    to="/search/especialidades?sec=Pediátricos"
                    className="opt"
                    onClick={() => handleCategory("Pediátricos")}
                  >
                    Pediátricos
                  </Link>
                  <Link
                    to="/especialidades/Diagnóstico%20por%20imágenes/Sobre%20el%20servicio/adultos/CvsvECgwinB4GaFnOCX5"
                    className="opt"
                    onClick={handleSelect}
                  >
                    Diagnóstico por Imágenes
                  </Link>
                  <Link
                    to="/laboratorios/Informacion%20general"
                    className="opt"
                    onClick={() => handleSelect("lab")}
                  >
                    Laboratorios
                  </Link>
                  <Link
                    to="/search/unidadesAsistenciales?name=¿Qué%20son%20las%20unidades%20asistenciales?"
                    className="opt"
                    onClick={handleSelect}
                  >
                    Unidades asistenciales
                  </Link>
                </div>
                <div className="cont">
                  <img className="logo" src={guia} alt="*" />
                  <p className="titleOption">GUÍA DEL PACIENTE</p>
                </div>
                <div className="division" />
                <div className="contOpt">
                  <Link
                    to="/guiaDelPaciente"
                    className="opt"
                    onClick={handleSelect}
                  >
                    Acceso a la guía
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                Circuitos médicos
                <img className="arrow" src={arrow} alt="*" />
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <Link
                  to="/general/circuitosMedicos/Ginecologico"
                  className="link"
                  onClick={handleSelect}
                >
                  <div className="cont">
                    <img className="logo2" src={gineco} alt="*" />
                    <p className="titleOption2">GINECOLÓGICO</p>
                  </div>
                </Link>
                <Link
                  to="/general/circuitosMedicos/Integral"
                  className="link"
                  onClick={handleSelect}
                >
                  <div className="cont">
                    <img className="logo2" src={burbuja} alt="*" />
                    <p className="titleOption2">MÉDICO INTEGRAL</p>
                  </div>
                </Link>
                <Link
                  to="/general/circuitosMedicos/Prequirurgico"
                  className="link"
                  onClick={handleSelect}
                >
                  <div className="cont">
                    <img className="logo2" src={suero} alt="*" />
                    <p className="titleOption2">PRE QIRÚRGICO</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingFour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour"
                aria-expanded="false"
                aria-controls="flush-collapseFour"
              >
                Autogestión
                <img className="arrow" src={arrow} alt="*" />
              </button>
            </h2>
            <div
              id="flush-collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingFour"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <div className="contOpt">
                  <Link to="/MiPortal" className="link" onClick={handleSelect}>
                    <div className="cont">
                      <img className="logo2" src={miportal} alt="*" />
                      <p className="titleOption2">MI PORTAL</p>
                    </div>
                  </Link>
                  <Link
                    to="/Telemedicina"
                    className="link"
                    onClick={handleSelect}
                  >
                    <div className="cont">
                      <img className="logo2" src={tele} alt="*" />
                      <p className="titleOption2">TELEMEDICINA</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingFive">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFive"
                aria-expanded="false"
                aria-controls="flush-collapseFive"
              >
                Guardias
                <img className="arrow" src={arrow} alt="*" />
              </button>
            </h2>
            <div
              id="flush-collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingFive"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <Link
                  to="/general/guardiaDeAdultos/Central"
                  className="link"
                  onClick={handleSelect}
                >
                  <div className="cont">
                    <img className="logo2" src={guardiaadultos} alt="*" />
                    <p className="titleOption2">GUARDIA DE ADULTOS</p>
                  </div>
                </Link>
                <Link
                  to="/general/guardiaPediatrica/Descripción"
                  className="link"
                  onClick={handleSelect}
                >
                  <div className="cont">
                    <img className="logo2" src={guardiap} alt="*" />
                    <p className="titleOption2">GUARDIA PEDIÁTRICA</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="containerBtn">
        <Link to="/sedes/Oncativo" onClick={handleSelect}>
          <button className="boton ripple">
            <img src={sedes} alt="" className="btnLog" />
            <span>Sedes</span>
          </button>
        </Link>
        {/* <MyButton>
          <img src={sedes} alt="" className="btnLog" /> Sedes
        </MyButton> */}
        <a href="tel:(351)4142121">
          <button className="button ripple2">
            <img src={cel} alt="" className="btnLog" />
            <span>Contacto (351)4142121</span>
          </button>
        </a>
      </div>
    </div>
  );
}

export default MenuMovil;
