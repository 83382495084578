import { useState } from "react";
import BackofficeStatistics from "../Backoffice-Statistics/Backoffice-Statistics";
import BackofficeContactTel from "../Backoffice-ContactTel/Backoffice-ContactTel";
import Box from "@mui/material/Box";
import { BackofficeNav } from "../Backoffice-Nav/Backoffice-Nav";
import { BackofficeSidebar } from "../Backoffice-Sidebar/Backoffice-Sidebar";
import PhoneIcon from "@mui/icons-material/Phone";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import EmailIcon from "@mui/icons-material/Email";
import BackofficeEmail from "../Backoffice-Email/Backoffice-Email";
import "./Backoffice-Edit-Datos.css";


export default function BackofficeEditDatos() {
  let [select, setSelect] = useState("telefono");

  return (
    <Box sx={{ width: "100%" }}>
      <hr />
      <Box>
        <BackofficeNav />
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box>
          <BackofficeSidebar />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Box
            sx={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Box
              className="edit-icon-button-tel"
              onClick={() => {
                setSelect("telefono");
              }}
              sx={{ cursor: "pointer" }}
            >
              <PhoneIcon color="primary" />
            </Box>
            <Box
              className="edit-icon-button-email"
              onClick={() => {
                setSelect("email");
              }}
              sx={{ cursor: "pointer" }}
            >
              <EmailIcon color="error" />
            </Box>
            <Box
              className="edit-icon-button-estadisticas"
              onClick={() => {
                setSelect("estadisticas");
              }}
              sx={{ cursor: "pointer" }}
            >
              <LeaderboardIcon style={{color:"teal"}} />
            </Box>
          </Box>
          <hr />
          <article>
            {select === "estadisticas" ? (
              <BackofficeStatistics />
            ) : select === "telefono" ? (
              <BackofficeContactTel />
            ) : (
              <BackofficeEmail />
            )}
          </article>
        </Box>
      </Box>
    </Box>
  );
}
