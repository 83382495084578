import { useState, useEffect } from "react";
import styles from "./FormularioCircuitosMedicos.module.css";
import FormCircuitoGinecologico from "../FormCircuitoGinecologico/FormCircuitoGinecologico";
import FormPostCovid19 from "../FormPostCovid19/FormPostCovid19";
import FormMedicoIntegral from "../FormMedicoIntegral/FormMedicoIntegral";
import FormPrequirurgico from "../FormPrequirúgico/FormPrequirurgico";
import axios from "axios";

const FormularioCircuitosMedicos = ({ circuito = "Ginecológico" }) => {
  let [municipios, setMunicipios] = useState([]);

  // Funcion para traerme los municipios de la API del gobierno
  const getMunicipios = async () => {
    try {
      let response = await axios.get(
        "https://apis.datos.gob.ar/georef/api/municipios?provincia=14&campos=id,nombre&max=500"
      );
      let arrayAux = response.data.municipios.map((elem) => elem.nombre);
      arrayAux.sort((a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      });
      setMunicipios(arrayAux);
    } catch (e) {
      console.log("Error al traer las localidades: ", e);
    }
  };

  useEffect(() => {
    getMunicipios();
  }, []);
  return (
    <div className={styles.container__main}>
      <div className={styles.container__content}>
        <h2 className={styles.content_title}>Sacá tu turno</h2>
        {circuito === "Ginecologico" ? (
          <FormCircuitoGinecologico municipios={municipios} />
        ) : circuito === "Integral" ? (
          <FormMedicoIntegral municipios={municipios} />
        ) : circuito === "Prequirurgico" ? (
          <FormPrequirurgico />
        ) : circuito === "Covid" ? (
          <FormPostCovid19 municipios={municipios} />
        ) : (
          console.log(
            "Aqui se tendria que renderizar una pagina que no funciona"
          )
        )}
      </div>
    </div>
  );
};

export default FormularioCircuitosMedicos;
