import { useDispatch } from "react-redux";
import "./Backoffice-Modal.css";
import { BackofficeUploadFiles } from "../Backoffice-UploadFiles/Backoffice-UploadFiles";
import closeModalImage from "../../../../actions/backoffice-actions/closeModal";
import { ImageOutlined } from "@mui/icons-material";

export const BackofficeModal = () => {
  
  const dispatch = useDispatch();

  const handleModal = () => {
    dispatch(closeModalImage());
  };

  return (
    <div className="backoffice-modal-container">
      <article>
        <div className="modal-container">
          <header className="button-container">
            <button onClick={handleModal} className="modal-close">
              <ImageOutlined
                icon="dashicons:dismiss"
                width="40"
                height="40"
                color="red"
              />
            </button>
          </header>
          <div className="BackofficeUploadFiles">
            <BackofficeUploadFiles />
          </div>
        </div>
      </article>
    </div>
  );
};
