import { 
    USER_LOGOUT, 
    USER_LOGIN, 
    BURGER_MENU, 
    SECTION_SHOW, 
    GET_SPECIALITIES, 
    FILTER_SEARCH, 
    FILTER_SEARCH_BO, 
    SET_CATEGORY, 
    GET_LABORATORIES, 
    GET_UNITS, 
    RATING, 
    GET_PREPS, 
    GET_STUDIES, 
    GET_GUIDES, 
    GET_ADULTS_GUARDS, 
    GET_KIDS_GUARDS, 
    GET_ALL_SITES, 
    SWITCH_INIT, 
    SET_SEARCH_ID, 
    SET_SHOW, 
    GET_STAFF_MEDIC 
  } from "./constantes";
  
  export function userLogin(payload) {
    return {
      type: USER_LOGIN,
      payload,
    };
  }
  
  export function userLogout() {
    return {
      type: USER_LOGOUT,
    };
  }
  
  export function burgerMenu(payload) {
    return {
      type: BURGER_MENU,
      payload,
    };
  }
  
  export function sectionShow(payload) {
    return {
      type: SECTION_SHOW,
      payload,
    };
  }
  
  export function getSpecialities(payload) {
    return {
      type: GET_SPECIALITIES,
      payload,
    };
  }
  
  export function getLaboratories(payload) {
    return {
      type: GET_LABORATORIES,
      payload,
    };
  }
  
  export function getPreps(payload) {
    return {
      type: GET_PREPS,
      payload,
    };
  }
  
  export function getStudies(payload) {
    return {
      type: GET_STUDIES,
      payload,
    };
  }
  
  export function getUnits(payload) {
    return {
      type: GET_UNITS,
      payload,
    };
  }
  
  export function filterSearch(payload) {
    return {
      type: FILTER_SEARCH,
      payload,
    };
  }
  
  export function filterSearchBo(payload) {
    return {
      type: FILTER_SEARCH_BO,
      payload,
    };
  }
  
  export function changeRating(payload) {
    return {
      type: RATING,
      payload,
    };
  }
  
  export function setCategory(payload) {
    return {
      type: SET_CATEGORY,
      payload,
    };
  }
  
  export function setSearchId(payload) {
    return {
      type: SET_SEARCH_ID,
      payload,
    };
  }
  
  export function setShow(payload) {
    return {
      type: SET_SHOW,
      payload,
    };
  }
  
  // Nuevas acciones agregadas
  export function getStaffMedic(payload) {
    return {
      type: GET_STAFF_MEDIC,
      payload,
    };
  }
  
  export function getGuides(payload) {
    return {
      type: GET_GUIDES,
      payload,
    };
  }
  
  export function getAdultsGuards(payload) {
    return {
      type: GET_ADULTS_GUARDS,
      payload,
    };
  }
  
  export function getKidsGuards(payload) {
    return {
      type: GET_KIDS_GUARDS,
      payload,
    };
  }
  
  export function getAllSites(payload) {
    return {
      type: GET_ALL_SITES,
      payload,
    };
  }
  
  export function switchInitAction() {
    return {
      type: SWITCH_INIT,
    };
  }
  