import { NavBar } from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import { ContactForm } from "../../components/Form/Form";
import "./Contact.css";

export const Contact = () => {
  return (
    <article>
      <NavBar />
      <header>
        <h1>Contacto</h1>
      </header>
      <section className="form">
        <ContactForm />
      </section>
      <Footer />
    </article>
  );
};
