import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import style from "./Backoffice-NewInstallations.module.css";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { useDataById, useAddData, useEditData } from "../../../../hooks/api";

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = error => reject(error);
  });
};

export const BackofficeNewInstallations = () => {
  const [input, setInput] = useState({
    name: "",
    number: "",
    icon: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const { id } = useParams();
  const navigate = useNavigate();
  const { data: installationData, isLoading, error } = useDataById("instalaciones", id);
  const addDataMutation = useAddData("instalaciones");
  const editDataMutation = useEditData("instalaciones");

  useEffect(() => {
    if (installationData && id) {
      setInput(installationData);
      if (installationData.icon) {
        setPreviewUrl(installationData.icon);
      }
    }
  }, [installationData, id]);

  const handleChangeText = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value || "",
    });
  };

  const handleIcons = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setSelectedFile(file);
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = { ...input };
    
    if (selectedFile) {
      const base64File = await fileToBase64(selectedFile);
      formData = {
        ...formData,
        iconContent: base64File,
        iconName: selectedFile.name,
      };
    }

    if (id) {
      editDataMutation.mutate(formData, {
        onSuccess: () => {
          navigate("/backoffice/instalaciones");
        },
      });
    } else {
      addDataMutation.mutate(formData, {
        onSuccess: () => {
          navigate("/backoffice/instalaciones");
        },
      });
    }
  };

  const deleteIcon = (e) => {
    e.preventDefault();
    setInput({
      ...input,
      icon: "",
    });
    setPreviewUrl(null);
    setSelectedFile(null);
  };

  const open = (e) => {
    e.preventDefault();
    document.getElementById("file").click();
  };

  if (id && isLoading) {
    return <div>Cargando...</div>;
  }

  if (id && error) {
    return <div>Error al cargar los datos: {error.message}</div>;
  }

  return (
    <div className={style.container}>
      <form className={style.form} onSubmit={handleSubmit}>
        <div className={style.name}>
          <label className={style.nameLabel} htmlFor="name">
            Nombre
          </label>
          <input
            className={style.nameInput}
            type="text"
            required
            placeholder="Nombre"
            value={input.name || ""}
            name="name"
            onChange={handleChangeText}
          />
        </div>
        <div className={style.number}>
          <label className={style.numberLabel} htmlFor="number">
            Número
          </label>
          <input
            className={style.numberInput}
            type="text"
            required
            placeholder="Número"
            value={input.number || ""}
            name="number"
            onChange={handleChangeText}
          />
        </div>
        <div className={style.icon}>
          <label className={style.iconLabel} htmlFor="file">
            Ícono
          </label>
          <div className={style.inputContainer}>
            {!previewUrl && (
              <AddPhotoAlternateIcon
                className={style.iconElement}
                onClick={open}
              />
            )}
            <input
              className={style.iconInput}
              type="file"
              name="file"
              id="file"
              onChange={handleIcons}
            />
            {previewUrl && (
              <div className={style.cancel}>
                <CancelRoundedIcon
                  className={style.x}
                  onClick={deleteIcon}
                />
                <img className={style.img} src={previewUrl} alt="img" />
              </div>
            )}
          </div>
        </div>
        <div className={style.buttonContainer}>
          <button type="submit" className={style.createButton}>
            {id ? "Modificar" : "Crear"}
          </button>
        </div>
      </form>
    </div>
  );
};
