import style from "./BannerPatient.module.css";
import frame from "../../assets/Frame.svg";
import frame2 from "../../assets/Frame2.svg";
import agenda from '../../assets/agenda.svg';
import mask from './mask.png';

export const BannerPatient = () => {
  return (
    <div className={style.container}>
      <div style={{backgroundImage: `url(${mask})`}} className={style.mask}></div>
      <div className={style.filter}></div>
      <article className={style.containerElements}>
        <div className={style.frameContainer}>
          <img className={style.frame1} src={frame} alt="" />
        </div>
        <section className={style.responsive}>
        <div className={style.frameAndOtherContainers}>
            <section className={style.titleContainer}>
                <img className={style.agenda} src={agenda} alt="" />
                <h1 className={style.title}>Guía del Paciente</h1>
            </section>
          <img className={style.frame2} src={frame2} alt="" />
        </div>
        </section>
      </article>
    </div>
  );
};
