import { useDispatch } from 'react-redux';
import setComponent from "../../../../actions/backoffice-actions/setComponent";
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import './Backoffice-Nav-Image.css';

export const BackofficeNavImage = () => {

  const dispatch = useDispatch();

  const handleComponent = (payload) => {
    dispatch(setComponent(payload))
  };

  return (
    <>
      <nav className="backofficeNavImage-container">
        <ul className="backofficeNavImage-ul">
          <li className="backofficeNavImage-li">
            <ViewCarouselIcon style={{color: "white"}}/>
            <a onClick={() => handleComponent("carousel")} className="backofficeNavImage-a" href="#">
              Carrusel de Imágenes
            </a>
          </li>
          <li className="backofficeNavImage-li">
            <WallpaperIcon style={{color: "white"}}/>
            <a onClick={() => handleComponent("carouselMobile")} className="backofficeNavImage-a" href="#">
            Carrusel de Imágenes móvil
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};
