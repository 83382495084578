/* eslint-disable react/no-unknown-property */
import { useState } from "react";
import close from "./close.svg";
import { useSelector, useDispatch } from "react-redux";
import { sectionShow, setCategory } from "../../actions";
import style from "./ColumnaIzq.module.css";
import { useEffect } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import setSection from "../../actions/setSection";
import setContent from "../../actions/setContent";

const ColumnaIzq = ({ sec, sectionSug }) => {
  const navigate = useNavigate();
  let sections =
    sec === "especialidades"
      ? ["Adultos", "Pediátricos"]
      : sec === "unidadesAsistenciales" && [
          "¿Qué son las unidades asistenciales?",
          "Buscador de unidades asistenciales",
        ];
  let [active, setActive] = useState(sections[0]);
  let dispatch = useDispatch();
  let show = useSelector((state) => state.rootReducer.sectionShow);
  let units = useSelector((state) => state.rootReducer.units);
  let category = useSelector((state) => state.rootReducer.category);
  const [searchParams] = useSearchParams();
  let paramName = `${searchParams.get("name")}`;
  let content;
  //Funcion para manejar el hover de los filtros
  const handleClick = (e) => {
    let section = e.target.getAttribute("section");
    dispatch(setCategory(e.target.getAttribute("section")));
    setActive(section);
    dispatch(setSection(section));
    dispatch(setContent(content));
    closeSections();
    if (section === "Adultos" || section === "Pediátricos") {
      navigate(`/search/especialidades?sec=${section}`);
    }
  };

  const handleFilter = () => {
    return (content = units && units.filter((u) => u.name === paramName));
  };

  //Funcion para cerrar la ventana desplegable de secciones
  const closeSections = () => {
    dispatch(sectionShow(false));
  };

  const convertToQuery = (id) => {
    let result = units && units?.filter((unit) => unit.id === id);
    return result && result[0]?.sections[0]?.name;
  };

  useEffect(() => {
    dispatch(sectionShow(false));
    handleFilter();
  }, []);
  return (
    <div
      className={`${style.columnaizq__container_main} ${
        !show ? style.columnaizq__noshow : ""
      }`}
    >
      {sectionSug && (
        <div className={style.containerColumn}>
          <div className={style.columnaizq__container_content}>
            <h3 className={style.columnaizq__title_secciones}>Secciones</h3>
            <ul className={style.columnaizq__sections_container}>
              {sectionSug &&
                sectionSug?.map((sec, index) => {
                  return (
                    <li
                      key={index}
                      onClick={handleClick}
                      section={sec}
                      className={active === sec ? style.active : ""}
                    >
                      {sec}
                    </li>
                  );
                })}
            </ul>
          </div>
          <img
            src={close}
            alt="Close"
            className={style.close__button}
            onClick={closeSections}
          />
        </div>
      )}
      {sec ? (
        sec === "especialidades" ? (
          <div className={style.containerColumn}>
            <div className={style.columnaizq__container_content}>
              <h3 className={style.columnaizq__title_secciones}>Secciones</h3>
              <ul className={style.columnaizq__sections_container}>
                {sections &&
                  sections?.map((sec, index) => {
                    return (
                      <li
                        key={index}
                        onClick={handleClick}
                        section={sec}
                        className={
                          category === sec || paramName === sec
                            ? style.active
                            : ""
                        }
                      >
                        {sec}
                      </li>
                    );
                  })}
              </ul>
            </div>
            <img
              src={close}
              alt="Close"
              className={style.close__button}
              onClick={closeSections}
            />
          </div>
        ) : (
          <div>
            <div className={style.columnaizq__container_content}>
              <h3 className={style.columnaizq__title_secciones}>Secciones</h3>
              <ul className={style.columnaizq__sections_container}>
                {sections &&
                  sections?.map((sec, index) => {
                    return (
                      <Link
                        key={index}
                        to={`/search/unidadesAsistenciales?name=${sec}`}
                        style={{ textDecoration: "none" }}
                      >
                        <li
                          key={index}
                          onClick={handleClick}
                          section={sec}
                          className={active === sec ? style.active : ""}
                        >
                          {sec}
                        </li>
                      </Link>
                    );
                  })}
              </ul>
              <h3 className={style.columnaizq__subTitle}>
                Unidades principales
              </h3>
              <div className={style.divisor}></div>
              <ul className={style.columnaizq__sections_container}>
                {units &&
                  units
                    ?.filter((e) => e.name !== "default")
                    .filter(
                      (p) => p.name !== "¿Qué son las unidades asistenciales?"
                    )
                    .map((r, i) => {
                      return (
                        <Link
                          key={i}
                          to={`/${sec}/${r.name}/${convertToQuery(r.id)}`}
                          className="link"
                        >
                          <li
                            onClick={closeSections}
                            key={i}
                            className={active === sec ? style.active : ""}
                          >
                            {r.name}
                          </li>
                        </Link>
                      );
                    })}
              </ul>
            </div>
            <img
              src={close}
              alt="Close"
              className={style.close__button}
              onClick={closeSections}
            />
          </div>
        )
      ) : null}
    </div>
  );
};

export default ColumnaIzq;
