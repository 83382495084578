import { useEffect, useState, useCallback, useRef } from "react";
import styles from "./FormCircuitoGinecologico.module.css";
import subirArchivo from "./subirArchivo.svg";
import felchaAbajo from "./felchaAbajo.svg";
import archivoCargado from "./archivoCargado.svg";
import closeBtn from "./closeBtn.svg";
import Swal from "sweetalert2";
import successIcon from "./succesIcon.svg";
import axios from "axios";
import BounceLoader from 'react-spinners/BounceLoader';
const { REACT_APP_API_URL } = process.env;

const FormCircuitoGinecologico = ({ municipios }) => {
  let [disabled, setDisabled] = useState(true);
  let [input, setInput] = useState({});
  let [localidades, setLocalidades] = useState(municipios);
  let [resultadoLocalidades, setResultadoLocalidades] = useState(municipios);
  let [show, setShow] = useState(false);
  let [errors, setErrors] = useState({});
  let [archivos, setArchivos] = useState([]);
  let [loading, setLoading] = useState(false)
  let file = useRef();

  const handleChange = (event) => {
    if (event.target.name === "file") {
      let archivos = event.target.files;
      let aux = [];
      for (let clave in archivos) {
        aux.push(archivos[clave]);
      }
      aux.splice(-2);
      setArchivos(aux);
      setInput({ ...input, archivos: archivos });
    } else {
      setInput({ ...input, [event.target.name]: event.target.value });
      if (event.target.name === "localidad") {
        filtrarLocalidades(event.target.value);
      }
    }
  };

  const validate = () => {
    let erroresMensajes = {};
    if (!input.nombre) {
      erroresMensajes.nombre = "Debe ingresar un nombre";
    }
    if (!input.apellido) {
      erroresMensajes.apellido = "Debe ingresar un apellido";
    }
    if (!input.localidad) {
      erroresMensajes.localidad = "Debe seleccionar una localidad";
    }
    if (!input.email) {
      erroresMensajes.email = "Debe ingresar un email";
    }
    if (!input.telPrefijo || !input.telNumero) {
      erroresMensajes.telefono = "Debe ingresar un teléfono";
    }
    if (!input.fechaDeNacimiento) {
      erroresMensajes.fechaDeNacimiento = "Debe ingresar una fecha";
    }
    if (!input.obraSocial) {
      erroresMensajes.obraSocial = "Debe ingresar una obra social";
    }
    if (!input.dni) {
      erroresMensajes.dni = "Debe ingresar un DNI";
    }
    if (!input.mensaje) {
      erroresMensajes.mensaje = "Debe ingresar un mensaje";
    }
    if (!input.archivos) {
      erroresMensajes.archivos = "Debe ingresar un pedido médico";
    }
    return erroresMensajes;
  };

  const filtrarLocalidades = (termino) => {
    if (termino) {
      let resultadoLocalidad = localidades.filter((elem) =>
        elem.toLowerCase().includes(termino.toLowerCase())
      );
      setResultadoLocalidades(resultadoLocalidad);
    }
  };

  const handlerSubmit = async (e) => {
    e.preventDefault();
    let erroresMensajes = validate();
    if (!Object.keys(erroresMensajes).length) {
      let infoSend;
      setErrors(erroresMensajes);
      if (archivos.length > 0) {
        let auxArchivos = [];
        archivos.forEach((e) => auxArchivos.push(e.name));
        infoSend = {
          ...input,
          archivos: auxArchivos,
        };
      } else {
        infoSend = input;
      }

      let formData = new FormData();
      archivos.forEach((f) => {
        formData.append("file", f);
      });
      formData.append("infoData", JSON.stringify(infoSend));
      setLoading(true)
      try {
        await axios.post(`${REACT_APP_API_URL}/formularios/ginecologico`, formData, {
          headers: { "Content-type": "multipart/form-data" },
        });
        setLoading(false)
        Swal.fire({
          title: "Solicitud enviada con éxito",
          confirmButtonText: "Entendido",
          buttonsStyling: false,
          customClass: {
            popup: `${styles.containerAlert}`,
            confirmButton: `${styles.buttonConfirm}`,
            title: `${styles.titleAlert}`,
            htmlContainer: `${styles.containerHtml}`,
          },
          html: `<img src=${successIcon} />`,
        });
      } catch (e) {
        console.log("Error al hacer la peticion", e);
      }
      setInput({
        nombre: "",
        apellido: "",
        email: "",
        fechaDeNacimiento: "",
        obraSocial: "",
        telPrefijo: "",
        telNumero: "",
        dni: "",
        localidad: "",
        mensaje: "",
      });
      setArchivos([]);
    } else {
      setErrors(erroresMensajes);
      console.log("formulario con error");
    }
  };

  const alertRemoveFile = (name) => {
    Swal.fire({
      title: "¿Esta seguro que desea eliminar el archivo adjunto?",
      showCancelButton: true,
      confirmButtonText: "No",
      cancelButtonText: "Eliminar",
      buttonsStyling: false,
      customClass: {
        popup: `${styles.cancelAlert}`,
        title: `${styles.titleAlert}`,
        confirmButton: `${styles.no_btn}`,
        cancelButton: `${styles.eliminar_btn}`,
      },
    }).then((res) => {
      if (!res.value) {
        let aux = archivos.filter((f) => f.name !== name);
        setArchivos(aux);

        let list = new DataTransfer();
        Object.values(file.current.files).filter((f) => {
          if (f.name !== name) {
            list.items.add(f);
            return f;
          }
        });

        file.current.files = list.files;
      }
    });
  };

  const disabledButton = useCallback(() => {
    if (
      !input.nombre ||
      !input.apellido ||
      !input.email ||
      !input.fechaDeNacimiento ||
      !input.localidad ||
      !input.obraSocial ||
      !input.telPrefijo ||
      !input.telNumero ||
      !input.mensaje ||
      !input.archivos ||
      !input.dni
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [input]);

  useEffect(() => {
    setLocalidades(municipios);
    setResultadoLocalidades(municipios);
  }, [municipios]);

  useEffect(() => {
    disabledButton();
  }, [input, disabledButton]);

  return (
    <form className={styles.container__form} onSubmit={handlerSubmit}>
      <div
        className={`${styles.container__label_input} ${styles.container__nombre}`}
      >
        <label htmlFor="nombre">Nombre</label>
        <input
          type="text"
          name="nombre"
          id="nombre"
          placeholder="Ej: María"
          onChange={handleChange}
          value={input.nombre}
          autoComplete="off"
          className={input.nombre ? styles.not__empty : null}
        />
        {errors.nombre && (
          <span className={styles.error__message}>{errors.nombre}</span>
        )}
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__apellido}`}
      >
        <label htmlFor="apellido">Apellido</label>
        <input
          type="text"
          name="apellido"
          id="apellido"
          placeholder="Ej: Romero"
          onChange={handleChange}
          value={input.apellido}
          autoComplete="off"
          className={input.apellido ? styles.not__empty : null}
        />
        {errors.apellido && (
          <span className={styles.error__message}>{errors.apellido}</span>
        )}
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__dni}`}
      >
        <label htmlFor="dni">DNI</label>
        <input
          type="number"
          name="dni"
          id="dni"
          placeholder="Ej: 99.999.999"
          onChange={handleChange}
          autoComplete="off"
          className={input.dni ? styles.not__empty : null}
          value={input.dni}
        />
        {errors.dni && (
          <span className={styles.error__message}>{errors.dni}</span>
        )}
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__email}`}
      >
        <label htmlFor="email">E-mail</label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="midireccion@mail.com"
          onChange={handleChange}
          value={input.email}
          autoComplete="off"
          className={input.email ? styles.not__empty : null}
        />
        {errors.email && (
          <span className={styles.error__message}>{errors.email}</span>
        )}
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__localidad}`}
      >
        <label htmlFor="localidad">Localidad</label>
        <input
          type="text"
          name="localidad"
          id="localidad"
          placeholder="Selecciona una localidad"
          onChange={handleChange}
          value={input.localidad}
          onClick={() => setShow(true)}
          onBeforeInput={() => setShow(true)}
          autoComplete="off"
          onBlur={() => {
            setTimeout(() => {
              setShow(false);
            }, 100);
          }}
          className={input.localidad ? styles.not__empty : null}
        />
        {errors.localidad && (
          <span className={styles.error__message}>{errors.localidad}</span>
        )}
        <img
          src={felchaAbajo}
          alt="felcha abajo"
          className={`${styles.icono__flecha} ${
            show && styles.invertir__chevron
          }`}
          onClick={() => setShow(true)}
        />
        <ul
          className={`${styles.container__localidades_resultados} ${
            !show && styles.noshow_localidades
          }`}
        >
          {resultadoLocalidades?.map((elem, index) => (
            <li
              key={index}
              onClick={() => {
                setInput({ ...input, localidad: elem });
                setShow(false);
              }}
            >
              {elem}
            </li>
          ))}
        </ul>
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__telefono}`}
      >
        <label htmlFor="teléfono">Teléfono</label>
        <div className={styles.container__telefono_input}>
          <input
            type="number"
            name="telPrefijo"
            id="teléfono"
            placeholder="Prefijo"
            className={`${styles.telefono__prefijo} ${
              input.telPrefijo ? styles.not__empty : null
            }`}
            onChange={handleChange}
            value={input.telPrefijo}
            autoComplete="off"
          />
          <input
            type="number"
            name="telNumero"
            id="teléfono"
            placeholder="Número"
            className={`${styles.telefono__numero} ${
              input.telNumero ? styles.not__empty : null
            }`}
            onChange={handleChange}
            value={input.telNumero}
            autoComplete="off"
          />
          {errors.telefono && (
            <span className={styles.error__message}>{errors.telefono}</span>
          )}
        </div>
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__fechaDeNacimiento}`}
      >
        <label htmlFor="fechaDeNacimiento">Fecha de nacimiento</label>
        <input
          type="date"
          name="fechaDeNacimiento"
          id="fechaDeNacimiento"
          placeholder="asdasd"
          onChange={handleChange}
          value={input.fechaDeNacimiento}
          autoComplete="off"
          className={input.fechaDeNacimiento ? styles.not__empty : null}
        />
        {errors.fechaDeNacimiento && (
          <span className={styles.error__message}>
            {errors.fechaDeNacimiento}
          </span>
        )}
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__obraSocial}`}
      >
        <label htmlFor="obraSocial">Obra social</label>
        <input
          type="text"
          name="obraSocial"
          id="obraSocial"
          placeholder="Nombre y plan de tu obra social"
          onChange={handleChange}
          value={input.obraSocial}
          autoComplete="off"
          className={input.obraSocial ? styles.not__empty : null}
        />
        {errors.obraSocial && (
          <span className={styles.error__message}>{errors.obraSocial}</span>
        )}
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__mensaje}`}
      >
        <label htmlFor="mensaje">Mensaje</label>
        <textarea
          name="mensaje"
          id="mensaje"
          cols="30"
          rows="3"
          placeholder="Dejanos tu mensaje"
          onChange={handleChange}
          value={input.mensaje}
          autoComplete="off"
          className={input.mensaje ? styles.not__empty : null}
        ></textarea>
        {errors.mensaje && (
          <span className={styles.error__message}>{errors.mensaje}</span>
        )}
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__pedidos}`}
      >
        <label>Adjuntar archivo</label>

        <input
          type="file"
          name="file"
          id="file"
          onChange={handleChange}
          multiple
          ref={file}
        />

        {!archivos.length > 0 ? (
          <label htmlFor="file" className={styles.label__custom}>
            <img src={subirArchivo} alt="subir archivo" />
            <span>Seleccionar</span>
          </label>
        ) : (
          archivos.map((f, index) => (
            <div className={styles.container__label_archivoCargado} key={index}>
              <img
                src={archivoCargado}
                alt="cargado archivo"
                className={styles.archivoCargado__btn}
              />
              <span>{f.name}</span>
              <img
                src={closeBtn}
                alt="close button"
                className={styles.archivoCargado__btn_close}
                onClick={() => {
                  alertRemoveFile(f.name);
                }}
              />
            </div>
          ))
        )}
        {errors.archivos && (
          <span className={styles.error__message}>{errors.archivos}</span>
        )}
      </div>
      {loading ?
      <div className={styles.loadingContainer}>
        <BounceLoader color="#002856" /> 
      </div>
      :
      <button
        type="submit"
        className={`${styles.btn__enviar} ${disabled ? styles.disabled : null}`}
      >
        Enviar solicitud
      </button>
}
    </form>
  );
};

export default FormCircuitoGinecologico;
