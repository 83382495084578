import { useState, useEffect, useCallback } from "react";
import style from "./FormSugerencias.module.css";
import Swal from "sweetalert2";
import successIcon from "./succesIcon.svg";
import flechaSelect from "./flecha select.svg";
import Axios from "axios";
import BounceLoader from "react-spinners/BounceLoader";
const { REACT_APP_API_URL } = process.env;

const FormSugerencias = () => {
  let [disabled, setDisabled] = useState(true);
  let [input, setInput] = useState({
    nombre: "",
    apellido: "",
    email: "",
    fechaDelSuceso: "",
    lugar: "",
    sector: "",
    telPrefijo: "",
    telNumero: "",
    mensaje: "",
    dni: "",
  });
  let [errors, setErrors] = useState({});
  let [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  const validate = () => {
    let erroresMensajes = {};
    if (!input.nombre) {
      erroresMensajes.nombre = "Debe ingresar un nombre";
    }
    if (!input.apellido) {
      erroresMensajes.apellido = "Debe ingresar un apellido";
    }
    if (!input.email) {
      erroresMensajes.email = "Debe ingresar un email";
    }
    if (!input.telPrefijo || !input.telNumero) {
      erroresMensajes.telefono = "Debe ingresar un teléfono";
    }
    if (!input.fechaDelSuceso) {
      erroresMensajes.fechaDelSuceso = "Debe ingresar una fecha";
    }
    if (!input.sector) {
      erroresMensajes.sector = "Debe ingresar un sector";
    }
    if (!input.dni) {
      erroresMensajes.dni = "Debe ingresar un DNI";
    }
    if (!input.lugar) {
      erroresMensajes.lugar = "Debe ingresar un lugar";
    }
    if (!input.mensaje) {
      erroresMensajes.mensaje = "Debe ingresar un mensaje";
    }

    return erroresMensajes;
  };

  const handlerSubmit = async (e) => {
    e.preventDefault();
    let erroresMensajes = validate();
    if (!Object.keys(erroresMensajes).length) {
      setErrors(erroresMensajes);
      const infoSend = input;
      let formData = new FormData();
      formData.append("infoData", JSON.stringify(infoSend));
      setLoading(true);
      try {
        await Axios.post(
          `${REACT_APP_API_URL}/formularios/sugerencias`,
          formData,
          {
            headers: { "Content-type": "multipart/form-data" },
          }
        );
        setLoading(false);
        Swal.fire({
          title: "Solicitud enviada con éxito",
          confirmButtonText: "Entendido",
          buttonsStyling: false,
          customClass: {
            popup: `${style.containerAlert}`,
            confirmButton: `${style.buttonConfirm}`,
            title: `${style.titleAlert}`,
            htmlContainer: `${style.containerHtml}`,
          },
          html: `<img src=${successIcon} />`,
        });
        setInput({
          nombre: "",
          apellido: "",
          email: "",
          fechaDelSuceso: "",
          lugar: "",
          sector: "",
          telPrefijo: "",
          telNumero: "",
          mensaje: "",
          dni: "",
        });
      } catch (e) {
        console.log("Error al mandar el formulario", e);
      }
    } else {
      setErrors(erroresMensajes);
      console.log("formulario con error");
    }
  };

  const disabledButton = useCallback(() => {
    if (
      !input.nombre ||
      !input.apellido ||
      !input.email ||
      !input.fechaDelSuceso ||
      !input.lugar ||
      !input.sector ||
      !input.telPrefijo ||
      !input.telNumero ||
      !input.mensaje ||
      !input.dni
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [input]);

  useEffect(() => {
    disabledButton();
  }, [input, disabledButton]);
  return (
    <>
      <h3 className={style.title__form}>Dejanos tu mensaje</h3>
      <form className={style.container__form} onSubmit={handlerSubmit}>
        <div
          className={`${style.container__label_input} ${style.container__nombre}`}
        >
          <label htmlFor="nombre">Nombre</label>
          <input
            type="text"
            name="nombre"
            id="nombre"
            placeholder="Ej: María"
            onChange={handleChange}
            value={input.nombre}
            autoComplete="off"
            className={input.nombre ? style.not__empty : null}
          />
          {errors.nombre && (
            <span className={style.error__message}>{errors.nombre}</span>
          )}
        </div>
        <div
          className={`${style.container__label_input} ${style.container__apellido}`}
        >
          <label htmlFor="apellido">Apellido</label>
          <input
            type="text"
            name="apellido"
            id="apellido"
            placeholder="Ej: Romero"
            onChange={handleChange}
            value={input.apellido}
            autoComplete="off"
            className={input.apellido ? style.not__empty : null}
          />
          {errors.apellido && (
            <span className={style.error__message}>{errors.apellido}</span>
          )}
        </div>

        <div
          className={`${style.container__label_input} ${style.container__telefono}`}
        >
          <label htmlFor="teléfono">Teléfono</label>
          <div className={style.container__telefono_input}>
            <input
              type="number"
              name="telPrefijo"
              id="teléfono"
              placeholder="Prefijo"
              className={`${style.telefono__prefijo} ${
                input.telPrefijo ? style.not__empty : null
              }`}
              onChange={handleChange}
              value={input.telPrefijo}
              autoComplete="off"
            />
            <input
              type="number"
              name="telNumero"
              id="teléfono"
              placeholder="Número"
              className={`${style.telefono__numero} ${
                input.telNumero ? style.not__empty : null
              }`}
              onChange={handleChange}
              value={input.telNumero}
              autoComplete="off"
            />
            {errors.telefono && (
              <span className={style.error__message}>{errors.telefono}</span>
            )}
          </div>
        </div>
        <div
          className={`${style.container__label_input} ${style.container__dni}`}
        >
          <label htmlFor="dni">DNI</label>
          <input
            type="number"
            name="dni"
            id="dni"
            value={input.dni}
            placeholder="Ej: 99.999.999"
            onChange={handleChange}
            autoComplete="off"
            className={input.dni ? style.not__empty : null}
          />
          {errors.dni && (
            <span className={style.error__message}>{errors.dni}</span>
          )}
        </div>
        <div
          className={`${style.container__label_input} ${style.container__email}`}
        >
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="midireccion@mail.com"
            onChange={handleChange}
            value={input.email}
            autoComplete="off"
            className={input.email ? style.not__empty : null}
          />
          {errors.email && (
            <span className={style.error__message}>{errors.email}</span>
          )}
        </div>

        <div
          className={`${style.container__label_input} ${style.container__fechaDelSuceso}`}
        >
          <label htmlFor="fechaDelSuceso">
            Fecha en la que aconteció el suceso vinculado a su sugerencia (si no
            la recuerda con exactitud, indique la fecha estimada)
          </label>
          <input
            type="date"
            name="fechaDelSuceso"
            id="fechaDelSuceso"
            onChange={handleChange}
            value={input.fechaDelSuceso}
            autoComplete="off"
            className={input.fechaDelSuceso ? style.not__empty : null}
          />
          {errors.fechaDelSuceso && (
            <span className={style.error__message}>
              {errors.fechaDelSuceso}
            </span>
          )}
        </div>
        <div
          className={`${style.container__label_input} ${style.container__sector}`}
        >
          <label htmlFor="sector">
            Escriba el nombre del sector sobre el cual desea realizar la
            sugerencia
          </label>
          <input
            type="text"
            name="sector"
            id="sector"
            placeholder="Nombre del sector"
            onChange={handleChange}
            value={input.sector}
            autoComplete="off"
            className={input.sector ? style.not__empty : null}
          />
          {errors.sector && (
            <span className={style.error__message}>{errors.sector}</span>
          )}
        </div>
        <div
          className={`${style.container__label_input} ${style.container__lugar}`}
        >
          <label htmlFor="lugar">
            Seleccione el lugar de donde realiza la sugerencia
          </label>
          <select
            name="lugar"
            id="lugar"
            onChange={handleChange}
            value={input.lugar}
          >
            <option disabled selected value="">
              Ninguna opción seleccionada.
            </option>
            <option value="Centro de rehabilitación y Fisioterapia - Suipacha 1139">
              Centro de rehabilitación y Fisioterapia - Suipacha 1139
            </option>
            <option value="Consultorios externos - Jacinto Ríos 554">
              Consultorios externos - Jacinto Ríos 554
            </option>
            <option value="Consultorios externos - Paseo del Jockey">
              Consultorios externos - Paseo del Jockey
            </option>
            <option value="Sede central - Oncativo 1248 - Pacientes ambulatorios">
              Sede central - Oncativo 1248 - Pacientes ambulatorios
            </option>
            <option value="Sede central - Oncativo 1248 - Pacientes internados">
              Sede central - Oncativo 1248 - Pacientes internados
            </option>
            <option value="Audiología clínica y de alta complejidad - Catamarca 1248">
              Audiología clínica y de alta complejidad - Catamarca 1248
            </option>
            <option value="Telemedicina">Telemedicina</option>
            <option value="Sitio web">Sitio web</option>
          </select>
          {errors.lugar && (
            <span className={style.error__message}>{errors.lugar}</span>
          )}
          <img
            src={flechaSelect}
            alt="flecha select"
            className={style.flecha_select}
          />
        </div>
        <div
          className={`${style.container__label_input} ${style.container__mensaje}`}
        >
          <label htmlFor="mensaje">Describa detalladamente su mensaje</label>
          <textarea
            name="mensaje"
            id="mensaje"
            cols="30"
            rows="10"
            placeholder="Dejanos tu mensaje"
            onChange={handleChange}
            value={input.mensaje}
            autoComplete="off"
            className={input.mensaje ? style.not__empty : null}
          ></textarea>
          {errors.mensaje && (
            <span className={style.error__message}>{errors.mensaje}</span>
          )}
        </div>
        {loading ? (
          <div className={style.loadingContainer}>
            <BounceLoader color="#002856" />
          </div>
        ) : (
          <button
            type="submit"
            className={`${style.btn__enviar} ${
              disabled ? style.disabled : null
            }`}
          >
            Enviar
          </button>
        )}
      </form>
    </>
  );
};

export default FormSugerencias;
