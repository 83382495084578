import styled from "styled-components";

export const LoginButton = styled.button`
  border-radius: 1em;
  width: 18em;
  margin: 0.2em;
  font-size: 0.9em;
  border: none;
  &:hover {
    background: black;
    color: lightgray;
  }
  &:active {
    color: black;
    background-color: lightgray;
  }
`;
