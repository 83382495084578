import "./NavBarResponsive.css";

export const NavBarResponsive = () => {
  return (
    <>
      <button id="turno">
        <a
          style={{ textDecoration: "none", color: "white" }}
          href={"http://turnos.curf.com.ar"}
        >
          Sacar un turno
        </a>
      </button>
    </>
  );
};
