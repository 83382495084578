import { useEffect, useRef, useCallback, useState } from "react";
import styled from "styled-components";

const Slideshow = ({ children, velocidad = "500", intervalo = "5000" }) => {
  const slideshow = useRef(null);
  const intervaloSlideShow = useRef(null);
  const [width, setWidth] = useState(window.innerWidth);

  const siguiente = useCallback(() => {
    if (width <= 607) {
      //Animacion para MOVIL
      //Comprobamos que el slideshow tenga elementos
      if (slideshow.current.children) {
        if (slideshow.current.children.length > 3) {
          //Obtenemos el primer elemento
          const primerElemento = slideshow.current.children[0];
          //Establecemos la transicion
          slideshow.current.style.transition = `${velocidad}ms ease-out all`;
          const tamañoSlide = slideshow.current.children[0].offsetWidth;
          //Movemos el slideshow
          slideshow.current.style.transform = `translateX(-${tamañoSlide}px)`;

          const transicion = () => {
            //Reiniciamos la posicion del slide
            slideshow.current.style.transition = `none`;
            slideshow.current.style.transform = `translateX(0)`;

            //Tomamos el primer elemento y lo mandamos al final.
            slideshow.current.appendChild(primerElemento);

            // slideshow.current.removeEventListener("transitionend", transicion);
          };
          //Eventlistener para cuando termine la animacion.
          slideshow.current.addEventListener("transitionend", transicion);
        }
      }
    } else if (width <= 1175 && width >= 608) {
      //Animacion para TABLET
      //Comprobamos que el slideshow tenga elementos
      if (slideshow.current.children.length > 3) {
        //Obtenemos los primeros 4 elementos
        const primerElemento = slideshow.current.children[0];
        const segundoElemento = slideshow.current.children[1];
        const tercerElemento = slideshow.current.children[2];

        //Establecemos la transicion
        slideshow.current.style.transition = `${velocidad}ms ease-out all`;

        //Movemos el slideshow
        slideshow.current.style.opacity = 0;

        const transicion = () => {
          //Reiniciamos la posicion del slide
          slideshow.current.style.opacity = 1;
          slideshow.current.style.transition = `${velocidad}ms ease-out all`;
          // slideshow.current.style.transform = `translateY(0)`;

          //Tomamos el primer elemento y lo mandamos al final.
          slideshow.current.appendChild(primerElemento);
          slideshow.current.appendChild(segundoElemento);
          slideshow.current.appendChild(tercerElemento);

          // slideshow.current.removeEventListener("transitionend", transicion);
        };
        //Eventlistener para cuando termine la animacion.
        slideshow.current.addEventListener("transitionend", transicion);
      }
    } else {
      //Animacion para PC
      if (slideshow.current.children.length > 4) {
        //Obtenemos los primeros 4 elementos
        const primerElemento = slideshow.current.children[0];
        const segundoElemento = slideshow.current.children[1];
        const tercerElemento = slideshow.current.children[2];
        const cuartoElemento = slideshow.current.children[3];

        //Establecemos la transicion
        slideshow.current.style.transition = `${velocidad}ms ease-out all`;

        //Movemos el slideshow
        slideshow.current.style.opacity = 0;

        const transicion = () => {
          //Reiniciamos la posicion del slide
          slideshow.current.style.opacity = 1;
          slideshow.current.style.transition = `${velocidad}ms ease-out all`;
          // slideshow.current.style.transform = `translateY(0)`;

          //Tomamos el primer elemento y lo mandamos al final.
          slideshow.current.appendChild(primerElemento);
          slideshow.current.appendChild(segundoElemento);
          slideshow.current.appendChild(tercerElemento);
          slideshow.current.appendChild(cuartoElemento);

          // slideshow.current.removeEventListener("transitionend", transicion);
        };
        //Eventlistener para cuando termine la animacion.
        slideshow.current.addEventListener("transitionend", transicion);
      }
    }
  }, [velocidad, width]);

  useEffect(() => {
    //Establecemos el intervalo
    intervaloSlideShow.current = setInterval(() => {
      siguiente();
    }, intervalo);
    return () => clearInterval(intervaloSlideShow.current);
  }, []);

  const handleResize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return (
    <ContenedorPrincipal>
      {children && (
        <ContenedorSlideshow ref={slideshow}>{children}</ContenedorSlideshow>
      )}
    </ContenedorPrincipal>
  );
};

const ContenedorPrincipal = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1175px) {
    width: 90%;
  }
  @media screen and (max-width: 791px) {
    width: 100%;
  }
  @media screen and (max-width: 607px) {
    width: 100%;
    justify-content: center;
  }
`;
const ContenedorSlideshow = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  height: 170px;
  width: 100%;
  position: relative;
  left: 15px;
  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (max-width: 1175px) {
    height: 133px;
    left: 10px;
  }
  @media screen and (max-width: 791px) {
    left: 0px;
  }
  @media screen and (max-width: 607px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    top: 6px;
    height: fit-content;
  }
`;
const Slide = styled.div`
  transition: 0.3s ease all;
  z-index: 9;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { Slideshow, Slide };
