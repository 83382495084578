import { useAddData } from '../hooks/api';

export const useSendUserExperience = () => {
  const addUserExperienceMutation = useAddData('userExperience');

  const sendUserExperience = async (input) => {
    try {
      await addUserExperienceMutation.mutateAsync(input);
    } catch (e) {
      console.error('Error al guardar la reseña', e);
    }
  };

  return {
    sendUserExperience,
    isLoading: addUserExperienceMutation.isLoading,
    error: addUserExperienceMutation.error,
  };
};
