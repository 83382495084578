import { useState, useEffect } from "react";
import style from "./Backoffice-News.module.css";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import BackofficeFilter from "../Backoffice-Filter/BackofficeFilter";
import { useData, useDeleteData, useEditMultipleData } from "../../../../hooks/api";

export const BackofficeNews = () => {
  const [rows, setRows] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [filterSpecialty, setFilterSpecialty] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const navigate = useNavigate();

  const { data: news } = useData("news");
  const deleteNewsMutation = useDeleteData("news");
  const editMultipleDataMutation = useEditMultipleData("news");

  const handleSelect = (e) => {
    const value = e.target.value;
    if (value === "all") {
      setFilterSpecialty(news);
    } else {
      const filter =
        news &&
        news?.filter((news) => news.specialty.some((e) => e.id === value));
      setFilterSpecialty(
        filter?.sort((a, b) => (a.date > b.date ? -1 : b.date > a.date ? 1 : 0))
      );
    }
  };

  const deleteNews = async (id) => {
    try {
      await deleteNewsMutation.mutateAsync(id);
    } catch (e) {
      console.error("El usuario no ha podido ser eliminado");
    }
  };

  const activeItems = async (active) => {
    const itemsToUpdate = selectionModel.map((id) => {
      const item = news.find((newsItem) => newsItem.id === id);
      return { ...item, active };
    });

    try {
      await editMultipleDataMutation.mutateAsync(itemsToUpdate);
    } catch (e) {
      console.error("Error actualizando los registros");
    }
  };

  const adultsOrKids = (id) => {
    let filters = specialties?.filter(
      (e) => e.name !== "Diagnóstico por imágenes"
    );
    let adults = filters?.filter((e) => e.id === id);
    if (adults[0]?.adults === "false" && adults[0]?.kids === "false") {
      return "";
    } else {
      if (adults[0]?.adults === "true") {
        return "Adulto";
      } else {
        return "Pediátrico";
      }
    }
  };

  const getByQuery = (query) => {
    let results = specialties?.filter((specialty) => specialty.id === query);
    return results.length ? results[0] : null;
  };

  useEffect(() => {
    if (news) {
      setSpecialties(
        news.map((n) => n.specialty[0]).filter((specialty) => specialty)
      );
      setFilterSpecialty(
        news?.sort((a, b) =>
          a.date > b.date ? -1 : b.date > a.date ? 1 : 0
        )
      );
    }
  }, [news]);

  useEffect(() => {
    setRows(
      filterSpecialty &&
        filterSpecialty?.map((u) => ({
          id: u.id,
          title: u.title,
          resume: u.resume,
          area: u.area,
          specialty: u.specialty,
          date: u.date,
          featured: u.featured,
          active: u.active,
        }))
    );
  }, [filterSpecialty]);

  const columns = [
    {
      field: "title",
      headerName: "Título",
      width: 200,
      alignHeader: "center",
    },
    {
      field: "area",
      headerName: "Área",
      width: 200,
      renderCell: (params) => (
        <div className="check">
          {params.row.area.length > 0
            ? params.row.area?.map((e, index) => <span key={index}>{e}</span>)
            : null}
        </div>
      ),
    },
    {
      field: "specialty",
      headerName: "Asociado a...",
      width: 225,
      renderCell: (params) => (
        <div className="selectSections">
          {params.row.specialty.length
            ? params.row.specialty.map((e, index) =>
                getByQuery(e.id)?.adults === "true" ||
                getByQuery(e.id)?.adults === "false" ? (
                  <span key={index}>{`${e.name} (${adultsOrKids(
                    e.id
                  )}) - `}</span>
                ) : // eslint-disable-next-line no-prototype-builtins
                getByQuery(e.id)?.hasOwnProperty("principal") ? (
                  <span key={index}>{`${e.name} - `}</span>
                ) : // eslint-disable-next-line no-prototype-builtins
                getByQuery(e.id)?.hasOwnProperty("profession") ? (
                  <span>{`${e.name} - (${getByQuery(e.id)?.specialty?.map(
                    (s) => `${getByQuery(s)?.name} (${adultsOrKids(s)})`
                  )})`}</span>
                ) : (
                  <span key={index}>{`${e.name} - `}</span>
                )
              )
            : null}
        </div>
      ),
    },
    {
      field: "date",
      headerName: "Fecha",
      width: 100,
      alignHeader: "center",
    },
    {
      field: "featured",
      headerName: "Destacada",
      width: 100,
      alignHeader: "center",
      renderCell: (params) => (
        <div className={style.checkContainer}>
          {params.row.featured === "true" ? (
            <CheckCircleIcon className={style.checkIcon} />
          ) : (
            <CancelIcon className={style.cancelIcon} />
          )}
        </div>
      ),
    },
    {
      field: "active",
      headerName: "Activa",
      width: 80,
      alignHeader: "center",
      renderCell: (params) => (
        <div className={style.checkContainer}>
          {params.row.active === true ? (
            <CheckCircleIcon className={style.checkIcon} />
          ) : (
            <CancelIcon className={style.cancelIcon} />
          )}
        </div>
      ),
    },
    {
      field: "",
      headerName: "Editar/Borrar",
      width: 150,
      renderCell: (params) => (
        <div className="delete-edit-button">
          <Link
            style={{ textDecoration: "none" }}
            to={`/backoffice/noticias/editar/${params.row.id}`}
          >
            <button className="userListEdit">Edit</button>
          </Link>
          <DeleteOutlinedIcon
            className="userListDelete"
            onClick={() =>
              window.confirm("Está seguro que desea eliminar este item?")
                ? deleteNews(params.row.id)
                : null
            }
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className={style.newsContainer}>
        <div className={style.createAndFilter}>
          <button
            onClick={() => navigate(`/backoffice/noticias/crear`)}
            className={style.newButtonCreate}
          >
            Crear
          </button>
          <button
            onClick={() => activeItems(true)}
            className={style.newButtonActive}
          >
            Activar
          </button>
          <button
            onClick={() => activeItems(false)}
            className={style.newButtonActiveOff}
          >
            Desactivar
          </button>
          <div>
            <BackofficeFilter
              className={style.selectSpecialty}
              specialties={specialties || []}
              staff={[]}
              labs={[]}
              units={[]}
              handlerChange={handleSelect}
            />
          </div>
        </div>
        <div className={style.datagridContainer}>
          <DataGrid
            rows={rows}
            pageSize={10}
            checkboxSelection
            onRowSelectionModelChange={(newSelectionModel) => {
              console.log('Selected rows: ', newSelectionModel);
              setSelectionModel(newSelectionModel);
            }}
            columns={columns}
            selectionModel={selectionModel}
          />
        </div>
      </div>
    </>
  );
};
