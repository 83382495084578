import {
  GET_NEWS,
  USER_LOGIN,
  USER_LOGOUT,
  SET_SHOW,
  SET_VALUE,
  BURGER_MENU,
  CURRENT_SPECIALTY,
  SECTION_SHOW,
  SET_SECTION,
  SET_AREA,
  SET_CONTENT,
  SET_PAGINATION,
  INCREASE_PAGE,
  GET_SPECIALITIES,
  GET_LABORATORIES,
  GET_UNITS,
  FILTER_SEARCH,
  SET_CATEGORY,
  RATING,
  SWITCH_INIT,
  SET_LOCATION,
  GET_PREPS,
  GET_STUDIES,
  GET_GUIDES,
  GET_ADULTS_GUARDS,
  GET_KIDS_GUARDS,
  CLEAR_CATEGORY,
  CLEAR_CONTENT,
  CLEAR_SECTION,
  CLEAR_LOCATION,
} from "../actions/constantes";
import { GET_STAFF_MEDIC } from "../utils/constantes";

const initialState = {
  users: [],
  user_Login: null,
  news: [],
  show: false,
  value: "",
  burgerMenu: false,
  specialty: "",
  sectionShow: false,
  section: "",
  pagination: 1,
  area: "",
  content: "",
  specialities: [],
  filterSpecials: [],
  laboratories: [],
  units: [],
  staff: [],
  preps: [],
  studies: [],
  category: "Adultos",
  rating: false,
  guides: [],
  adultsGuards: [],
  kidsGuards: [],
  switchInit: false,
  location: "",
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        user_Login: action.payload,
      };

    case USER_LOGOUT:
      return {
        ...state,
        user_Login: null,
      };

    case GET_NEWS:
      return {
        ...state,
        news: action.payload,
      };

    case SET_SHOW:
      return {
        ...state,
        show: action.payload,
      };

    case SET_VALUE:
      return {
        ...state,
        value: action.payload,
      };

    case BURGER_MENU:
      return {
        ...state,
        burgerMenu: action.payload,
      };

    case CURRENT_SPECIALTY:
      return {
        ...state,
        specialty: action.payload,
      };

    case SECTION_SHOW:
      return {
        ...state,
        sectionShow: action.payload,
      };

    case SET_SECTION:
      return {
        ...state,
        section: action.payload,
      };

    case SET_AREA:
      return {
        ...state,
        area: action.payload,
      };

    case SET_CONTENT:
      return {
        ...state,
        content: action.payload,
      };

    case SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      };

    case INCREASE_PAGE:
      return {
        ...state,
        pagination: state.pagination + 1,
      };

    case GET_SPECIALITIES:
      return {
        ...state,
        specialities: action.payload,
        filterSpecials: action.payload,
      };

    case GET_LABORATORIES:
      return {
        ...state,
        laboratories: action.payload,
      };

    case GET_UNITS:
      return {
        ...state,
        units: action.payload,
      };
    case GET_STAFF_MEDIC:
      return {
        ...state,
        staff: action.payload,
      };

    case GET_ADULTS_GUARDS:
      return {
        ...state,
        adultsGuards: action.payload,
      };

    case GET_KIDS_GUARDS:
      return {
        ...state,
        kidsGuards: action.payload,
      };

    case GET_PREPS:
      return {
        ...state,
        preps: action.payload,
      };

    case GET_STUDIES:
      return {
        ...state,
        studies: action.payload,
      };

    case FILTER_SEARCH:
      return {
        ...state,
        filterSpecials: action.payload,
      };

    case SET_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };

    case CLEAR_CATEGORY:
      return {
        ...state,
        category: "",
      };

    case RATING:
      return {
        ...state,
        rating: true,
      };

    case SWITCH_INIT:
      return {
        ...state,
        switchInit: true,
      };

    case SET_LOCATION:
      return {
        ...state,
        location: action.payload,
      };

    case CLEAR_LOCATION:
      return {
        ...state,
        location: action.payload,
      };

    case GET_GUIDES:
      return {
        ...state,
        guides: action.payload,
      };

    case CLEAR_CONTENT:
      return {
        ...state,
        content: "",
      };

    case CLEAR_SECTION:
      return {
        ...state,
        section: "",
      };

    default:
      return state;
  }
};

export default rootReducer;
