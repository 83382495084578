import { useState } from "react";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./CarouselCard.module.css";
import { useDeleteCarouselImage, useUpdateCarouselImageUrl } from "../../../../../hooks/api";

const CarouselCard = ({ image }) => {
  let [input, setInput] = useState("");
  const deleteCarouselImage = useDeleteCarouselImage();
  const updateCarouselImageUrl = useUpdateCarouselImageUrl();

  function handleChange(event) {
    setInput(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (input !== "") {
      updateUrl(input);
      setInput("");
      alert("URL actualizada!");
    } else {
      alert("Campo vacío!");
      setInput("");
    }
  }

  async function updateUrl(newUrl) {
    try {
      await updateCarouselImageUrl.mutateAsync({ id: image.id, mobile: image.mobile, url: newUrl });
      console.log(`URL de la imagen ${image.id} actualizada a ${newUrl}`);
    } catch (error) {
      console.log(error);
    }
  }

  const deleteImage = async () => {
    try {
      await deleteCarouselImage.mutateAsync({ id: image.id, mobile: image.mobile });
      alert("Imagen eliminada exitosamente");
    } catch (e) {
      console.log("Error al intentar eliminar la imagen del carousel", e);
      alert("Error al intentar eliminar la imagen");
    }
  };

  return (
    <div className={styles.main__container}>
      <div className={styles.content__container}>
        <div className={styles.close_button__container} onClick={deleteImage}>
          <CloseIcon color="error" />
        </div>
        <img src={image?.img} alt={image.name} />
        <form className={styles.form__container} onSubmit={handleSubmit}>
          <input
            type="text"
            id="url"
            name="url"
            value={input}
            onChange={handleChange}
            placeholder={image.url}
          />
          <button type="submit">
            <SaveOutlinedIcon /> Guardar
          </button>
        </form>
      </div>
    </div>
  );
};

export default CarouselCard;
