import "./Backoffice-Nav.css";
import { Link, useNavigate } from "react-router-dom";
import { LoginButton } from "../../Backoffice-Auth/Styled-Components/LogueoPrueba/LoginButton";
import logo from "../../../../images/LogoReinaFabiola.svg";
import { signOut } from '@aws-amplify/auth';

export const BackofficeNav = () => {
  let navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate("/backoffice/login");
    } catch (error) {
      console.log('Error signing out: ', error);
    }
  };

  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <div className="topLeft">
          <Link style={{ textDecoration: "none" }} to="/">
            <img src={logo} alt="Reina Fabiola" />
          </Link>
        </div>
        <div className="topRight">
          <div className="topbarIconContainer">
            <LoginButton onClick={handleSignOut}>
              Cerrar sesión
            </LoginButton>
          </div>
        </div>
      </div>
    </div>
  );
};

