import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { sectionShow } from "../../actions";
import { Link, useNavigate } from "react-router-dom";
import close from "./closeNotas.svg";
import styles from "./ColumnaDerNews.module.css";

const ColumnaDerNews = ({ data, area }) => {
  let [featured, setFeatured] = useState();
  let dispatch = useDispatch();
  let show = useSelector((state) => state.rootReducer.sectionShow);
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(sectionShow(false));
  }, []);

  useEffect(() => {
    setFeatured(
      data
        ?.filter((elem) => elem.featured === "true" && elem.active === true)
        .sort((a, b) => {
          if (a.date > b.date) return -1;
          if (a.date < b.date) return 1;
          return 0;
        })
    );
  }, [data]);

  const closeSections = () => {
    dispatch(sectionShow(false));
  };

  return (
    <div
      className={`${styles.container__main} ${show && styles.show_sections}`}
    >
      <div className={styles.sombreado__nuestrasnotas}></div>
      <div className={styles.container__content}>
        <img
          src={close}
          alt="Close"
          className={styles.close_btn}
          onClick={() => closeSections()}
        />
        <div className={styles.container__nuestrasnotas}>
          <h3>Nuestras notas</h3>
          <ul className={styles.container__sections}>
            <Link to="/noticias/Programa%20de%20buena%20salud">
              <li
                onClick={closeSections}
                className={
                  area === "Programa de buena salud" ? styles.selected : ""
                }
              >
                Programa de Buena Salud
              </li>
            </Link>
            <Link to="/noticias/Actividades%20para%20la%20comunidad">
              <li
                onClick={closeSections}
                className={
                  area === "Actividades para la comunidad"
                    ? styles.selected
                    : ""
                }
              >
                Actividades para la comunidad
              </li>
            </Link>
            <Link to="/noticias/Novedades%20institucionales">
              <li
                onClick={closeSections}
                className={
                  area === "Novedades institucionales" ? styles.selected : ""
                }
              >
                Novedades institucionales
              </li>
            </Link>
            <Link to="/noticias/Actividades%20académicas">
              <li
                onClick={closeSections}
                className={
                  area === "Actividades académicas" ? styles.selected : ""
                }
              >
                Actividades académicas
              </li>
            </Link>
          </ul>
        </div>
        {featured?.length !== 0 ? (
          <div className={styles.container__notas_recomendadas}>
            <h4>Destacadas</h4>
            <ul className={styles.container__recomendadas}>
              {featured?.map((elem, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      navigate(`/noticiasDetails/${elem.id}`);
                    }}
                  >
                    {elem.title}
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ColumnaDerNews;
