import { useState, useEffect, useCallback } from "react";
import styles from "./FormMedicoIntegral.module.css";
import felchaAbajo from "./felchaAbajo.svg";
import Swal from "sweetalert2";
import successIcon from "./succesIcon.svg";
import Axios from "axios";
import BounceLoader from "react-spinners/BounceLoader";

const { REACT_APP_API_URL } = process.env;

const FormMedicoIntegral = ({ municipios }) => {
  let [disabled, setDisabled] = useState(true);
  let [input, setInput] = useState({});
  let [localidades, setLocalidades] = useState(municipios);
  let [resultadoLocalidades, setResultadoLocalidades] = useState(municipios);
  let [show, setShow] = useState(false);
  let [errors, setErrors] = useState({});
  let [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setInput({ ...input, [event.target.name]: event.target.value });
    if (event.target.name === "localidad") {
      filtrarLocalidades(event.target.value);
    }
  };

  const validate = () => {
    let erroresMensajes = {};
    if (!input.nombre) {
      erroresMensajes.nombre = "Debe ingresar un nombre";
    }
    if (!input.apellido) {
      erroresMensajes.apellido = "Debe ingresar un apellido";
    }
    if (!input.localidad) {
      erroresMensajes.localidad = "Debe seleccionar una localidad";
    }
    if (!input.email) {
      erroresMensajes.email = "Debe ingresar un email";
    }
    if (!input.telPrefijo || !input.telNumero) {
      erroresMensajes.telefono = "Debe ingresar un teléfono";
    }
    if (!input.fechaDeNacimiento) {
      erroresMensajes.fechaDeNacimiento = "Debe ingresar una fecha";
    }
    if (!input.obraSocial) {
      erroresMensajes.obraSocial = "Debe ingresar una obra social";
    }
    if (!input.dni) {
      erroresMensajes.dni = "Debe ingresar un DNI";
    }
    if (!input.mensaje) {
      erroresMensajes.mensaje = "Debe ingresar un mensaje";
    }

    return erroresMensajes;
  };

  const filtrarLocalidades = (termino) => {
    if (termino) {
      let resultadoLocalidad = localidades.filter((elem) =>
        elem.toLowerCase().includes(termino.toLowerCase())
      );
      setResultadoLocalidades(resultadoLocalidad);
    }
  };

  const handlerSubmit = async (e) => {
    e.preventDefault();
    let erroresMensajes = validate();
    if (!Object.keys(erroresMensajes).length) {
      let infoSend;
      setErrors(erroresMensajes);

      infoSend = input;

      let formData = new FormData();

      formData.append("infoData", JSON.stringify(infoSend));
      setLoading(true);
      try {
        await Axios.post(
          `${REACT_APP_API_URL}/formularios/integral`,
          formData,
          {
            headers: { "Content-type": "multipart/form-data" },
          }
        );
        setLoading(false);
        Swal.fire({
          title: "Solicitud enviada con éxito",
          confirmButtonText: "Entendido",
          buttonsStyling: false,
          customClass: {
            popup: `${styles.containerAlert}`,
            confirmButton: `${styles.buttonConfirm}`,
            title: `${styles.titleAlert}`,
            htmlContainer: `${styles.containerHtml}`,
          },
          html: `<img src=${successIcon} />`,
        });
      } catch (e) {
        console.log("Error al mandar el formulario", e);
      }
      setInput({
        nombre: "",
        apellido: "",
        email: "",
        fechaDeNacimiento: "",
        obraSocial: "",
        telPrefijo: "",
        telNumero: "",
        dni: "",
        mensaje: "",
        localidad: "",
      });
    } else {
      setErrors(erroresMensajes);
      console.log("formulario con error");
    }
  };

  const disabledButton = useCallback(() => {
    if (
      !input.nombre ||
      !input.apellido ||
      !input.email ||
      !input.fechaDeNacimiento ||
      !input.localidad ||
      !input.obraSocial ||
      !input.telPrefijo ||
      !input.telNumero ||
      !input.mensaje ||
      !input.dni
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [input]);

  useEffect(() => {
    setLocalidades(municipios);
    setResultadoLocalidades(municipios);
  }, [municipios]);

  useEffect(() => {
    disabledButton();
  }, [input, disabledButton]);
  return (
    <form className={styles.container__form} onSubmit={handlerSubmit}>
      <div
        className={`${styles.container__label_input} ${styles.container__nombre}`}
      >
        <label htmlFor="nombre">Nombre</label>
        <input
          type="text"
          name="nombre"
          id="nombre"
          placeholder="Ej: María"
          onChange={handleChange}
          value={input.nombre}
          autoComplete="off"
          className={input.nombre ? styles.not__empty : null}
        />
        {errors.nombre && (
          <span className={styles.error__message}>{errors.nombre}</span>
        )}
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__apellido}`}
      >
        <label htmlFor="apellido">Apellido</label>
        <input
          type="text"
          name="apellido"
          id="apellido"
          placeholder="Ej: Romero"
          onChange={handleChange}
          value={input.apellido}
          autoComplete="off"
          className={input.apellido ? styles.not__empty : null}
        />
        {errors.apellido && (
          <span className={styles.error__message}>{errors.apellido}</span>
        )}
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__dni}`}
      >
        <label htmlFor="dni">DNI</label>
        <input
          type="number"
          name="dni"
          id="dni"
          placeholder="Ej: 99.999.999"
          onChange={handleChange}
          autoComplete="off"
          className={input.dni ? styles.not__empty : null}
          value={input.dni}
        />
        {errors.dni && (
          <span className={styles.error__message}>{errors.dni}</span>
        )}
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__email}`}
      >
        <label htmlFor="email">E-mail</label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="midireccion@mail.com"
          onChange={handleChange}
          value={input.email}
          autoComplete="off"
          className={input.email ? styles.not__empty : null}
        />
        {errors.email && (
          <span className={styles.error__message}>{errors.email}</span>
        )}
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__localidad}`}
      >
        <label htmlFor="localidad">Localidad</label>
        <input
          type="text"
          name="localidad"
          id="localidad"
          placeholder="Selecciona una localidad"
          onChange={handleChange}
          value={input.localidad}
          onClick={() => setShow(true)}
          onBeforeInput={() => setShow(true)}
          autoComplete="off"
          onBlur={() => {
            setTimeout(() => {
              setShow(false);
            }, 100);
          }}
          className={input.localidad ? styles.not__empty : null}
        />
        {errors.localidad && (
          <span className={styles.error__message}>{errors.localidad}</span>
        )}
        <img
          src={felchaAbajo}
          alt="felcha abajo"
          className={`${styles.icono__flecha} ${
            show && styles.invertir__chevron
          }`}
          onClick={() => setShow(true)}
        />
        <ul
          className={`${styles.container__localidades_resultados} ${
            !show && styles.noshow_localidades
          }`}
        >
          {resultadoLocalidades.map((elem, index) => (
            <li
              key={index}
              onClick={() => {
                setInput({ ...input, localidad: elem });
                setShow(false);
              }}
            >
              {elem}
            </li>
          ))}
        </ul>
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__telefono}`}
      >
        <label htmlFor="teléfono">Teléfono</label>
        <div className={styles.container__telefono_input}>
          <input
            type="number"
            name="telPrefijo"
            id="teléfono"
            placeholder="Prefijo"
            className={`${styles.telefono__prefijo} ${
              input.telPrefijo ? styles.not__empty : null
            }`}
            onChange={handleChange}
            value={input.telPrefijo}
            autoComplete="off"
          />
          <input
            type="number"
            name="telNumero"
            id="teléfono"
            placeholder="Número"
            className={`${styles.telefono__numero} ${
              input.telNumero ? styles.not__empty : null
            }`}
            onChange={handleChange}
            value={input.telNumero}
            autoComplete="off"
          />
          {errors.telefono && (
            <span className={styles.error__message}>{errors.telefono}</span>
          )}
        </div>
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__fechaDeNacimiento}`}
      >
        <label htmlFor="fechaDeNacimiento">Fecha de nacimiento</label>
        <input
          type="date"
          name="fechaDeNacimiento"
          id="fechaDeNacimiento"
          placeholder="asdasd"
          onChange={handleChange}
          value={input.fechaDeNacimiento}
          autoComplete="off"
          className={input.fechaDeNacimiento ? styles.not__empty : null}
        />
        {errors.fechaDeNacimiento && (
          <span className={styles.error__message}>
            {errors.fechaDeNacimiento}
          </span>
        )}
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__obraSocial}`}
      >
        <label htmlFor="obraSocial">Obra social</label>
        <input
          type="text"
          name="obraSocial"
          id="obraSocial"
          placeholder="Nombre y plan de tu obra social"
          onChange={handleChange}
          value={input.obraSocial}
          autoComplete="off"
          className={input.obraSocial ? styles.not__empty : null}
        />
        {errors.obraSocial && (
          <span className={styles.error__message}>{errors.obraSocial}</span>
        )}
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__mensaje}`}
      >
        <label htmlFor="mensaje">Mensaje</label>
        <textarea
          name="mensaje"
          id="mensaje"
          cols="30"
          rows="3"
          placeholder="Dejanos tu mensaje"
          onChange={handleChange}
          value={input.mensaje}
          autoComplete="off"
          className={input.mensaje ? styles.not__empty : null}
        ></textarea>
        {errors.mensaje && (
          <span className={styles.error__message}>{errors.mensaje}</span>
        )}
      </div>
      {loading ? (
        <div className={styles.loadingContainer}>
          <BounceLoader color="#002856" />
        </div>
      ) : (
        <button
          type="submit"
          className={`${styles.btn__enviar} ${
            disabled ? styles.disabled : null
          }`}
        >
          Enviar solicitud
        </button>
      )}
    </form>
  );
};

export default FormMedicoIntegral;
