import "./Backoffice-Sidebar.css";
import { LineStyle, BarChart, DynamicFeed } from "@mui/icons-material";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import HealingIcon from "@mui/icons-material/Healing";
import ScienceOutlinedIcon from "@mui/icons-material/ScienceOutlined";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import ApartmentIcon from "@mui/icons-material/Apartment";
import FeedIcon from "@mui/icons-material/Feed";
import ImageIcon from "@mui/icons-material/Image";
import LocalConvenienceStoreIcon from "@mui/icons-material/LocalConvenienceStore";
import HandymanIcon from "@mui/icons-material/Handyman";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import { Link } from "react-router-dom";

export const BackofficeSidebar = () => {
  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Dashboard</h3>
          <ul className="sidebarList">
            <Link to="/" className="link">
              <li className="sidebarListItem active">
                <LineStyle className="sidebarIcon" />
                Home
              </li>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/backoffice/noticias">
              <li className="sidebarListItem">
                <FeedIcon className="sidebarIcon" />
                Noticias
              </li>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to="/backoffice/experiencia"
            >
              <li className="sidebarListItem">
                <SentimentSatisfiedIcon className="sidebarIcon" />
                Experiencia
              </li>
            </Link>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Quick Menu</h3>
          <ul className="sidebarList">
            <Link to="/backoffice/sedes" className="link">
              <li className="sidebarListItem">
                <ApartmentIcon className="sidebarIcon" />
                Sedes
              </li>
            </Link>
            <Link to="/backoffice/staff" className="link">
              <li className="sidebarListItem">
                <WorkspacesIcon className="sidebarIcon" />
                Staff
              </li>
            </Link>
            <Link to="/backoffice/instalaciones" className="link">
              <li className="sidebarListItem">
                <LocalConvenienceStoreIcon className="sidebarIcon" />
                Instalaciones
              </li>
            </Link>
            <Link to="/backoffice/equipamientos" className="link">
              <li className="sidebarListItem">
                <HandymanIcon className="sidebarIcon" />
                Equipamientos
              </li>
            </Link>
            <Link to="/backoffice/imagenes" className="link">
              <li className="sidebarListItem">
                <ImageIcon className="sidebarIcon" />
                Imagenes
              </li>
            </Link>
            <Link to="/backoffice/editdatos" className="link">
              <li className="sidebarListItem">
                <BarChart className="sidebarIcon" />
                Estadísticas
              </li>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/backoffice/banner">
              <li className="sidebarListItem">
                <BackupTableIcon className="sidebarIcon" />
                Pop-up Info
              </li>
            </Link>
          </ul>
        </div>
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Áreas</h3>
          <ul className="sidebarList">
            <Link
              style={{ textDecoration: "none" }}
              to="/backoffice/especialidades"
            >
              <li id="especialidad">
                <LocalHospitalOutlinedIcon className="sidebarIcon" />
                Especialidad
              </li>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to="/backoffice/areasGenerales"
            >
              <li className="sidebarListItem">
                <DynamicFeed className="sidebarIcon" />
                Generales
              </li>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to="/backoffice/laboratorios"
            >
              <li className="sidebarListItem">
                <ScienceOutlinedIcon className="sidebarIcon" />
                Laboratorios
              </li>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to="/backoffice/unidadesAsistenciales"
            >
              <li className="sidebarListItem">
                <HealingIcon className="sidebarIcon" />
                Unidades
              </li>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to="/backoffice/preparaciones"
            >
              <li className="sidebarListItem">
                <VaccinesIcon className="sidebarIcon" />
                Preparaciones
              </li>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to="/backoffice/guiaDelPaciente"
            >
              <li className="sidebarListItem">
                <NoteAltIcon className="sidebarIcon" />
                Guía del Paciente
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
};
