import { useState, useEffect } from "react";
import style from "./Backoffice-InstallationsImages.module.css";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useData, useAddData, useDeleteData } from "../../../../hooks/api";

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = error => reject(error);
  });
};

export const BackofficeInstallationsImages = () => {
  const { data: images, isLoading, error } = useData("galeriaInstalaciones");
  const addImageMutation = useAddData("galeriaInstalaciones");
  const deleteImageMutation = useDeleteData("galeriaInstalaciones");
  const [selectedFile, setSelectedFile] = useState(null);

  const handleImages = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const uploadImageAsPromise = async (uploadFile) => {
    const { img, name } = uploadFile;

    const base64File = await fileToBase64(img);
    const formData = {
      fileContent: base64File,
      fileName: name,
    };

    addImageMutation.mutate(formData, {
      onSuccess: () => {
        setSelectedFile(null); // Clear the selected file after successful upload
      },
      onError: (error) => {
        console.error("Error uploading image:", error);
      },
    });
  };

  const addFile = () => {
    if (selectedFile) {
      uploadImageAsPromise({
        img: selectedFile,
        name: selectedFile.name,
      });
    }
  };

  const deleteImages = (id) => {
    deleteImageMutation.mutate(id, {
      onSuccess: () => {
        // Optionally, you can add some logic here if needed after deletion
      },
      onError: (error) => {
        console.error("Error deleting image:", error);
      },
    });
  };

  const open = () => {
    document.getElementById("file").click();
  };

  useEffect(() => {
    if (selectedFile) {
      addFile();
    }
  }, [selectedFile]);

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Error al cargar los datos: {error.message}</div>;
  }

  return (
    <div className={style.container}>
      <div className={style.inputImgContainer}>
        <label htmlFor="">Cargar nueva imagen </label>
        <AddPhotoAlternateIcon
          className={style.iconElement}
          onClick={() => open()}
        />
        <input
          className={style.inputImg}
          onChange={handleImages}
          type="file"
          id="file"
          style={{ display: 'none' }}
        />
      </div>
      <div className={style.imageContainer}>
        {images &&
          images?.map((element, index) => (
            <div className={style.imgContainerMap} key={index}>
              <CancelRoundedIcon
                className={style.cancelIcon}
                onClick={() => deleteImages(element?.id)}
              />
              <img src={`${element.img}`} alt="" />
            </div>
          ))}
      </div>
    </div>
  );
};
