import { useState, useEffect } from "react";
import style from "./Backoffice-Preparations.module.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { DataGrid } from "@mui/x-data-grid";
import BackofficeFilter from "../Backoffice-Filter/BackofficeFilter";
import { useData, useDeleteData, useDeleteSpecialties } from "../../../../hooks/api";

export const BackofficePreparations = () => {
  const [searchParams] = useSearchParams();
  let queryParams = searchParams.get("type") || "default";
  const [type, setType] = useState(queryParams);
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const [filterSpecialty, setFilterSpecialty] = useState([]);

  const { data: laboratories } = useData("laboratorios");
  laboratories.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

  const { data: preparationsLabs } = useData("preparationsLabs");
  preparationsLabs.sort((a, b) => (a.date > b.date ? -1 : b.date > a.date ? 1 : 0));

  const { data: preparationsSpecialties } = useData("preparationsSpecialties");
  preparationsSpecialties.sort((a, b) => (a.date > b.date ? -1 : b.date > a.date ? 1 : 0));

  const { data: specialties } = useData("specialtiesList");
  specialties.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

  // Hooks personalizados para eliminar en cada tipo
  const { mutate: deleteLabs } = useDeleteData("preparationsLabs");
  const { mutate: deleteSpecialties } = useDeleteData("preparationsSpecialties");

  const columns = [
    {
      field: "name",
      headerName: "Preparación",
      width: 300,
      alignHeader: "center",
    },
    {
      field: "adults",
      headerName: "Relacionado a :",
      width: 500,
      renderCell: (params) => {
        return (
          <div className="check">
            {type === "preparationsLabs" ? (
              params.row.laboratories?.map((e, i) => (
                <span key={i}>{`${getByQuery(e)} -`}</span>
              ))
            ) : type === "preparationsSpecialties" ? (
              params.row.specialties?.map((e, i) => (
                <span key={i}>
                  {getByQuery(e) === "Diagnóstico por imágenes"
                    ? `${getByQuery(e)}`
                    : `${getByQuery(e)} (${adultsOrKids(e)})`}
                </span>
              ))
            ) : (
              <>
                {params.row.laboratories?.map((e, i) => (
                  <span key={i}>{`${getByQuery(e)} -`}</span>
                ))}
                {params.row.specialties?.map((e, i) => (
                  <span key={i}>
                    {getByQuery(e) === "Diagnóstico por imágenes"
                      ? `${getByQuery(e)}`
                      : `${getByQuery(e)} (${adultsOrKids(e)})`}
                  </span>
                ))}
              </>
            )}
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "Editar/Borrar",
      width: 200,
      renderCell: (params) => {
        const dynamicType = params.row.laboratories?.length
          ? "preparationsLabs"
          : params.row.specialties?.length
          ? "preparationsSpecialties"
          : "default";

        const handleDelete = (id) => {
          if (window.confirm("Está seguro que desea eliminar este item?")) {
            if (dynamicType === "preparationsLabs") {
              deleteLabs(id, {
                onSuccess: () => {
                  navigate(`/backoffice/preparaciones?type=preparationsLabs`);
                },
              });
            } else if (dynamicType === "preparationsSpecialties") {
              deleteSpecialties(id, {
                onSuccess: () => {
                  navigate(`/backoffice/preparaciones?type=preparationsSpecialties`);
                },
              });
            }
          }
        };

        return (
          <div className="delete-edit-button">
            <Link
              style={{ textDecoration: "none" }}
              to={`/backoffice/preparaciones/${dynamicType}/${params.row.id}`}
            >
              <button className="userListEdit">Edit</button>
            </Link>

            <DeleteOutlinedIcon
              className="userListDelete"
              onClick={() => handleDelete(params.row.id)}
            />
          </div>
        );
      },
    },
  ];

  const adultsOrKids = (id) => {
    let filters = specialties?.filter((e) => e.name !== "Diagnóstico por imágenes");
    let adults = filters?.filter((e) => e.id === id);
    if (adults[0]?.adults === "false" && adults[0]?.kids === "false") {
      return "";
    } else {
      if (adults[0]?.adults === "true") {
        return "Adulto";
      } else {
        return "Pediátrico";
      }
    }
  };

  const getByQuery = (queryId) => {
    if (laboratories) {
      const labResult = laboratories.find((lab) => lab.id === queryId);
      if (labResult) {
        return labResult.name || "Desconocido";
      }
    }
    if (specialties) {
      const specialtyResult = specialties.find((specialty) => specialty.id === queryId);
      if (specialtyResult) {
        return specialtyResult.name || "Desconocido";
      }
    }
    return "Desconocido";
  };

  const handleSelect = (e) => {
    const value = e.target.value;

    if (value === "all") {
      setFilterSpecialty([...preparationsLabs, ...preparationsSpecialties]);
    } else {
      if (type === "preparationsLabs" && preparationsLabs) {
        const filter = preparationsLabs?.filter((preparation) =>
          preparation.laboratories?.some((labId) => labId === value)
        );
        setFilterSpecialty(
          filter?.sort((a, b) => (a.date > b.date ? -1 : b.date > a.date ? 1 : 0))
        );
      } else if (type === "preparationsSpecialties" && preparationsSpecialties) {
        const filter = preparationsSpecialties?.filter((preparation) =>
          preparation.specialties?.some((specialtyId) => specialtyId === value)
        );
        setFilterSpecialty(
          filter?.sort((a, b) => (a.date > b.date ? -1 : b.date > a.date ? 1 : 0))
        );
      } else if (type === "default") {
        const filterLabs = preparationsLabs?.filter((preparation) =>
          preparation.laboratories?.some((labId) => labId === value)
        );
        const filterSpecialties = preparationsSpecialties?.filter((preparation) =>
          preparation.specialties?.some((specialtyId) => specialtyId === value)
        );
        const combinedFilter = [...(filterLabs || []), ...(filterSpecialties || [])].sort(
          (a, b) => (a.date > b.date ? -1 : b.date > a.date ? 1 : 0)
        );
        setFilterSpecialty(combinedFilter);
      }
    }
  };

  const handleSelectType = (e) => {
    const selectedType = e.target.value;
    setType(selectedType);
    navigate(`/backoffice/preparaciones?type=${selectedType}`);
    if (selectedType === "default") {
      setFilterSpecialty([...preparationsLabs, ...preparationsSpecialties]);
    } else if (selectedType === "preparationsLabs") {
      setFilterSpecialty(preparationsLabs);
    } else if (selectedType === "preparationsSpecialties") {
      setFilterSpecialty(preparationsSpecialties);
    }
  };

  useEffect(() => {
    if (preparationsLabs && preparationsSpecialties) {
      if (type === "preparationsLabs") {
        setRows(
          filterSpecialty?.map((u) => ({
            id: u.id,
            name: u.name,
            laboratories: u.laboratories,
            content: u.content,
          }))
        );
      } else if (type === "preparationsSpecialties") {
        setRows(
          filterSpecialty?.map((u) => ({
            id: u.id,
            name: u.name,
            specialties: u.specialties,
            content: u.content,
          }))
        );
      } else if (type === "default") {
        setRows(
          filterSpecialty?.map((u) => ({
            id: u.id,
            name: u.name,
            specialties: u.specialties || [],
            laboratories: u.laboratories || [],
            content: u.content,
          }))
        );
      }
    }
  }, [filterSpecialty, type, preparationsLabs, preparationsSpecialties]);

  useEffect(() => {
    if (preparationsLabs && preparationsSpecialties) {
      if (type === "default") {
        setFilterSpecialty([...preparationsLabs, ...preparationsSpecialties]);
      } else if (type === "preparationsLabs") {
        setFilterSpecialty(preparationsLabs);
      } else if (type === "preparationsSpecialties") {
        setFilterSpecialty(preparationsSpecialties);
      }
    }
  }, [preparationsLabs, preparationsSpecialties, type]);

  useEffect(() => {
    if (!type || type === "") {
      setType("default");
      navigate("/backoffice/preparaciones?type=default");
    }
  }, [type, navigate]);

  return (
    <div className={style.container}>
      <div className={style.selectContainer}>
        <div className={style.containerButton}>
          <div>
            <label htmlFor="">Tipo de preparación :</label>
            <select
              className={style.selectType}
              onChange={handleSelectType}
              name="type"
              id=""
            >
              <option value="default">-</option>
              <option value="preparationsLabs">Laboratorio</option>
              <option value="preparationsSpecialties">Especialidad</option>
            </select>
          </div>
          <button
            onClick={() =>
              navigate("/backoffice/preparaciones/crear?type=preparationsLabs")
            }
            className={style.newButton}
          >
            Nueva
          </button>
        </div>

        {type === "preparationsLabs" && (
          <BackofficeFilter labs={laboratories} handlerChange={handleSelect} />
        )}
        {type === "preparationsSpecialties" && (
          <BackofficeFilter specialties={specialties} handlerChange={handleSelect} />
        )}
        {type === "default" && (
          <>
            <BackofficeFilter labs={laboratories} handlerChange={handleSelect} />
            <BackofficeFilter specialties={specialties} handlerChange={handleSelect} />
          </>
        )}
      </div>

      <div className="datagridContainer" style={{ height: "100vh", width: "100vw%" }}>
        <DataGrid
          rows={rows}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          checkboxSelection
        />
      </div>
    </div>
  );
};
