import "./Backoffice-Home.css";

import { BackofficeTemplateDefault } from "../../Backoffice-Components/Backoffice-TemplateDefault/Backoffice-TemplateDefault";

export const BackofficeHome = () => {
  return (
    <>
      <BackofficeTemplateDefault />
    </>
  );
};
