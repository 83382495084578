import { GET_STAFF_MEDIC } from "../../utils/constantes";

const getStaffMedic = (payload) => {
  return {
    type: GET_STAFF_MEDIC,
    payload,
  };
};

export default getStaffMedic;
