import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emoji from "./images/emoji.svg";
import happyFace from "./images/happyFace.svg";
import normalFace from "./images/normalFace.svg";
import sadFace from "./images/sadFace.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  changeRating,
  setShow,
} from "./actions";
import { useEffect } from "react";
import { RaitingExperience } from "./components/RaitingExperience/RaitingExperience";
import { Portal } from "./Portal";
import { Helmet } from "react-helmet";
import { RootRouter } from "./router/index.jsx";
import useInitializer from "./useInitializer"; 
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseApp } from "./firebase";

const analytics = getAnalytics(firebaseApp);

function App() {
  const { switchInit, value, show } = useSelector((state) => state.rootReducer);
  const rating = useSelector((state) => state.rootReducer.rating);
  const dispatch = useDispatch();

  useInitializer(switchInit);

  const handleRating = () => {
    if (!rating) {
      setTimeout(() => {
        dispatch(changeRating());
      }, 30000);
    }
  };

  const handleShow = () => {
    dispatch(setShow(true));
  };

  useEffect(() => {
    handleRating();
    logEvent(analytics, 'notification_received');
  }, [dispatch]);

  return (
    <div className="App">
      <Helmet>
        <title>CURF</title>
        <meta
          name="description"
          content="Desde 1959 junto a nuestros pacientes. La Clínica Universitaria Reina Fabiola, brinda servicios de salud de excelencia centrados en la persona y su familia."
        />
        <meta
          property="og:image"
          content="https://www.curf.com.ar/logoconbanner.png"
        />
      </Helmet>
      <div className="container__caritas__mensaje">
        {rating === true && !window.location.pathname.includes("backoffice") ? (
          !show ? (
            <div id="emoji">
              {value === 1 ? (
                <img className="happyFace" src={happyFace} alt="" />
              ) : value === 2 ? (
                <img className="normalFace" src={normalFace} alt="" />
              ) : value === 3 ? (
                <img className="sadFace" src={sadFace} alt="" />
              ) : (
                <img
                  onClick={() => handleShow()}
                  id="default-emoticon"
                  src={emoji}
                  alt=""
                />
              )}
            </div>
          ) : null
        ) : null}
      </div>
      {show === true && window.innerWidth < 1176 ? (
        <Portal />
      ) : show === true && window.innerWidth > 1176 ? (
        <section id="raiting" className="containerRaitingComponent">
          <RaitingExperience />
        </section>
      ) : null}
      <RootRouter />
      <ToastContainer />
    </div>
  );
}

export default App;
