import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import ColumnaIzq from "../ColumnaIzq/ColumnaIzq";
import ContenidoCentral from "../ContenidoCentral/ContenidoCentral";
import setContent from "../../actions/setContent";
import Banner from "../Banner/Banner";
import flecha from "./felcha izquierda.svg";
import style from "./Units.module.css";
import { useData } from "../../hooks/api";
import {ContentLeft} from "../ContentLeft/ContentLeft";
import {ContentRight} from "../ContentRight/ContentRight";

export const Units = () => {
  const navigate = useNavigate();
  const { name, section } = useParams();
  const dispatch = useDispatch();
  const [currentData, setCurrentData] = useState("");

  const { data: unitsData } = useData("unidadesAsistenciales");

  useEffect(() => {
    if (name && unitsData) {
      const filteredData = unitsData.find(unit => unit.name === name);
      setCurrentData(filteredData || {});
    }
  }, [name, unitsData]);

  useEffect(() => {
    if (currentData) {
      const filterSec = section
        ? currentData.sections?.filter((e) => e.name === section)
        : null;
      dispatch(setContent(filterSec && filterSec[0]?.content));
    }
  }, [currentData, section, dispatch]);

  const path =
    currentData.kids || currentData.adults
      ? "especialidades"
      : currentData?.principal
      ? "unidadesAsistenciales"
      : currentData.tag
      ? "general"
      : "laboratorios";

  return (
    <div className={style.container__main}>
      <Banner
        image={currentData?.img}
        title={currentData?.name}
        type={
          currentData?.adults === "true"
            ? "Adultos"
            : currentData?.kids
            ? "Pediátrica"
            : null
        }
      />
      <div className={style.container__flecha_home}>
        <div className={style.container__flecha_home_content}>
          <img
            src={flecha}
            alt="Flecha"
            className={style.flecha__home}
            onClick={() => navigate(-1)}
          />
          <span onClick={() => navigate(-1)}>Volver</span>
        </div>
      </div>
      <div className={style.container__content}>
        {name ? (
          <ContentLeft
            path={path}
            sections={currentData?.sections}
            sites={currentData?.sites}
          />
        ) : (
          <ColumnaIzq />
        )}
        {name ? <ContentRight currentData={currentData} /> : <ContenidoCentral />}
      </div>
    </div>
  );
};

export default Units;
