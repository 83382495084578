export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const GET_NEWS = "GET_NEWS";
export const SET_SHOW = "SET_SHOW";
export const SET_VALUE = "SET_VALUE";
export const BURGER_MENU = "BURGER_MENU";
export const CURRENT_SPECIALTY = "CURRENT_SPECIALTY";
export const SECTION_SHOW = "SECTION_SHOW";
export const SET_SECTION = "SET_SECTION";
export const SET_AREA = "SET_AREA";
export const SET_CONTENT = "SET_CONTENT";
export const SET_PAGINATION = "SET_PAGINATION";
export const INCREASE_PAGE = "INCREASE_PAGE";
export const GET_SPECIALITIES = "GET_SPECIALITIES";
export const GET_LABORATORIES = "GET_LABORATORIES";
export const GET_UNITS = "GET_UNITS";
export const FILTER_SEARCH = "FILTER_SEARCH";
export const SET_CATEGORY = "SET_CATEGORY";
export const RATING = "RATING";
export const SET_STAFF = "SET_STAFF";
export const FILTER_SEARCH_BO = "FILTER_SEARCH_BO";
export const SWITCH_INIT = "SWITCH_INIT";
export const SET_LOCATION = "SET_LOCATION";
export const GET_PREPS = "GET_PREPS";
export const GET_STUDIES = "GET_STUDIES";
export const GET_GUIDES = "GET_GUIDES";
export const GET_ADULTS_GUARDS = "GET_ADULTS_GUARDS";
export const GET_KIDS_GUARDS = "GET_KIDS_GUARDS";
export const CLEAR_CATEGORY = "CLEAR_CATEGORY";
export const CLEAR_SECTION = "CLEAR_SECTION";
export const CLEAR_CONTENT = "CLEAR_CONTENT";
export const CLEAR_LOCATION = "CLEAR_LOCATION";

// Nuevas constantes agregadas
export const SET_SEARCH_ID = "SET_SEARCH_ID";
export const GET_ALL_SITES = "GET_ALL_SITES";
export const GET_STAFF_MEDIC = "GET_STAFF_MEDIC";
