import style from "./Header.module.css";
import Logo from "../../images/LogoReinaFabiola.svg";
import { Link } from "react-router-dom";
import { Search } from "../Search/Search";
import renglones from "../../images/renglones.svg";
import MenuMovil from "../MenuMovil/MenuMovil";
import { useDispatch } from "react-redux";
import { burgerMenu } from "../../actions";

export const Header = () => {
  const dispatch = useDispatch();

  const handleMenu = () => {
    dispatch(burgerMenu("true"));
  };

  return (
    <div className={style.Header_container}>
      <div className={style.Header_in}>
        <Link className="link" to="/">
          <img className={style.logoRF} src={Logo} alt="No disponible" />
        </Link>
        <div className={style.Header2}>
          <Search />
          <a
            style={{ textDecoration: "none", color: "white" }}
            target="_blank"
            href={"http://turnos.curf.com.ar"}
          >
            <button className={style.Button_header}>Sacá un turno</button>
          </a>
        </div>
        <div className={style.headerResponsive}>
          <button onClick={handleMenu}>
            <img src={renglones} alt="" />
          </button>
        </div>
        <MenuMovil />
      </div>
    </div>
  );
};
