import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useData, useAddData, useEditData, useDeleteData } from "../../../../hooks/api";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import "./Backoffice-Statistics.css";
import { EditStatistics } from "./EditStatistics";
import { CreateStatistics } from "./CreateStatistics";

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = error => reject(error);
  });
};

export default function BackofficeStatistics() {
  const [rows, setRows] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [statistics, setStatistics] = useState(null);
  const [updateImg, setImageUpdate] = useState("");
  const [display, setDisplay] = useState(false);
  const [view, setView] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [values, setValues] = useState({
    name: "",
    value: "",
    img: "",
  });

  const { data: estadisticas, isLoading, error } = useData("estadisticas");
  const addDataMutation = useAddData("estadisticas");
  const editDataMutation = useEditData("estadisticas");
  const deleteDataMutation = useDeleteData("estadisticas");

  useEffect(() => {
    if (estadisticas) {
      setDataList(estadisticas);
    }
  }, [estadisticas]);

  useEffect(() => {
    setRows(
      dataList?.map((u) => ({
        id: u.id,
        name: u.name,
        value: u.value,
        img: u.img,
      }))
    );
  }, [dataList]);

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setImageUpdate(fileUrl);
    }
  };

  const handleDisplay = () => {
    setDisplay(true);
    setView(false);
    setStatistics(null);
    setValues({ name: "", value: "", img: "" });
    setImageUpdate("");
    setSelectedFile(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, value } = values;
    let newData = {
      name: name || (statistics ? statistics.name : "Estadística sin nombre"),
      value: value || (statistics ? statistics.value : "0"),
      img: updateImg || (statistics ? statistics.img : ""),
    };

    if (selectedFile) {
      const base64File = await fileToBase64(selectedFile);
      newData = {
        ...newData,
        fileContent: base64File,
        fileName: selectedFile.name,
      };
    }

    if (statistics && statistics.id) {
      editDataMutation.mutate({ ...newData, id: statistics.id }, {
        onSuccess: () => {
          setView(false);
          setDisplay(false);
          setStatistics(null);
          setValues({ name: "", value: "", img: "" });
          setImageUpdate("");
          setSelectedFile(null);
        },
      });
    } else {
      addDataMutation.mutate(newData, {
        onSuccess: () => {
          setView(false);
          setDisplay(false);
          setStatistics(null);
          setValues({ name: "", value: "", img: "" });
          setImageUpdate("");
          setSelectedFile(null);
        },
      });
    }
  };

  const deleteStatistics = async (id) => {
    deleteDataMutation.mutate(id, {
      onSuccess: () => {
        setStatistics(null);
      },
    });
  };

  const columns = [
    {
      field: "name",
      headerName: "Estadística",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "value",
      headerName: "Valor",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "img",
      headerName: "Ícono",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <img className="iconPictureImg" src={params.row.img} alt="" />
      ),
    },
    {
      field: "",
      headerName: "Editar/Borrar",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div className="delete-edit-button">
          <button
            onClick={() => {
              setStatistics({
                id: params.row.id,
                name: params.row.name,
                value: params.row.value,
                img: params.row.img,
              });
              setView(true);
              setDisplay(false);
              setValues({ name: params.row.name, value: params.row.value, img: params.row.img });
              setImageUpdate(params.row.img);
              setSelectedFile(null);
            }}
            className="editButton"
          >
            Edit
          </button>
          <DeleteOutlinedIcon
            className="userListDelete"
            onClick={() =>
              window.confirm("Está seguro que desea eliminar esta estadística?")
                ? deleteStatistics(params.row.id)
                : null
            }
          />
        </div>
      ),
    },
  ];

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Error al cargar los datos: {error.message}</div>;
  }

  return (
    <div className="containerGral">
      <button onClick={handleDisplay} className="userAddButton">
        Create
      </button>
      <EditStatistics
        view={view}
        setView={setView}
        statistics={statistics}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleUpload={handleUpload}
        updateImg={updateImg}
        setUpdateImg={setImageUpdate}
      />
      {display && (
        <CreateStatistics
          display={display}
          setDisplay={setDisplay}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleUpload={handleUpload}
          updateImg={updateImg}
          setUpdateImg={setImageUpdate}
        />
      )}
      <div className="containerTable">
        <DataGrid
          rows={rows}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          checkboxSelection={false}
        />
      </div>
    </div>
  );
}
