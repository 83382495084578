import { useState, useEffect } from "react";
import style from "./Guides.module.css";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { GuideBanner } from "./Guide-Banner/Guide-Banner";
import { useParams, useNavigate } from "react-router-dom";
import flecha from "./felcha izquierda.svg";
import { GuideContentLeft } from "./Guides - ContentLeft/Guide-ContentLeft";
import { GuideContentRight } from "./Guide-ContentRight/Guide-ContentRight";

export const Guides = () => {
  const [current, setCurrent] = useState("");
  const { name } = useParams();
  const rootReducer = useSelector((state) => state.rootReducer);
  const { guides } = rootReducer;
  const navigate = useNavigate();

  const filter = () => {
    let result = guides && guides?.find((guide) => guide.name == name);
    setCurrent(result);
  };

  useEffect(() => {
    filter();
  }, [guides, name]);


  return (
    <div>
      <div className={styled.container__main}>
        <GuideBanner image={current?.image} title={current?.name} />
        <div className={style.container__flecha_home}>
          <div
            className={style.container__flecha_home_content}
            onClick={() => navigate(-1)}
          >
            <img src={flecha} alt="Flecha" className={style.flecha__home} />
            <span>Volver</span>
          </div>
        </div>
      </div>
      <div className={style.container__content}>
        <GuideContentLeft />
        <GuideContentRight />
      </div>
    </div>
  );
};
