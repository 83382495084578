import './Backoffice-TemplateDefault.css';
import { BackofficeNav } from "../Backoffice-Nav/Backoffice-Nav";
import { BackofficeSidebar } from "../Backoffice-Sidebar/Backoffice-Sidebar";

export const BackofficeTemplateDefault = ({body}) => {
  return (
    <div className="backofficeContainer">
      <div className="backofficeNav">
        <BackofficeNav />
      </div>
      <div className="sidebarContainer">
        <div className="sidebar">
          <BackofficeSidebar />
        </div>
        <div className="bodyContainer">
        {body}
        </div>
      </div>
    </div>
  );
};
