import { useEffect } from "react";
import { Link } from "react-router-dom";
import flechaDerecha from "./flechaderecha.svg";
import style from "./CardNoticias.module.css";
import defaultImg from './default.png';
import { getAnalytics, logEvent } from "firebase/analytics";

const CardNoticias = ({ data }) => {

  //Firebase Analytics
  //-----------------------------------------------------//
  const analytics = getAnalytics();
  logEvent(analytics, "screen_view", {
    firebase_screen: `Noticia visitada: ${data?.title}`,
    firebase_screen_class: `${data?.area[0]}`,
  });
  const dataAnalytics = () => {
    logEvent(analytics, onclick, {
      content_type: `${data?.title}`,
      content_id: `${data?.id}`,
      items: [{ name:`${data?.area}` }],
    });
  }
  //-----------------------------------------------------//
  useEffect(() => {
    dataAnalytics()
  }, [data])


  return (
    <div className={style.container__main}>
      <div className={style.container__content}>
        <div className={style.container__noticias_image}>
          <img
            src={data?.img ? data?.img : defaultImg}
            alt="imagen de prueba"
            className={style.noticias__image}
          />
        </div>
        <div className={style.container__description}>
          <div className={style.container__fecha}>
            <span className={style.description__fecha}>{data.date}</span>
          </div>

          <span className={style.description__title}>{data.title}</span>

          <span className={style.description__previa}>{data.resume}</span>

          <Link to={`/noticiasDetails/${data.id}`}>
            <div className={style.container__leernota}>
              <span>Leer nota</span>
              <img
                src={flechaDerecha}
                alt="flecha derecha"
                className={style.flecha_leer_nota}
              />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CardNoticias;
