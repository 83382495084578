import { useNavigate } from "react-router-dom";
import style from "./Backoffice-GeneralAreas.module.css";

const collectionAreas = [
  { name: "Comités", tag: "comite" },
  { name: "Coberturas", tag: "coberturas" },
  { name: "Circuitos médicos", tag: "circuitosMedicos" },
  { name: "Institucional", tag: "institucional" },
  { name: "Telemedicina", tag: "telemedicina" },
  { name: "Guardia de Adultos", tag: "guardiaDeAdultos" },
  { name: "Guardia Pediátrica", tag: "guardiaPediatrica" },
  { name: "Mi Portal", tag: "miPortal" },
  { name: "Docencia", tag: "docencia" },
  { name: "Trabaja con nosotros", tag: "trabajaConNosotros" },
  { name: "Sugerencias y agradecimientos", tag: "sugerencias" },
  { name: "Nuestro equipo", tag: "equipo" },
];
export const BackofficeGeneralAreas = () => {
  const navigate = useNavigate();

  return (
    <div className={style.containerGral}>
      <article className={style.tableContainer}>
        <table className="table">
          <thead>
            <tr className="headerTable">
              <th>Área</th>
              <th className="thAction">Acción</th>
            </tr>
          </thead>
          <tbody>
            {collectionAreas &&
              collectionAreas?.map((areas, index) => (
                <tr key={index}>
                  <td className="tdSection">{areas.name}</td>
                  <td className="buttons">
                    <button
                      id="buttonEdit"
                      onClick={() =>
                        navigate(`/backoffice/areasGenerales/${areas.tag}`)
                      }
                      className="tableButton"
                    >
                      Editar
                    </button>
                  </td>
                </tr>
              ))}
            <tr></tr>
          </tbody>
        </table>
      </article>
    </div>
  );
};
