import { useEffect, useState } from "react";
import style from "./Backoffice-GuiaDelPaciente.module.css";
import { DataGrid } from "@mui/x-data-grid";
import { useData, useDeleteData } from "../../../../hooks/api";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Link, useNavigate } from "react-router-dom";

export const BackofficeGuiaDelPaciente = () => {
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  const { data: guideData, isLoading, error } = useData("guiaPaciente");
  const deleteGuideMutation = useDeleteData("guiaPaciente");

  const deleteGuide = (id) => {
    deleteGuideMutation.mutate(id, {
      onSuccess: () => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      },
      onError: (error) => {
        console.error("El usuario no ha podido ser eliminado", error);
      },
    });
  };

  useEffect(() => {
    if (guideData) {
      const sortedData = [...guideData].sort((a, b) => (a.date > b.date ? 1 : -1));
      setRows(
        sortedData.map((u) => ({
          id: u.id,
          name: u.name,
          resume: u.resume,
          date: u.date,
          icon: u.icon,
          img: u.img,
        }))
      );
    }
  }, [guideData]);

  const columns = [
    {
      field: "name",
      headerName: "Nombre",
      width: 250,
      alignHeader: "center",
    },
    {
      field: "icon",
      headerName: "Ícono",
      width: 150,
      renderCell: (params) => (
        <div className={style.iconContainer}>
          <img src={params.row?.icon} alt="" />
        </div>
      ),
    },
    {
      field: "img",
      headerName: "Imágen",
      width: 250,
      alignHeader: "center",
      renderCell: (params) => (
        <div className={style.imageContainer}>
          <img className={style.image} src={params.row.img} alt="" />
        </div>
      ),
    },
    {
      field: "",
      headerName: "Editar/Borrar",
      width: 150,
      renderCell: (params) => (
        <div className="delete-edit-button">
          <Link
            style={{ textDecoration: "none" }}
            to={`/backoffice/guiaDelPaciente/crear/${params.row.id}`}
          >
            <button className="userListEdit">Edit</button>
          </Link>
          <DeleteOutlinedIcon
            className="userListDelete"
            onClick={() =>
              window.confirm("Está seguro que desea eliminar este item?")
                ? deleteGuide(params.row.id)
                : null
            }
          />
        </div>
      ),
    },
  ];

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Error al cargar los datos: {error.message}</div>;
  }

  return (
    <div className={style.containerGral}>
      <div className={style.createContainer}>
        <button
          className={style.newButtonCreate}
          onClick={() => navigate("/backoffice/guiaDelPaciente/crear")}
        >
          Crear
        </button>
      </div>
      <div className={style.datagridContainer}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          checkboxSelection
        />
      </div>
    </div>
  );
};
