import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { RaitingExperience } from "./components/RaitingExperience/RaitingExperience";
import style from "./Portal.module.css";

export const Portal = () => {
  const rootReducer = useSelector((state) => state.rootReducer);
  const { show } = rootReducer;

  return (
    <>
      {ReactDOM.createPortal(
        <div className={style.modal}>
          {show === true && window.innerWidth < 1176 ? (
            <div className={style.maskBodyHome}>
              <div>
                <RaitingExperience />
              </div>
            </div>
          ) : null}
        </div>,
        document.getElementById("modal-root")
      )}
    </>
  );
};
