import { useEffect, useState, useCallback, useRef } from "react";
import styles from "./FormPrequirurgico.module.css";
import subirArchivo from "./subirArchivo.svg";
import archivoCargado from "./archivoCargado.svg";
import closeBtn from "./closeBtn.svg";
import Swal from "sweetalert2";
import successIcon from "./succesIcon.svg";
import Axios from "axios";
import BounceLoader from "react-spinners/BounceLoader";
const { REACT_APP_API_URL } = process.env;

const FormPrequirurgico = () => {
  let [disabled, setDisabled] = useState(true);
  let [input, setInput] = useState({});
  let [errors, setErrors] = useState({});
  let [dniYCredencial, setDniYCredencial] = useState([]);
  let [ordenYMateriales, setOrdenYMateriales] = useState([]);
  let [informes, setInformes] = useState([]);
  let [loading, setLoading] = useState(false);

  let refOrdenMat = useRef();
  let refInformes = useRef();
  let refDniCredencial = useRef();

  const handleChange = (event) => {
    if (event.target.name === "dniYCredencial") {
      let dniYCredencial = event.target.files;
      let aux = [];
      for (let clave in dniYCredencial) {
        aux.push(dniYCredencial[clave]);
      }
      aux.splice(-2);
      setDniYCredencial(aux);
      setInput({ ...input, dniYCredencial: aux });
    } else if (event.target.name === "ordenYMateriales") {
      let ordenYMateriales = event.target.files;
      let aux = [];
      for (let clave in ordenYMateriales) {
        aux.push(ordenYMateriales[clave]);
      }
      aux.splice(-2);
      setOrdenYMateriales(aux);
      setInput({ ...input, ordenYMateriales: aux });
    } else if (event.target.name === "informes") {
      let informes = event.target.files;
      let aux = [];
      for (let clave in informes) {
        aux.push(informes[clave]);
      }
      aux.splice(-2);
      setInformes(aux);
      setInput({ ...input, informes: aux });
    } else {
      setInput({ ...input, [event.target.name]: event.target.value });
    }
  };
  const validate = () => {
    let erroresMensajes = {};
    if (!input.nombre) {
      erroresMensajes.nombre = "Debe ingresar un nombre";
    }
    if (!input.apellido) {
      erroresMensajes.apellido = "Debe ingresar un apellido";
    }
    if (!input.email) {
      erroresMensajes.email = "Debe ingresar un email";
    }
    if (!input.telPrefijo || !input.telNumero) {
      erroresMensajes.telefono = "Debe ingresar un teléfono";
    }
    if (!input.dni) {
      erroresMensajes.dni = "Debe ingresar un DNI";
    }
    if (!input.mensaje) {
      erroresMensajes.mensaje = "Debe ingresar un mensaje";
    }
    if (!input.dniYCredencial) {
      erroresMensajes.dniYcredencial = "Debe ingresar el dni y la credencial";
    }
    if (!input.ordenYMateriales) {
      erroresMensajes.ordenYmateriales = "Debe ingresar la orden y materiales";
    }
    if (!input.informes) {
      erroresMensajes.informes = "Debe ingresar los informes";
    }
    return erroresMensajes;
  };

  const handlerSubmit = async (e) => {
    e.preventDefault();
    let erroresMensajes = validate();
    if (!Object.keys(erroresMensajes).length) {
      let infoSend = input;
      setErrors(erroresMensajes);

      if (dniYCredencial.length > 0) {
        let auxDniYCredencial = [];
        dniYCredencial.forEach((e) => auxDniYCredencial.push(e.name));
        infoSend = {
          ...infoSend,
          dniYCredencial: auxDniYCredencial,
        };
        setInput(infoSend);
      }

      if (ordenYMateriales.length > 0) {
        let auxordenYMateriales = [];
        ordenYMateriales.forEach((e) => auxordenYMateriales.push(e.name));
        infoSend = {
          ...infoSend,
          ordenYMateriales: auxordenYMateriales,
        };
        setInput(infoSend);
      }

      if (informes.length > 0) {
        let auxinformes = [];
        informes.forEach((e) => auxinformes.push(e.name));
        infoSend = {
          ...infoSend,
          informes: auxinformes,
        };
        setInput(infoSend);
      }

      if (
        informes.length === 0 &&
        dniYCredencial.length === 0 &&
        ordenYMateriales.length === 0
      ) {
        infoSend = input;
      }
      let formData = new FormData();
      dniYCredencial.forEach((f) => {
        formData.append("file", f);
      });
      ordenYMateriales.forEach((f) => {
        formData.append("file", f);
      });
      informes.forEach((f) => {
        formData.append("file", f);
      });
      formData.append("infoData", JSON.stringify(infoSend));
      setLoading(true);
      try {
        await Axios.post(
          `${REACT_APP_API_URL}/formularios/prequirurgico`,
          formData,
          { headers: { "Content-type": "multipart/form-data" } }
        );
        setLoading(false);
        Swal.fire({
          title: "Solicitud enviada con éxito",
          confirmButtonText: "Entendido",
          buttonsStyling: false,
          customClass: {
            popup: `${styles.containerAlert}`,
            confirmButton: `${styles.buttonConfirm}`,
            title: `${styles.titleAlert}`,
            htmlContainer: `${styles.containerHtml}`,
          },
          html: `<img src=${successIcon} />`,
        });
        setInput({
          nombre: "",
          apellido: "",
          email: "",
          fechaDelSuceso: "",
          vivencia: "",
          telPrefijo: "",
          telNumero: "",
          dni: "",
          representante: "",
          ig: "",
        });
        setDniYCredencial([]);
        setOrdenYMateriales([]);
        setInformes([]);
      } catch (e) {
        console.log("Error al mandar el formulario", e);
      }
    } else {
      setErrors(erroresMensajes);
      console.log("formulario con error");
    }
  };

  const alertRemoveFile = (name, type) => {
    Swal.fire({
      title: "¿Esta seguro que desea eliminar el archivo adjunto?",
      showCancelButton: true,
      confirmButtonText: "No",
      cancelButtonText: "Eliminar",
      buttonsStyling: false,
      customClass: {
        popup: `${styles.cancelAlert}`,
        title: `${styles.titleAlert}`,
        confirmButton: `${styles.no_btn}`,
        cancelButton: `${styles.eliminar_btn}`,
      },
    }).then((res) => {
      if (type === "dniYCredencial") {
        if (!res.value) {
          let aux = dniYCredencial.filter((f) => f.name !== name);
          setDniYCredencial(aux);

          let list = new DataTransfer();
          Object.values(refDniCredencial.current.files).filter((f) => {
            if (f.name !== name) {
              list.items.add(f);
              return f;
            }
          });

          refDniCredencial.current.files = list.files;
        }
      }

      if (type === "ordenYMateriales") {
        if (!res.value) {
          let aux = ordenYMateriales.filter((f) => f.name !== name);
          setOrdenYMateriales(aux);

          let list = new DataTransfer();
          Object.values(refOrdenMat.current.files).filter((f) => {
            if (f.name !== name) {
              list.items.add(f);
              return f;
            }
          });

          refOrdenMat.current.files = list.files;
        }
      }

      if (type === "informes") {
        if (!res.value) {
          let aux = informes.filter((f) => f.name !== name);
          setInformes(aux);

          let list = new DataTransfer();
          Object.values(refInformes.current.files).filter((f) => {
            if (f.name !== name) {
              list.items.add(f);
              return f;
            }
          });

          refInformes.current.files = list.files;
        }
      }
    });
  };

  const disabledButton = useCallback(() => {
    if (
      !input.nombre ||
      !input.apellido ||
      !input.email ||
      !input.telPrefijo ||
      !input.telNumero ||
      !input.mensaje ||
      !input.dni ||
      !input.dniYCredencial ||
      !input.ordenYMateriales ||
      !input.informes
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [input]);

  useEffect(() => {
    disabledButton();
  }, [input, disabledButton]);

  return (
    <form className={styles.container__form} onSubmit={handlerSubmit}>
      <div
        className={`${styles.container__label_input} ${styles.container__nombre}`}
      >
        <label htmlFor="nombre">Nombre</label>
        <input
          type="text"
          name="nombre"
          id="nombre"
          placeholder="Ej: María"
          onChange={handleChange}
          value={input.nombre}
          autoComplete="off"
          className={input.nombre ? styles.not__empty : null}
        />
        {errors.nombre && (
          <span className={styles.error__message}>{errors.nombre}</span>
        )}
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__apellido}`}
      >
        <label htmlFor="apellido">Apellido</label>
        <input
          type="text"
          name="apellido"
          id="apellido"
          placeholder="Ej: Romero"
          onChange={handleChange}
          value={input.apellido}
          autoComplete="off"
          className={input.apellido ? styles.not__empty : null}
        />
        {errors.apellido && (
          <span className={styles.error__message}>{errors.apellido}</span>
        )}
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__dni}`}
      >
        <label htmlFor="dni">DNI</label>
        <input
          type="number"
          name="dni"
          id="dni"
          placeholder="Ej: 99.999.999"
          onChange={handleChange}
          autoComplete="off"
          className={input.dni ? styles.not__empty : null}
        />
        {errors.dni && (
          <span className={styles.error__message}>{errors.dni}</span>
        )}
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__telefono}`}
      >
        <label htmlFor="teléfono">Teléfono</label>
        <div className={styles.container__telefono_input}>
          <input
            type="number"
            name="telPrefijo"
            id="teléfono"
            placeholder="Prefijo"
            className={`${styles.telefono__prefijo} ${
              input.telPrefijo ? styles.not__empty : null
            }`}
            onChange={handleChange}
            value={input.telPrefijo}
            autoComplete="off"
          />
          <input
            type="number"
            name="telNumero"
            id="teléfono"
            placeholder="Número"
            className={`${styles.telefono__numero} ${
              input.telNumero ? styles.not__empty : null
            }`}
            onChange={handleChange}
            value={input.telNumero}
            autoComplete="off"
          />
          {errors.telefono && (
            <span className={styles.error__message}>{errors.telefono}</span>
          )}
        </div>
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__email}`}
      >
        <label htmlFor="email">E-mail</label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="midireccion@mail.com"
          onChange={handleChange}
          value={input.email}
          autoComplete="off"
          className={input.email ? styles.not__empty : null}
        />
        {errors.email && (
          <span className={styles.error__message}>{errors.email}</span>
        )}
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__dniCredencial}`}
      >
        <label htmlFor="dniYCredencial">Adjuntar foto DNI y credencial</label>

        <input
          type="file"
          name="dniYCredencial"
          id="dniYCredencial"
          onChange={handleChange}
          multiple
          ref={refDniCredencial}
        />

        {!dniYCredencial.length > 0 ? (
          <label htmlFor="dniYCredencial" className={styles.label__custom}>
            <img src={subirArchivo} alt="subir archivo" />
            <span>Seleccionar</span>
          </label>
        ) : (
          dniYCredencial.map((f, index) => (
            <div className={styles.container__label_archivoCargado} key={index}>
              <img
                src={archivoCargado}
                alt="cargado archivo"
                className={styles.archivoCargado__btn}
              />
              <span>{f.name}</span>
              <img
                src={closeBtn}
                alt="close button"
                className={styles.archivoCargado__btn_close}
                onClick={() => {
                  alertRemoveFile(f.name, "dniYCredencial");
                }}
              />
            </div>
          ))
        )}
        {errors.dniYcredencial && (
          <span className={styles.error__message__pedidosMedicos}>
            {errors.dniYcredencial}
          </span>
        )}
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__ordenPedido}`}
      >
        <label htmlFor="ordenYMateriales">
          Adjuntar orden y pedido de materiales (si corresponde)
        </label>

        <input
          type="file"
          name="ordenYMateriales"
          id="ordenYMateriales"
          onChange={handleChange}
          multiple
          ref={refOrdenMat}
        />

        {!ordenYMateriales.length > 0 ? (
          <label htmlFor="ordenYMateriales" className={styles.label__custom}>
            <img src={subirArchivo} alt="subir archivo" />
            <span>Seleccionar</span>
          </label>
        ) : (
          ordenYMateriales.map((f, index) => (
            <div className={styles.container__label_archivoCargado} key={index}>
              <img
                src={archivoCargado}
                alt="cargado archivo"
                className={styles.archivoCargado__btn}
              />
              <span>{f.name}</span>
              <img
                src={closeBtn}
                alt="close button"
                className={styles.archivoCargado__btn_close}
                onClick={() => {
                  alertRemoveFile(f.name, "ordenYMateriales");
                }}
              />
            </div>
          ))
        )}
        {errors.ordenYmateriales && (
          <span className={styles.error__message__pedidosMedicos}>
            {errors.ordenYmateriales}
          </span>
        )}
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__informes}`}
      >
        <label htmlFor="informes">
          Adjuntar informes de estudios relacionados realizados en otra
          institución/ externos a la CURF
        </label>

        <input
          type="file"
          name="informes"
          id="informes"
          onChange={handleChange}
          multiple
          ref={refInformes}
        />

        {!informes.length > 0 ? (
          <label htmlFor="informes" className={styles.label__custom}>
            <img src={subirArchivo} alt="subir archivo" />
            <span>Seleccionar</span>
          </label>
        ) : (
          informes.map((f, index) => (
            <div className={styles.container__label_archivoCargado} key={index}>
              <img
                src={archivoCargado}
                alt="cargado archivo"
                className={styles.archivoCargado__btn}
              />
              <span>{f.name}</span>
              <img
                src={closeBtn}
                alt="close button"
                className={styles.archivoCargado__btn_close}
                onClick={() => {
                  alertRemoveFile(f.name, "informes");
                }}
              />
            </div>
          ))
        )}
        {errors.informes && (
          <span className={styles.error__message__pedidosMedicos}>
            {errors.informes}
          </span>
        )}
      </div>
      <div
        className={`${styles.container__label_input} ${styles.container__mensaje}`}
      >
        <label htmlFor="mensaje">Mensaje</label>
        <textarea
          name="mensaje"
          id="mensaje"
          cols="30"
          rows="3"
          placeholder="Dejanos tu mensaje"
          onChange={handleChange}
          value={input.mensaje}
          autoComplete="off"
          className={input.mensaje ? styles.not__empty : null}
        ></textarea>
        {errors.mensaje && (
          <span className={styles.error__message}>{errors.mensaje}</span>
        )}
      </div>
      {loading ? (
        <div className={styles.loadingContainer}>
          <BounceLoader color="#002856" />
        </div>
      ) : (
        <button
          type="submit"
          className={`${styles.btn__enviar} ${
            disabled ? styles.disabled : null
          }`}
        >
          Enviar solicitud
        </button>
      )}
    </form>
  );
};

export default FormPrequirurgico;
