import flechaDerecha from "./flecha derecha.svg";
import { useNavigate } from "react-router-dom";
import style from "./CardGaleriaNews.module.css";

const CardGaleriaNews = ({ img, title, resume, id }) => {
  let navigate = useNavigate();
  return (
    <div className={style.container__main}>
      <img src={img} alt="imagenPrueba" className={style.img__card} />
      <div className={style.container__content}>
        <span className={style.title}>{title}</span>
        <span className={style.resume}>{resume}</span>
        <div
          className={style.btn__leerNota}
          onClick={() => navigate(`/noticiasDetails/${id}`)}
        >
          <span>Leer nota</span>
          <img src={flechaDerecha} alt="flecha derecha" />
        </div>
      </div>
    </div>
  );
};

export default CardGaleriaNews;
