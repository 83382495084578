import style from "./GuideCard.module.css";
import arrow from "../../assets/arrowYellow.svg";

export const GuideCard = ({ name, resume, image, icon, isLinked }) => {
  const encodedImage = image ? image.replace(/ /g, '%20') : '';

  return (
    <div className={style.containerGral}>
      <div
        className={style.backgroundImage}
        style={{ backgroundImage: `url(${encodedImage})` }}
      >
        <div className={style.filter}>
          <section className={style.front}>
            <img className={style.icon} src={icon} alt="" />
            <div className={style.nameContainer}>
              <h5 className={style.name}>{name}</h5>
            </div>
          </section>
        </div>
        <div className={style.radius}>
          <aside className={style.resumeContainer}>
            <div className={style.box}>
              <span className={style.resume}>{resume}</span>
              <div className={style.moreContainer}>
                {isLinked ? (
                  <span>
                    Leer más
                    <img src={arrow} alt="" />
                  </span>
                ) : (
                  <a href="">
                    <span>Leer más</span>
                    <img src={arrow} alt="" />
                  </a>
                )}
              </div>
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
};

