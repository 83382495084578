import { useState, useEffect } from "react";
import style from "./Backoffice-Equipment.module.css";
import { useNavigate, Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useData, useDeleteData } from "../../../../hooks/api";

export const BackofficeEquipment = () => {
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const area = "equipment"; // Definiendo el área para reutilizar el hook
  const { data: equipmentData, isLoading, error } = useData("equipment");
  const deleteEquipmentMutation = useDeleteData(area);

  useEffect(() => {
    if (equipmentData) {
      setRows(
        equipmentData.map((u) => ({
          id: u.id,
          name: u.name,
          icon: u.icon,
          items: u.items,
        }))
      );
    }
  }, [equipmentData]);

  const deleteEquipment = (id) => {
    deleteEquipmentMutation.mutate(id, {
      onSuccess: () => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
      },
      onError: (error) => {
        console.error("Error deleting equipment:", error);
      },
    });
  };

  const columns = [
    {
      field: "name",
      headerName: "Nombre",
      width: 200,
      alignHeader: "center",
    },
    {
      field: "img",
      headerName: "Ícono",
      width: 100,
      renderCell: (params) => (
        <div>
          {params?.row.icon ? <img src={params.row.icon} alt="" /> : null}
        </div>
      ),
    },
    {
      field: "items",
      headerName: "Ítems",
      width: 450,
      renderCell: (params) => (
        <div>
          {params?.row.items?.map((item, index) => (
            <span key={index}>{item + " | "}</span>
          ))}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Editar/Borrar",
      width: 200,
      renderCell: (params) => (
        <div className="delete-edit-button">
          <Link
            style={{ textDecoration: "none" }}
            to={`/backoffice/equipamientos/edit/${params.row.id}`}
          >
            <button className="userListEdit">Edit</button>
          </Link>
          <DeleteOutlinedIcon
            className="userListDelete"
            onClick={() =>
              window.confirm("Está seguro que desea eliminar este item?")
                ? deleteEquipment(params.row.id)
                : null
            }
          />
        </div>
      ),
    },
  ];

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Error al cargar los datos: {error.message}</div>;
  }

  return (
    <div className={style.container}>
      <div className={style.galleryButtonContainer}>
        <button
          className={style.button}
          onClick={() => navigate("/backoffice/equipamientos/crear")}
        >
          Crear
        </button>
        <button
          onClick={() => navigate("/backoffice/equipamientos/imagenes")}
          className={style.button}
        >
          Galería
        </button>
      </div>
      <div className={style.gridContainer}>
        <DataGrid
          rows={rows}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
        />
      </div>
    </div>
  );
};
