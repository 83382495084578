import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import style from "./Backoffice-NewStaff.module.css";
import { useData, useAddProfession, useGetStaffById, useAddOrUpdateStaff } from "../../../../hooks/api";

export const BackofficeNewStaff = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [search, setSearch] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [units, setUnits] = useState([]);
  const [labs, setLabs] = useState([]);
  const [staff, setStaff] = useState({
    name: "",
    specialty: [],
    profession: "",
  });
  const [staffById, setStaffById] = useState({});
  const [newProf, setNewProf] = useState("");
  const [input, setInput] = useState(false);

  const { data: professionsData } = useData("profesion");
  const { data: staffData } = useGetStaffById(id, { enabled: !!id });
  const { data: specialtiesData } = useData("specialtiesList");
  const { data: unitsData } = useData("unidadesAsistenciales");
  const { data: labsData } = useData("laboratorios");

  const { mutate: addProfession } = useAddProfession();
  const { mutate: addOrUpdateStaff } = useAddOrUpdateStaff();

  useEffect(() => {
    setProfessions(professionsData);
  }, [professionsData]);

  useEffect(() => {
    setSpecialties(specialtiesData);
  }, [specialtiesData]);

  useEffect(() => {
    setUnits(unitsData);
  }, [unitsData]);

  useEffect(() => {
    setLabs(labsData);
  }, [labsData]);

  useEffect(() => {
    if (id && staffData) {
      setStaffById(staffData);
      setSearch(staffData.specialty);
    }
  }, [id, staffData]);

  const getSpecialtyDetails = (specialtyId) => {
    let specialty = specialties.find((specialty) => specialty.id === specialtyId) ||
                   units.find((unit) => unit.id === specialtyId) ||
                   labs.find((lab) => lab.id === specialtyId);

    if (!specialty) return null;

    let label = specialty.name;
    if (specialty.adults === "true") {
      label += " (Adulto)";
    } else if (specialty.kids === "true") {
      label += " (Pediátrico)";
    }

    return { value: specialty.id, label };
  };

  const specialtyOptions = [...specialties, ...units, ...labs].map((item) => {
    const details = getSpecialtyDetails(item.id);
    return details ? details : { value: item.id, label: item.name };
  });

  const selectedSpecialties = specialtyOptions.filter(option =>
    search.includes(option.value)
  );

  const professionOptions = professions.map((profession) => ({
    value: profession.id,
    label: profession.name,
  }));

  const selectedProfession = professionOptions.find(option => 
    option.value === (id ? staffById.profession : staff.profession)
  );

  const handleDesSelectSpecialty = (removedSpecialty) => {
    if (id) {
      setStaffById((prev) => ({
        ...prev,
        specialty: prev.specialty.filter((el) => el !== removedSpecialty.value),
      }));
    } else {
      setStaff((prev) => ({
        ...prev,
        specialty: prev.specialty.filter((el) => el !== removedSpecialty.value),
      }));
    }
    setSearch((prev) => prev.filter((el) => el !== removedSpecialty.value));
  };

  const handleChangeName = (e) => {
    const value = e.target.value;
    if (id) {
      setStaffById((prev) => ({ ...prev, name: value }));
    } else {
      setStaff((prev) => ({ ...prev, name: value }));
    }
  };

  const handleSelectProfession = (selectedOption) => {
    const value = selectedOption?.value || "";
    if (id) {
      setStaffById((prev) => ({ ...prev, profession: value }));
    } else {
      setStaff((prev) => ({ ...prev, profession: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const staffPayload = {
      name: id ? staffById.name : staff.name,
      profession: id ? staffById.profession : staff.profession,
      specialty: search,
    };
    if (id) {
      addOrUpdateStaff({ ...staffPayload, id: staffById.id }, {
        onSuccess: () => navigate("/backoffice/staff"),
      });
    } else {
      addOrUpdateStaff(staffPayload, {
        onSuccess: () => navigate("/backoffice/staff"),
      });
    }
  };

  const handleNewProf = (e) => {
    setNewProf(e.target.value);
  };

  const handleInput = () => {
    setInput(true);
  };

  const handleAdd = (e) => {
    e.preventDefault();
    addProfession(newProf, {
      onSuccess: () => setNewProf(""),
    });
    setInput(false);
  };

  const handleSelectSpecialty = (selectedOptions) => {
    const selectedValues = selectedOptions.map(option => option.value);
    setSearch(selectedValues);
    if (id) {
      setStaffById((prev) => ({ ...prev, specialty: selectedValues }));
    } else {
      setStaff((prev) => ({ ...prev, specialty: selectedValues }));
    }
  };

  return (
    <div className={style.contenedorGral}>
      <div className={style.contenedor}>
        <form className={style.container}>
          <div className={style.title}>
            <label className={style.titleLabel} htmlFor="">
              Nombre
            </label>
            <input
              className={style.titleInput}
              type="text"
              placeholder="Nombre"
              value={id ? staffById.name : staff.name || ""}
              name="name"
              onChange={handleChangeName}
            />
          </div>
          <div className={style.title3}>
            <div className={style.puesto}>
              <label className={style.titleLabel} htmlFor="">
                Puesto
              </label>
              <div>
                <Select
                  options={professionOptions}
                  onChange={handleSelectProfession}
                  value={selectedProfession}
                  className={style.titleInput}
                  placeholder="Seleccionar puesto..."
                  styles={{
                    control: (base) => ({
                      ...base,
                      minWidth: '100%',
                      width: '100%',
                    }),
                    container: (base) => ({
                      ...base,
                      minWidth: '100%',
                      width: '100%',
                    }),
                  }}
                />
                <AddCircleOutlineIcon onClick={handleInput} />
              </div>
            </div>
            {input ? (
              <div className={style.container1}>
                <HighlightOffIcon
                  className={style.img}
                  onClick={() => setInput(false)}
                />
                <div className={style.container}>
                  <input
                    type="text"
                    value={newProf}
                    onChange={handleNewProf}
                    placeholder="Ingrese el nuevo nombre para el puesto"
                  />
                  <button className={style.btn2} onClick={handleAdd}>
                    Agregar
                  </button>
                </div>
              </div>
            ) : null}
          </div>
          <div className={style.title3}>
            <div>
              <label className={style.titleLabel} htmlFor="">
                Especialidad
              </label>
              <Select
                isMulti
                options={specialtyOptions}
                onChange={handleSelectSpecialty}
                value={selectedSpecialties}
                className={style.titleInput}
                placeholder="Seleccionar especialidad..."
                styles={{
                  control: (base) => ({
                    ...base,
                    minWidth: '100%',
                    width: '100%',
                  }),
                  container: (base) => ({
                    ...base,
                    minWidth: '100%',
                    width: '100%',
                  }),
                }}
              />
            </div>
          </div>
          <button className={style.btn} onClick={handleSubmit}>
            Listo
          </button>
        </form>
      </div>
    </div>
  );
};
