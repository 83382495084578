import style from './Backoffice-EditGeneralPage.module.css'
import { BackofficeTemplateDefault } from '../../Backoffice-Components/Backoffice-TemplateDefault/Backoffice-TemplateDefault';
import { BackofficeGeneralAreas } from '../../Backoffice-Components/Backoffice-GeneralAreas/Backoffice-GeneralAreas';

export const BackofficeEditGeneralPage = () => {
  return (
    <div className={style.container}>
        <BackofficeTemplateDefault body={<BackofficeGeneralAreas />}/>
    </div>
  )
}
