import { useState } from "react";
import search from "../../images/search.svg";
import "./Search.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { filterSearch } from "../../actions";
import setSearch from "../../actions/backoffice-actions/setSearch";
import setStaff from "../../actions/backoffice-actions/setStaff";
import setLocation from "../../actions/setLocation";
import { getArrayStaff, getArray, getArrayFilter } from "../../hooks/selectors";

export const Search = ({ bf, staff, onSelect }) => {
  const arrayProps = useSelector(getArrayStaff);
  const array = useSelector(getArray);
  const arrayFilter = useSelector(getArrayFilter);

  const [busqueda, setBusqueda] = useState("");
  const [foco, setFoco] = useState(false);
  const dispatch = useDispatch();

  let accent_map = {
    á: "a",
    é: "e",
    è: "e",
    í: "i",
    ó: "o",
    ú: "u",
    Á: "a",
    É: "e",
    Í: "i",
    Ó: "o",
    Ú: "u",
  };

  function accent_fold(s) {
    if (!s) {
      return "";
    }
    var ret = "";
    for (var i = 0; i < s.length; i++) {
      ret += accent_map[s.charAt(i)] || s.charAt(i);
    }
    return ret;
  }

  const guards = arrayProps
    .map((e) => e.name)
    .concat(arrayProps.map((f) => f.name));

  const filter = (r) => {
    let aux = [];
    let aux2 = [];
    if (bf) {
      if (staff) {
        arrayProps
          ?.sort((a, b) => {
            if (a.name > b.name) return -1;
            if (a.name < b.name) return 1;
            return 0;
          })
          .filter((e) => {
            if (accent_fold(e.name).toLowerCase().startsWith(r.toLowerCase())) {
              aux.unshift(e);
            } else if (
              accent_fold(e.name).toLowerCase().includes(r.toLowerCase())
            ) {
              aux2.unshift(e);
            }
          });
        const resultado = aux;
        dispatch(filterSearch(resultado));
      } else {
        arrayProps
          ?.sort((a, b) => {
            if (a.name > b.name) return -1;
            if (a.name < b.name) return 1;
            return 0;
          })
          .filter((e) => {
            if (accent_fold(e.name).toLowerCase().startsWith(r.toLowerCase())) {
              aux.unshift(e);
            } else if (
              accent_fold(e.name).toLowerCase().includes(r.toLowerCase())
            ) {
              aux2.unshift(e);
            }
          });
        const resultado = aux.concat(
          aux2.sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
          })
        );
        dispatch(filterSearch(resultado));
      }
    } else {
      array
        ?.sort((a, b) => {
          const aName = a.name || a.title || a.nombre || a.nombreA;
          const bName = b.name || b.title || b.nombre || b.nombreA;
          return aName > bName ? -1 : aName < bName ? 1 : 0;
        })
        .filter((e) => {
          const name = e.name || e.title || e.nnombre || e.nombreA;
          if (accent_fold(name)?.toLowerCase().startsWith(r?.toLowerCase())) {
            aux.unshift(e);
          } else if (name?.toLowerCase().includes(r?.toLowerCase())) {
            aux2.unshift(e);
          }
        });
      const resultado = aux
        .concat(
          aux2.sort((a, b) => {
            const aName = a.name || a.title || a.nombre || a.nombreA;
            const bName = b.name || b.title || b.nombre || b.nombreA;
            return aName > bName ? 1 : aName < bName ? -1 : 0;
          })
        )
        .filter((e) => e.name !== "default");

      dispatch(filterSearch(resultado));
    }
  };

  const handleChange = (e) => {
    filter(e.target.value);
    setBusqueda(e.target.value);
  };

  const handleFocus = () => {
    setFoco(true);
  };

  const waitFor = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const handleBlur = async () => {
    await waitFor(200);
    setFoco(false);
  };

  const handleOption = (name) => {
    if (bf) {
      if (staff) {
        dispatch(setStaff(name?.id));
      } else {
        dispatch(setSearch(name?.id));
      }
      onSelect(name); // Call the onSelect callback with the selected specialty
    }
    setBusqueda("");
  };

  const getByQuery = (query) => {
    const results = arrayProps?.filter((specialty) => specialty.id === query);
    return results[0];
  };

  const handleEnter = (e) => {
    if (bf !== true) {
      if (e.key === "Enter") {
        setBusqueda(arrayFilter[0]?.name);
        // logic to handle navigation based on the first filtered result
        setBusqueda("");
      }
    }
  };

  const handleLupa = () => {
    if (bf !== true && window.innerWidth > 607 && foco === true) {
      if (busqueda !== "") {
        // logic to handle navigation based on the first filtered result
        setBusqueda("");
      }
    }
  };

  return (
    <div className="buscador">
      <div className="conteiner">
        <input
          value={busqueda}
          onChange={(e) => handleChange(e)}
          onFocus={(e) => handleFocus(e)}
          onBlur={(e) => handleBlur(e)}
          onKeyPress={(e) => handleEnter(e)}
          className="searchBar"
          placeholder="¿Qué estás buscando?"
        />
        <div className="searchBtn" onClick={(e) => handleLupa(e)}>
          <img className="lupa" src={search} alt="Lupa" />
        </div>
      </div>
      <div className="sugCont">
        <div className={foco && busqueda ? "sugerencias" : "sugerencias falseSearch"}>
          {arrayFilter?.length > 0 ? (
            arrayFilter.map((r, i) =>
              bf !== true && r?.profession ? (
                r.specialty?.map((special, index) => (
                  <Link
                    className="link"
                    key={index}
                    to={
                      getByQuery(special)?.principal
                        ? `/unidadesAsistenciales/${getByQuery(special)?.name}/Staff`
                        : (getByQuery(special)?.adults === "true" &&
                            getByQuery(special)?.kids === "false") ||
                          (getByQuery(special)?.adults === "false" &&
                            getByQuery(special)?.kids === "false")
                        ? `/especialidades/${getByQuery(special)?.name}/Staff/adultos/${getByQuery(special)?.id}`
                        : `/especialidades/${getByQuery(special)?.name}/Staff/pediatricos/${getByQuery(special)?.id}`
                    }
                  >
                    <div className="option">
                      <p className="optionName">{r?.name}</p>
                      <p className="ref">{`${getByQuery(special)?.name.toUpperCase()} ${
                        getByQuery(special)?.adults === "true"
                          ? "ADULTOS"
                          : getByQuery(special)?.kids === "true"
                          ? "PEDIÁTRICO"
                          : ""
                      }`}</p>
                    </div>
                  </Link>
                ))
              ) : (
                <div key={i}>
                  {bf !== true ? (
                    r.link && r.link.includes("http") ? (
                      <a className="link" target="_blank" href={r.link}>
                        <div key={r.id} className="option" onClick={() => handleOption(r.name)}>
                          <p className="optionName">{r.nombreA}</p>
                          <p className="ref">{`${r.sub}`}</p>
                        </div>
                      </a>
                    ) : (
                      <Link
                        onClick={r.name === "Información general" ? () => dispatch(setLocation("mega")) : null}
                        to={
                          r.specialties
                            ? `/estudios/listado/Indicaciones%20y%20preparaciones?name=${r.name}`
                            : r.laboratories
                            ? `/laboratorios/Indicaciones%20y%20preparaciones?name=${r.name}`
                            : r.nombreA
                            ? `${r.link}`
                            : r.nombre
                            ? r.nombre === "Telemedicina"
                              ? `/Telemedicina`
                              : `/sedes/${r.nombre}`
                            : r.title
                            ? `/noticiasDetails/${r.id}`
                            : r.name === "Diagnóstico por imágenes"
                            ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                            : r.adults
                            ? r.adults === "true"
                              ? `/especialidades/${r.name}/Sobre%20el%20servicio/adultos/${r.id}`
                              : `/especialidades/${r.name}/Sobre%20el%20servicio/pediatricos/${r.id}`
                            : r.principal
                            ? `/unidadesAsistenciales/${r.name}/Sobre%20el%20servicio`
                            : r.name === "Información general"
                            ? `/laboratorios/Informacion%20general`
                            : `/laboratorios/${r.name}/Sobre%20el%20servicio/${r.id}`
                        }
                        className="link"
                      >
                        <div key={r.id} className="option" onClick={() => handleOption(r.name)}>
                          <p className="optionName">
                            {r.name === "Diagnóstico por imágenes" ? "Diagnóstico por Imágenes" : r.name || r.title || r.nombre || r.nombreA}
                          </p>
                          {r.laboratories ? (
                            <p className="ref">PREPARACIÓN</p>
                          ) : r.specialties ? (
                            <p className="ref">ESTUDIO COMPLEMENTARIO</p>
                          ) : r.profession ? (
                            <p className="ref">STAFF</p>
                          ) : r.nombreA ? (
                            <p className="ref">{`${r.sub}`}</p>
                          ) : r.nombre ? (
                            r.nombre === "Telemedicina" ? (
                              <p className="ref">TELEMEDICINA</p>
                            ) : (
                              <p className="ref">SEDE</p>
                            )
                          ) : r.title ? (
                            <p className="ref">NOTA</p>
                          ) : r.name == "Diagnóstico por imágenes" ? (
                            <p className="ref">ESPECIALIDAD</p>
                          ) : r.adults ? (
                            r.adults === "true" ? (
                              <p className="ref">ESPECIALIDAD ADULTOS</p>
                            ) : (
                              <p className="ref">ESPECIALIDAD PEDIÁTRICA</p>
                            )
                          ) : r.principal ? (
                            <p className="ref">UNIDAD ASISTENCIAL</p>
                          ) : r.name === "Información general" ? (
                            <p className="ref">LABORATORIOS</p>
                          ) : (
                            <p className="ref">LABORATORIO</p>
                          )}
                        </div>
                      </Link>
                    )
                  ) : (
                    <div key={r.id} className="option" onClick={() => handleOption(r)}>
                      <p className="optionName">{r.name}</p>
                      {r?.name === "Diagnóstico por imágenes" ? (
                        <p className="ref">ESPECIALIDAD</p>
                      ) : r.adults ? (
                        r.adults === "true" ? (
                          <p className="ref">ESPECIALIDAD ADULTOS</p>
                        ) : (
                          <p className="ref">ESPECIALIDAD PEDIÁTRICA</p>
                        )
                      ) : r.principal ? (
                        <p className="ref">UNIDAD ASISTENCIAL</p>
                      ) : guards.includes(`${r.name}`) ? (
                        <p className="ref">GUARDIA</p>
                      ) : (
                        <p className="ref">LABORATORIO</p>
                      )}
                    </div>
                  )}
                </div>
              )
            )
          ) : (
            <p className="option">No encontrado</p>
          )}
        </div>
      </div>
    </div>
  );
};
