import frame from "./Frame.svg";
import icon from "./IconoPrueba.svg";
import backgroundComites from "../../assets/comites.jpg";
import backgroundCoberturas from "../../assets/coberturas medicas.jpg";
import backgroundCircuitos from "../../assets/circuitos medicos.jpg";
import backgroundInstitucional from "../../assets/institucional.jpg";
import backgroundGuardiAdultos from "../../assets/Guardia de adultos.jpg";
import backgroundGuardiaPeditatrica from "../../assets/guardia pediátrica.jpg";
import backgroundDocencia from "../../assets/docencia.png";
import backgroundSugerencias from "../../assets/sugerencias  y agradecimientos.jpg";
import backgroundNuestroEquipo from "../../assets/nuestro equipo.jpg";
import backgroundLaboratorios from "../../assets/laboratorios 2-65.jpg";
import backgroundUnidadesAsistenciales from "../../assets/unidades asistenciales-63.jpg";
import backgroundEstudiosComplementarios from '../../assets/estudiosComplementarios.png';
import style from "./Banner.module.css";
import { useParams, useLocation, useSearchParams } from "react-router-dom";

const Banner = ({ image, title, type, iconProp }) => {
  const [searchParams] = useSearchParams();
  const sec = searchParams.get("sec");
  const { area, name } = useParams();
  const location = useLocation();
  const laboratorio = location.pathname.slice(0, 13);
  return (
    <div className={style.container__main}>
      <img
        src={
          image
            ? image
            : name === "comite"
            ? backgroundComites
            : name === "coberturas"
            ? backgroundCoberturas
            : name === "circuitosMedicos"
            ? backgroundCircuitos
            : name === "institucional"
            ? backgroundInstitucional
            : name === "guardiaDeAdultos" || sec === "Adultos"
            ? backgroundGuardiAdultos
            : name === "guardiaPediatrica" || sec === "Pediátricos"
            ? backgroundGuardiaPeditatrica
            : name === "docencia"
            ? backgroundDocencia
            : name === "sugerencias"
            ? backgroundSugerencias
            : name === "equipo"
            ? backgroundNuestroEquipo
            : laboratorio === "/laboratorios"
            ? backgroundLaboratorios
            : area === "unidadesAsistenciales"
            ? backgroundUnidadesAsistenciales
            : name === "Laboratorio Central"
            ? backgroundLaboratorios
            : backgroundEstudiosComplementarios
        }
        alt="Background"
        className={style.imagen__background}
      />
      <div className={style.container__content}>
        <div className={style.container__frame1}>
          <img src={frame} alt="Frame" />
        </div>
        <div className={style.container__title_image}>
          {area !== "unidadesAsistenciales"
            ? title !== "Diagnóstico por Imágenes" && <span>{type}</span>
            : null}
          <div className={style.titulo}>
            <img src={iconProp ? iconProp : icon} alt="Imagen de Prueba" />
            <h2 className={style.titleContain}>{title}</h2>
          </div>
        </div>
        <div className={style.container__frame2}>
          <img src={frame} alt="Frame" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
