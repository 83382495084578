import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "./Body-Home.css";
import CarouselComponent from "../../Views/Backoffice/Backoffice-Components/Backoffice-Carousel/Backoffice-Carousel";
import laboratorios from "../../images/Laboratorios.svg";
import estudios from "../../images/Estudios.svg";
import miPortal from "../../images/MiPortal.svg";
import telemedicina from "../../images/Telemedicina.svg";
import frame2 from "../../images/Frame2.svg";
import CarouselNews from "../Carousel-news/CarouselNews";
import form1 from "../../images/form.svg";
import form2 from "../../images/form1.svg";
import form3 from "../../images/form2.svg";
import form4 from "../../images/form3.svg";
import frame1 from "../../images/FramePuntos.svg";
import Statistics from "../Statistics/Statistics";
import Modal from "../Modal/Modal";
import { getAnalytics, logEvent } from "firebase/analytics";
import setLocation from "../../actions/setLocation";
import { useCarousel } from "../../hooks/api";

export const BodyHome = () => {
  const dispatch = useDispatch();

  const { data: images } = useCarousel();

  //Firebase Analytics
  //-----------------------------------------------------//
  const analytics = getAnalytics();
  logEvent(analytics, "screen_view", {
    firebase_screen: `Pantalla: Home V2`,
    firebase_screen_class: "Home",
    onclick: "ga('send', 'event', 'Imagen', 'click');",
  });

  return (
    <div className="BodyHomeContainer">
      <article className="Carousel">
        <CarouselComponent
          images={images}
          controllers={window.innerWidth <= 607 ? false : true}
        />
      </article>
      <article className="cardsHomeContainer">
        <div className="cardsHomeContainerSub">
          <Link to="/MiPortal" className="linkCard">
            <section className="cardsHome1">
              <img src={miPortal} alt="" />
              <span>Mi portal</span>
            </section>
          </Link>
          <Link to="Telemedicina" className="linkCard">
            <section className="cardsHome1">
              <img src={telemedicina} alt="" />
              <span href="">Telemedicina </span>
            </section>
          </Link>
          <Link
            to="/laboratorios/Indicaciones%20y%20preparaciones"
            className="linkCard"
            onClick={() => dispatch(setLocation("home"))}
          >
            <section className="cardsHome1">
              <img src={laboratorios} alt="" />
              <span href="">Laboratorios</span>
            </section>
          </Link>
          <Link
            to="/estudios/listado/Indicaciones%20y%20preparaciones"
            className="linkCard"
          >
            <section className="cardsHome1">
              <img src={estudios} alt="" />
              <span href="">Estudios complementarios</span>
            </section>
          </Link>
        </div>
      </article>
      <div className="frameContainerGral">
        <div className="frameContainer">
          <img id="frame1" src={frame1} alt="" />
          <img id="frame2" src={frame1} alt="" />
        </div>
      </div>
      <article className="dataTitleContainer">
        <section className="dataTitle">
          <span id="dataTitle1">Nuestros números</span>
          <span id="dataTitle2">Continuamos creciendo</span>
        </section>
        <aside className="statistics">
          <Statistics />
        </aside>
      </article>
      <div className="frame2ContainerGral">
        <div className="frame2Container">
          <img id="frame3" src={frame2} alt="" />
          <img id="frame4" src={frame2} alt="" />
        </div>
      </div>
      <article className="health">
        <div className="formContainer1">
          <img src={form1} alt="" id="form1" />
        </div>
        <div className="formContainer2">
          <img src={form2} alt="" id="form2" />
        </div>
        <div className="formContainer3">
          <img src={form3} alt="" id="form3" />
        </div>
        <div className="formContainer4">
          <img src={form4} alt="" id="form4" />
        </div>
        <div className="healthContainer">
          <div className="healthContainer1">
            <a href="" id="health1">
              Notas de salud
            </a>
            <div className="health2">
              <a href="">Programa de Buena Salud</a>
            </div>
            <div className="health3">
              <a href="">
                Un espacio donde te compartimos los consejos de nuestros
                profesionales
              </a>
            </div>
            <Link to="/noticias/Programa%20de%20buena%20salud">
              <button id="healthButton">Todas las notas</button>
            </Link>
          </div>
          <section className="carouselNewsSection">
            <CarouselNews />
          </section>
          <Link to="/noticias/Programa%20de%20buena%20salud">
            <button id="healthButton2">Todas las notas</button>
          </Link>
        </div>
      </article>
      <Modal />
    </div>
  );
};
