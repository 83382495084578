import { useState } from "react";
import style from "./GuardiasNav.module.css";
import { Link } from "react-router-dom";
import baby from "../../images/baby.svg";
import persona from "../../images/persona.svg";
import polygon from "../../images/polygon.svg";

export const GuardiasNav = () => {
  let [open, setOpen] = useState(false);

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };
  return (
    <>
      <li
        className={style.liGuardias}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <figure>
          <img src={polygon} alt="" />
        </figure>
        <span className={style.navBarTitle}>Guardias</span>
        <article
          className={
            open ? `${style.article} ${style.visible}` : `${style.article}`
          }
        >
          <section /* className="megaMenuNosotros" */>
            <Link
              style={{ textDecoration: "none" }}
              to="/general/guardiaDeAdultos/Central"
              onClick={(e) => handleMouseLeave(e)}
            >
              <div className={style.megaMenuGuardias}>
                <picture>
                  <img className={style.img} src={persona} alt="" />
                </picture>
                <p>GUARDIA DE ADULTOS</p>
              </div>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to="/general/guardiaPediatrica/Descripción"
              onClick={(e) => handleMouseLeave(e)}
            >
              <div className={style.megaMenuGuardiasBaby}>
                <picture>
                  <img className={style.img} src={baby} alt="" />
                </picture>
                <p>GUARDIA PEDIÁTRICA</p>
              </div>
            </Link>
          </section>
        </article>
      </li>
    </>
  );
};
