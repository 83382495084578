import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./NoticiasDetails.module.css";
import ColumnaDerNews from "../ColumnaDerNews/ColumnaDerNews";
import ContenidoCentralNewsDetails from "../ContenidoCentralNewsDetails/ContenidoCentralNewsDetails";
import BannerNews from "../BannerNews/BannerNews";
import felchaIzquierda from "./flechaIzquierda.svg";
import { useData, useNewsById } from "../../hooks/api";

const NoticiasDetails = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [filteredNews, setFilteredNews] = useState([]);

  const { data: allNews } = useData("news");
  const { data: newsDetail } = useNewsById(id);

  useEffect(() => {
    if (allNews && newsDetail) {
      const newsFilter = allNews.filter((elem) =>
        elem.area.includes(newsDetail.area[0])
      );
      setFilteredNews(newsFilter);
    }
  }, [allNews, newsDetail]);

  return (
    <div className={styles.container__main}>
      <BannerNews area={newsDetail && newsDetail.area[0]} />
      <div className={styles.container__btn_volver}>
        <img
          src={felchaIzquierda}
          alt="felcha izquierda"
          className={styles.btn_image}
          onClick={() => navigate(-1)}
        />
        <span className={styles.volver_span} onClick={() => navigate(-1)}>
          Volver
        </span>
      </div>
      <div className={styles.container__content}>
        <ContenidoCentralNewsDetails news={newsDetail} />
        <ColumnaDerNews area={newsDetail && newsDetail.area[0]} data={filteredNews} />
      </div>
    </div>
  );
};

export default NoticiasDetails;
