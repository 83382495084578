import { useState, useEffect } from "react";
import { DataGrid, GridToolbarContainer, useGridApiRef } from "@mui/x-data-grid";
import { useData, useDeleteData } from "../../../../hooks/api";
import style from "./Backoffice-RaitingExperience.module.css";
import happy from "../../../../images/emotion-happy-line.svg";
import normal from "../../../../images/emotion-normal-line.svg";
import sad from "../../../../images/emotion-unhappy-line.svg";
import GetAppIcon from '@mui/icons-material/GetApp';

function CustomToolbar({ apiRef }) {
  const handleExport = () => {
    apiRef.current.exportDataAsCsv({ 
      fileName: "customerDataBase",
      delimiter: ";",
      utf8WithBom: true 
    });
  };

  return (
    <GridToolbarContainer>
      <button onClick={handleExport} className={style.exportButton}>
        <GetAppIcon style={{ marginRight: 8 }} />
        EXPORT
      </button>
    </GridToolbarContainer>
  );
}

export const BackofficeRaitingExperience = () => {
  const [rows, setRows] = useState([]);
  const [idDelete, setIdDelete] = useState([]);
  const apiRef = useGridApiRef();

  const { data: experience } = useData("userExperience");
  const { mutate: deleteData } = useDeleteData("userExperience");

  const deleteFeedback = async () => {
    idDelete?.map((id) => {
      deleteData(id);
    });
  };

  const columns = [
    { field: "user", headerName: "Usuario", width: 150, headerAlign: "center" },
    {
      field: "value",
      headerName: "Experiencia",
      width: 200,
      headerAlign: "center",
      renderCell: (params) => (
        <div className="check">
          {params.row.value === "Buena" ? (
            <>
              <img className={style.emoticon} src={happy} alt="Buena" />
              <span>Buena</span>
            </>
          ) : params.row.value === "Intermedia" ? (
            <>
              <img className={style.emoticon} src={normal} alt="Intermedia" />
              <span>Intermedia</span>
            </>
          ) : (
            <>
              <img className={style.emoticon} src={sad} alt="Mala" />
              <span>Mala</span>
            </>
          )}
        </div>
      ),
    },
    { field: "date", headerName: "Fecha", width: 150, headerAlign: "center" },
    { field: "comments", headerName: "Comentarios", width: 750, headerAlign: "center" },
  ];

  useEffect(() => {
    setRows(
      experience &&
      experience.map((u) => ({
        id: u.id,
        user: u.user,
        value: u.value,
        date: u.date,
        comments: u.comments,
      }))
    );
  }, [experience]);

  return (
    <div className={style.container}>
      <button onClick={() => apiRef.current.exportDataAsCsv({
        fileName: "customerDataBase",
        delimiter: ";",
        utf8WithBom: true
      })} className={style.exportButton}>
        <GetAppIcon style={{ marginRight: 8 }} />
        EXPORT
      </button>
      {idDelete.length > 0 && (
        <div className={style.containerDelete}>
          <button
            onClick={() =>
              window.confirm(
                "Está seguro que desea eliminar los elementos seleccionados?"
              ) && deleteFeedback()
            }
            className={style.deleteButton}
          >
            Eliminar
          </button>
        </div>
      )}
      <DataGrid
        apiRef={apiRef}
        rows={rows}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
        checkboxSelection
        onSelectionModelChange={(ids) => setIdDelete(ids)}
        components={{ Toolbar: CustomToolbar }}
        componentsProps={{ toolbar: { apiRef } }}
      />
    </div>
  );
};
