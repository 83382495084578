import "./Backoffice-ImageControl.css";
import { useSelector } from "react-redux";
import { BackofficeCarousel } from "../../Backoffice-Components/Backoffice-Carousel/Backoffice-Carousel-Container";
import { BackofficeCarouselMobile } from "../../Backoffice-Components/Backoffice-Carousel/Backoffice-CarouselMobile";
import { BackofficeTemplateDefault } from '../../Backoffice-Components/Backoffice-TemplateDefault/Backoffice-TemplateDefault';

export const BackofficeImageControl = () => {
  const backofficeReducer = useSelector((state) => state.backofficeReducer);
  const { setComponent } = backofficeReducer;

  return (
    <>
    <BackofficeTemplateDefault body={setComponent === "carousel" ? (
              <BackofficeCarousel />
            ) : (
              <BackofficeCarouselMobile />
            )}/>
            </>
  );
};
