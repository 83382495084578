import { useState, useEffect } from "react";
import { ContentLeft } from "../ContentLeft/ContentLeft";
import { ContentRight } from "../ContentRight/ContentRight";
import BannerNews from "../BannerNews/BannerNews";
import flecha from "./felcha izquierda.svg";
import iconBanner from "./iconoBanner.svg";
import { useNavigate } from "react-router-dom";
import backgroundTrabaja from "../../assets/trabaja con nosotros.jpg";
import style from "./TrabajaConNosotros.module.css";
import { useData } from "../../hooks/api";

const TrabajaConNosotros = () => {
  let [rrhh, setRrhh] = useState(null);
  let [docShow, setDocShow] = useState(null);
  let navigate = useNavigate();

  const { data: trabajaConNosotrosData } = useData("trabajaConNosotros");

  useEffect(() => {
    if (trabajaConNosotrosData) {
      let aux = trabajaConNosotrosData;
      let aux2 = aux.filter((el) => el.name === "Información de RRHH");

      setDocShow(aux2[0]);
      setRrhh(aux);
    }
  }, [trabajaConNosotrosData]);

  const docSelection = (doc) => {
    setDocShow(doc);
  };

  return (
    <>
      <BannerNews
        area="Trabajá con nosotros"
        icon={iconBanner}
        background={backgroundTrabaja}
      />
      <div className={style.container__flecha_home}>
        <div
          className={style.container__content_flecha_home}
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={flecha} alt="Flecha" className={style.flecha__home} />
          <span>Volver</span>
        </div>
      </div>
      <div className={style.container__main}>
        <div className={style.container__content}>
          <ContentLeft rrhh={rrhh} docSelection={docSelection} />
          <ContentRight docShow={docShow} />
        </div>
      </div>
    </>
  );
};

export default TrabajaConNosotros;
