import { useState, useEffect } from "react";
import "./Institutional.css";
import { NavBar } from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import { useData } from "../../hooks/api";

export const Institutional = () => {
  const [input, setInput] = useState("");
  const { data: institutionalData } = useData("institucional");

  useEffect(() => {
    if (institutionalData.length > 0) {
      setInput(institutionalData);
    }
  }, [institutionalData]);

  function createMarkup() {
    return { __html: input[0]?.description };
  }

  return (
    <>
      <article>
        <header>
          <NavBar />
        </header>
        <div>
          <div dangerouslySetInnerHTML={createMarkup()} />
        </div>
        <Footer />
      </article>
    </>
  );
};
