import styles from "./ContenidoCentralNewsDetails.module.css";

const ContenidoCentralNewsDetails = ({ news }) => {
  function createMarkup() {
    return { __html: news?.content };
  }
  return (
    <div className={styles.container__main}>
      <div className={styles.container__content}>
        <span className={styles.content_fecha_hora}>{news?.date}</span>
        <h3 className={styles.content_title}>{news?.title}</h3>
        <div className={styles.container__image}>
          {news?.img ? 
          <img
            src={news?.img}
            alt="imagen de prueba"
            className={styles.content_image}
          />
          : null}
        </div>
        <div
          className={styles.content__contenido}
          dangerouslySetInnerHTML={createMarkup()}
        />
      </div>
    </div>
  );
};

export default ContenidoCentralNewsDetails;
