// src/components/Laboratories/IndYPrep/constants.js

export const INDICACIONES_PREPARACIONES = "Indicaciones y preparaciones";
export const COMMON_DESC = `Buscá y seleccioná todos los estudios que figuran en tu pedido médico para acceder a sus respectivas indicaciones. A medida que cliquees en cada estudio, visualizarás en la sección "¿Cómo prepararme?" las indicaciones correspondientes. Una vez seleccionados todos los estudios, podrás descargar la información completa.`;

export const FAST = [
  { name: "Lactantes", hours: "De 2 a 4h" },
  { name: "Bebes de 6 meses a 1 año", hours: "4h" },
  { name: "Niños de 1-5 años", hours: "de 4 a 6h" },
  { name: "Niños de 5-12 años", hours: "8h" },
  { name: "Mayores de 12 años", hours: "De 10 a 12h" },
];

export const LETTER_MAPPING = {
  a: { page: "0", section: "A-B" },
  b: { page: "0", section: "A-B" },
  c: { page: "1", section: "C-D" },
  d: { page: "1", section: "C-D" },
  e: { page: "2", section: "E-F" },
  f: { page: "2", section: "E-F" },
  g: { page: "3", section: "G-I" },
  h: { page: "3", section: "G-I" },
  i: { page: "3", section: "G-I" },
  j: { page: "4", section: "J-L" },
  k: { page: "4", section: "J-L" },
  l: { page: "4", section: "J-L" },
  m: { page: "5", section: "M-Ñ" },
  n: { page: "5", section: "M-Ñ" },
  ñ: { page: "5", section: "M-Ñ" },
  o: { page: "6", section: "O-Q" },
  p: { page: "6", section: "O-Q" },
  q: { page: "6", section: "O-Q" },
  r: { page: "7", section: "R-S" },
  s: { page: "7", section: "R-S" },
  t: { page: "8", section: "T-V" },
  u: { page: "8", section: "T-V" },
  v: { page: "8", section: "T-V" },
  w: { page: "9", section: "W-Z" },
  x: { page: "9", section: "W-Z" },
  y: { page: "9", section: "W-Z" },
  z: { page: "9", section: "W-Z" },
};

export const FILTROS = [
  { vista: "A-B", value: "0" },
  { vista: "C-D", value: "1" },
  { vista: "E-F", value: "2" },
  { vista: "G-I", value: "3" },
  { vista: "J-L", value: "4" },
  { vista: "M-Ñ", value: "5" },
  { vista: "O-Q", value: "6" },
  { vista: "R-S", value: "7" },
  { vista: "T-V", value: "8" },
  { vista: "W-Z", value: "9" },
];

export const ACCENT_MAP = {
  á: "a",
  é: "e",
  è: "e",
  í: "i",
  ó: "o",
  ú: "u",
  Á: "a",
  É: "e",
  Í: "i",
  Ó: "o",
  Ú: "u",
};

export const PAGE_MAPPING = {
  "0": ["A", "B"],
  "1": ["C", "D"],
  "2": ["E", "F"],
  "3": ["G", "H", "I"],
  "4": ["J", "K", "L"],
  "5": ["M", "N", "Ñ"],
  "6": ["O", "P", "Q"],
  "7": ["R", "S"],
  "8": ["T", "U", "V"],
  "9": ["W", "X", "Y", "Z"],
};
