import { SET_STAFF } from "../actions/constantes";
import {
  FILTER_BY_ORIGIN,
  ADD_IMAGE_URL,
  OPEN_MODAL,
  CLOSE_MODAL,
  SET_COMPONENT,
  RESET_BACKGROUND_IMAGE,
  GET_SITES,
  GET_SECTIONS,
  OPEN_SPECIALTY,
  CLOSE_SPECIALTY,
  FILTER_BY_AREA,
  SET_SEARCH,
  CLEAN_SEARCH,
  FILTER_SEARCH_BO,
  SET_SEARCH_ID,
} from "../utils/constantes";

const initialState = {
  area: "",
  section: "",
  url: [],
  setModal: false,
  setComponent: "carousel",
  sites: [],
  sections: [],
  editSpecialty: false,
  search: [],
};

const backofficeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_BY_ORIGIN:
      return {
        ...state,
        section: action.payload,
      };

    case ADD_IMAGE_URL:
      return {
        ...state,
        url: action.payload,
      };

    case OPEN_MODAL:
      return {
        ...state,
        setModal: true,
      };

    case CLOSE_MODAL:
      return {
        ...state,
        setModal: false,
      };

    case SET_COMPONENT:
      return {
        ...state,
        setComponent: action.payload,
      };

    case RESET_BACKGROUND_IMAGE:
      return {
        ...state,
        background: [],
      };

    case GET_SITES:
      return {
        ...state,
        sites: action.payload,
      };

    case GET_SECTIONS:
      return {
        ...state,
        sections: action.payload,
      };

    case OPEN_SPECIALTY:
      return {
        ...state,
        editSpecialty: true,
      };

    case CLOSE_SPECIALTY:
      return {
        ...state,
        editSpecialty: false,
      };

    case FILTER_BY_AREA:
      return {
        ...state,
        area: action.payload,
      };

    case SET_SEARCH:
      return {
        ...state,
        search: [...state.search, action.payload],
      };

    case CLEAN_SEARCH:
      return {
        ...state,
        search: [],
      };
    case SET_STAFF:
      return {
        ...state,
        search: [...state.search, action.payload],
      };

    case FILTER_SEARCH_BO:
      return {
        ...state,
        search: state.search.filter((e) => e !== action.payload),
      };
    case SET_SEARCH_ID:
      return {
        ...state,
        search: [...action.payload],
      };
    default:
      return state;
  }
};

export default backofficeReducer;
