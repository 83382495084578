import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import {
  getSpecialities,
  getLaboratories,
  getStaffMedic,
  getUnits,
  getPreps,
  getStudies,
  getGuides,
  getAdultsGuards,
  getKidsGuards,
  getAllSites,
  switchInitAction,
} from './actions';
import { useEffect } from 'react';
import { useData } from './hooks/api';  // Asegúrate de que la ruta sea correcta

const useInitializer = (switchInit) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { data: specialities } = useData('specialtiesList');
  const { data: laboratories } = useData('laboratorios');
  const { data: staffMedic } = useData('staff');
  const { data: units } = useData('unidadesAsistenciales');
  const { data: preps } = useData('preparationsLabs');
  const { data: studies } = useData('preparationsSpecialties');
  const { data: guides } = useData('guiaPaciente');
  const { data: adultsGuards } = useData('guardiaDeAdultos');
  const { data: kidsGuards } = useData('guardiaPediatrica');
  const { data: sites } = useData('sites');

  useEffect(() => {
    const initializer = async () => {
      if (switchInit === false) {
        dispatch(getSpecialities(specialities));
        dispatch(getLaboratories(laboratories));
        dispatch(getStaffMedic(staffMedic));
        dispatch(getUnits(units));
        dispatch(getPreps(preps));
        dispatch(getStudies(studies));
        dispatch(getGuides(guides));
        dispatch(getAdultsGuards(adultsGuards));
        dispatch(getKidsGuards(kidsGuards));
        dispatch(getAllSites(sites));
        dispatch(switchInitAction());
      }
    };

    initializer();
  }, [
    dispatch,
    switchInit,
    queryClient,
    specialities,
    laboratories,
    staffMedic,
    units,
    preps,
    studies,
    guides,
    adultsGuards,
    kidsGuards,
    sites
  ]);
};

export default useInitializer;
