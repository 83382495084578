/* eslint-disable no-unused-vars */
import { Route, Routes } from "react-router-dom";
import { BackofficeHome } from "../Views/Backoffice/Backoffice-Pages/Backoffice-Home/Backoffice-Home";
import BackofficeLogin from "../Views/Backoffice/Backoffice-Auth/BackofficeLogin";
import { BackofficeImageControl } from "../Views/Backoffice/Backoffice-Pages/Backoffice-ImageControl/Backoffice-ImageControl";
import BackofficeEditDatos from "../Views/Backoffice/Backoffice-Components/Backoffice-Edit-Datos/Backoffice-Edit-Datos.jsx";
import { BackofficeSpecialties } from "../Views/Backoffice/Backoffice-Pages/Backoffice-Specialties/Backoffice-Specialties";
import { BackofficeEditSpecialtiesPage } from "../Views/Backoffice/Backoffice-Pages/Backoffice-EditSpecialtiesPage/Backoffice-EditSpecialtiesPage";
import { BackofficeEditGeneralPage } from "../Views/Backoffice/Backoffice-Pages/Backoffice-EditGeneralPage/Backoffice-EditGeneralPage";
import { BackofficeTemplateDefault } from "../Views/Backoffice/Backoffice-Components/Backoffice-TemplateDefault/Backoffice-TemplateDefault";
import { BackofficeNewSpecialties } from "../Views/Backoffice/Backoffice-Pages/Bakoffice-NewSpecialties/Backoffice-NewSpecialties";
import { BackofficeCurrentSpecialty } from "../Views/Backoffice/Backoffice-Components/Backoffice-CurrentSpecialty/Backoffice-CurrentSpecialty";
import { BackofficeGeneralAreas } from "../Views/Backoffice/Backoffice-Components/Backoffice-GeneralAreas/Backoffice-GeneralAreas";
import { BackofficeNews } from "../Views/Backoffice/Backoffice-Components/Backoffice-News/Backoffice-News.jsx";
import { BackofficeNewsMaker } from "../Views/Backoffice/Backoffice-Components/Backoffice-NewsMaker/Backoffice-NewsMaker";
import { BackofficeInstallations } from "../Views/Backoffice/Backoffice-Components/Backoffice-Installations/Backoffice-Installations";
import { BackofficeNewPreparations } from "../Views/Backoffice/Backoffice-Components/Backoffice-NewPreparations/Backoffice-NewPreparations";
import { BackofficeEditGeneralAreas } from "../Views/Backoffice/Backoffice-Components/Backoffice-Edit-GeneralAreas/Backoffice-Edit-GeneralAreas";
import { BackofficeRaitingExperience } from "../Views/Backoffice/Backoffice-Components/Backoffice-RaitingExperience/Backoffice-RaitingExperience";
import { BackofficeSites } from "../Views/Backoffice/Backoffice-Components/Backoffice-Sites/Backoffice-Sites";
import { BackofficeEditSites } from "../Views/Backoffice/Backoffice-Components/Backoffice-EditSites/Backoffice-EditSites";
import { BackofficePreparations } from "../Views/Backoffice/Backoffice-Components/Backoffice-Preparations/Backoffice-Preparations";
import { BackofficeNewInstallations } from "../Views/Backoffice/Backoffice-Components/Backoffice-NewInstallations/Backoffice-NewInstallations";
import { BackofficeInstallationsImages } from "../Views/Backoffice/Backoffice-Components/Backoffice-InstallationsImages/Backoffice-InstallationsImages";
import Backoffice_Banner from "../Views/Backoffice/Backoffice-Pages/Backoffice-Banner/Backoffice-Banner";
import { BackofficeEquipment } from "../Views/Backoffice/Backoffice-Components/Backoffice-Equipment/Backoffice-Equipment";
import { BackofficeNewEquipment } from "../Views/Backoffice/Backoffice-Components/Backoffice-NewEquipment/Backoffice-NewEquipment";
import { BackofficeEquipmentImages } from "../Views/Backoffice/Backoffice-Components/Backoffice-EquipmentImages/Backoffice-EquipmentImages";
import { BackofficeNewStaff } from "../Views/Backoffice/Backoffice-Components/Backoffice-NewStaff/Backoffice-NewStaff";
import { BackofficeStaff } from "../Views/Backoffice/Backoffice-Components/Backoffice-Staff/Backoffice-Staff";
import { BackofficeGuiaDelPaciente } from "../Views/Backoffice/Backoffice-Components/Backoffice-GuiaDelPaciente/Backoffice-GuiaDelPaciente";
import { BackofficeCrearGuiaDelPaciente } from "../Views/Backoffice/Backoffice-Components/Backoffice-Crear-GuiaDelPaciente/Backoffice-Crear-GuiaDelPaciente";
import ProtectedRoute from "./ProtectedRoute";

export const BackofficeRouter = () => {
  return (
    <Routes>
      <Route path="/login" element={<BackofficeLogin />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <BackofficeHome />
          </ProtectedRoute>
        }
      />
      <Route
        path="/editdatos"
        element={
          <ProtectedRoute>
            <BackofficeEditDatos />
          </ProtectedRoute>
        }
      />
      <Route
        path="/imagenes"
        element={
          <ProtectedRoute>
            <BackofficeImageControl />
          </ProtectedRoute>
        }
      />
      <Route
        path="/:area"
        element={
          <ProtectedRoute>
            <BackofficeSpecialties />
          </ProtectedRoute>
        }
      />
      <Route
        path="/:area/:id"
        element={
          <ProtectedRoute>
            <BackofficeEditSpecialtiesPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/noticias"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeNews />} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/:area"
        element={
          <ProtectedRoute>
            <BackofficeEditGeneralPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/noticias/crear"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeNewsMaker />} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/noticias/editar/:id"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeNewsMaker />} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/preparaciones"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficePreparations />} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/preparaciones/crear"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeNewPreparations />} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/preparaciones/:area/:id"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeNewPreparations />} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/:area/crear"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeNewSpecialties />} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/:area/editar/:id"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeCurrentSpecialty />} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/areasGenerales"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeGeneralAreas />} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/areasGenerales/:area"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeEditGeneralAreas />} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/guiaDelPaciente"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeGuiaDelPaciente />} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/guiaDelPaciente/crear"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault
              body={<BackofficeCrearGuiaDelPaciente />}
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="/guiaDelPaciente/crear/:id"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault
              body={<BackofficeCrearGuiaDelPaciente />}
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="/experiencia"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeRaitingExperience />} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sedes"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeSites />} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/sedes/:id"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeEditSites />} />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/instalaciones"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeInstallations />} />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/instalaciones/crear"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeNewInstallations />} />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/instalaciones/editar/:id"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeNewInstallations />} />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/instalaciones/imagenes"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault
              body={<BackofficeInstallationsImages />}
            />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/banner"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<Backoffice_Banner />} />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/equipamientos"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeEquipment />} />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/equipamientos/crear"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeNewEquipment />} />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/equipamientos/edit/:id"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeNewEquipment />} />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/equipamientos/imagenes"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeEquipmentImages />} />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/staff"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeStaff />} />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/staff/:id"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeNewStaff />} />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/staff/create"
        element={
          <ProtectedRoute>
            <BackofficeTemplateDefault body={<BackofficeNewStaff />} />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
