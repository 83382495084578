import { useState, useEffect } from "react";
import style from "./Backoffice-Installations.module.css";
import { useNavigate, Link } from "react-router-dom";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { DataGrid } from "@mui/x-data-grid";
import { useData, useDeleteData } from "../../../../hooks/api";

export const BackofficeInstallations = () => {
  const [rows, setRows] = useState([]);
  const [installations, setInstallations] = useState([]);
  const navigate = useNavigate();

  const { data: installationsData, isLoading, error } = useData("instalaciones", undefined, undefined, false, false);
  const deleteInstallationMutation = useDeleteData("instalaciones");

  useEffect(() => {
    if (installationsData) {
      setInstallations(installationsData);
    }
  }, [installationsData]);

  useEffect(() => {
    const mappedRows = installations?.map((u) => ({
      id: u.id,
      name: u.name,
      number: u.number,
      icon: u.icon,
    }));
    setRows(mappedRows);
  }, [installations]);

  const deleteInstallation = async (id) => {
    deleteInstallationMutation.mutate(id, {
      onSuccess: () => {
        setInstallations((prev) => prev.filter((item) => item.id !== id));
      },
    });
  };

  const columns = [
    {
      field: "name",
      headerName: "Nombre",
      width: 300,
      alignHeader: "center",
    },
    {
      field: "number",
      headerName: "Valor",
      width: 100,
      alignHeader: "center",
      renderCell: (params) => {
        return (
          <div className={style.numberContainer}>
            <span>{params.row?.number}</span>
          </div>
        );
      },
    },
    {
      field: "icon",
      headerName: "Ícono",
      width: 200,
      renderCell: (params) => {
        return (
          <div className={style.paramImg}>
            {params.row?.icon ? <img src={params.row.icon} alt="icon" /> : null}
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "Editar/Borrar",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="delete-edit-button">
            <Link
              style={{ textDecoration: "none" }}
              to={`/backoffice/instalaciones/editar/${params.row.id}`}
            >
              <button className="userListEdit">Edit</button>
            </Link>
            <DeleteOutlinedIcon
              className="userListDelete"
              onClick={() =>
                window.confirm("Está seguro que desea eliminar esta instalación?")
                  ? deleteInstallation(params.row.id)
                  : null
              }
            />
          </div>
        );
      },
    },
  ];

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Error al cargar los datos: {error.message}</div>;
  }

  return (
    <div className={style.container}>
      <div className={style.create}>
        <button
          onClick={() => navigate("/backoffice/instalaciones/crear")}
          className={style.createButton}
        >
          Nueva instalación
        </button>
        <button
          onClick={() => navigate("/backoffice/instalaciones/imagenes")}
          className={style.createButton}
        >
          Galería de imágenes
        </button>
      </div>
      <div className={style.gridContainer}>
        <DataGrid
          rows={rows}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
        />
      </div>
    </div>
  );
};
