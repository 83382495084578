import { useDispatch } from "react-redux";
import Galeria from "../Galeria/Galeria";
import { sectionShow } from "../../actions";
import sections from "./sections.svg";
import style from "./Instalaciones.module.css";
import { useData} from "../../hooks/api";

function Instalaciones() {
  const dispatch = useDispatch();
  const { data: instalaciones, isLoading: isLoadingInstallations, error: errorInstallations } = useData("instalaciones");
  const { data: instalacionesGallery, isLoading: isLoadingGallery, error: errorGallery } = useData("galeriaInstalaciones");
  const openSections = () => {
    dispatch(sectionShow(true));
  };

  if (isLoadingInstallations || isLoadingGallery) {
    return <div>Cargando...</div>;
  }

  if (errorInstallations || errorGallery) {
    return <div>Error al cargar los datos: {errorInstallations?.message || errorGallery?.message}</div>;
  }

  return (
    <div>
      <img
        src={sections}
        alt="Section"
        className={style.sections__button}
        onClick={openSections}
      />
      <h1 className={style.title}>Instalaciones</h1>
      <div className={style.division}></div>
      <h3 className={style.description}>
        Información destacada de nuestras sedes
      </h3>
      <div className={style.grid}>
        {instalaciones &&
          instalaciones.map((r) => (
            <div className={style.instalacion} key={r.id}>
              <div className={style.img}>
                <img src={r.icon} alt={r.name} />
              </div>
              <div className={style.containerInfo}>
                <h1 className={style.cant}>{r.number}</h1>
                <p className={style.name}>{r.name}</p>
              </div>
            </div>
          ))}
      </div>
      {instalacionesGallery ? <Galeria images={instalacionesGallery.map(image => image.img)} /> : ""}
    </div>
  );
}

export default Instalaciones;
