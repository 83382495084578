import { useEffect } from "react";
import "./Home.css";
import { useDispatch } from "react-redux";
import { NavBar } from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";
import { BodyHome } from "../../components/Body-Home/Body-Home";
import { useData } from "../../hooks/api";
import { getLaboratories, getSpecialities, getUnits } from "../../actions";
import getAllSites from "../../actions/backoffice-actions/getAllSites";
import getStaffMedic from "../../actions/backoffice-actions/getStaffMedic";

export const Home = () => {
  const dispatch = useDispatch();

  const { data: specialtiesData } = useData("specialtiesList");
  const { data: laboratoriesData } = useData("laboratorios");
  const { data: unitsData } = useData("unidadesAsistenciales");
  const { data: sitesData } = useData("sites");
  const { data: staffData } = useData("staff");

  useEffect(() => {
    if (specialtiesData.length) {
      dispatch(getSpecialities(specialtiesData));
    }
  }, [specialtiesData, dispatch]);

  useEffect(() => {
    if (laboratoriesData.length) {
      dispatch(getLaboratories(laboratoriesData));
    }
  }, [laboratoriesData, dispatch]);

  useEffect(() => {
    if (unitsData.length) {
      dispatch(getUnits(unitsData));
    }
  }, [unitsData, dispatch]);

  useEffect(() => {
    if (sitesData.length) {
      dispatch(getAllSites(sitesData));
    }
  }, [sitesData, dispatch]);

  useEffect(() => {
    if (staffData.length) {
      dispatch(getStaffMedic(staffData));
    }
  }, [staffData, dispatch]);

  return (
    <div className="homeContainer">
      <nav className="navBar__Home">
        <Header />
        <NavBar />
      </nav>
      <BodyHome />
      <Footer />
    </div>
  );
};
