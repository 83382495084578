import style from "./Backoffice-Sites.module.css";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useData } from "../../../../hooks/api";

export const BackofficeSites = () => {
  const { data: sites } = useData("sites");
  const rows = sites
    ?.filter((e) => e.nombre !== "Telemedicina")
    .map((u) => {
      return {
        id: u.id,
        nombre: u.nombre,
        barrio: u.barrio,
        domicilio: u.domicilio,
        tel: u.tel,
      };
    });

  const columns = [
    {
      field: "nombre",
      headerName: "Nombre",
      width: 250,
      alignHeader: "center",
    },
    {
      field: "barrio",
      headerName: "Barrio",
      width: 150,
      alignHeader: "center",
    },
    {
      field: "domicilio",
      headerName: "Domicilio",
      width: 350,
      alignHeader: "center",
    },
    {
      field: "tel",
      headerName: "tel",
      width: 250,
      alignHeader: "center",
    },
    {
      field: "",
      headerName: "Editar",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="delete-edit-button">
            <Link
              style={{ textDecoration: "none" }}
              to={`/backoffice/sedes/${params.row.id}`}
            >
              <button className="userListEdit">Edit</button>
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <div className={style.container}>
      <DataGrid
        rows={rows}
        disableSelectionOnClick
        columns={columns}
        pageSize={10}
      />
    </div>
  );
};
