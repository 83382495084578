import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSendUserExperience } from "../../actions/sendUserExperience";
import equis from "../../images/x.svg";
import setValue from "../../actions/setValue";
import "./RaitingExperience.css";
import happy from "../../images/happy.png";
import normal from "../../images/emotion-normal-line.svg";
import sad from "../../images/emotion-unhappy-line.svg";
import close from "../../images/close-line.png";
import setShow from "../../actions/setShow";
import { v4 as uuidv4 } from "uuid";

export const RaitingExperience = () => {
  const [happyOff, setHappyOff] = useState(false);
  const [happyClick, setHappyClick] = useState(false);
  const [normalOff, setNormalOff] = useState(false);
  const [normalClick, setNormalClick] = useState(false);
  const [sadOff, setSadOff] = useState(false);
  const [sadClick, setSadClick] = useState(false);
  const [display, setDisplay] = useState(false);
  const [input, setInput] = useState({
    id: uuidv4(),
    user: "",
    date: "",
    value: "",
    comments: "",
    number: ""
  });
  const dispatch = useDispatch();

  const { sendUserExperience } = useSendUserExperience();

  const experience = [
    {
      value: "Buena",
      number: 1,
      icon: (
        <img
          onClick={() => handleHappy()}
          alt=""
          src={happy}
          className={happyOff || happyClick ? "satisfiedOff" : "satisfiedClass"}
          id="satisfied"
        />
      ),
      label: "Very Satisfied",
    },
    {
      value: "Intermedia",
      number: 2,
      icon: (
        <img
          onClick={() => handleNormal()}
          alt=""
          src={normal}
          className={normalOff || normalClick ? "normalOff" : "normalClass"}
          id="normal"
        />
      ),
      label: "Normal",
    },
    {
      value: "Mala",
      number: 3,
      icon: (
        <img
          onClick={() => handleSad()}
          alt=""
          src={sad}
          className={
            sadOff || sadClick ? "dissatisfiedOff" : "dissatisfiedClass"
          }
          id="dissatisfied"
        />
      ),
      label: "Very Dissatisfied",
    },
  ];

  const addDate = () => {
    const fecha = new Date();
    const months = ["ENERO", "FEBRERO", "MARZO","ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];
    setInput({
      ...input,
      date:
        fecha.getDate() +
        "/" +
        months[fecha.getMonth()] +
        "/" +
        fecha.getFullYear(),
    });
  };

  useEffect(() => {
    addDate();
  }, []);

  const handleHappy = () => {
    setSadClick(false);
    setNormalClick(false);
    setHappyClick(true);
    sendUserExperience({ ...input, value: experience[0].value });
    setInput({ ...input, value: experience[0].value, number: experience[0].number });
    dispatch(setShow(false));
    dispatch(setValue(1));
  };

  const handleNormal = () => {
    setSadClick(false);
    setHappyClick(false);
    setNormalClick(true);
    setInput({ ...input, value: experience[1].value, number: experience[1].number });
    setDisplay(true);
  };

  const handleSad = () => {
    setHappyClick(false);
    setNormalClick(false);
    setSadClick(true);
    setDisplay(true);
    setInput({ ...input, value: experience[2].value, number: experience[2].number });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await sendUserExperience(input);
    dispatch(setValue(input.number));
    setHappyOff(false);
    setNormalOff(false);
    setSadOff(false);
    setSadClick(false);
    setHappyClick(false);
    setNormalClick(false);
    setDisplay(false);
    dispatch(setShow(false));
    setInput({
      id: "",
      value: "",
      comments: "",
    });
  };

  const handleClose = () => {
    setHappyOff(false);
    setNormalOff(false);
    setSadOff(false);
    setSadClick(false);
    setHappyClick(false);
    setNormalClick(false);
    setDisplay(false);
  };

  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const handleCloseShow = () => {
    dispatch(setShow(false));
  };

  const mouseOverDissatisfied = document.getElementById("dissatisfied");
  const mouseOverSatisfied = document.getElementById("satisfied");
  const mouseOverNormal = document.getElementById("normal");

  //Mouse over y out sobre el ícono insatisfecho

  if (mouseOverDissatisfied) {
    mouseOverDissatisfied.addEventListener(
      "mouseover",
      () => {
        setHappyOff(true);
        setNormalOff(true);
      },
      false
    );
    mouseOverDissatisfied.addEventListener(
      "mouseout",
      () => {
        setHappyOff(false);
        setNormalOff(false);
      },
      false
    );
  }

  //Mouse over y out sobre el ícono satisfecho

  if (mouseOverSatisfied) {
    mouseOverSatisfied.addEventListener(
      "mouseover",
      () => {
        setSadOff(true);
        setNormalOff(true);
      },
      false
    );
    mouseOverSatisfied.addEventListener(
      "mouseout",
      () => {
        setSadOff(false);
        setNormalOff(false);
      },
      false
    );
  }

  //Mouse over y out sobre el ícono normal

  if (mouseOverNormal) {
    mouseOverNormal.addEventListener(
      "mouseover",
      () => {
        setSadOff(true);
        setHappyOff(true);
      },
      false
    );
    mouseOverNormal.addEventListener(
      "mouseout",
      () => {
        setSadOff(false);
        setHappyOff(false);
      },
      false
    );
  }

  return (
    <>
      <div className={!display ? "containerRaiting" : "containerRaitingOff"}>
        <div className="cancelContainer">
          <img
            onClick={() => handleCloseShow()}
            src={equis}
            className="equis"
          />
        </div>
        <article className="articleContainer">
          <div className="spanContainerRaiting">
            <span>Valorá tu experiencia en la web</span>
          </div>
          <div className="caritaContainer">
            {experience.map((e, i) => (
              <span className={input.value ? "icon" : "default-Icon"} key={i}>
                {e.icon}
              </span>
            ))}
          </div>
        </article>
      </div>
      <section className={display ? "observations" : "observationsOff"}>
        <img
          className="imgClose"
          src={close}
          onClick={() => handleClose()}
          alt=""
        />
        <div className="spanContainer">
          <span className="spanExperience">
            ¿Qué podemos hacer para mejorar el sitio?
          </span>
        </div>
        <div className="formExperience">
          <form onSubmit={(e) => handleSubmit(e)}>
            <textarea
              onChange={(e) => handleChange(e)}
              name="comments"
              value={input.comments}
              max="140"
              type="text"
              placeholder="Contanos tu experiencia... "
            />
            <input
              name="user"
              onChange={(e) => handleChange(e)}
              className="inputMail"
              placeholder="Dejanos tu mail"
            ></input>
            <button type="submit" className="buttonExperience">
              Enviar
            </button>
          </form>
          <div></div>
        </div>
      </section>
    </>
  );
};
