import sadFace from "./sadFace.svg";

import style from "./InvalidRoute.module.css";
import { Link } from "react-router-dom";

function InvalidRoute() {
  return (
    <div className={style.main}>
      <div className={style.container}>
        <img src={sadFace} alt="sadFace" />
        <h1 className={style.title}>¡Disculpa!</h1>
        <p className={style.txt}>
          La página que buscas es inexistente o inaccesible
        </p>
        <Link to="/">
          <button className={style.btn}>Regresar al home</button>
        </Link>
      </div>
    </div>
  );
}

export default InvalidRoute;
