import { createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import rootReducer from "../reducers/index";
import backofficeReducer from "../reducers/backoffice_reducer";

const allReducers = combineReducers({
  rootReducer,
  backofficeReducer,
});

export const store = createStore(allReducers, applyMiddleware(thunk));
