import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import style from "./Backoffice-Crear-GuiaDelPaciente.module.css";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import { Editor } from "@tinymce/tinymce-react";
import { BackofficeModal } from "../Backoffice-Modal/Backoffice-Modal";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { useDispatch, useSelector } from "react-redux";
import { ImageOutlined } from "@mui/icons-material";
import { useDataById, useAddData, useEditData } from "../../../../hooks/api";
import openModal from "../../../../actions/backoffice-actions/openModal";

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (error) => reject(error);
  });
};

export const BackofficeCrearGuiaDelPaciente = () => {
  const [modal, setModal] = useState(false);
  const [save, setSave] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [guide, setGuide] = useState({
    name: "",
    resume: "",
    img: "",
    icon: "",
    sections: [],
    date: new Date(),
  });
  const [input, setInput] = useState({
    name: "",
    resume: "",
    img: "",
    icon: "",
    sections: [],
    date: new Date(),
  });
  const [inputSection, setInputSection] = useState({
    name: "",
    content: "",
    date: new Date(),
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [showInput, setShowInput] = useState(false);
  const [change, setChange] = useState([]);
  const [filter, setFilter] = useState("");
  const editorRef = useRef(null);

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const backofficeReducer = useSelector((state) => state.backofficeReducer);
  const modalImage = backofficeReducer.setModal;

  const addDataMutation = useAddData("guiaPaciente");
  const editDataMutation = useEditData("guiaPaciente");

  const { data: guideData } = useDataById("guiaPaciente", id);

  useEffect(() => {
    if (guideData) {
      setGuide(guideData);
      setInput(guideData);
    }
  }, [guideData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGuide((prevGuide) => ({ ...prevGuide, [name]: value }));
    setInput((prevInput) => ({ ...prevInput, [name]: value }));
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    const name = e.target.name;
    if (file) {
      if (name === "image") {
        setSelectedImage(file);
        const fileUrl = URL.createObjectURL(file);
        setInput((prevInput) => ({ ...prevInput, img: fileUrl }));
      } else if (name === "icon") {
        setSelectedIcon(file);
        const fileUrl = URL.createObjectURL(file);
        setInput((prevInput) => ({ ...prevInput, icon: fileUrl }));
      }
    }
  };

  const handleFinish = async (e) => {
    e.preventDefault();
    let updatedInput = { ...input };

    if (selectedImage) {
      const base64Image = await fileToBase64(selectedImage);
      updatedInput = {
        ...updatedInput,
        fileContent: base64Image,
        fileName: selectedImage.name,
      };
    }

    if (selectedIcon) {
      const base64Icon = await fileToBase64(selectedIcon);
      updatedInput = {
        ...updatedInput,
        iconContent: base64Icon,
        iconName: selectedIcon.name,
      };
    }

    if (id) {
      editDataMutation.mutate(updatedInput, {
        onSuccess: () => navigate("/backoffice/guiaDelPaciente"),
      });
    } else {
      addDataMutation.mutate(updatedInput, {
        onSuccess: () => navigate("/backoffice/guiaDelPaciente"),
      });
    }
  };

  const handleInput = (e) => {
    e.preventDefault();
    setShowInput(true);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setInputSection((prevSection) => ({ ...prevSection, [name]: value }));
  };

  const inputSubmit = (e) => {
    e.preventDefault();
    if (id) {
      setGuide((prevGuide) => ({
        ...prevGuide,
        sections: [...(prevGuide.sections || []), inputSection],
      }));
    } else {
      setInput((prevInput) => ({
        ...prevInput,
        sections: [...(prevInput.sections || []), inputSection],
      }));
    }
    setInputSection({
      name: "",
      content: "",
      date: new Date(),
    });
    setShowInput(false);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setModal(true);
    const { name, idsec, content, date } = e.currentTarget.dataset;
    setFilter({
      name,
      id: idsec,
      content,
      date,
    });
  };

  const handleDelete = (e) => {
    e.preventDefault();
    const idSection = e.currentTarget.dataset.iddelete;
    const sections = id ? guide.sections : input.sections;
    const updatedSections = sections.filter((section) => section.id !== idSection);

    if (id) {
      setGuide((prevGuide) => ({ ...prevGuide, sections: updatedSections }));
    } else {
      setInput((prevInput) => ({ ...prevInput, sections: updatedSections }));
    }
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    setFilter("");
    setModal(false);
  };

  const handleConfirmModal = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      setDirty(false);
      setSave(true);
      editorRef.current.setDirty(false);
      const obj = {
        name: filter.name,
        id: filter.id,
        content,
        date: filter.date,
      };
      setChange((prevChange) => [...prevChange, obj]);
    }
  };

  const handleSubmitModal = () => {
    if (id) {
      updatePrev();
    }
    setModal(false);
    setSave(false);
  };

  const updatePrev = async () => {
    const edits = change.map((ob) => ob.id);
    const differentItems = (guide.sections || []).filter((e) => !edits.includes(e.id));
    const updatedGuide = {
      ...guide,
      sections: [...differentItems, ...change],
    };
    setGuide(updatedGuide);
  };

  return (
    <div className={style.container}>
      <form className={style.form} action="">
        <div className={style.name}>
          <label className={style.nameLabel} htmlFor="">
            Nombre
          </label>
          <input
            className={style.nameInput}
            type="text"
            value={input.name || ""}
            name="name"
            onChange={handleChange}
          />
        </div>
        <div className={style.resume}>
          <label className={style.resumeLabel} htmlFor="">
            Resumen
          </label>
          <textarea
            className={style.textAreaResume}
            type="text"
            name="resume"
            onChange={handleChange}
            value={input.resume || ""}
          />
        </div>
        <div className={style.image}>
          <label className={style.imageLabel} htmlFor="">
            Imagen
          </label>
          <div className={style.imageContainer}>
            {!input.img ? (
              <ImageSearchIcon className="searchImage" onClick={() => document.getElementById("file").click()} />
            ) : (
              <>
                <CancelRoundedIcon
                  onClick={() => setInput((prevInput) => ({ ...prevInput, img: "" }))}
                  className={style.deleteImg}
                />
                <img className={style.img} src={input.img} alt="" />
              </>
            )}
          </div>
          <input
            onChange={handleUpload}
            className={style.inputImg}
            type="file"
            id="file"
            name="image"
          />
        </div>
        <div className={style.image}>
          <label className={style.imageLabel} htmlFor="">
            Ícono
          </label>
          <div className={style.imageContainer}>
            {!input.icon ? (
              <ImageSearchIcon className="searchImage" onClick={() => document.getElementById("iconFile").click()} />
            ) : (
              <>
                <CancelRoundedIcon
                  onClick={() => setInput((prevInput) => ({ ...prevInput, icon: "" }))}
                  className={style.deleteImg}
                />
                <img className={style.icon} src={input.icon} alt="" />
              </>
            )}
          </div>
          <input
            onChange={handleUpload}
            className={style.inputImg}
            type="file"
            id="iconFile"
            name="icon"
          />
        </div>
        <div className={style.editor}>
          <label className={style.editorLabel} htmlFor="">
            Secciones
          </label>
          <button onClick={handleInput} className={style.buttonContent}>
            Crear
          </button>
          {showInput && (
            <div className={style.inputCreateContainer}>
              <input
                type="text"
                name="name"
                value={inputSection.name}
                onChange={handleChangeInput}
                style={{ border: "solid 1px #002856" }}
              />
              <button className={style.buttonContent} onClick={inputSubmit}>
                Crear
              </button>
            </div>
          )}
          {(id && guide.sections?.length > 0) ? (
  <table className="table">
    <thead>
      <tr className="headerTable">
        <th>Sección</th>
        <th className="thAction">Acción</th>
      </tr>
    </thead>
    <tbody>
      {guide.sections.map((section, index) => (
        <tr key={index}>
          <td className="tdSection">{section.name}</td>
          <td className="buttons">
            <button
              id="buttonEdit"
              onClick={handleEdit}
              className="tableButton"
              data-name={section.name}
              data-idsec={section.id}
              data-content={section.content}
              data-date={section.date}
            >
              Editar
            </button>
            <button
              className="deleteButton"
              data-iddelete={section.id}
              onClick={handleDelete}
            >
              Borrar
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
) : (input.sections?.length > 0) ? (
  <table className="table">
    <thead>
      <tr className="headerTable">
        <th>Sección</th>
        <th className="thAction">Acción</th>
      </tr>
    </thead>
    <tbody>
      {input.sections.map((section, index) => (
        <tr key={index}>
          <td className="tdSection">{section.name}</td>
          <td className="buttons">
            <button
              id="buttonEdit"
              onClick={handleEdit}
              className="tableButton"
              data-name={section.name}
              data-idsec={section.id}
              data-content={section.content}
              data-date={section.date}
            >
              Editar
            </button>
            <button
              className="deleteButton"
              data-iddelete={section.id}
              onClick={handleDelete}
            >
              Borrar
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
) : null}

        </div>
        <div className={style.finish}>
          <button className={style.buttonFinish} onClick={handleFinish}>
            Finalizar
          </button>
        </div>
      </form>
      {modal && (
        <article className={style.modal}>
          <section className={style.modalContainer}>
            <div className={style.modalButtonContainer}>
              <button className={style.openModal} onClick={() => dispatch(openModal())}>
                <ImageOutlined width="40" height="40" />
              </button>
            </div>
            <div
              className={
                modalImage ? style.modalImageContainer : style.modalImageContainerOff
              }
            >
              <BackofficeModal />
            </div>
            <form className={style.formMarkdown}>
              <Editor
                apiKey="slz93nsspb367zfebawu3ptagqrlbuyxfora657iog3xugbm"
                textareaName="description"
                initialValue={filter?.content}
                onInit={(evt, editor) => (editorRef.current = editor)}
                onDirty={() => setDirty(true)}
                init={{
                  selector: "textarea.tinymce",
                  theme: "silver",
                  height: 500,
                  plugins: [
                    "advlist autolink link image lists charmap preview hr anchor pagebreak",
                    "searchreplace wordcount visualblocks code visualchars nonbreaking",
                    "save table contextmenu directionality paste textcolor",
                  ],
                  toolbar:
                    "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media | print preview fullpage | forecolor backcolor",
                  image_advtab: true,
                }}
              />
            </form>
          </section>
          <div className={style.containerButtonGroup}>
            <button className="markdown-button" onClick={handleCloseModal}>
              Cancelar
            </button>
            <button
              className="modified-button"
              onClick={handleConfirmModal}
              disabled={!dirty}
            >
              Modificar
            </button>
            <button
              className={save ? "saveButton" : "saveDisabled"}
              disabled={save ? false : true}
              onClick={handleSubmitModal}
            >
              Guardar
            </button>
          </div>
        </article>
      )}
    </div>
  );
};
