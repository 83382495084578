import { useState } from "react";
import style from "./PacientesNav.module.css";
import { Link } from "react-router-dom";
import especialidades from "../../images/especialidadNav.svg";
import informacion from "../../images/informacion.svg";
import guia from "../../images/guiaNav.svg";
import polygon from "../../images/polygon.svg";
import { useDispatch } from "react-redux";
import { setCategory } from "../../actions";
import setLocation from "../../actions/setLocation";

export const PacientesNav = () => {
  let dispatch = useDispatch();
  let [open, setOpen] = useState(false);

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  const handleCategory = (r) => {
    setOpen(false);
    dispatch(setCategory(r));
  };
  return (
    <>
      <li
        className={style.liPacientes}
        onMouseEnter={(e) => handleMouseEnter(e)}
        onMouseLeave={(e) => handleMouseLeave(e)}
      >
        <figure>
          <img src={polygon} alt="" />
        </figure>
        <div className={style.titleContainer}>
          <span className={style.navBarTitle}>Pacientes</span>
        </div>
        <article
          className={
            open ? `${style.article} ${style.visible}` : `${style.article}`
          }
        >
          <section /* className="megaMenuNosotros" */>
            <div className={style.megaMenuPacientes}>
              <div className={style.megaMenuTitleContainer}>
                <div className={style.megaMenuTitle}>
                  <div className={style.iconsNavContainer}>
                    <img className={style.iconsNav} src={informacion} alt="" />
                  </div>
                  <div className={style.megaMenuPcontainer}>
                    <p className={style.megaMenuP}>INFORMACIÓN GENERAL</p>
                  </div>
                </div>
              </div>
              <ul className={style.pacientesUl}>
                <li>
                  <div
                    className={style.institucionalLink}
                    onClick={(e) => handleMouseLeave(e)}
                  >
                    <Link to="/general/coberturas/Prepagas">Coberturas</Link>
                  </div>
                </li>
                <li>
                  <div
                    className={style.institucionalLink}
                    onClick={(e) => handleMouseLeave(e)}
                  >
                    <Link to="/noticias/Programa%20de%20buena%20salud">
                      Programa de Buena Salud
                    </Link>
                  </div>
                </li>
                <li>
                  <div
                    className={style.institucionalLink}
                    onClick={(e) => handleMouseLeave(e)}
                  >
                    <Link to="/noticias/Actividades%20para%20la%20comunidad">
                      Actividades para la comunidad
                    </Link>
                  </div>
                </li>
                <li>
                  <div
                    className={style.institucionalLink}
                    onClick={(e) => handleMouseLeave(e)}
                  >
                    <Link to="/general/sugerencias/Agradecimientos">
                      Sugerencias y agradecimientos
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
            <div className={style.megaMenuPacientesProfesionales}>
              <div className={style.megaMenuTitleContainer}>
                <div className={style.megaMenuTitle}>
                  <div className={style.iconsNavContainer}>
                    <img
                      className={style.iconsNav}
                      src={especialidades}
                      alt=""
                    />
                  </div>
                  <div className={style.megaMenuPcontainerProfesional}>
                    <p className={style.megaMenuP}>
                      ESPECIALIDADES Y PROFESIONALES
                    </p>
                  </div>
                </div>
              </div>
              <ul className={style.pacientesUl}>
                <li>
                  <div
                    className={style.institucionalLink}
                    onClick={(e) => handleMouseLeave(e)}
                  >
                    <Link
                      to="/search/especialidades?sec=Adultos"
                      onClick={() => handleCategory("Adultos")}
                    >
                      Adultos
                    </Link>
                  </div>
                </li>
                <li>
                  <div
                    className={style.institucionalLink}
                    onClick={(e) => handleMouseLeave(e)}
                  >
                    <Link
                      to="/search/especialidades?sec=Pediátricos"
                      onClick={() => handleCategory("Pediátricos")}
                    >
                      Pediátricos
                    </Link>
                  </div>
                </li>
                <Link
                  style={{ textDecoration: "none" }}
                  onClick={(e) => handleMouseLeave(e)}
                  to="/especialidades/Diagnóstico%20por%20imágenes/Sobre%20el%20servicio/adultos/CvsvECgwinB4GaFnOCX5
              "
                >
                  <li>
                    <div
                      className={style.institucionalLink}
                      onClick={(e) => handleMouseLeave(e)}
                    >
                      <span>Diagnóstico por Imágenes</span>
                    </div>
                  </li>
                </Link>
                <li>
                  <div
                    className={style.institucionalLink}
                    onClick={(e) => handleMouseLeave(e)}
                  >
                    <Link onClick={() => dispatch(setLocation("mega"))} to="/laboratorios/Informacion%20general">
                      Laboratorios
                    </Link>
                  </div>
                </li>
                <li>
                  <div
                    className={style.institucionalLink}
                    onClick={() =>
                      handleCategory("¿Que son las unidades asistenciales?")
                    }
                  >
                    <Link to="/search/unidadesAsistenciales?name=¿Qué%20son%20las%20unidades%20asistenciales?">
                      Unidades asistenciales
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
            <div className={style.megaMenuPacientes}>
              <div className={style.megaMenuTitleContainer}>
                <div className={style.megaMenuTitle}>
                  <div className={style.iconsNavContainer}>
                    <img className={style.iconsNav} src={guia} alt="" />
                  </div>
                  <div className={style.megaMenuPcontainer}>
                    <Link
                      to="/guiaDelPaciente"
                      style={{ margin: "0px" }}
                    >
                      <p className={style.megaMenuP}>GUÍA DEL PACIENTE</p>
                    </Link>
                  </div>
                </div>
              </div>
              <ul className={style.pacientesUl}>
                <li>
                  <div
                    className={style.institucionalLink}
                    onClick={(e) => handleMouseLeave(e)}
                  >
                    <Link to="/guiaDelPaciente">
                     Acceso a la guía
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </article>
      </li>
    </>
  );
};
