import "./Backoffice-Carousel-Container.css";
import CarouselCard from "./CarouselCard/CarouselCard";
import { AddDeleteFiles } from "./Add-Delete-Files";
import { BackofficeNavImage } from "../Backoffice-Nav/Backoffice-Nav-Image";
import imgBackupMobile from "../../assets/imgBackupMobile.jpg";
import { useCarousel, useAddCarouselImage, useDeleteCarouselImage } from "../../../../hooks/api";

export const BackofficeCarouselMobile = () => {
  const { data: imageMobile } = useCarousel({ mobile: 'true' });
  const addCarouselImage = useAddCarouselImage();
  const deleteCarouselImage = useDeleteCarouselImage();

  const handleUpload = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      addCarouselImage.mutate({
        image: imageFile,
        mobile: 'true',
      });
      e.target.value = null;
    }
  };

  const onDelete = async () => {
    imageMobile.map(async (e) => {
      await deleteCarouselImage.mutate(e.id);
    });
  };

  const open = () => {
    document.getElementById("file").click();
  };

  let imageBackup = [
    {
      id: 1,
      url: "",
      img: imgBackupMobile,
      backup: true,
    },
  ];

  return (
    <div className="carousel-body">
      <div className="nav-container-back">
        <BackofficeNavImage />
      </div>
      <div className="addDelete">
        <AddDeleteFiles
          handleUpload={handleUpload}
          onDelete={onDelete}
          open={open}
          images={imageMobile}
        />
      </div>
      <div>
        <h3 className="h3-carousel">Vista previa</h3>
      </div>
      <div className="images__container">
        {imageMobile?.length > 0 ? (
          imageMobile?.map((image, index) => (
            <CarouselCard image={image} collec="carouselMobile" key={index} />
          ))
        ) : (
          <CarouselCard image={imageBackup} collec="carouselMobile" />
        )}
      </div>
    </div>
  );
};
