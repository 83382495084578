import { useEffect, useState } from "react";
import style from "./SedesNav.module.css";
import map from "../Footer/images/Ubicacion.svg";
import { Link } from "react-router-dom";
import telephone from "../Footer/images/Telefono.svg";
import { useData } from "../../hooks/api"; // Ajusta la ruta según sea necesario

export const SedesNav = () => {
  const { data: phoneData, isLoading, error } = useData("phone");
  const [tel, setTel] = useState({ id: "", codArea: "", tel: "" });

  useEffect(() => {
    if (phoneData && phoneData.length > 0) {
      setTel(phoneData[0]);
    }
  }, [phoneData]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading phone data</div>;
  }

  return (
    <div className={style.sedesContainer}>
      <div>
        <Link className={style.linkSedes} to="/sedes/Oncativo">
          <img src={map} alt="" />
          <span className={style.sedes}>Sedes</span>
        </Link>
      </div>
      <div className={style.verticalLine}></div>
      <div className={style.linkPhone}>
        <img src={telephone} alt="" />{" "}
        <a
          className={style.contact}
          href={`tel:(${tel.codArea}) ${tel.tel}`}
        >
          Contacto {`(${tel.codArea})`}
          {tel.tel}
        </a>
      </div>
    </div>
  );
};
