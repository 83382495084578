import { useState } from "react";
import style from "./AutogestionNav.module.css";
import miPortal from "../../images/miPortalNav.svg";
import telemedicina from "../../images/telemedicinaNav.svg";
import polygon from "../../images/polygon.svg";
import { Link } from "react-router-dom";

export const AutogestionNav = () => {
  let [open, setOpen] = useState(false);

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };
  return (
    <>
      <li
        className={style.liGuardias}
        onMouseEnter={(e) => handleMouseEnter(e)}
        onMouseLeave={(e) => handleMouseLeave(e)}
      >
        <figure>
          <img src={polygon} alt="" />
        </figure>
        <span className={style.navBarTitle}>Autogestión</span>
        <article
          className={
            open ? `${style.article} ${style.visible}` : `${style.article}`
          }
        >
          <section /* className="megaMenuNosotros" */>
            <Link
              style={{ textDecoration: "none" }}
              to="/MiPortal"
              onClick={(e) => handleMouseLeave(e)}
            >
              <div className={style.megaMenuGuardias}>
                <picture>
                  <img className={style.img} src={miPortal} alt="" />
                </picture>
                <p>MI PORTAL</p>
              </div>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to="/Telemedicina"
              onClick={(e) => handleMouseLeave(e)}
            >
              <div className={style.megaMenuGuardiasBaby}>
                <picture>
                  <img className={style.imgTele} src={telemedicina} alt="" />
                </picture>
                <p>TELEMEDICINA</p>
              </div>
            </Link>
          </section>
        </article>
      </li>
    </>
  );
};
