/* eslint-disable react/no-unknown-property */
import { useState } from "react";
import { useParams } from "react-router-dom";
import "./Backoffice-EditSpecialty.css";
import { BackofficeCurrentSpecialty } from "../Backoffice-CurrentSpecialty/Backoffice-CurrentSpecialty";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import style from "../../Backoffice-Pages/Bakoffice-NewSpecialties/Backoffice-NewSpecialties.module.css";
import { useData, useDataById, useEditData } from "../../../../hooks/api";
import { fileToBase64 } from "../../../../utils/files";

export const BackofficeEditSpecialty = () => {
  const [edited, setEdited] = useState();
  const [nextComponent, setNextComponent] = useState(false);
  const params = useParams();
  const id = params.id;
  const { area } = useParams();

  let dbQuery = area;

  if (dbQuery === "especialidades") {
    dbQuery = "specialtiesList";
  } else {
    dbQuery = area;
  }

  const { data: sites } = useData("sites");
  const { data: sections } = useData("sections");
  const editDataMutation = useEditData(dbQuery);
  const { data: previousData } = useDataById(dbQuery, id);
  const current = edited || previousData || {};

  const editSpecialty = async () => {
    editDataMutation.mutate(current);
  };

  const handleChange = (e) => {
    setEdited({ ...current, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    editSpecialty();
    setNextComponent(true);
  };

  const handleCheckSites = (e) => {
    let nombre = e.target.getAttribute("datanombre");
    let id = e.target.getAttribute("dataidsite");
    let pais = e.target.getAttribute("datapais");
    let provincia = e.target.getAttribute("dataprovincia");
    let barrio = e.target.getAttribute("databarrio");
    let domicilio = e.target.getAttribute("datadireccion");
    let tel = e.target.getAttribute("datatel");
    let content = e.target.getAttribute("datacontent");
    let img = e.target.getAttribute("dataimg");
    let galeria = e.target.getAttribute("datagaleria");
    let diasHorarios = e.target.getAttribute("datadias");
    let mail = e.target.getAttribute("datamail");

    const objectValue = {
      nombre,
      id,
      pais,
      provincia,
      barrio,
      domicilio,
      tel,
      img,
      galeria,
      content,
      diasHorarios,
      mail,
    };

    if (e.target.checked === true) {
      setEdited({
        ...current,
        sites: [...(current.sites || []), objectValue],
      });
    }
    if (e.target.checked === false) {
      setEdited({
        ...current,
        sites: (current.sites || []).filter((e) => e.name !== objectValue.name),
      });
    }
  };

  const handleCheckSections = (e) => {
    let name = e.target.getAttribute("dataname");
    let id = e.target.getAttribute("dataid");
    let content = e.target.getAttribute("datacontent");
    let index = e.target.getAttribute("index");

    const objectValue = { name, id, content, index };

    if (e.target.checked === true) {
      setEdited({
        ...current,
        sections: [...(current.sections || []), objectValue],
      });
    }
    if (e.target.checked === false) {
      setEdited({
        ...current,
        sections: (current.sections || []).filter(
          (e) => e.name !== objectValue.name
        ),
      });
    }
  };

  const handleUpload = (e) => {
    uploadImageAsPromise({
      img: e.target.files[0],
      name: e.target.files[0]?.name,
    });
  };

  const uploadImageAsPromise = async (uploadFile) => {
    const { img, name } = uploadFile;
    const base64File = await fileToBase64(img);
    const formData = {
      fileContent: base64File,
      fileName: name,
    };
    setEdited({
      ...current,
      ...formData,
      img: URL.createObjectURL(img),
    });
  };

  const open = () => {
    document.getElementById("file").click();
  };

  const handleChangeImg = (e) => {
    e.preventDefault();
    open();
  };

  return (
    <div className={style.container}>
      {nextComponent === false ? (
        <div className={style.editContainer}>
          <div className={style.titleContainer}>
            <label className={style.title}>Editar {current?.name || ""}</label>
          </div>
          <form className={style.formEditSpecialties} action="">
            <>
              <label className={style.headersLabel} htmlFor="name">
                Nombre
              </label>
              <input
                id="name"
                name="name"
                value={current?.name || ""}
                required
                type="text"
                className={style.inputNameEdit}
                onChange={(e) => handleChange(e)}
              />
            </>
            {id && area !== "unidadesAsistenciales" ? null : area ===
                "especialidades" || area === "unidadesAsistenciales" ? (
              <>
                <label className={style.headersLabel}>Tipo</label>
                <div
                  className={
                    area === "unidadesAsistenciales"
                      ? style.principalProp
                      : style.adultsOrKids
                  }
                >
                  <div
                    className={
                      area === "unidadesAsistenciales"
                        ? style.adultsOrkidsOff
                        : null
                    }
                  >
                    <input
                      id="adults"
                      name="adults"
                      value={current?.adults === "false" ? true : false}
                      type="checkbox"
                      onChange={(e) => handleChange(e)}
                      disabled={area === "especialidades" ? true : false}
                    />
                    <label htmlFor="adults">Adultos</label>
                  </div>
                  <div>
                    <input
                      className={style.inputUni}
                      id="kids"
                      name={
                        area === "unidadesAsistenciales" ? "principal" : "kids"
                      }
                      value={
                        area === "unidadesAsistenciales"
                          ? current?.principal === "false"
                            ? true
                            : false
                          : current?.kids === "false"
                          ? true
                          : false
                      }
                      checked={current?.principal === "true" ? true : false}
                      type="checkbox"
                      onChange={(e) => handleChange(e)}
                      disabled={area === "especialidades" ? true : false}
                    />
                    <label className={style.uniOrKids} htmlFor="adults">
                      {area === "unidadesAsistenciales"
                        ? "Unidad Principal"
                        : "Niños"}
                    </label>
                  </div>
                </div>
              </>
            ) : null}
            <label className={style.headersLabel}>Imagen</label>
            <div className={style.imageSpecialtyContainer}>
              <input
                onChange={handleUpload}
                className={style.uploadImgSpecialty}
                type="file"
                id="file"
              />
              <img
                className={style.imageHeaderSpecialty}
                src={current?.img || ""}
                alt=""
              />
              {current?.img ? (
                <button
                  className={style.changeImg}
                  onClick={(e) => handleChangeImg(e)}
                >
                  Cambiar...
                </button>
              ) : (
                <>
                  <span>Cargar imagen</span>
                  <ImageSearchIcon
                    className={style.searchImage}
                    onClick={() => open()}
                  />
                </>
              )}
            </div>
            <div>
              <label className={style.headersLabel} htmlFor="sites">
                Sedes
              </label>
              <div className={style.siteContainer}>
                <div>
                  {sites &&
                    sites.map((site, index) => (
                      <div className={style.siteMap} key={index}>
                        <input
                          type="checkbox"
                          className={style.inputDisplayFlex}
                          id={`${site.name}`}
                          key={index}
                          onChange={(e) => handleCheckSites(e)}
                          name="sites"
                          checked={
                            current?.sites?.find((e) =>
                              e.nombre === `${site.nombre}` ? true : false
                            )
                          }
                          datanombre={site.nombre}
                          dataidsite={site.id}
                          datapais={site.pais}
                          dataprovincia={site.provincia}
                          databarrio={site.barrio}
                          datadireccion={site.domicilio}
                          datadias={site.diasHorarios}
                          datatel={site.tel}
                          datagaleria={site.galeria}
                          dataimg={site.img}
                          datacontent={site.content}
                          datamail={site.mail}
                          value={site}
                        />
                        <label htmlFor={`${site.nombre}`}>{site.nombre}</label>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <label className={style.headersLabel} htmlFor="section">
              Secciones base
            </label>
            <div className={style.sectionsContainer}>
              <div>
                {sections &&
                  sections.map((sec, index) => (
                    <div className={style.siteMap} key={index}>
                      <input
                        type="checkbox"
                        className={style.inputDisplay}
                        id={`${sec.name}`}
                        key={index}
                        index={sec.index}
                        onChange={(e) => handleCheckSections(e)}
                        checked={current?.sections?.find((e) =>
                          e.name === `${sec.name}` ? true : false
                        )}
                        name={`${sec.name}`}
                        value={sec}
                        dataname={sec.name}
                        dataid={sec.id}
                        datacontent={sec.content}
                      />
                      <label htmlFor={`${sec.name}`}>{sec.name}</label>
                    </div>
                  ))}
              </div>
            </div>
            <div className={style.nextEditButtonContainer}>
              <button
                className={style.nextButton}
                type="submit"
                onClick={(e) => handleSubmit(e)}
              >
                Siguiente
              </button>
            </div>
          </form>
        </div>
      ) : (
        <BackofficeCurrentSpecialty />
      )}
    </div>
  );
};