import { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useData, useUpdateEmail, createEmail } from "../../../../hooks/api";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";

export default function BackofficeEmail() {
  const queryClient = useQueryClient();
  const [email, setEmail] = useState({ id: "", email: "" });
  const [input, setInput] = useState({ email: "" });

  const { data: emailData, error, isLoading } = useData("email", undefined, undefined, false, false);
  const updateEmailMutation = useUpdateEmail();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email.email) {
      try {
        const response = await createEmail(input.email);
        setEmail({
          ...email,
          email: response.email,
        });
        alert("Email creado");
      } catch (e) {
        console.log(e);
      }
    } else {
      updateEmailMutation.mutate(
        { currentEmail: email.email, newEmail: input.email },
        {
          onSuccess: () => {
            setEmail({
              ...email,
              email: input.email,
            });
            alert("Email actualizado!");
          },
          onError: (error) => {
            console.error("Error al actualizar el email:", error);
          },
        }
      );
    }

    queryClient.invalidateQueries("email");
    setInput({ email: "" });
  };

  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (emailData && emailData.length > 0) {
      setEmail({
        id: emailData[0].id,
        email: emailData[0].email,
      });
    }
  }, [emailData]);

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Error al cargar el email</div>;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Card sx={{ padding: "10px" }}>
        <h2>Email de contacto</h2>
        <Card sx={{ display: "flex", justifyContent: "space-around" }}>
          <form onSubmit={handleSubmit}>
            <TextField
              type="email"
              name="email"
              id="email"
              onChange={handleChange}
              value={input.email}
              autoFocus
              label="Email"
              sx={{ margin: "10px", padding: "10px", width: "500px" }}
            />
            <Button type="submit" variant="outlined" sx={{ margin: "10px" }}>
              {email.email ? "Editar" : "Crear"}
            </Button>
          </form>
        </Card>
        <hr />
        <Card raised={true} sx={{ margin: "10px", padding: "10px" }}>
          <h4>Email vigente: </h4>
          <p>{email.email}</p>
        </Card>
      </Card>
    </Box>
  );
}
