export const FILTER_BY_ORIGIN = "FILTER_BY_ORIGIN";
export const ADD_IMAGE_URL = "ADD_IMAGE_URL";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const SET_COMPONENT = "SET_COMPONENT";
export const RESET_BACKGROUND_IMAGE = "RESET_BACKGROUND_IMAGE";
export const GET_SITES = "GET_SITES";
export const GET_STAFF_MEDIC = "GET_STAFF_MEDIC";
export const GET_SECTIONS = "GET_SECTIONS";
export const OPEN_SPECIALTY = "OPEN_SPECIALTY";
export const CLOSE_SPECIALTY = "CLOSE_SPECIALTY";
export const FILTER_BY_AREA = "FILTER_BY_AREA";
export const SET_SEARCH = "SET_SEARCH";
export const FILTER_SEARCH_BO = "FILTER_SEARCH_BO";
export const SET_SEARCH_ID = "SET_SEARCH_ID";
export const CLEAN_SEARCH = "CLEAN_SEARCH";
