import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import style from "./Backoffice-NewEquipment.module.css";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useDataById, useAddData, useEditData } from "../../../../hooks/api";

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = error => reject(error);
  });
};

export const BackofficeNewEquipment = () => {
  const [value, setValue] = useState({ data: "" });
  const [input, setInput] = useState({
    name: "",
    img: "",
    items: [],
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const { id } = useParams();
  const navigate = useNavigate();
  const area = "equipment";
  const { data: dataEquipment, isLoading, error } = useDataById(area, id);
  const equipment = dataEquipment;
  const addDataMutation = useAddData(area);
  const editDataMutation = useEditData(area);

  useEffect(() => {
    if (equipment && id) {
      setInput(equipment);
      if (equipment.img) {
        setPreviewUrl(equipment.img);
      }
    }
  }, [equipment, id]);

  const handleChangeText = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value || "", 
    });
  };

  const handleChangeValue = (e) => {
    let data = e.target.value;
    setValue({ data: data || "" }); 
  };

  const deleteItem = (e, item) => {
    e.preventDefault();
    setInput({
      ...input,
      items: input.items.filter((e) => e !== item),
    });
  };

  const textSubmit = (e) => {
    e.preventDefault();
    if (value.data) {
      setInput((prevInput) => ({
        ...prevInput,
        items: [...prevInput.items, value.data],
      }));
      setValue({ data: "" });
    }
  };

  const handleIcons = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setSelectedFile(file);
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let updatedInput = { ...input };
    if (selectedFile) {
      const base64File = await fileToBase64(selectedFile);
      updatedInput = {
        ...input,
        fileContent: base64File,
        fileName: selectedFile.name,
      };
    }

    if (id) {
      editDataMutation.mutate(updatedInput, {
        onSuccess: () => {
          navigate("/backoffice/equipamientos");
        },
      });
    } else {
      addDataMutation.mutate(updatedInput, {
        onSuccess: () => {
          navigate("/backoffice/equipamientos");
        },
      });
    }
  };

  const deleteIcon = (e) => {
    e.preventDefault();
        setInput({
          ...input,
          img: "",
        });
        setPreviewUrl(null);
  };

  const open = (e) => {
    e.preventDefault();
    document.getElementById("file").click();
  };

  if (id && isLoading) {
    return <div>Cargando...</div>;
  }

  if (id && error) {
    return <div>Error al cargar los datos: {error.message}</div>;
  }

  return (
    <div className={style.container}>
      <form action="" className={style.form}>
        <div className={style.name}>
          <label className={style.nameLabel} htmlFor="">
            Nombre
          </label>
          <input
            className={style.nameInput}
            type="text"
            required
            placeholder={input?.name || "Nombre"}
            value={input?.name || ""}
            name="name"
            onChange={handleChangeText}
          />
        </div>
        <div className={style.icon}>
          <label className={style.iconLabel} htmlFor="">
            Ícono
          </label>
          <div className={style.inputContainer}>
            {!previewUrl ? (
              <>
                <AddPhotoAlternateIcon
                  className={style.iconElement}
                  onClick={open}
                />
                <input
                  className={style.iconInput}
                  type="file"
                  name="file"
                  id="file"
                  onChange={handleIcons}
                />
              </>
            ) : (
              <div className={style.cancel}>
                <CancelRoundedIcon
                  className={style.x}
                  onClick={(e) => deleteIcon(e, input?.img)}
                />
                <img className={style.img} src={previewUrl} alt="Icon" />
              </div>
            )}
          </div>
        </div>
        <div className={style.indications}>
          <label className={style.indicationsLabel} htmlFor="">
            Ítems
          </label>
          <div className={style.indicationsContainerButton}>
            <div className={style.containerNew}>
              <label htmlFor="">Crear nuevo: </label>
              <input
                type="text"
                name="name"
                id=""
                value={value.data || ""}
                onChange={handleChangeValue}
              />
              <button onClick={textSubmit}>
                {input?.items?.length ? "Modificar" : "Crear"}
              </button>
            </div>
            <div className={style.items}>
              {input?.items?.map((item, index) => (
                <div className={style.itemCancel} key={index}>
                  <CancelRoundedIcon
                    className={style.x}
                    onClick={(e) => deleteItem(e, item)}
                  />
                  <span>{item}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={style.buttonCont}>
          <button
            className={style.buttonSubmit}
            onClick={handleSubmit}
          >
            {id ? "Actualizar" : "Crear"}
          </button>
        </div>
      </form>
    </div>
  );
};
