import { useState } from "react";
import style from "./Backoffice-Banner.module.css";
import { useData, useEditData, useAddData } from "../../../../hooks/api";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (error) => reject(error);
  });
};

function Backoffice_Banner() {
  const { data: images, isLoading, error } = useData("modal");
  const editImageMutation = useEditData("modal");
  const addImageMutation = useAddData("modal");

  const [selectedDesktopFile, setSelectedDesktopFile] = useState(null);
  const [selectedMobileFile, setSelectedMobileFile] = useState(null);
  const [previewDesktop, setPreviewDesktop] = useState("");
  const [previewMobile, setPreviewMobile] = useState("");

  const handleImg = (e, type) => {
    const file = e.target.files[0];
    const previewUrl = URL.createObjectURL(file);

    if (type === "desktop") {
      setSelectedDesktopFile(file);
      setPreviewDesktop(previewUrl);
    } else {
      setSelectedMobileFile(file);
      setPreviewMobile(previewUrl);
    }
  };

  const handleActive = () => {
    if (images) {
      images.forEach((img) => {
        editImageMutation.mutate({
          id: img.id,
          active: !img.active,
        });
      });
    }
  };

  const handleSave = async () => {
    const base64FileDesktop = selectedDesktopFile
      ? await fileToBase64(selectedDesktopFile)
      : null;
    const base64FileMobile = selectedMobileFile
      ? await fileToBase64(selectedMobileFile)
      : null;

    if (images.length === 0) {
      // No hay registros existentes, se deben crear nuevos
      if (selectedDesktopFile) {
        addImageMutation.mutate({
          fileContent: base64FileDesktop,
          fileName: selectedDesktopFile.name,
          type: "desktop",
        });
      }

      if (selectedMobileFile) {
        addImageMutation.mutate({
          fileContent: base64FileMobile,
          fileName: selectedMobileFile.name,
          type: "mobile",
        });
      }
    } else {
      // Existen registros, se deben actualizar
      if (selectedDesktopFile) {
        const desktopImage = images.find((img) => img.type === "desktop");
        if (desktopImage) {
          editImageMutation.mutate({
            id: desktopImage.id,
            fileContent: base64FileDesktop,
            fileName: selectedDesktopFile.name,
          });
        }
      }

      if (selectedMobileFile) {
        const mobileImage = images.find((img) => img.type === "mobile");
        if (mobileImage) {
          editImageMutation.mutate({
            id: mobileImage.id,
            fileContent: base64FileMobile,
            fileName: selectedMobileFile.name,
          });
        }
      }
    }
  };

  const open = (type) => {
    document.getElementById(type).click();
  };

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Error al cargar las imágenes: {error.message}</div>;
  }

  const imgDesk = images && images.filter((r) => r.type === "desktop");
  const imgCel = images && images.filter((r) => r.type === "mobile");

  return (
    <div className={style.father}>
      <h1 className={style.title}>Modal publicitario</h1>
      <div className={style.container}>
        <div className={style.containerDispositive}>
          <h2 className={style.dispositive}>Modal Desktop</h2>
          <button className={style.btn} onClick={() => open("desktop")}>
            <AddPhotoAlternateIcon className={style.iconElement} />
            Subir foto
          </button>
          <input
            type="file"
            onChange={(e) => handleImg(e, "desktop")}
            style={{ display: "none" }}
            id="desktop"
          />
          <img
            className={style.imgD}
            src={previewDesktop || (imgDesk.length > 0 ? imgDesk[0]?.img : "")}
            alt="Modal Desktop"
          />
        </div>
        <div className={style.containerDispositive}>
          <h2 className={style.dispositive}>Modal Mobile</h2>
          <button className={style.btn} onClick={() => open("mobile")}>
            <AddPhotoAlternateIcon className={style.iconElement} />
            Subir foto
          </button>
          <input
            type="file"
            onChange={(e) => handleImg(e, "mobile")}
            style={{ display: "none" }}
            id="mobile"
          />
          <img
            className={style.imgM}
            src={previewMobile || (imgCel.length > 0 ? imgCel[0]?.img : "")}
            alt="Modal Mobile"
          />
        </div>
      </div>
      <div className={style.containerModal}>
        <button
          onClick={handleActive}
          className={
            images && images.some((i) => i.active) ? style.btnOff : style.btnOn
          }
        >
          {images && images.some((i) => i.active)
            ? "Inhabilitar Modal"
            : "Habilitar Modal"}
        </button>
        <button className={style.btnGrabar} onClick={handleSave}>
          Grabar
        </button>
      </div>
    </div>
  );
}

export default Backoffice_Banner;
