import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { sectionShow } from "../../../actions";
import setContent from "../../../actions/setContent";
import style from "./Guide-ContentRight.module.css";
import menu from "./sections.svg";

export const GuideContentRight = () => {
  const dispatch = useDispatch();
  const { name, id } = useParams();
  const rootReducer = useSelector((state) => state.rootReducer);
  const { guides, content } = rootReducer;
  const [current, setCurrent] = useState("");

  const openSections = () => {
    dispatch(sectionShow(true));
  };

  const filter = () => {
    let result = guides && guides?.find((guide) => guide.name == name);
    let currentSection = result?.sections?.filter(section => section.id === id)
    setCurrent(currentSection);
    if(currentSection?.length > 0) {
        dispatch(setContent(currentSection[0]?.content))
    }
  };

  function createMarkup() {
    return { __html: content };
  }

  useEffect(() => {
    filter();
  }, [guides, name, id]);

  useEffect(() => {
    createMarkup();
  }, []);

  return (
    <div className={style.sectionContainer}>
      <div className={style.content}>
        <div className={style.cont}>
          <div
            className={style.container}
          >
              <span className={style.title}>
                {current && current[0]?.name}
              </span>
            <img
              src={menu}
              onClick={openSections}
              className={style.img}
              alt="menu"
            />
          </div>
            <div className={style.division}></div>
        </div>
        <div className={style.p} dangerouslySetInnerHTML={createMarkup()} />
      </div>
    </div>
  );
};
