import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUploadImageToS3 } from "../../../../hooks/api";
import addImageUrl from "../../../../actions/backoffice-actions/addImageUrl";
import closeModalImage from "../../../../actions/backoffice-actions/closeModal";
import { toast } from "react-toastify";

import "./Backoffice-UploadFiles.css";

export const BackofficeUploadFiles = () => {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [input, setInput] = useState("");
  const inputRef = useRef(null);

  const backofficeReducer = useSelector((state) => state.backofficeReducer);
  const { url } = backofficeReducer;
  const urlImage = url.url;

  const { mutate: uploadImage } = useUploadImageToS3();

  const handleInputChange = (e) => {
      const [name, value] = e.target;
      setInput({
          ...input,
          [name]: value,
      });
  };

  const copyToClipboard = (e) => {
      inputRef.current.select();
      document.execCommand('copy');
      e.target.focus();
      toast.success("El enlace ha sido copiado con éxito: ", {
        theme: "colored",
        autoClose: 1800,
        position:"top-center"
      });
      setTimeout(() => {
        dispatch(closeModalImage());
      }, 2000);
  };

  const simulateProgress = () => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 90) {
          clearInterval(interval);
          return 90;
        }
        return prevProgress + 10;
      });
    }, 200); // Incrementa el progreso cada 200 ms
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProgress(0);
      simulateProgress();
      uploadImage(file, {
        onSuccess: (data) => {
          dispatch(addImageUrl({ url: data.url }));
          setProgress(100);
        },
        onError: (error) => {
          console.error(error);
          toast.error("Error uploading image");
        },
        onSettled: () => {
          if (progress < 100) {
            setProgress(100);
          }
        },
      });
    }
  };

  return (
    <>
      <div className="upload-image">
        <div className="input-image">
          <input multiple type="file" onChange={handleUpload} />
        </div>
        <div className="progress">
          <progress
            className="progress-bar"
            value={progress}
            max="100"
          ></progress>
          <h6>Uploaded {progress} %</h6>
        </div>
      </div>
      <div className="image-select">
        {urlImage ? (
          <img
            className="image-uploadFile"
            src={urlImage}
            alt="Imagen no disponible"
          />
        ) : null}
      </div>
      <div className="placeholder-image">
        {urlImage ? <input onChange={handleInputChange} ref={inputRef} name="input" type="text" value={urlImage} /> : null}
        {urlImage ? <button className="button-clipboard" onClick={copyToClipboard}>Copiar enlace</button> : null}
      </div>
    </>
  );
};
