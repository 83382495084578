import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ContenidoCentralNews from "../ContenidoCentralNews/ContenidoCentralNews";
import ColumnaDerNews from "../ColumnaDerNews/ColumnaDerNews";
import BannerNews from "../BannerNews/BannerNews";
import flecha from "./felcha izquierda.svg";
import style from "./Noticias.module.css";
import { useData } from "../../hooks/api";

const Noticias = () => {
  let { area } = useParams();
  let navigate = useNavigate();
  let [news, setNews] = useState([]);
  let [loading, setLoading] = useState(true); // Estado para controlar la carga de datos
  const {data: newsData} =  useData("news");

  useEffect(() => {
    if (newsData) {
      try {
        let newsFilter = newsData.filter((elem) => elem.area.includes(area));
        newsFilter = newsFilter.filter((elem) => elem.active);
        setNews(newsFilter.sort((a, b) => {
          if (a.date > b.date) return -1;
          if (a.date < b.date) return 1;
          return 0;
        }));
      } catch (e) {
        console.error("No hay resultados disponibles:", e);
      } finally {
        setLoading(false); // Se detiene el estado de carga
      }
    }
  }, [newsData, area]); // newsData y area como dependencias

  // Mostrar un mensaje de carga o un spinner mientras los datos se están cargando
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={style.container__main}>
      <BannerNews area={area} />
      <div className={style.container__flecha_home}>
        <img
          src={flecha}
          alt="Flecha"
          className={style.flecha__home}
          onClick={() => navigate(-1)}
        />
        <span onClick={() => navigate(-1)}>Volver</span>
      </div>
      <div className={style.container__content}>
        <ContenidoCentralNews data={news} area={area} />
        <ColumnaDerNews data={news} area={area} />
      </div>
    </div>
  );
};

export default Noticias;
