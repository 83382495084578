import { useEffect, useState } from "react";
import styles from "./Pagination.module.css";
import dobleFlecha from "./dobleFlechaDerecha.svg";

const Pagination = ({ newsPerPage, totalNews, paginate, currentPage }) => {
  let [pageNumbers, setPageNumbers] = useState([]);
  let [showOne, setShowOne] = useState(false);
  let [showTwo, setShowTwo] = useState(false);
  let [pageShow, setPageShow] = useState([]);
  let [firstIndex, setFirstIndex] = useState(0);
  let [lastIndex, setLastIndex] = useState(window.innerWidth < 607 ? 4 : 9);
  let [currentIndex, setCurrentIndex] = useState();

  let createPages = () => {
    let aux = [];
    for (let i = 1; i <= Math.ceil(totalNews / newsPerPage); i++) {
      aux.push(i);
    }
    setPageShow(aux.slice(firstIndex, lastIndex));
    setPageNumbers(aux);
  };

  let controllerEllipsis = () => {
    if (pageShow[0] === 1) {
      setShowOne(false);
    } else {
      setShowOne(true);
    }
    if (lastIndex >= pageNumbers.length) {
      setShowTwo(false);
    } else {
      setShowTwo(true);
    }
  };

  let indexControllerUp = () => {
    if (currentIndex === pageShow.length - 1 && showTwo) {
      let aux = pageNumbers.slice(firstIndex + 1, lastIndex + 1);
      setPageShow(aux);
      setFirstIndex(firstIndex + 1);
      setLastIndex(lastIndex + 1);
    }
  };

  let indexControllerDown = () => {
    if (currentIndex === 0 && showOne) {
      let aux = pageNumbers.slice(firstIndex - 1, lastIndex - 1);
      setPageShow(aux);
      setFirstIndex(firstIndex - 1);
      setLastIndex(lastIndex - 1);
    }
  };

  const clickController = () => {
    if (currentIndex === 0 && firstIndex > 0) {
      indexControllerDown();
    }
    if (
      currentIndex === pageShow.length - 1 ||
      currentIndex === pageNumbers.length
    ) {
      indexControllerUp();
    }
  };

  const getIndex = () => {
    let auxIndex = pageShow.indexOf(currentPage);
    setCurrentIndex(auxIndex);
  };

  useEffect(() => {
    createPages();
  }, [newsPerPage, totalNews]);

  useEffect(() => {
    controllerEllipsis();
    clickController();
  }, [currentIndex, currentPage, pageNumbers]);

  useEffect(() => {
    getIndex();
  }, []);

  return (
    <ul className={styles.container__content}>
      <div
        className={`${styles.container__dobleFlecha_back} ${
          currentPage === 1 ? styles.disabled : ""
        }`}
        onClick={() => {
          if (currentPage > 1) {
            paginate(currentPage - 1);
            clickController();
          }
        }}
      >
        <img src={dobleFlecha} alt="doble flecha" />
      </div>
      {showOne ? (
        <div className={styles.paginado__puntos_suspensivos_menos}>
          <span>...</span>
        </div>
      ) : null}
      <div className={styles.paginado__separacion}></div>
      {pageShow.map((page) => (
        <li
          key={page}  // Aquí el key debe ser único, lo que ya está correcto
          onClick={() => {
            paginate(page);
            clickController();
          }}
          className={currentPage === page ? styles.selected : ""}
        >
          {page}
        </li>
      ))}
      {showTwo ? (
        <div className={styles.paginado__puntos_suspensivos_mas}>
          <span>...</span>
        </div>
      ) : null}

      <div
        className={`${styles.container__dobleFlecha} ${
          currentPage === pageNumbers[pageNumbers.length - 1]
            ? styles.disabled
            : ""
        }`}
        onClick={() => {
          if (currentPage < pageNumbers[pageNumbers.length - 1]) {
            paginate(currentPage + 1);
            clickController();
          }
        }}
      >
        <img src={dobleFlecha} alt="doble flecha" />
      </div>
    </ul>
  );
};

export default Pagination;
