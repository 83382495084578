import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import { getNews } from "../../actions/getNews";
import { useData } from "../../hooks/api"; // Asegúrate de ajustar la ruta de importación según sea necesario
import arrow from "../../images/arrow.svg";
import defaultImg from "./DefautImg.png";
import "./CarouselNews.css";

const CarouselNews = () => {
  const dispatch = useDispatch();

  const limit = 8;

  const { data: newsData, error } = useData("news", limit);
  useEffect(() => {
    if (newsData) {
      dispatch(getNews(newsData));
    }
  }, [newsData, dispatch]);

  const rootReducer = useSelector((state) => state.rootReducer);
  const { news } = rootReducer;

  // Filtrar y tomar solo los primeros 8 registros
  const filteredNews = news
    ?.filter((r) => r.area[0] === "Programa de buena salud")
    .slice(0, 8);

  if (error) {
    return <div>Error loading news</div>;
  }

  return (
    <Carousel indicators={false} fade={false} pause={false} variant="dark">
      {filteredNews.map((e) => {
        return (
          <Carousel.Item key={e.id} interval={8000}>
            <div className="itemContainer">
              <img
                className="carouselNewsImg"
                src={e.img ? e.img : defaultImg}
                alt={e.title}
              />
              <div className="pContainer">
                <div className="containerText">
                  <p className="carouselNewsTittle">{e.title}</p>
                  <p className="carouselNewsResume">{e.resume}</p>
                </div>
                <Link to={`/noticiasDetails/${e.id}`}>
                  <div className="readNote">
                    <p>Leer nota</p>
                    <img src={arrow} alt="" />
                  </div>
                </Link>
              </div>
            </div>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default CarouselNews;
