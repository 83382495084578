import "./NavBar.css";
import { NosotrosNav } from "./NosotrosNav";
import { PacientesNav } from "./PacientesNav";
import { CircuitoMedicoNav } from "./CircuitoMedicoNav";
import { AutogestionNav } from "./AutogestionNav";
import { GuardiasNav } from "./GuardiasNav";
import { SedesNav } from './SedesNav';
import { NavBarResponsive } from "./NavBarResponsive";

export const NavBar = () => {

  return (
    <div className="NavBarContainer">
      <div className="NavBarContainerSub">
        <ul className="ulPrincipal">
          <NosotrosNav/>
          <PacientesNav/>
          <CircuitoMedicoNav/>
          <AutogestionNav/>
          <GuardiasNav/>
        </ul>
        <SedesNav/>
      </div>
      <div className="navBarResponsiveContainer">
      <NavBarResponsive/>
      </div>
    </div>
  );
};
