import { useEffect, useState, useCallback } from "react";
import style from "./Guide-ContentLeft.module.css";
import { sectionShow } from "../../../actions";
import setContent from "../../../actions/setContent";
import setSection from "../../../actions/setSection";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import close from "./images/close.svg";
import { clearContent } from "../../../actions/clearContent";
import { clearSection } from "../../../actions/clearSection";
import { useData } from "../../../hooks/api";
//TODO NO SE COMO SACAR REDUX DE ACA

export const GuideContentLeft = () => {
    const [sectionParams, setSectionParams] = useState("");
    const dispatch = useDispatch();
    const { name, id } = useParams();
    const { data: guides } = useData("guiaPaciente");
    const menuState = useSelector((state) => state.rootReducer.sectionShow);
    const [current, setCurrent] = useState(null);
    const [sectionSelect, setSectionSelect] = useState(null);

    const filter = useCallback(() => {
        let result = guides && guides.find((guide) => guide.name === name);
        let sectionSelected = result?.sections?.filter(section => section.id === id);
        setCurrent(result);
        setSectionSelect(sectionSelected);
    }, [guides, name, id]);

    useEffect(() => {
        filter();
    }, [guides, name, filter]);

    useEffect(() => {
        if (sectionSelect && sectionSelect[0]) {
            setSectionParams(sectionSelect[0].name);
            dispatch(setSection(sectionSelect[0].id));
            dispatch(setContent(sectionSelect[0].content));
            dispatch(sectionShow(false));
        }
    }, [sectionSelect, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(clearContent());
            dispatch(clearSection());
        };
    }, [dispatch]);

    const handleSection = (value, content) => {
        dispatch(setSection(value));
        dispatch(setContent(content));
    };

    const handleClose = () => {
        dispatch(sectionShow(false));
    };

    return (
        <div
            className={
                menuState
                    ? `${style.containerGral}`
                    : `${style.containerGral} ${style.false}`
            }
        >
            <div className={style.seccionesContainer}>
                <h3 className={style.title__column}>Secciones</h3>
                <img
                    src={close}
                    className={style.close}
                    onClick={handleClose}
                    alt="close"
                />
            </div>
            <div className={style.sectionContainer}>
                {current?.sections &&
                    current?.sections
                        ?.sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0))
                        .map((section, i) => (
                            <Link
                                style={{ textDecoration: "none" }}
                                to={`/guiaDelPaciente/${name}/${section.id}`}
                                key={i}
                            >
                                <button
                                    className={
                                        sectionParams && sectionParams
                                            ? section?.id === id
                                                ? style.buttonSelect
                                                : style.buttonSection
                                            : style.buttonSection
                                    }
                                    onClick={() => handleSection(section.id, section.content)}
                                >
                                    {section?.name}
                                </button>
                            </Link>
                        ))}
            </div>
        </div>
    );
};
