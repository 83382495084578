import { useState, useEffect, useCallback, useRef } from "react";
import style from "./FormReclamos.module.css";
import Swal from "sweetalert2";
import successIcon from "./succesIcon.svg";
import closeBtn from "./closeBtn.svg";
import archivoCargado from "./archivoCargado.svg";
import subirArchivo from "./subirArchivo.svg";
import flechaSelect from "./flecha select.svg";
import Axios from "axios";
import BounceLoader from "react-spinners/BounceLoader";
const { REACT_APP_API_URL } = process.env;

const FormReclamos = () => {
  let ref = useRef();
  let [disabled, setDisabled] = useState(true);
  let [inputCheckbox, setInputCheckbox] = useState({ otroTema: "", temas: [] });
  let [input, setInput] = useState({
    nombre: "",
    apellido: "",
    email: "",
    fechaDelSuceso: "",
    vivencia: "",
    telPrefijo: "",
    telNumero: "",
    imagenes: "",
    dni: "",
    representante: "",
    horaDelSuceso: "",
    lugar: "",
    temas: { otroTema: "", temas: [] },
  });
  let [errors, setErrors] = useState({});
  let [archivos, setArchivos] = useState([]);
  let [loading, setLoading] = useState(false);
  let refReclamo = useRef();

  const handleChange = (event) => {
    if (event.target.name === "file") {
      let archivos = event.target.files;
      let aux = [];
      for (let clave in archivos) {
        aux.push(archivos[clave]);
      }
      aux.splice(-2);
      setArchivos(aux);
    } else {
      setInput({ ...input, [event.target.name]: event.target.value });
    }
  };

  const handleChangeCheckbox = (event) => {
    let aux = {};
    if (event.target.name === "otra") {
      setInputCheckbox({ ...inputCheckbox, otroTema: event.target.value });
    } else {
      if (event.target.checked) {
        aux = {
          ...inputCheckbox,
          temas: [...inputCheckbox.temas, event.target.value],
        };
        setInputCheckbox(aux);
      } else {
        aux = inputCheckbox.temas?.filter((e) => e !== event.target.value);
        setInputCheckbox({ ...inputCheckbox, temas: aux });
      }
    }
  };

  const validate = () => {
    let erroresMensajes = {};
    if (!input.nombre) {
      erroresMensajes.nombre = "Debe ingresar un nombre";
    }
    if (!input.apellido) {
      erroresMensajes.apellido = "Debe ingresar un apellido";
    }
    if (!input.email) {
      erroresMensajes.email = "Debe ingresar un email";
    }
    if (!input.telPrefijo || !input.telNumero) {
      erroresMensajes.telefono = "Debe ingresar un teléfono";
    }
    if (!input.vivencia) {
      erroresMensajes.vivencia = "Debe ingresar la vivencia";
    }
    if (!input.dni) {
      erroresMensajes.dni = "Debe ingresar un DNI";
    }
    if (!input.fechaDelSuceso) {
      erroresMensajes.fechaDelSuceso = "Debe ingresar una fecha";
    }
    if (!input.sector) {
      erroresMensajes.sector = "Debe ingresar un sector";
    }
    if (!input.lugar) {
      erroresMensajes.lugar = "Debe seleccionar un lugar";
    }
    if (!inputCheckbox.otroTema.length && !inputCheckbox.temas.length) {
      erroresMensajes.temas = "Debe seleccionar un tema";
    }
    return erroresMensajes;
  };

  const handlerSubmit = async (e) => {
    e.preventDefault();
    let erroresMensajes = validate();
    if (!Object.keys(erroresMensajes).length) {
      let infoSend;
      setErrors(erroresMensajes);
      if (archivos.length > 0) {
        let auxArchivos = [];
        archivos.forEach((e) => auxArchivos.push(e.name));
        infoSend = {
          ...input,
          archivos: auxArchivos,
        };
      } else {
        infoSend = input;
      }
      infoSend.temas = inputCheckbox;
      let formData = new FormData();
      archivos.forEach((f) => {
        formData.append("file", f);
      });
      formData.append("infoData", JSON.stringify(infoSend));
      setLoading(true);
      try {
        await Axios.post(
          `${REACT_APP_API_URL}/formularios/reclamos`,
          formData,
          {
            headers: { "Content-type": "multipart/form-data" },
          }
        );
        setLoading(false);
        Swal.fire({
          title: "Solicitud enviada con éxito",
          confirmButtonText: "Entendido",
          buttonsStyling: false,
          customClass: {
            popup: `${style.containerAlert}`,
            confirmButton: `${style.buttonConfirm}`,
            title: `${style.titleAlert}`,
            htmlContainer: `${style.containerHtml}`,
          },
          html: `<img src=${successIcon} />`,
        });
        ref.current.reset();
        setInput({
          nombre: "",
          apellido: "",
          email: "",
          fechaDelSuceso: "",
          vivencia: "",
          telPrefijo: "",
          telNumero: "",
          dni: "",
          representante: "",
          ig: "",
          lugar: "",
        });
        setArchivos([]);
      } catch (e) {
        console.log("Error al mandar el formulario", e);
      }
      setInputCheckbox({ otroTema: "", temas: [] });
    } else {
      setErrors(erroresMensajes);
      console.log("formulario con error");
    }
  };

  const alertRemoveFile = (name) => {
    Swal.fire({
      title: "¿Esta seguro que desea eliminar el archivo adjunto?",
      showCancelButton: true,
      confirmButtonText: "No",
      cancelButtonText: "Eliminar",
      buttonsStyling: false,
      customClass: {
        popup: `${style.cancelAlert}`,
        title: `${style.titleAlert}`,
        confirmButton: `${style.no_btn}`,
        cancelButton: `${style.eliminar_btn}`,
      },
    }).then((res) => {
      if (!res.value) {
        let aux = archivos.filter((f) => f.name !== name);
        setArchivos(aux);

        let list = new DataTransfer();
        Object.values(refReclamo.current.files).filter((f) => {
          if (f.name !== name) {
            list.items.add(f);
            return f;
          }
        });

        refReclamo.current.files = list.files;
      }
    });
  };

  console.log(disabled, "disabled");

  const disabledButton = useCallback(() => {
    if (
      !input.nombre ||
      !input.apellido ||
      !input.email ||
      !input.vivencia ||
      !input.telPrefijo ||
      !input.telNumero ||
      !input.dni ||
      !input.fechaDelSuceso ||
      !input.sector ||
      !input.lugar ||
      (!inputCheckbox.otroTema.length && !inputCheckbox.temas.length)
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [input, inputCheckbox]);

  useEffect(() => {
    disabledButton();
  }, [input, disabledButton]);
  return (
    <>
      <h3 className={style.title__form}>Dejanos tu mensaje</h3>
      <form
        className={style.container__form}
        onSubmit={handlerSubmit}
        ref={ref}
      >
        <div
          className={`${style.container__label_input} ${style.container__nombre}`}
        >
          <label htmlFor="nombre">Nombre</label>
          <input
            type="text"
            name="nombre"
            id="nombre"
            placeholder="Ej: María"
            onChange={handleChange}
            value={input.nombre}
            autoComplete="off"
            className={input.nombre ? style.not__empty : null}
          />
          {errors.nombre && (
            <span className={style.error__message}>{errors.nombre}</span>
          )}
        </div>
        <div
          className={`${style.container__label_input} ${style.container__apellido}`}
        >
          <label htmlFor="apellido">Apellido</label>
          <input
            type="text"
            name="apellido"
            id="apellido"
            placeholder="Ej: Romero"
            onChange={handleChange}
            value={input.apellido}
            autoComplete="off"
            className={input.apellido ? style.not__empty : null}
          />
          {errors.apellido && (
            <span className={style.error__message}>{errors.apellido}</span>
          )}
        </div>

        <div
          className={`${style.container__label_input} ${style.container__telefono}`}
        >
          <label htmlFor="teléfono">Teléfono</label>
          <div className={style.container__telefono_input}>
            <input
              type="number"
              name="telPrefijo"
              id="teléfono"
              placeholder="Prefijo"
              className={`${style.telefono__prefijo} ${
                input.telPrefijo ? style.not__empty : null
              }`}
              onChange={handleChange}
              value={input.telPrefijo}
              autoComplete="off"
            />
            <input
              type="number"
              name="telNumero"
              id="teléfono"
              placeholder="Número"
              className={`${style.telefono__numero} ${
                input.telNumero ? style.not__empty : null
              }`}
              onChange={handleChange}
              value={input.telNumero}
              autoComplete="off"
            />
            {errors.telefono && (
              <span className={style.error__message}>{errors.telefono}</span>
            )}
          </div>
        </div>
        <div
          className={`${style.container__label_input} ${style.container__dni}`}
        >
          <label htmlFor="dni">DNI</label>
          <input
            type="number"
            name="dni"
            id="dni"
            value={input.dni}
            placeholder="Ej: 99.999.999"
            onChange={handleChange}
            autoComplete="off"
            className={input.dni ? style.not__empty : null}
          />
          {errors.dni && (
            <span className={style.error__message}>{errors.dni}</span>
          )}
        </div>
        <div
          className={`${style.container__label_input} ${style.container__email}`}
        >
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="midireccion@mail.com"
            onChange={handleChange}
            value={input.email}
            autoComplete="off"
            className={input.email ? style.not__empty : null}
          />
          {errors.email && (
            <span className={style.error__message}>{errors.email}</span>
          )}
        </div>
        <div
          className={`${style.container__label_input} ${style.container__representante}`}
        >
          <label htmlFor="representante">
            Si es un familiar o representante legal del paciente por el cual
            escribe este reclamo, por favor informe en el siguiente campo:
            nombre completo del paciente, DNI y vínculo con el paciente
          </label>
          <input
            type="text"
            name="representante"
            id="representante"
            placeholder="Ej: Pareja, Madre-Hijo"
            onChange={handleChange}
            value={input.representante}
            autoComplete="off"
            className={input.representante ? style.not__empty : null}
          />
          {errors.representante && (
            <span className={style.error__message}>{errors.representante}</span>
          )}
        </div>
        <div
          className={`${style.container__label_input} ${style.container__fechaDelSuceso}`}
        >
          <label htmlFor="fechaDelSuceso">
            Si corresponde, indique la fecha del suceso por el cual escribe este
            formulario.
          </label>
          <input
            type="date"
            name="fechaDelSuceso"
            id="fechaDelSuceso"
            onChange={handleChange}
            value={input.fechaDelSuceso}
            autoComplete="off"
            className={input.fechaDelSuceso ? style.not__empty : null}
          />
          {errors.fechaDelSuceso && (
            <span className={style.error__message}>
              {errors.fechaDelSuceso}
            </span>
          )}
        </div>
        <div
          className={`${style.container__label_input} ${style.container__horaDelSuceso}`}
        >
          <label htmlFor="horaDelSuceso">
            Coloque la hora estimada en la que aconteció el suceso:
          </label>
          <input
            type="time"
            name="horaDelSuceso"
            id="horaDelSuceso"
            onChange={handleChange}
            value={input.horaDelSuceso}
          />
        </div>
        <div
          className={`${style.container__label_input} ${style.container__sector}`}
        >
          <label htmlFor="sector">
            Escriba el nombre del sector sobre el cual desea realizar la
            sugerencia/reclamo:
          </label>
          <input
            type="sector"
            name="sector"
            id="sector"
            placeholder="Nombre del sector"
            onChange={handleChange}
            value={input.sector}
            autoComplete="off"
            className={input.sector ? style.not__empty : null}
          />
          {errors.sector && (
            <span className={style.error__message}>{errors.sector}</span>
          )}
        </div>
        <div
          className={`${style.container__label_input} ${style.container__lugar}`}
        >
          <label htmlFor="lugar">
            Seleccione el lugar donde ocurrió el suceso que reporta:
          </label>
          <select
            name="lugar"
            id="lugar"
            onChange={handleChange}
            value={input.lugar}
          >
            <option disabled selected value="" style={{ color: "gray" }}>
              Ninguna opción seleccionada.
            </option>
            <option value="Centro de rehabilitación y Fisioterapia - Suipacha 1139">
              Centro de rehabilitación y Fisioterapia - Suipacha 1139
            </option>
            <option value="Consultorios externos - Jacinto Ríos 554">
              Consultorios externos - Jacinto Ríos 554
            </option>
            <option value="Consultorios externos - Paseo del Jockey">
              Consultorios externos - Paseo del Jockey
            </option>
            <option value="Sede central - Oncativo 1248 - Pacientes ambulatorios">
              Sede central - Oncativo 1248 - Pacientes ambulatorios
            </option>
            <option value="Sede central - Oncativo 1248 - Pacientes internados">
              Sede central - Oncativo 1248 - Pacientes internados
            </option>
            <option value="Audiología clínica y de alta complejidad - Catamarca 1248">
              Audiología clínica y de alta complejidad - Catamarca 1248
            </option>
            <option value="Telemedicina">Telemedicina</option>
            <option value="Sitio web">Sitio web</option>
          </select>
          {errors.lugar && (
            <span className={style.error__message}>{errors.lugar}</span>
          )}
          <img
            src={flechaSelect}
            alt="flecha select"
            className={style.flecha_select}
          />
        </div>
        <div
          className={`${style.container__label_input} ${style.container__temas}`}
        >
          <label className={style.title_cb}>
            De las siguientes opciones, marque aquellas que se asocian a los
            principales ejes temáticos vinculados con el suceso reportado:
          </label>
          <div className={style.container__label_checkbox}>
            <input
              type="checkbox"
              name="cb-1"
              value="Confort del paciente"
              id="cb-1"
              onChange={handleChangeCheckbox}
            />
            <label htmlFor="cb-1">Confort del paciente</label>
          </div>
          <div className={style.container__label_checkbox}>
            <input
              type="checkbox"
              name="cb-2"
              value="Disponibilidad de turnos"
              id="cb-2"
              onChange={handleChangeCheckbox}
            />
            <label htmlFor="cb-2">Disponibilidad de turnos</label>
          </div>
          <div className={style.container__label_checkbox}>
            <input
              type="checkbox"
              name="cb-3"
              value="Tiempos de espera"
              id="cb-3"
              onChange={handleChangeCheckbox}
            />
            <label htmlFor="cb-3">Tiempos de espera</label>
          </div>
          <div className={style.container__label_checkbox}>
            <input
              type="checkbox"
              name="cb-4"
              value="Trato humano (respeto, empatía, amabilidad, etc.)"
              id="cb-4"
              onChange={handleChangeCheckbox}
            />
            <label htmlFor="cb-4">
              Trato humano (respeto, empatía, amabilidad, etc.)
            </label>
          </div>
          <div className={style.container__label_checkbox}>
            <input
              type="checkbox"
              name="cb-5"
              value="Higiene Hospitalaria"
              id="cb-5"
              onChange={handleChangeCheckbox}
            />
            <label htmlFor="cb-5">Higiene Hospitalaria</label>
          </div>
          <div className={style.container__label_checkbox}>
            <input
              type="checkbox"
              name="cb-6"
              value="Equipos / Insumos"
              id="cb-6"
              onChange={handleChangeCheckbox}
            />
            <label htmlFor="cb-6">Equipos / Insumos</label>
          </div>
          <div className={style.container__label_checkbox}>
            <input
              type="checkbox"
              name="cb-7"
              value="Pagos / Depósitos en garantía"
              id="cb-7"
              onChange={handleChangeCheckbox}
            />
            <label htmlFor="cb-7">Pagos / Depósitos en garantía</label>
          </div>
          <div className={style.container__label_checkbox}>
            <input
              type="checkbox"
              name="cb-8"
              value="Telemedicina"
              id="cb-8"
              onChange={handleChangeCheckbox}
            />
            <label htmlFor="cb-8">Telemedicina</label>
          </div>
          <div className={style.container__label_checkbox}>
            <input
              type="checkbox"
              name="cb-9"
              value="Información brindada desde áreas de soporte (Secretaría, Centro de
                Contacto, Personal de Seguridad, Web, etc.)"
              id="cb-9"
              onChange={handleChangeCheckbox}
              className={style.especial__cb}
            />
            <label htmlFor="cb-9">
              Información brindada desde áreas de soporte (Secretaría, Centro de
              Contacto, Personal de Seguridad, Web, etc.)
            </label>
          </div>
          <div className={style.container__label_checkbox}>
            <input
              type="checkbox"
              name="cb-10"
              value="Información brindada desde servicios asistenciales (médicos,
                enfermeros, kinesiólogos, nutricionistas, etc.)"
              id="cb-10"
              onChange={handleChangeCheckbox}
              className={style.especial__cb}
            />
            <label htmlFor="cb-10">
              Información brindada desde servicios asistenciales (médicos,
              enfermeros, kinesiólogos, nutricionistas, etc.)
            </label>
          </div>
          <div className={style.container__label_checkbox}>
            <input
              type="checkbox"
              name="cb-11"
              value="Informes de estudios realizados"
              id="cb-11"
              onChange={handleChangeCheckbox}
            />
            <label htmlFor="cb-11">Informes de estudios realizados</label>
          </div>
          <div className={style.container__label_checkbox}>
            <input
              type="checkbox"
              name="cb-12"
              value="Infraestructura"
              id="cb-12"
              onChange={handleChangeCheckbox}
            />
            <label htmlFor="cb-12">Infraestructura</label>
          </div>
          <div className={style.container__label_checkbox}>
            <input
              type="checkbox"
              name="cb-13"
              value="Diagnóstico médico recibido"
              id="cb-13"
              onChange={handleChangeCheckbox}
            />
            <label htmlFor="cb-13">Diagnóstico médico recibido</label>
          </div>
          <div className={style.container__label_checkbox}>
            <input
              type="checkbox"
              name="cb-14"
              value="Acto/intervención profesional"
              id="cb-14"
              onChange={handleChangeCheckbox}
            />
            <label htmlFor="cb-14">Acto/intervención profesional</label>
          </div>

          <input
            type="text"
            placeholder="Otra..."
            name="otra"
            onChange={handleChangeCheckbox}
            value={inputCheckbox.otroTema}
            className={style.container__input_otra}
          />
          {errors.temas && (
            <span className={style.error__message}>{errors.temas}</span>
          )}
        </div>
        <div
          className={`${style.container__label_input} ${style.container__vivencia}`}
        >
          <label htmlFor="vivencia">
            A continuación, le solicitamos por favor describa detalladamente la
            vivencia que lo motiva a presentarnos esta sugerencia/reclamo:
          </label>
          <textarea
            type="text"
            name="vivencia"
            id="vivencia"
            cols="30"
            rows="10"
            placeholder="Descripción de la vivencia"
            onChange={handleChange}
            value={input.vivencia}
            autoComplete="off"
            className={input.vivencia ? style.not__empty : null}
          />
          {errors.vivencia && (
            <span className={style.error__message}>{errors.vivencia}</span>
          )}
        </div>

        <div
          className={`${style.container__label_input} ${style.container__pedidos}`}
        >
          <label htmlFor="file">
            Si usted desea, puede adjuntar imágenes a su relato.
          </label>

          <input
            type="file"
            name="file"
            id="file"
            onChange={handleChange}
            multiple
            ref={refReclamo}
          />

          {!archivos.length > 0 ? (
            <label htmlFor="file" className={style.label__custom}>
              <img src={subirArchivo} alt="subir archivo" />
              <span>Seleccionar</span>
            </label>
          ) : (
            archivos.map((f, index) => (
              <div
                className={style.container__label_archivoCargado}
                key={index}
              >
                <img
                  src={archivoCargado}
                  alt="cargado archivo"
                  className={style.archivoCargado__btn}
                />
                <span>{f.name}</span>
                <img
                  src={closeBtn}
                  alt="close button"
                  className={style.archivoCargado__btn_close}
                  onClick={() => {
                    alertRemoveFile(f.name);
                  }}
                />
              </div>
            ))
          )}
        </div>
        {loading ? (
          <div className={style.loadingContainer}>
            <BounceLoader color="#002856" />
          </div>
        ) : (
          <button
            type="submit"
            className={`${style.btn__enviar} ${
              disabled ? style.disabled : null
            }`}
          >
            Enviar
          </button>
        )}
      </form>
    </>
  );
};

export default FormReclamos;
