import { useEffect, useState } from "react";
import "./Footer.css";
import logo from "./images/Logo.svg";
import uniCatolica from "./images/UCC.svg";
import jesuitas from "./images/jesuitas_logo 2.png";
import ubicacion from "./images/Ubicacion.svg";
import telefono from "./images/Telefono.svg";
import emailIcon from "./images/Email.svg";
import gestionCalidad from "./images/Gestion de calidad.svg";
import iqnet from "./images/Group 469.png";
import itaes from "./images/ITAES.svg";
import fb from "./images/fb.svg";
import ig from "./images/ig.svg";
import yt from "./images/yt.svg";
import linkedin from "./images/linkedin.svg";
import dataFiscal from "./images/Data fiscal.svg";
import pdp from "./images/pdp.svg";
import { Link } from "react-router-dom";
import { useData, useEmail} from "../../hooks/api";

const Footer = () => {
  const { data: phoneData, error: phoneError, isLoading: phoneLoading } = useData("phone", undefined, undefined, false, false);
  const { data: emailData, error: emailError, isLoading: emailLoading } = useData('email');
  
  const [phone, setPhone] = useState("");
  const [codArea, setCodArea] = useState("");

  useEffect(() => {
    if (phoneData && phoneData.length > 0) {
      setPhone(phoneData[0].tel);
      setCodArea(phoneData[0].codArea);
    }
  }, [phoneData]);

  if (phoneLoading || emailLoading) {
    return <div>Loading...</div>;
  }

  if (phoneError || emailError) {
    return <div>Error al cargar los datos</div>;
  }

  const email = emailData?.[0]?.email || "No email available";

  return (
    <footer className="footer__container_main">
      <div className="footer__container_content">
        <div className="footer__container_content_one_logo_nosacompaña">
          <Link style={{ textDecoration: "none" }} to="/">
            <img src={logo} alt="Logo" className="footer__logo" />
          </Link>
          <div className="footer__container_content_one_nosacompaña">
            <div className="footer__container_content_one_nosacompaña_img">
              <a
                href="https://www.ucc.edu.ar/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={uniCatolica}
                  alt="Universidad Catolica de Cordoba"
                  className="footer__ucc"
                />
              </a>
              <a
                href="https://jesuitasaru.org/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={jesuitas}
                  alt="Jesuitas"
                  className="footer__jesuitas"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="footer__container_content_one_sedes">
          <span>Sedes</span>
          <div className="footer__container_barrio_gp">
            <div className="footer__container_barrio_gp_img_span">
              <img
                src={ubicacion}
                alt="Ubicacion"
                className="footer__ubicacion"
              />
              <span>Barrio General Paz</span>
            </div>
            <ul className="footer__ul_barrio_gp">
              <Link to={`/sedes/Oncativo`} className="footer__li">
                <li>Oncativo (Central)</li>
              </Link>
              <Link to={`/sedes/Jacinto%20Ríos`} className="footer__li">
                <li>Jacinto Ríos</li>
              </Link>
              <Link
                to={`/sedes/Audiologia%20Clínica%20y%20de%20Alta%20Complejidad`}
                className="footer__li"
              >
                <li>Audiología Clínica y de Alta Complejidad</li>
              </Link>
            </ul>
          </div>
          <div className="footer__container_barrio_gp">
            <div className="footer__container_barrio_gp_img_span">
              <img
                src={ubicacion}
                alt="Ubicacion"
                className="footer__ubicacion"
              />
              <span>Barrio Pueyrredón</span>
            </div>
            <ul className="footer__ul_barrio_gp">
              <Link
                to={`/sedes/Centro%20de%20Rehabilitación`}
                className="footer__li"
              >
                <li>Centro de Rehabilitación  Integral</li>
              </Link>
            </ul>
          </div>
          <div className="footer_container_barrio_jardin">
            <div className="footer__container_barrio_jardin_img_span">
              <img
                src={ubicacion}
                alt="Ubicacion"
                className="footer__ubicacion"
              />
              <span>Barrio Jardín Espinosa</span>
            </div>
            <ul className="footer__ul_barrio_jardin">
              <Link to={`/sedes/Paseo%20del%20Jockey`} className="footer__li">
                <li>Paseo del Jockey</li>
              </Link>
            </ul>
          </div>
        </div>
        <div className="footer__container_content_one_contacto_trabaja_sugerencias">
          <span>Contacto</span>
          <div className="footer__container_content_one_contacto">
            <div className="footer__container_contacto_telefono">
              <img src={telefono} alt="Telefono" className="footer__telefono" />
              <a href={`tel:${codArea}${phone}`}>
                <span>{`Tel-fax: (${codArea}) ${phone}`}</span>
              </a>
            </div>
            <div className="footer__container_contacto_email">
              <img src={emailIcon} alt="Email" className="footer__email" />
              <a href={`mailto: ${email}`}>
                <span>{`${email}`}</span>
              </a>
            </div>
          </div>
          <div className="footer__contacto_line"></div>
          <div className="footer__container_content_one_trabaja">
            <Link to="/trabajaConNosotros">
              <span>Trabajá con nosotros</span>
            </Link>
          </div>
          <div className="footer__container_content_one_sugerencias">
            <Link to="/general/sugerencias/Agradecimientos">
              <span>Sugerencias y agradecimientos</span>
            </Link>
          </div>
        </div>
        <div className="footer__container_content_one_acreditaciones">
          <span>Acreditaciones</span>
          <div className="footer__container_content_one_acreditaciones_images">
            <a href="">
              <img
                src={gestionCalidad}
                alt="Gestion de calidad"
                className="footer__gestionCalidad"
              />
            </a>
            <a
              href="https://www.iqnet-certification.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={iqnet} alt="IQNET" className="footer__iqnet" />
            </a>
            <a
              href="https://www.itaes.org.ar/Inicio.aspx"
              target="_blank"
              rel="noreferrer"
            >
              <img src={itaes} alt="ITAES" className="footer__itaes" />
            </a>
          </div>
        </div>

        <div className="footer__container_content_two_redes">
          <span>Seguinos en: </span>
          <a
            href="https://www.facebook.com/clinicareinafabiola/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={fb}
              alt="Facebook"
              className="footer__fb"
              target="_blank"
              rel="noreferrer"
            />
          </a>
          <a
            href="https://www.instagram.com/clinicareinafabiola/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={ig} alt="Instagram" className="footer__ig" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCeWZhXIRWxElIzG55O5NUbA"
            target="_blank"
            rel="noreferrer"
          >
            <img src={yt} alt="Youtube" className="footer__yt" />
          </a>
          <a
            href="https://www.linkedin.com/company/clinicauniversitariareinafabiola/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedin} alt="Linkedin" className="footer__linkedin" />
          </a>
        </div>
        <div className="footer__line"></div>
        <div className="footer__container_content_two_derechos">
          <span>©2022 todos los derechos reservados por CURF</span>
        </div>
        <div className="footer__container_content_two_datafiscal_pdp">
          <div className="footer__container_datafiscal_content">
            <a
              href="https://serviciosweb.afip.gob.ar/clavefiscal/qr/publicInfoD.aspx"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={dataFiscal}
                alt="Data fiscal"
                className="footer__dataFiscal"
              />
            </a>
            <a href="">
              <img src={pdp} alt="PDP" className="footer__pdp" />
            </a>
          </div>
        </div>
        <div className="footer__line2"></div>
        <div className="footer__line3"></div>
        <div className="footer__line4"></div>
        <div className="footer__line5"></div>
        <div className="footer__line6"></div>
      </div>
    </footer>
  );
};

export default Footer;
