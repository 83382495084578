
import { createSelector } from 'reselect';

//TODO AQUI TENEMOS QUE HACER LA LOGICA DEL BUSCADOR DINAMICO
const getUnits = (state) => state.rootReducer.units;
const getLaboratories = (state) => state.rootReducer.laboratories;
const getAdultsGuards = (state) => state.rootReducer.adultsGuards;
const getKidsGuards = (state) => state.rootReducer.kidsGuards;
const getSpecialities = (state) => state.rootReducer.specialities;
const getStudies = (state) => state.rootReducer.studies;
const getPreps = (state) => state.rootReducer.preps;
const getStaff = (state) => state.rootReducer.staff;
const getNews = (state) => state.rootReducer.news;
const getSites = (state) => state.backofficeReducer.sites;

// Create memoized selectors
export const getArrayStaff = createSelector(
    [getUnits, getLaboratories, getAdultsGuards, getKidsGuards, getSpecialities],
    (units, laboratories, adultsGuards, kidsGuards, specialities) => {
    return specialities.concat(
        units.filter((e) => e.name !== '¿Qué son las unidades asistenciales?'),
        laboratories.filter((e) => e.name !== 'default'),
        adultsGuards.filter((e) => e.name !== 'default'),
        kidsGuards.filter((e) => e.name !== 'default')
    );
    }
);

export const getArray = createSelector(
    [getStudies, getPreps, getStaff, getSpecialities, getLaboratories, getUnits, getNews, getSites],
    (studies, preps, staff, specialities, laboratories, units, news, sites) => {
    return studies
        ?.concat(
        preps?.concat(
            staff?.concat(
            specialities?.concat(
                laboratories?.concat(
                units?.concat(
                    news?.concat(
                    sites?.concat([
                        { nombreA: "Guardia adultos", sub: "GUARDIA", link: "/general/guardiaDeAdultos/Central" },
                        { nombreA: "Guardia pediatrica", sub: "GUARDIA", link: "/general/guardiaPediatrica/Central" },
                        { nombreA: "Ginecológico", sub: "CIRCUITO MÉDICO", link: "/general/circuitosMedicos/Ginecologico" },
                        { nombreA: "Médico integral", sub: "CIRCUITO MÉDICO", link: "/general/circuitosMedicos/Integral" },
                        { nombreA: "Pre quirúrgico", sub: "CIRCUITO MÉDICO", link: "/general/circuitosMedicos/Prequirurgico" },
                        { nombreA: "Coberturas", sub: "INFORMACIÓN", link: "/general/coberturas/Prepagas" },
                        { nombreA: "Mi portal", sub: "AUTOGESTIÓN", link: "/MiPortal" },
                        { nombreA: "Institucional", sub: "CLINICA", link: "/general/institucional/Historia" },
                        { nombreA: "Docencia", sub: "PROFESIONALES", link: "/general/docencia/Informacion%20general" },
                        { nombreA: "Comités", sub: "PROFESIONALES", link: "/general/comite/Informacion%20general" },
                        { nombreA: "Recursos humanos", sub: "RRHH", link: "/trabajaConNosotros" },
                        { nombreA: "Guía del Paciente", sub: "INFORMACIÓN", link: "/general/guiaDelPaciente/Derechos" },
                        { nombreA: "Turnero online", sub: "MI PORTAL", link: "http://turnos.curf.com.ar/" },
                        { nombreA: "Informe digital", sub: "MI PORTAL", link: "https://informedigital.curf.com.ar/" },
                        { nombreA: "Recetas online", sub: "MI PORTAL", link: "https://docs.google.com/forms/d/e/1FAIpQLSfWqtR8xmyxaJHR14uS2pRhsu6Dq_T3WR1Yn1nfYtL4PXnU5g/viewform" },
                    ])
                    )
                )
                )
            )
            )
        )
        )
        .sort((a, b) => (a.name > b.name ? -1 : a.name < b.name ? 1 : 0));
    }
);

export const getArrayFilter = (state) => state.rootReducer.filterSpecials;
