/* eslint-disable react/no-unknown-property */
import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "./Backoffice-CurrentSpecialty.css";
import { Editor } from "@tinymce/tinymce-react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { BackofficeModal } from "../Backoffice-Modal/Backoffice-Modal";
import openModal from "../../../../actions/backoffice-actions/openModal";
import { ImageOutlined } from "@mui/icons-material";
import { useDataById, useEditData } from "../../../../hooks/api";

export const BackofficeCurrentSpecialty = () => {
  const [modal, setModal] = useState(false);
  const [newSection, setNewSection] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [filter, setFilter] = useState("");
  const [change, setChange] = useState([]);
  const [save, setSave] = useState(false);
  let index = 4;
  const [input, setInput] = useState({
    name: "",
    id: uuidv4(),
    content: "",
    index: ++index,
  });

  const editorRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { area, id } = useParams();

  let dbQuery = area;

  if (dbQuery === "especialidades") {
    dbQuery = "specialtiesList";
  } else {
    dbQuery = area;
  }

  const modalImage = useSelector((state) => state.backofficeReducer.setModal);

  const { data: current } = useDataById(dbQuery, id);
  const editDataMutation = useEditData(dbQuery);

  const handleEdit = (e) => {
    setModal(true);
    let name = e.target.getAttribute("name");
    let id = e.target.getAttribute("idsec");
    let content = e.target.getAttribute("content");
    let index = e.target.getAttribute("index");
    setFilter({ name: name, id: id, content: content, index: index });
  };

  const handleClose = (e) => {
    e.preventDefault();
    setFilter("");
    setModal(false);
  };

  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleConfirm = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      setDirty(false);
      setSave(true);
      editorRef.current.setDirty(false);
      // an application would save the editor content to the server here
      setChange({ ...filter, content: content });
    }
  };

  const editSections = async () => {
    const sectionItem = [change];
    const differentItems = current?.sections.filter((e) => e.id !== filter.id);
    const results = [];
    differentItems.forEach((e) => results.push(e));
    sectionItem.forEach((e) => results.push(e));
    editDataMutation.mutate({ ...current, sections: results });
  };

  const handleSubmit = () => {
    editSections();
    setChange("");
    setModal(false);
    setSave(false);
    setFilter("");
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    setNewSection(false);
    editDataMutation.mutate({
      ...current,
      sections: [...(current.sections || []), input],
    });
  };

  const handleDelete = async (e) => {
    let idSection = e.target.getAttribute("iddelete");
    const filteredSections = current.sections.filter((e) => e.id !== idSection);
    const confirmDelete = async () => {
      editDataMutation.mutate({
        ...current,
        sections: filteredSections,
      });
    };
    window.confirm("¿Desea eliminar esta sección?")
      ? confirmDelete()
      : setNewSection(false);
  };

  const handleModal = () => {
    dispatch(openModal());
  };

  const handleFinish = () => {
    navigate(`/backoffice/${area}`);
  };

  return (
    <div className="currentContainer">
      <div className="buttonNewContainer">
        <button className="newButton" onClick={() => setNewSection(true)}>
          Nueva
        </button>
      </div>
      {newSection ? (
        <section className="formContainerSection">
          <form className="formNewSection" action="">
            <div>
              <aside>
                <label htmlFor="">Nombre</label>
                <input
                  name="name"
                  value={input.name}
                  onChange={(e) => handleChange(e)}
                  type="text"
                />
              </aside>
              <button className="newButton" onClick={(e) => handleCreate(e)}>
                Guardar
              </button>
              <button
                className="cancelButton"
                onClick={() => setNewSection(false)}
              >
                <DeleteOutlinedIcon />
              </button>
            </div>
          </form>
        </section>
      ) : null}
      <article className="tableContainer">
        <table className="table">
          <thead>
            <tr className="headerTable">
              <th>Especialidad</th>
              <th className="thAction">Acción</th>
            </tr>
          </thead>
          <tbody>
            {current &&
              current.sections?.map((section, index) => (
                <tr key={index}>
                  <td className="tdSection">{section.name}</td>
                  <td className="buttons">
                    <button
                      id="buttonEdit"
                      onClick={(e) => handleEdit(e)}
                      className="tableButton"
                      name={section.name}
                      idsec={section.id}
                      content={section.content}
                      index={section.index}
                    >
                      Editar
                    </button>
                    <button
                      namedelete={section.name}
                      iddelete={section.id}
                      contentdelete={section.content}
                      index={section.index}
                      className="deleteButton"
                      onClick={(e) => handleDelete(e)}
                    >
                      Borrar
                    </button>
                  </td>
                </tr>
              ))}
            <tr></tr>
          </tbody>
        </table>
      </article>
      <article className={modal === true ? "modal" : "modalOff"}>
        <section className="modalContainer">
          <span>{filter.name}</span>
          <div>
            <button className="open-modal" onClick={() => handleModal()}>
              <ImageOutlined width="40" height="40" />
            </button>
          </div>
          <div
            className={
              modalImage ? "modalImageContainer" : "modalImageContainerOff"
            }
          >
            <BackofficeModal />
          </div>
          <form className="form-markdown">
            <Editor
              apiKey="slz93nsspb367zfebawu3ptagqrlbuyxfora657iog3xugbm"
              textareaName="description"
              initialValue={filter?.content}
              onInit={(evt, editor) => (editorRef.current = editor)}
              onDirty={() => setDirty(true)}
              init={{
                selector: "textarea.tinymce",
                theme: "silver",
                height: 600,
                /* width: 300, */
                onchange_callback: "handleChange",
                plugins: [
                  // FORMATO DEL MENU
                  "advlist autolink link image lists charmap preview hr anchor pagebreak",
                  "searchreplace wordcount visualblocks code visualchars nonbreaking",
                  "save table contextmenu directionality paste textcolor ",
                ],
                /* toolbar1: "undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | styleselect", */
                toolbar:
                  "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media| print preview fullpage | forecolor backcolor",
                image_advtab: true,
              }}
            />
          </form>
        </section>
        <div className="containerButtonGroup">
          <button className="markdown-button" onClick={(e) => handleClose(e)}>
            Cancelar
          </button>
          <button
            className="modified-button"
            onClick={() => handleConfirm()}
            disabled={!dirty}
          >
            Modificar
          </button>
          <button
            className={save ? "saveButton" : "saveDisabled"}
            disabled={save ? false : true}
            onClick={() => handleSubmit()}
          >
            Guardar
          </button>
        </div>
      </article>
      <div className="finishButton">
        <button className="newButton" onClick={() => handleFinish()}>
          Finalizar
        </button>
      </div>
    </div>
  );
};
