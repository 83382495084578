import Axios from "axios";
import { useState, useEffect, useCallback, useRef } from "react";
import style from "./FormAgradecimientos.module.css";
import Swal from "sweetalert2";
import successIcon from "./succesIcon.svg";
import closeBtn from "./closeBtn.svg";
import archivoCargado from "./archivoCargado.svg";
import subirArchivo from "./subirArchivo.svg";
import BounceLoader from "react-spinners/BounceLoader";
const { REACT_APP_API_URL } = process.env;

const FormAgradecimientos = () => {
  let ref = useRef();
  let [disabled, setDisabled] = useState(true);
  let [input, setInput] = useState({
    nombre: "",
    apellido: "",
    email: "",
    fechaDelSuceso: "",
    vivencia: "",
    telPrefijo: "",
    telNumero: "",
    dni: "",
    representante: "",
    ig: "",
  });
  let [errors, setErrors] = useState({});
  let [archivos, setArchivos] = useState([]);
  let [loading, setLoading] = useState(false);
  let refAgradecimiento = useRef();

  const handleChange = (event) => {
    if (event.target.name === "file") {
      let archivos = event.target.files;
      let aux = [];
      for (let clave in archivos) {
        aux.push(archivos[clave]);
      }
      aux.splice(-2);
      setArchivos(aux);
    } else {
      setInput({ ...input, [event.target.name]: event.target.value });
    }
  };

  const validate = () => {
    let erroresMensajes = {};
    if (!input.nombre) {
      erroresMensajes.nombre = "Debe ingresar un nombre";
    }
    if (!input.apellido) {
      erroresMensajes.apellido = "Debe ingresar un apellido";
    }
    if (!input.email) {
      erroresMensajes.email = "Debe ingresar un email";
    }
    if (!input.telPrefijo || !input.telNumero) {
      erroresMensajes.telefono = "Debe ingresar un teléfono";
    }
    if (!input.vivencia) {
      erroresMensajes.vivencia = "Debe ingresar la vivencia";
    }
    if (!input.dni) {
      erroresMensajes.dni = "Debe ingresar un DNI";
    }

    return erroresMensajes;
  };

  const handlerSubmit = async (e) => {
    e.preventDefault();
    let erroresMensajes = validate();
    if (!Object.keys(erroresMensajes).length) {
      let infoSend;
      setErrors(erroresMensajes);
      if (archivos.length > 0) {
        let auxArchivos = [];
        archivos.forEach((e) => auxArchivos.push(e.name));
        infoSend = {
          ...input,
          archivos: auxArchivos,
        };
      } else {
        infoSend = input;
      }

      let formData = new FormData();
      if (archivos.length > 0) {
        archivos?.forEach((f) => {
          formData.append("file", f);
        });
      }
      formData.append("infoData", JSON.stringify(infoSend));
      setLoading(true);
      try {
        await Axios.post(
          `${REACT_APP_API_URL}/formularios/agradecimientos`,
          formData,
          { headers: { "Content-type": "multipart/form-data" } }
        );
        setLoading(false);
        Swal.fire({
          title: "Solicitud enviada con éxito",
          confirmButtonText: "Entendido",
          buttonsStyling: false,
          customClass: {
            popup: `${style.containerAlert}`,
            confirmButton: `${style.buttonConfirm}`,
            title: `${style.titleAlert}`,
            htmlContainer: `${style.containerHtml}`,
          },
          html: `<img src=${successIcon} />`,
        });
      } catch (e) {
        console.log("Error al mandar el formulario", e);
      }
      ref.current.reset();
      setInput({
        nombre: "",
        apellido: "",
        email: "",
        fechaDelSuceso: "",
        vivencia: "",
        telPrefijo: "",
        telNumero: "",
        dni: "",
        representante: "",
        ig: "",
      });
      setArchivos([]);
    } else {
      setErrors(erroresMensajes);
    }
  };

  const alertRemoveFile = (name) => {
    Swal.fire({
      title: "¿Esta seguro que desea eliminar el archivo adjunto?",
      showCancelButton: true,
      confirmButtonText: "No",
      cancelButtonText: "Eliminar",
      buttonsStyling: false,
      customClass: {
        popup: `${style.cancelAlert}`,
        title: `${style.titleAlert}`,
        confirmButton: `${style.no_btn}`,
        cancelButton: `${style.eliminar_btn}`,
      },
    }).then((res) => {
      if (!res.value) {
        let aux = archivos.filter((f) => f.name !== name);
        setArchivos(aux);

        let list = new DataTransfer();
        Object.values(refAgradecimiento.current.files).filter((f) => {
          if (f.name !== name) {
            list.items.add(f);
            return f;
          }
        });

        refAgradecimiento.current.files = list.files;
      }
    });
  };

  const disabledButton = useCallback(() => {
    if (
      !input.nombre ||
      !input.apellido ||
      !input.email ||
      !input.vivencia ||
      !input.telPrefijo ||
      !input.telNumero ||
      !input.dni
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [input]);

  useEffect(() => {
    disabledButton();
  }, [input, disabledButton]);
  return (
    <>
      <h3 className={style.title__form}>Dejanos tu mensaje</h3>
      <form
        className={style.container__form}
        onSubmit={handlerSubmit}
        ref={ref}
        encType="multipart/form-data"
      >
        <div
          className={`${style.container__label_input} ${style.container__nombre}`}
        >
          <label htmlFor="nombre">Nombre</label>
          <input
            type="text"
            name="nombre"
            id="nombre"
            placeholder="Ej: María"
            onChange={handleChange}
            value={input.nombre}
            autoComplete="off"
            className={input.nombre ? style.not__empty : null}
          />
          {errors.nombre && (
            <span className={style.error__message}>{errors.nombre}</span>
          )}
        </div>
        <div
          className={`${style.container__label_input} ${style.container__apellido}`}
        >
          <label htmlFor="apellido">Apellido</label>
          <input
            type="text"
            name="apellido"
            id="apellido"
            placeholder="Ej: Romero"
            onChange={handleChange}
            value={input.apellido}
            autoComplete="off"
            className={input.apellido ? style.not__empty : null}
          />
          {errors.apellido && (
            <span className={style.error__message}>{errors.apellido}</span>
          )}
        </div>

        <div
          className={`${style.container__label_input} ${style.container__telefono}`}
        >
          <label htmlFor="teléfono">Teléfono</label>
          <div className={style.container__telefono_input}>
            <input
              type="number"
              name="telPrefijo"
              id="teléfono"
              placeholder="Prefijo"
              className={`${style.telefono__prefijo} ${
                input.telPrefijo ? style.not__empty : null
              }`}
              onChange={handleChange}
              value={input.telPrefijo}
              autoComplete="off"
            />
            <input
              type="number"
              name="telNumero"
              id="teléfono"
              placeholder="Número"
              className={`${style.telefono__numero} ${
                input.telNumero ? style.not__empty : null
              }`}
              onChange={handleChange}
              value={input.telNumero}
              autoComplete="off"
            />
            {errors.telefono && (
              <span className={style.error__message}>{errors.telefono}</span>
            )}
          </div>
        </div>
        <div
          className={`${style.container__label_input} ${style.container__dni}`}
        >
          <label htmlFor="dni">DNI</label>
          <input
            type="number"
            name="dni"
            id="dni"
            value={input.dni}
            placeholder="Ej: 99.999.999"
            onChange={handleChange}
            autoComplete="off"
            className={input.dni ? style.not__empty : null}
          />
          {errors.dni && (
            <span className={style.error__message}>{errors.dni}</span>
          )}
        </div>
        <div
          className={`${style.container__label_input} ${style.container__email}`}
        >
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="midireccion@mail.com"
            onChange={handleChange}
            value={input.email}
            autoComplete="off"
            className={input.email ? style.not__empty : null}
          />
          {errors.email && (
            <span className={style.error__message}>{errors.email}</span>
          )}
        </div>
        <div
          className={`${style.container__label_input} ${style.container__representante}`}
        >
          <label htmlFor="representante">
            Si es un familiar o representante legal del paciente por el cual
            escribe este agradecimiento, por favor informe en el siguiente
            campo: nombre completo del paciente, DNI y vínculo con el paciente
          </label>
          <input
            type="text"
            name="representante"
            id="representante"
            placeholder="Ej: Pareja, Madre-Hijo"
            onChange={handleChange}
            value={input.representante}
            autoComplete="off"
            className={input.representante ? style.not__empty : null}
          />
          {errors.representante && (
            <span className={style.error__message}>{errors.representante}</span>
          )}
        </div>
        <div
          className={`${style.container__label_input} ${style.container__fechaDelSuceso}`}
        >
          <label htmlFor="fechaDelSuceso">
            Si corresponde, indique la fecha del suceso por el cual escribe este
            formulario.
          </label>
          <input
            type="date"
            name="fechaDelSuceso"
            id="fechaDelSuceso"
            onChange={handleChange}
            value={input.fechaDelSuceso}
            autoComplete="off"
            className={input.fechaDelSuceso ? style.not__empty : null}
          />
          {errors.fechaDelSuceso && (
            <span className={style.error__message}>
              {errors.fechaDelSuceso}
            </span>
          )}
        </div>
        <div
          className={`${style.container__label_input} ${style.container__vivencia}`}
        >
          <label htmlFor="vivencia">
            A continuación, le solicitamos por favor que describa detalladamente
            la vivencia:
          </label>
          <textarea
            type="text"
            name="vivencia"
            id="vivencia"
            cols="30"
            rows="10"
            placeholder="Descripción de la vivencia"
            onChange={handleChange}
            value={input.vivencia}
            autoComplete="off"
            className={input.vivencia ? style.not__empty : null}
          />
          {errors.vivencia && (
            <span className={style.error__message}>{errors.vivencia}</span>
          )}
        </div>
        <div
          className={`${style.container__label_input} ${style.container__ig}`}
        >
          <label>
            {
              '¿Le gustaría que su agradecimiento forme parte de nuestra sección de Instagram "relatos de pacientes"?'
            }
          </label>
          <span>
            En caso de contar con su autorización, nuestro equipo de
            Comunicación se pondrá en contacto con usted.
          </span>
          <div className={style.container__radio}>
            <label htmlFor="si">Sí</label>
            <input
              type="radio"
              id="si"
              value="si"
              name="ig"
              onClick={handleChange}
            />
          </div>
          <div className={style.container__radio}>
            <label htmlFor="no">No</label>
            <input
              type="radio"
              id="no"
              value="no"
              name="ig"
              onClick={handleChange}
            />
          </div>
        </div>

        <div
          className={`${style.container__label_input} ${style.container__pedidos}`}
        >
          <label htmlFor="file">
            Si usted desea, puede adjuntar imágenes a su relato.
          </label>

          <input
            type="file"
            name="file"
            id="file"
            onChange={handleChange}
            multiple
            ref={refAgradecimiento}
          />

          {!archivos.length > 0 ? (
            <label htmlFor="file" className={style.label__custom}>
              <img src={subirArchivo} alt="subir archivo" />
              <span>Seleccionar</span>
            </label>
          ) : (
            archivos.map((f, index) => (
              <div
                className={style.container__label_archivoCargado}
                key={index}
              >
                <img
                  src={archivoCargado}
                  alt="cargado archivo"
                  className={style.archivoCargado__btn}
                />
                <span>{f.name}</span>
                <img
                  src={closeBtn}
                  alt="close button"
                  className={style.archivoCargado__btn_close}
                  onClick={() => {
                    alertRemoveFile(f.name);
                  }}
                />
              </div>
            ))
          )}
        </div>
        {loading ? (
          <div className={style.loadingContainer}>
            <BounceLoader color="#002856" />
          </div>
        ) : (
          <button
            type="submit"
            className={`${style.btn__enviar} ${
              disabled ? style.disabled : null
            }`}
          >
            Enviar
          </button>
        )}
      </form>
    </>
  );
};

export default FormAgradecimientos;
