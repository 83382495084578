import { useContext, useEffect, useState, useCallback } from "react";
import {
  useSearchParams,
  useLocation,
  useParams,
  Link,
} from "react-router-dom";
import { Accordion, AccordionContext } from "react-bootstrap";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import { Helmet } from "react-helmet";
import { useData } from "../../../hooks/api";
import search from "./search-line.svg";
import plus from "./plus.svg";
import menos from "./menos.svg";
import trash from "./trash.svg";
import sections from "./sections.svg";
import close from "./close.svg";
import style from "./IndYPrep.module.css";
import { RenderPDF } from "../../Render-PDF/Render-PDF";
import {
  INDICACIONES_PREPARACIONES,
  COMMON_DESC,
  FAST,
  FILTROS,
  ACCENT_MAP,
  PAGE_MAPPING,
} from "./constants";

function accentFold(s) {
  if (!s) return "";
  let ret = "";
  for (let i = 0; i < s.length; i++) {
    ret += ACCENT_MAP[s.charAt(i)] || s.charAt(i);
  }
  return ret;
}

function IndYPrep({ preps, type }) {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [active, setActive] = useState("");
  const abc = searchParams.get("name") ?? "a";
  const [page, setPage] = useState("0");
  const [foco, setFoco] = useState(false);
  const [busqueda, setBusqueda] = useState("");
  const [prepSearch, setPrepSearch] = useState([]);
  const [select, setSelect] = useState([]);
  const [modal, setModal] = useState(false);
  const [filtrados, setFiltrados] = useState([]);
  const [match, setMatch] = useState([]);
  const [sect, setSect] = useState("");
  const { name, section } = useParams();
  const [modal2, setModal2] = useState(false);
  const [verMas, setVerMas] = useState("");

  let table

  switch (location.pathname.split("/")[1]) {
    case "laboratorios":
      table = "preparationsLabs";
      break;
    case "estudios":
      table = "preparationsSpecialties";
      break;
    case "especialidades":
      table = "preparationsSpecialties";
      break;
    default:
      table = location.pathname.split("/")[1];
  }
  const { data: preparations } = useData(table);
  const { data: specialtiesList } = useData("specialtiesList");

  useEffect(() => {
    if (preparations) {
      let parametro = abc;
      if (select.length < 1 && parametro) {
        let filtrado = preparations.filter((prep) => prep.name === parametro);
        if (filtrado.length > 0) handleOption(filtrado[0]);
        if (
          parametro[0].toLowerCase() === "a" ||
          parametro[0].toLowerCase() === "b"
        ) {
          setPage("0");
          setActive("A-B");
        } else if (
          parametro[0].toLowerCase() === "c" ||
          parametro[0].toLowerCase() === "d"
        ) {
          setPage("1");
          setActive("C-D");
        } else if (
          parametro[0].toLowerCase() === "e" ||
          parametro[0].toLowerCase() === "f"
        ) {
          setPage("2");
          setActive("E-F");
        } else if (
          parametro[0].toLowerCase() === "g" ||
          parametro[0].toLowerCase() === "h" ||
          parametro[0].toLowerCase() === "i"
        ) {
          setPage("3");
          setActive("G-I");
        } else if (
          parametro[0].toLowerCase() === "j" ||
          parametro[0].toLowerCase() === "k" ||
          parametro[0].toLowerCase() === "l"
        ) {
          setPage("4");
          setActive("J-L");
        } else if (
          parametro[0].toLowerCase() === "m" ||
          parametro[0].toLowerCase() === "n" ||
          parametro[0].toLowerCase() === "ñ"
        ) {
          setPage("5");
          setActive("M-Ñ");
        } else if (
          parametro[0].toLowerCase() === "o" ||
          parametro[0].toLowerCase() === "p" ||
          parametro[0].toLowerCase() === "q"
        ) {
          setPage("6");
          setActive("O-Q");
        } else if (
          parametro[0].toLowerCase() === "r" ||
          parametro[0].toLowerCase() === "s"
        ) {
          setPage("7");
          setActive("R-S");
        } else if (
          parametro[0].toLowerCase() === "t" ||
          parametro[0].toLowerCase() === "u" ||
          parametro[0].toLowerCase() === "v"
        ) {
          setPage("8");
          setActive("T-V");
        } else if (
          parametro[0].toLowerCase() === "w" ||
          parametro[0].toLowerCase() === "x" ||
          parametro[0].toLowerCase() === "y" ||
          parametro[0].toLowerCase() === "z"
        ) {
          setPage("9");
          setActive("W-Z");
        }
      }
      let aux = preparations.filter(
        (e) => select.length > 0 && e.name === select[0]?.name
      );

      setMatch(aux);
      if (busqueda) {
        if (
          busqueda[0].toLowerCase() === "a" ||
          busqueda[0].toLowerCase() === "b"
        ) {
          setPage("0");
          setActive("A-B");
        } else if (
          busqueda[0].toLowerCase() === "c" ||
          busqueda[0].toLowerCase() === "d"
        ) {
          setPage("1");
          setActive("C-D");
        } else if (
          busqueda[0].toLowerCase() === "e" ||
          busqueda[0].toLowerCase() === "f"
        ) {
          setPage("2");
          setActive("E-F");
        } else if (
          busqueda[0].toLowerCase() === "g" ||
          busqueda[0].toLowerCase() === "h" ||
          busqueda[0].toLowerCase() === "i"
        ) {
          setPage("3");
          setActive("G-I");
        } else if (
          busqueda[0].toLowerCase() === "j" ||
          busqueda[0].toLowerCase() === "k" ||
          busqueda[0].toLowerCase() === "l"
        ) {
          setPage("4");
          setActive("J-L");
        } else if (
          busqueda[0].toLowerCase() === "m" ||
          busqueda[0].toLowerCase() === "n" ||
          busqueda[0].toLowerCase() === "ñ"
        ) {
          setPage("5");
          setActive("M-Ñ");
        } else if (
          busqueda[0].toLowerCase() === "o" ||
          busqueda[0].toLowerCase() === "p" ||
          busqueda[0].toLowerCase() === "q"
        ) {
          setPage("6");
          setActive("O-Q");
        } else if (
          busqueda[0].toLowerCase() === "r" ||
          busqueda[0].toLowerCase() === "s"
        ) {
          setPage("7");
          setActive("R-S");
        } else if (
          busqueda[0].toLowerCase() === "t" ||
          busqueda[0].toLowerCase() === "u" ||
          busqueda[0].toLowerCase() === "v"
        ) {
          setPage("8");
          setActive("T-V");
        } else if (
          busqueda[0].toLowerCase() === "w" ||
          busqueda[0].toLowerCase() === "x" ||
          busqueda[0].toLowerCase() === "y" ||
          busqueda[0].toLowerCase() === "z"
        ) {
          setPage("9");
          setActive("W-Z");
        }
      }
    }
  }, [select, preparations, abc, busqueda]);

  useEffect(() => {
    setSect(section);
    const cat = section === "Adultos" ? "true" : "false";
    if (section === "Adultos" || section === "Pediátricos") {
      const aux1 = [];
      let aux2 = [];
      let noRepeat;
      let newData;
      const array =
        preparations &&
        preparations.filter((prep) =>
          prep.specialties.some((b) => getByQuery(b)?.adults === cat)
        );
      if (array.length > 0) {
        array.forEach((e) => {
          if (!aux2.some((b) => getByQuery(b)?.name)) {
            aux2.push(e.specialties);
          }
        });
      }
      aux2 = flattenDeep(aux2);
      aux2 = aux2.filter((special) => getByQuery(special)?.adults === cat);
      newData = new Set(aux2);
      noRepeat = [...newData];
      noRepeat.forEach((id) =>
        aux1.push({
          name: getByQuery(id)?.name,
          type: getByQuery(id)?.adults === "true" ? "adultos" : "pediatricos",
          id: getByQuery(id)?.id,
        })
      );
      setFiltrados(aux1.filter((e) => e.name !== "Diagnóstico por imágenes"));
    } else {
      setPrepSearch(preparations);
      const array = preparations
        ?.sort((a, b) => (a.name > b.name ? 1 : -1))
        .filter((e, i) => e.name !== preparations[i + 1]?.name);
      setFiltrados(array);
    }
    if (preps) {
      setFiltrados(preps);
    }
  }, [section, preparations, preps]);

  const filter = (r) => {
    if (!filtrados) return;
    let aux = [];
    let aux2 = [];
    filtrados
      .sort((a, b) => (a.name > b.name ? -1 : 1))
      .filter((e) => {
        if (accentFold(e.name)?.toLowerCase().startsWith(r.toLowerCase())) {
          aux.unshift(e);
        } else if (
          accentFold(e.name)?.toLowerCase().includes(r.toLowerCase())
        ) {
          aux2.unshift(e);
        }
      });
    const resultado = aux.concat(
      aux2.sort((a, b) => (a.name > b.name ? 1 : -1))
    );
    setPrepSearch(resultado);
  };

  const getByQuery = (query) => {
    if (!preparations) return null;

    const results = specialtiesList?.filter(
      (specialty) => specialty.id === query
    );
    return results[0];
  };

  const handleFocus = () => setFoco(true);
  const waitFor = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const handleBlur = async () => {
    await waitFor(200);
    setFoco(false);
  };
  const handleChange = (e) => {
    setBusqueda(e.target.value);
    filter(e.target.value);
  };

  const handleClick = (e) => {
    let filter = e.target.getAttribute("filter");
    let number = e.target.getAttribute("data-number");
    setActive(filter);
    setPage(number);
  };

  const handleOption = (p) => {
    if (!p) return;
    if (name === INDICACIONES_PREPARACIONES || type === "lab") {
      !select.includes(p) && setSelect([p, ...select]);
    } else {
      setSelect([p]);
    }
  };

  const handleFast = () => {
    if (window.innerWidth > 1175) {
      setModal(true);
    }
  };

  const handleTrash = (r) => {
    const filter = select.filter((e) => e !== r);
    setSelect(filter);
  };

  const createMarkup = (r) => ({ __html: r });
  const handleVerMas = (r) => {
    setVerMas(select.filter((e) => e.name === r.name));
    setModal2(true);
  };

  function ContextAwareToggle({ eventKey }) {
    const { activeEventKey } = useContext(AccordionContext);
    const isCurrentEventKey = activeEventKey === eventKey;
    return <img src={isCurrentEventKey ? menos : plus} />;
  }

  function flattenDeep(arr1) {
    return arr1.reduce(
      (acc, val) =>
        Array.isArray(val) ? acc.concat(flattenDeep(val)) : acc.concat(val),
      []
    );
  }

  const renderLetters = (letters, filtrados, section, select, handleOption) => {
    return letters.map((letter, index) => (
      <div
        key={index}
        className={`${style.contenidocentral__content_filtrado} ${
          style[`order${index + 1}`]
        }`}
      >
        <div className={style.letterIndex}>
          <h2 className={style.letter}>{letter}</h2>
        </div>
        <ul className={style.contenidocentral__filtrado_ul}>
          {filtrados?.length > 0 &&
          filtrados?.some((e) => e.name[0]?.includes(letter)) ? (
            filtrados?.map(
              (r) =>
                (r.name[0].toUpperCase() === letter ||
                  r.name[0].toUpperCase() === letter.toUpperCase()) &&
                (section === "Adultos" || section === "Pediátricos" ? (
                  <Link
                    to={`/especialidades/${r.name}/${INDICACIONES_PREPARACIONES}/${r.type}/${r.id}`}
                    key={r.id}
                  >
                    <div
                      className={
                        select.includes(r)
                          ? style.studyNameActive
                          : style.studyName
                      }
                      onClick={() => handleOption(r)}
                    >
                      {r.name}
                    </div>
                  </Link>
                ) : (
                  <div
                    className={
                      select.includes(r)
                        ? style.studyNameActive
                        : style.studyName
                    }
                    onClick={() => handleOption(r)}
                    key={r.id}
                  >
                    {r.name}
                  </div>
                ))
            )
          ) : (
            <div className={style.studyName}>Sin resultados</div>
          )}
        </ul>
      </div>
    ));
  };

  const renderContent = useCallback(
    (page, filtrados, section, select, handleOption) => {
      const letters = PAGE_MAPPING[page];
      if (letters && Array.isArray(filtrados)) {
        return renderLetters(letters, filtrados, section, select, handleOption);
      }
      return null;
    },
    []
  );

  return (
    <>
      <Helmet>
        <title>CURF - Laboratorios</title>
        <meta name="description" content="Indicaciones y preparaciones." />
      </Helmet>
      <div className={style.titleContainer}>
        <h1 className={style.title}>{INDICACIONES_PREPARACIONES}</h1>
        <img src={sections} className={style.img} onClick={() => {}} />
      </div>
      <div className={style.divisor}></div>
      <p className={style.desc}>
        {location === "home" || location === "mega"
          ? COMMON_DESC
          : (name && `${name.split(" ")[0]}` === "Laboratorio") ||
            (name && name.split(" ")[0] === "Anatomía") ||
            (name && name.split(" ")[0] === "Medicina")
          ? COMMON_DESC
          : `Seleccioná el estudio que debes realizar para acceder a sus respectivas indicaciones`}
      </p>
      <div className={style.searchBar}>
        <input
          className={style.search}
          value={busqueda}
          placeholder="Ingresá el nombre del estudio"
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
        />
        <img className={style.lupa} src={search} />
        {foco && busqueda && (
          <div
            className={
              foco
                ? `${style.recomendaciones}`
                : `${style.recomendaciones} ${style.false}`
            }
          >
            {Array.isArray(prepSearch) && prepSearch.length ? (
              prepSearch
                .filter((e) => e.name !== "default")
                .map(
                  (r, i) =>
                    r &&
                    r.name && (
                      <div className={style.sug} key={i}>
                        <p
                          key={r.id}
                          className={style.option}
                          onClick={() => handleOption(r)}
                        >
                          {r.name}
                        </p>
                      </div>
                    )
                )
            ) : (
              <p className={style.option}>No encontrado</p>
            )}
          </div>
        )}
      </div>
      <h3 className={style.subtitle}>Listado de estudios</h3>
      <div
        className={
          name === INDICACIONES_PREPARACIONES ||
          sect === INDICACIONES_PREPARACIONES
            ? style.filter2
            : style.filter
        }
      >
        {FILTROS.map((filtro, index) => (
          <div className={style.filtros_line} key={index}>
            <span
              filter={filtro.vista}
              onClick={handleClick}
              data-number={filtro.value}
              className={
                active === filtro.vista ? style.filtrado_active : style.filtrado
              }
              key={index + 1}
            >
              {filtro.vista}
            </span>
            <div className={style.separacion_filtrado} key={index + 2}></div>
          </div>
        ))}
      </div>
      <div
        className={
          name === INDICACIONES_PREPARACIONES ||
          sect === INDICACIONES_PREPARACIONES
            ? style.body
            : style.body2
        }
      >
        <div
          className={
            name === INDICACIONES_PREPARACIONES ||
            sect === INDICACIONES_PREPARACIONES
              ? style.abc1
              : style.abc
          }
        >
          {renderContent(page, filtrados, section, select, handleOption)}
        </div>
        <div
          className={
            name === INDICACIONES_PREPARACIONES ||
            sect === INDICACIONES_PREPARACIONES
              ? style.info
              : style.none
          }
        >
          <h2 className={style.prep_title}>
            {name === INDICACIONES_PREPARACIONES || type === "lab"
              ? "¿Cómo prepararme?"
              : "Indicaciones de:"}
          </h2>
          {select.length > 0 ? (
            <>
              {name === INDICACIONES_PREPARACIONES || type === "lab" ? (
                select?.some((e) => e?.fast === true) ? (
                  <>
                    <h3 className={style.prep_subtitle}>Ayuno</h3>
                    <div className={style.ayunoContainer}>
                      <div className={style.cont}>
                        {FAST.map((item, index) => (
                          <div className={style.pContainer} key={index}>
                            <p>{item.name}</p>
                            <p>{item.hours}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                ) : null
              ) : (
                <h2 className={style.titleStudy}>{select[0]?.name}</h2>
              )}
              {name === INDICACIONES_PREPARACIONES || type === "lab" ? (
                <h3 className={style.prep_subtitle}>
                  Procedimientos por estudio
                </h3>
              ) : (
                <h3 className={style.title_subtitle}>Se realiza en:</h3>
              )}
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                {name === INDICACIONES_PREPARACIONES || type === "lab"
                  ? select.length > 0 &&
                    select
                      ?.filter(
                        (e) => !Object.prototype.hasOwnProperty.call(e, "hours")
                      )
                      .map((r, i) => (
                        <Accordion defaultActiveKey="0" key={i}>
                          <Accordion.Item
                            className={style.Accordion_Item}
                            eventKey={`${i}`}
                          >
                            <Accordion.Header
                              className={style.Accordion_Header}
                            >
                              <div className={style.desplegable_title}>
                                <ContextAwareToggle
                                  eventKey={`${i}`}
                                ></ContextAwareToggle>
                                <p className={style.p}>{r?.name}</p>
                                <div
                                  className={style.trash_btn}
                                  onClick={() => handleTrash(r)}
                                >
                                  <img src={trash} />
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div
                                className={style.content}
                                dangerouslySetInnerHTML={createMarkup(
                                  r?.content
                                )}
                              />
                              <a
                                onClick={() => handleVerMas(r)}
                                className={style.link}
                              >
                                Ver más
                              </a>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      ))
                  : match &&
                    match?.map((r, i) => (
                      <Accordion defaultActiveKey="0" key={i}>
                        <Accordion.Item
                          eventKey={`${i}`}
                          className={style.Accordion_Item}
                        >
                          <Accordion.Header className={style.Accordion_Header}>
                            <div className={style.title_desplegable}>
                              <p className={style.p}>
                                {r?.specialties &&
                                r.specialties?.length > 0 &&
                                getByQuery(r.specialties[0]) &&
                                getByQuery(r.specialties[0]).name !==
                                  "Diagnóstico por imágenes"
                                  ? `${getByQuery(r.specialties[0]).name}${
                                      getByQuery(r.specialties[0]).adults ===
                                      "true"
                                        ? " (Adultos)"
                                        : " (Pediátrico)"
                                    }`
                                  : `${getByQuery(r.specialties[0]).name}`}
                              </p>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div
                              className={style.content2}
                              dangerouslySetInnerHTML={createMarkup(r?.content)}
                            />
                            {r?.file && r?.file.length > 0 && (
                              <button className={style.ind_btn}>
                                Descargar indicaciones
                              </button>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    ))}
              </div>
              {modal && (
                <div id="modal" className={style.modal}>
                  <img
                    onClick={() => setModal(false)}
                    src={close}
                    className={style.closeModal}
                    alt=""
                  />
                  {window.innerWidth > 1175 && (
                    <PDFViewer
                      style={{ zIndex: "3", height: "90%", width: "70%" }}
                    >
                      <RenderPDF documents={select && select} fast={FAST} />
                    </PDFViewer>
                  )}
                </div>
              )}
              {window.innerWidth <= 1175 && (
                <PDFDownloadLink
                  document={
                    <RenderPDF documents={select && select} fast={FAST} />
                  }
                  fileName="indicaciones.pdf"
                >
                  {({ loading }) =>
                    loading ? (
                      "Loading document..."
                    ) : (
                      <button className={style.prep_btn}>
                        Descargar todas las indicaciones
                      </button>
                    )
                  }
                </PDFDownloadLink>
              )}
              {name === INDICACIONES_PREPARACIONES || type === "lab" ? (
                window.innerWidth <= 1175 ? null : (
                  <button onClick={handleFast} className={style.prep_btn}>
                    Descargar todas las indicaciones
                  </button>
                )
              ) : null}
            </>
          ) : (
            <p className={style.msg}>
              Seleccioná el estudio a realizar para acceder a la información
            </p>
          )}
        </div>
      </div>
      {modal2 && (
        <div className={style.modal}>
          <div className={style.divModal}>
            <div className={style.containerModal}>
              <img
                onClick={() => setModal2(false)}
                src={close}
                className={style.closeModal1}
                alt=""
              />
              <h2>{verMas[0]?.name}</h2>
            </div>
            <div
              className={style.content1}
              dangerouslySetInnerHTML={createMarkup(verMas[0]?.content)}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default IndYPrep;
