import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { sectionShow } from "../../actions";
import sections from "./sections.svg";
import style from "./Equipamiento.module.css";
import Galeria from "../Galeria/Galeria";
import { useData} from "../../hooks/api";

function Equipamiento() {
  const dispatch = useDispatch();
  const { data: equipamiento, error: equipamientoError, isLoading: equipamientoLoading } = useData("equipment");
  const { data: equipamientoGallery, error: equipamientoGalleryError, isLoading: equipamientoGalleryLoading } = useData("galeriaEquipamientos");

  const openSections = () => {
    dispatch(sectionShow(true));
  };

  useEffect(() => {
    if (equipamientoError || equipamientoGalleryError) {
      console.error("Error al cargar los datos:", equipamientoError || equipamientoGalleryError);
    }
  }, [equipamientoError, equipamientoGalleryError]);

  if (equipamientoLoading || equipamientoGalleryLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <div>
      <img
        src={sections}
        alt="Section"
        className={style.sections__button}
        onClick={openSections}
      />
      <h1 className={style.title}>Equipamiento tecnológico</h1>
      <div className={style.division}></div>
      <h3 className={style.description}>Nuestros equipos más innovadores</h3>
      <div className={style.grid}>
        {equipamiento &&
          equipamiento.map((r) => (
            <div className={style.instalacion} key={r.name}>
              <div className={style.img}>
                <img src={r.icon} alt={r.name} />
              </div>
              <h1 className={style.name}>{r.name}</h1>
              <ul className={style.ul}>
                {r.items.map((e, i) => (
                  <li className={style.desc} key={i}>
                    {e}
                  </li>
                ))}
              </ul>
            </div>
          ))}
      </div>
      {equipamientoGallery && <Galeria images={equipamientoGallery.map(item => item.img)} />}
    </div>
  );
}

export default Equipamiento;
