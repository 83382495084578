import { Header } from "../Header/Header";
import { NavBar } from "../NavBar/NavBar";
import style from "./MyPortal.module.css";
import logo from "./logo.svg";
import img1 from "./tele1.png";
import img2 from "./tele2.png";
import img3 from "./tele3.png";
import banner from "../../assets/miportal.jpg";
import Footer from "../Footer/Footer";
import calendario from "./calendario.svg";
import persona from "./persona.svg";
import papel from "./papel.svg";
import arrow from "./arrow.svg";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export const MyPortal = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <title>CURF - Mi Portal</title>
        <meta
          name="description"
          content="Accedé a tus resultados y gestioná tus turnos."
        />
      </Helmet>
      <div className={style.container}>
        <nav className="navBar__Home">
          <Header />
          <NavBar />
        </nav>
        <div className="distancia"></div>
        <div className={style.banner}>
          <div
            className={style.bannerImage}
            style={{ backgroundImage: `url(${banner})` }}
          ></div>
          <button onClick={() => navigate("/")} className={style.goHomeBtn}>
            <img src={arrow} alt="flecha" />
            Volver
          </button>
          <div className={style.bannerCont}>
            <img className={style.bannerImg} src={logo} alt="logo" />
            <h2 className={style.bannerTitle}>Mi portal</h2>
          </div>
        </div>
        <div className={style.containerGallery}>
          <div className={style.gallery}>
            <a
              className={style.containerImg}
              href="http://turnos.curf.com.ar/"
              target="_blank"
            >
              <div className={style.containerImg}>
                <img src={img1} alt="img" />
                <div className={style.filtro}>
                  <img className={style.logo} src={calendario} alt="logo" />
                  <h3 className={style.sectionTitle}>Turnero online</h3>
                  <p className={style.desc}>
                    Gestioná tu turno para consultas presenciales o por
                    Telemedicina
                  </p>
                </div>
              </div>
            </a>
            <a
              className={style.containerImg}
              href="https://informedigital.curf.com.ar"
              target="_blank"
            >
              <div className={style.containerImg}>
                <img src={img2} alt="img" />
                <div className={style.filtro}>
                  <img className={style.logo} src={persona} alt="logo" />
                  <h3 className={style.sectionTitle}>Informe digital</h3>
                  <p className={style.desc}>
                    Consultá los resultados de estudios realizados en
                    Diagnóstico por Imágenes
                  </p>
                </div>
              </div>
            </a>
            <a
              className={style.containerImg}
              href="https://docs.google.com/forms/d/e/1FAIpQLSfWqtR8xmyxaJHR14uS2pRhsu6Dq_T3WR1Yn1nfYtL4PXnU5g/viewform"
              target="_blank"
            >
              <div className={style.containerImg}>
                <img src={img3} alt="img" />
                <div className={style.filtro}>
                  <img className={style.logo} src={papel} alt="logo" />
                  <h3 className={style.sectionTitle}>Recetas online</h3>
                  <p className={style.desc}>
                    Solicitá o renová aquí tus pedidos médicos (ver requisitos)
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className={style.pantalla}>
          <Footer />
        </div>
      </div>
    </div>
  );
};
