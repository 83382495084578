import style from "./ContentSug.module.css";
import FormSugerencias from "../FormSugerencias/FormSugerencias";
import FormAgradecimientos from "../FormAgradecimientos/FormAgradecimientos";
import FormReclamos from "../FormReclamos/FormReclamos";

const ContentSug = ({ data }) => {
  return (
    <div className={style.container__main}>
      <div className={style.container__content}>
        {data && data?.name === "Sugerencias" ? (
          <FormSugerencias />
        ) : data?.name === "Agradecimientos" ? (
          <FormAgradecimientos />
        ) : (
          <FormReclamos />
        )}
      </div>
    </div>
  );
};

export default ContentSug;
