import "./Backoffice-Statistics.css";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";

export const CreateStatistics = ({
  display,
  setDisplay,
  handleChange,
  handleSubmit,
  handleUpload,
  updateImg,
  setUpdateImg,
}) => {
  const open = () => {
    document.getElementById("file").click();
  };

  return (
    <div className="backofficeStatistics__container_main">
      <h3>Crear estadísticas</h3>
      <div className="inputStatitics">
        <form className="statiticsForm" onSubmit={handleSubmit}>
          <label htmlFor="">Estadística</label>
          <input
            type="text"
            placeholder="Nombre"
            onChange={handleChange}
            name="name"
          />
          <label htmlFor="">Valor</label>
          <input
            type="text"
            placeholder="Valor"
            onChange={handleChange}
            name="value"
          />
          <label htmlFor="">Ícono</label>
          {updateImg === undefined || updateImg === "" ? null : (
            <img className="imgIcon" src={updateImg} alt="Img" />
          )}

          <div className="customInputFile">
            <input
              type="file"
              className="inputFile"
              id="file"
              onChange={handleUpload}
            />
            <ImageSearchIcon id="searchButton" onClick={open} />
            <span>Buscar ícono</span>
          </div>
          <div className="customInputFile">
            <SaveIcon
              style={{ cursor: "pointer", color: "teal" }}
              onClick={handleSubmit}
            />
            <span>Crear estadística</span>
          </div>
          <div className="customInputFile">
            <CancelIcon
              style={{ cursor: "pointer", color: "red" }}
              onClick={() => {
                setDisplay(!display);
                setUpdateImg("");
              }}
            />
            <span>Cerrar y cancelar</span>
          </div>
        </form>
      </div>
    </div>
  );
};
