import { Carousel } from "react-bootstrap";
import { useCarousel } from "../../../../hooks/api";
import "./Backoffice-Carousel.css";
import imgBackup from "../../assets/imgBackup.jpg";
import imgBackupMobile from "../../assets/imgBackupMobile.jpg";

const CarouselComponent = ({ controllers }) => {
  // Usar el hook useCarousel para obtener los datos del carousel
  const { data: images, error} = useCarousel({ mobile: window.innerWidth < 608 });

  // Imágenes de respaldo en caso de que no haya imágenes en el carrusel
  let imageBackup = [
    {
      id: 1,
      url: "",
      img: imgBackup,
    },
  ];

  let imageBackupMobile = [
    {
      id: 1,
      url: "",
      img: imgBackupMobile,
    },
  ];



  if (error) {
    return <div>Error al cargar el carrusel: {error.message}</div>;
  }

  return (
    <Carousel
      indicators={false}
      fade={true}
      pause="hover"
      controls={controllers}
    >
      {images && images.length > 0
        ? images.map((e) => (
            <Carousel.Item
              key={e.id}
              interval={5000}
              className="carousel__container_main"
            >
              <div className="image-carousel-container">
                <a href={e.url ? e.url : null}>
                  <img
                    className={`d-block w-100 carouselComponent`}
                    src={e.img}
                    alt="First slide"
                    role="link"
                  />
                </a>
              </div>
            </Carousel.Item>
          ))
        : window.innerWidth > 608
        ? imageBackup.map((e) => (
            <Carousel.Item
              key={e.id}
              interval={5000}
              className="carousel__container_main"
            >
              <div className="image-carousel-container">
                <a href={e.url ? e.url : null}>
                  <img
                    className={`d-block w-100 carouselComponent`}
                    src={e.img}
                    alt="First slide"
                    role="link"
                  />
                </a>
              </div>
            </Carousel.Item>
          ))
        : imageBackupMobile.map((e) => (
            <Carousel.Item
              key={e.id}
              interval={5000}
              className="carousel__container_main"
            >
              <div className="image-carousel-container">
                <a href={e.url ? e.url : null}>
                  <img
                    className={`d-block w-100 carouselComponent`}
                    src={e.img}
                    alt="First slide"
                    role="link"
                  />
                </a>
              </div>
            </Carousel.Item>
          ))}
    </Carousel>
  );
};

export default CarouselComponent;
