import "./Backoffice-Statistics.css";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";

export const EditStatistics = ({
  view,
  setView,
  statistics,
  handleChange,
  handleSubmit,
  handleUpload,
  updateImg,
  setUpdateImg,
}) => {
  const open = () => {
    document.getElementById("file").click();
  };

  return (
    <>
      {view && (
        <div className="backofficeStatistics__container_main">
          <h3>Editar estadísticas</h3>
          <div className="inputStatitics">
            <form className="statiticsForm" onSubmit={handleSubmit}>
              <label htmlFor="">Estadística</label>
              <input
                type="text"
                placeholder={statistics?.name || "Estadística"}
                onChange={handleChange}
                name="name"
              />
              <label htmlFor="">Valor</label>
              <input
                type="text"
                placeholder={statistics?.value || "Valor"}
                onChange={handleChange}
                name="value"
              />
              <label htmlFor="">Ícono</label>
              <img
                className="imgIcon"
                src={
                  updateImg || statistics?.img || ""
                }
                alt="Img"
              />
              <div className="customInputFile">
                <input
                  type="file"
                  className="inputFile"
                  id="file"
                  onChange={handleUpload}
                />
                <ImageSearchIcon id="searchButton" onClick={open} />
                <span>Cambiar ícono</span>
              </div>
              <div className="customInputFile">
                <SaveIcon
                  style={{ cursor: "pointer", color: "teal" }}
                  onClick={(e) => handleSubmit(e)}
                />
                <span>Guardar cambios</span>
              </div>
              <div className="customInputFile">
                <CancelIcon
                  style={{ cursor: "pointer", color: "red" }}
                  onClick={() => {
                    setView(false);
                    setUpdateImg("");
                  }}
                />
                <span>Cerrar y cancelar</span>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};
