import { useState } from "react";
import { Link } from "react-router-dom";
import style from "./NosotrosNav.module.css";
import edificios from "../../images/edificio.svg";
import profesionales from "../../images/profesionaless.svg";
import rrhh from "../../images/rrhh.svg";
import curf from "../../images/curf.svg";
import polygon from "../../images/polygon.svg";

export const NosotrosNav = () => {
  let [open, setOpen] = useState(false);
  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };
  return (
    <>
      <li
        className={style.liPrincipal}
        onMouseEnter={(e) => handleMouseEnter(e)}
        onMouseLeave={(e) => handleMouseLeave(e)}
      >
        <figure>
          <img src={polygon} alt="" />
        </figure>
        <div className={style.titleContainer}>
          <span className={style.navBarTitle}>Nosotros</span>
        </div>
        <article
          className={
            open ? `${style.article} ${style.visible}` : `${style.article}`
          }
        >
          <section>
            <div className={style.megaMenuContainer}>
              <div className={style.megaMenuTitleContainer}>
                <div className={style.megaMenuTitle}>
                  <div className={style.iconsNavContainer}>
                    <img className={style.iconsNav} src={edificios} alt="" />
                  </div>
                  <div className={style.megaMenuPcontainer}>
                    <p className={style.megaMenuP}>INSTITUCIONAL</p>
                  </div>
                </div>
              </div>
              <ul>
                <li>
                  <div
                    className={style.institucionalLink}
                    onClick={(e) => handleMouseLeave(e)}
                  >
                    <Link to="/general/institucional/Historia">
                      Quiénes somos - Historia
                    </Link>
                  </div>
                </li>
                <li>
                  <div
                    className={style.institucionalLink}
                    onClick={(e) => handleMouseLeave(e)}
                  >
                    <Link to="/general/institucional/Mision">
                      Misión, visión y valores
                    </Link>
                  </div>
                </li>
                <li>
                  <div
                    className={style.institucionalLink}
                    onClick={(e) => handleMouseLeave(e)}
                  >
                    <Link to="/general/equipo/Autoridades">Nuestro equipo</Link>
                  </div>
                </li>
                <li>
                  <div
                    className={style.institucionalLink}
                    onClick={(e) => handleMouseLeave(e)}
                  >
                    <Link to="/sedes/Instalaciones?infraestructura=true">
                      Infraestructura
                    </Link>
                  </div>
                </li>
                <li>
                  <div
                    className={style.institucionalLink}
                    onClick={(e) => handleMouseLeave(e)}
                  >
                    <Link to="/noticias/Novedades%20institucionales">
                      Novedades institucionales
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
            <div className={style.megaMenuContainer}>
              <div className={style.megaMenuTitleContainer}>
                <div className={style.megaMenuTitle}>
                  <div className={style.iconsNavContainer}>
                    <img
                      className={style.iconsNav}
                      src={profesionales}
                      alt=""
                    />
                  </div>
                  <div className={style.megaMenuPcontainer}>
                    <p className={style.megaMenuP}>PROFESIONALES</p>
                  </div>
                </div>
              </div>
              <ul>
                <li>
                  <div
                    className={style.institucionalLink}
                    onClick={(e) => handleMouseLeave(e)}
                  >
                    <Link to="/general/docencia/Informacion%20general">
                      Docencia
                    </Link>
                  </div>
                </li>
                <li>
                  <div
                    className={style.institucionalLink}
                    onClick={(e) => handleMouseLeave(e)}
                  >
                    <Link
                      style={{ textDecoration: "none" }}
                      to="/general/comite/"
                    >
                      Comités
                    </Link>
                  </div>
                </li>
                <li>
                  <div
                    className={style.institucionalLink}
                    onClick={(e) => handleMouseLeave(e)}
                  >
                    <Link to="/noticias/Actividades%20académicas">
                      Actividades académicas
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
            <div className={style.megaMenuContainer}>
              <div className={style.megaMenuTitleContainer}>
                <div className={style.megaMenuTitle}>
                  <div className={style.iconsNavContainer}>
                    <img className={style.iconsNav} src={rrhh} alt="" />
                  </div>
                  <div className={style.megaMenuPcontainer}>
                    <p className={style.megaMenuP}>RECURSOS HUMANOS</p>
                  </div>
                </div>
              </div>
              <ul>
                <li>
                  <div
                    className={style.institucionalLink}
                    onClick={(e) => handleMouseLeave(e)}
                  >
                    <Link to="/trabajaConNosotros">
                      <span>Trabajá con nosotros</span>
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
            <div className={style.megaMenuContainer}>
              <div className={style.megaMenuTitleContainer}>
                <div className={style.megaMenuTitle}>
                  <div className={style.iconsNavContainer}>
                    <img className={style.iconsNav} src={curf} alt="" />
                  </div>
                  <div className={style.megaMenuPcontainer}>
                    <p className={style.megaMenuP}>COMUNIDAD CURF</p>
                  </div>
                </div>
              </div>
              <ul>
                <li>
                  <div
                    className={style.institucionalLink}
                    onClick={(e) => handleMouseLeave(e)}
                  >
                    <a
                      style={{ height: "40px" }}
                      href="https://comunidadcurf.clinicareinafabiola.com.ar/index.php/login/index"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Acceso exclusivo para el personal de la institución
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </article>
      </li>
    </>
  );
};
