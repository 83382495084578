import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import "./Backoffice-Carousel-Container.css";
import { useDeleteAllCarouselImages } from "../../../../hooks/api"; // Asegúrate de importar tu hook

export const AddDeleteFiles = ({ handleUpload, open, images }) => {
  const deleteAllCarouselImages = useDeleteAllCarouselImages();

  const handleDeleteAll = () => {
    if (window.confirm("¿Está seguro que desea eliminar todas las imágenes?")) {
      deleteAllCarouselImages.mutate(images, {
        onSuccess: () => {
          alert("Todas las imágenes han sido eliminadas");
        },
        onError: (error) => {
          console.error("Error al eliminar las imágenes", error);
          alert("Error al intentar eliminar las imágenes");
        },
      });
    }
  };

  return (
    <div className="input-container">
      <div className="container-add-files">
        <AddPhotoAlternateIcon
          className="open-file-icon"
          onClick={() => open()}
        />
        <input
          onChange={handleUpload}
          className="input-carousel"
          type="file"
          id="file"
        />
      </div>

      <div className="icon-delete">
        <DeleteOutlinedIcon
          className="userListDelete"
          onClick={handleDeleteAll}
        />
      </div>
    </div>
  );
};
