import { useState } from "react";
import style from "./CircuitoMedicoNav.module.css";
import { Link } from "react-router-dom";
import ginecologico from "../../images/ginecologico.svg";
import integral from "../../images/integral.svg";
import preQuirurgico from "../../images/preQuirurgico.svg";
import polygon from "../../images/polygon.svg";
export const CircuitoMedicoNav = () => {
  let [open, setOpen] = useState(false);

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  return (
    <>
      <li
        className={style.liCircuitos}
        onMouseEnter={(e) => handleMouseEnter(e)}
        onMouseLeave={(e) => handleMouseLeave(e)}
      >
        <figure>
          <img src={polygon} alt="" />
        </figure>
        <span className={style.navBarTitle}>Circuitos médicos</span>
        <article
          className={
            open ? `${style.article} ${style.visible}` : `${style.article}`
          }
        >
          <section /* className="megaMenuNosotros" */>
            <Link
              style={{ textDecoration: "none" }}
              to="/general/circuitosMedicos/Ginecologico"
              onClick={(e) => handleMouseLeave(e)}
            >
              <div className={style.megaMenuCircuitos}>
                <div className={style.imageContainer}>
                  <img className={style.img} src={ginecologico} alt="" />
                </div>
                <p>GINECOLÓGICO</p>
              </div>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to="/general/circuitosMedicos/Integral"
              onClick={(e) => handleMouseLeave(e)}
            >
              <div className={style.megaMenuCircuitos}>
                <div className={style.imageContainer}>
                  <img className={style.img} src={integral} alt="" />
                </div>
                <p>MÉDICO INTEGRAL</p>
              </div>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to="/general/circuitosMedicos/Prequirurgico"
              onClick={(e) => handleMouseLeave(e)}
            >
              <div className={style.megaMenuCircuitos}>
                <div className={style.imageContainer}>
                  <img className={style.img} src={preQuirurgico} alt="" />
                </div>
                <p>PRE QUIRÚRGICO</p>
              </div>
            </Link>
          </section>
        </article>
      </li>
    </>
  );
};
