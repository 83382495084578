
const BackofficeFilter = ({
  labs,
  specialties,
  units,
  staff,
  className,
  handlerChange,
}) => {

  const adultsOrKids = (id) => {
    let filters = specialties?.filter(e => e.name !== "Diagnóstico por imágenes")
    let adults = filters?.filter(e => e.id === id)
    if(adults[0]?.adults === "false" && adults[0]?.kids === "false") {
      return ""
    }else {
      if(adults[0]?.adults === "true") {
        return "Adulto"
      } else {
        return "Pediátrico"
      }
    }
  }

  return (
    <select
      name="filterSelect"
      id="filterSelect"
      className={className}
      onChange={handlerChange}
    >
      <option value="all" selected>
        Todos
      </option>
      {specialties?.length > 0 ? (
        <>
          <option disabled value="">
            --------------------
          </option>
          <option disabled value="">
            ESPECIALIDADES
          </option>
          {specialties &&
            specialties?.map((opt, index) => (
              <option value={opt.id} key={index}>
                {opt?.name === "Diagnóstico por imágenes" ?
                `${opt?.name}`
                :
                `${opt?.name} (${adultsOrKids(opt.id)})`
            }
              </option>
            ))}
        </>
      ) : null}
      {labs?.length > 0 ? (
        <>
          <option disabled value="">
            --------------------
          </option>
          <option disabled value="">
            LABORATORIOS
          </option>
          {labs &&
            labs
              ?.filter((e) => e.name !== "default")
              ?.filter((l) => l.name !== "Información general")
              ?.map((opt, index) => (
                <option value={opt.id} key={index}>
                  {opt.name}
                </option>
              ))}
        </>
      ) : null}
      {units?.length > 0 ? (
        <>
          <option disabled value="">
            --------------------
          </option>
          <option disabled value="">
            UNIDADES ASISTENCIALES
          </option>
          {units &&
            units
              ?.filter((e) => e.name !== "default")
              ?.filter((u) => u.name !== "¿Qué son las unidades asistenciales?")
              ?.map((opt, index) => (
                <option value={opt.id} key={index}>
                  {opt.name}
                </option>
              ))}
        </>
      ) : null}
      {staff?.length > 0 ? (
        <>
          <option disabled value="">
            --------------------
          </option>
          <option disabled value="">
            STAFF
          </option>
          {staff &&
            staff?.map((opt, index) => (
              <option value={opt.id} key={index}>
                {opt.name}
              </option>
            ))}
        </>
      ) : null}
    </select>
  );
};

export default BackofficeFilter;
