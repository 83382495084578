import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import style from "./Backoffice-EditSites.module.css";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { ImageOutlined } from "@mui/icons-material";
import { Editor } from "@tinymce/tinymce-react";
import { BackofficeModal } from "../Backoffice-Modal/Backoffice-Modal";
import openModal from "../../../../actions/backoffice-actions/openModal";
import { useAddData, useDataById, useEditData } from "../../../../hooks/api";
import { fileToBase64 } from "../../../../utils/files";

export const BackofficeEditSites = () => {
  const [editedSite, setEditedSite] = useState("");
  const [modal, setModal] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [save, setSave] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const backofficeReducer = useSelector((state) => state.backofficeReducer);
  const modalImage = backofficeReducer.setModal;
  const editorRef = useRef(null);
  const navigate = useNavigate();

  const { data } = useDataById("sites", id);
  const site = { ...data, ...editedSite };

  const { mutate: editSite } = useEditData("sites");
  const addImageMutation = useAddData("siteGallery");

  const modalOpenOrClose = (e) => {
    e.preventDefault();
    setModal(true);
  };

  const handleModal = (e) => {
    e.preventDefault();
    dispatch(openModal());
  };

  const handleChangeText = (e) => {
    setEditedSite({
      ...site,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpload = (e) => {
    uploadImageAsPromise({
      type: e.target.name,
      img: e.target.files[0],
      name: e.target.files[0]?.name,
    });
  };

  const uploadImageAsPromise = async (uploadFile) => {
    const { type, img, name } = uploadFile;
    const base64File = await fileToBase64(img);
    const formData = {
      fileContent: base64File,
      fileName: name,
    };
    if (type === "image") {
      setEditedSite({
        ...site,
        ...formData,
        img: URL.createObjectURL(img),
      });
    } else {
      addImageMutation.mutate(formData, {
        onSuccess: (data) => {
          console.log("Image uploaded successfully", data);
          setEditedSite({
            ...site,
            galeria: [...site.galeria, data?.downloadURL],
          });
        },
      });
    }
  };

  const deleteImg = async () => {
    setEditedSite({
      ...site,
      img: "",
    });
  };

  const deleteImgGallery = async (img) => {
    let newGallery = site.galeria.filter((e) => e !== img);
    setEditedSite({
      ...site,
      galeria: newGallery,
    });
  };

  const handleConfirm = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      setDirty(false);
      setSave(true);
      editorRef.current.setDirty(false);
      // an application would save the editor content to the server here
      setEditedSite({ ...site, content: content });
    }
  };

  const handleSubmit = () => {
    setModal(false);
    setSave(false);
  };

  const handleFinish = async (e) => {
    e.preventDefault();
    editSite({ ...site, id });
    navigate("/backoffice/sedes");
  };

  const handleClose = (e) => {
    e.preventDefault();
    setModal(false);
  };

  const open = () => {
    document.getElementById("file").click();
  };

  const openGallery = () => {
    document.getElementById("fileGallery").click();
  };

  return (
    <div className={style.container}>
      <form className={style.form} action="">
        <div className={style.name}>
          <label className={style.nameLabel} htmlFor="">
            Nombre
          </label>
          <input
            disabled={true}
            className={style.nameInput}
            type="text"
            placeholder={site?.nombre}
            value={site?.nombre}
            name="nombre"
            onChange={(e) => handleChangeText(e)}
          />
        </div>
        <div className={style.mail}>
          <label className={style.mailLabel} htmlFor="">
            Mail
          </label>
          <input
            className={style.mailInput}
            type="text"
            placeholder={site?.mail}
            value={site?.mail}
            name="mail"
            onChange={(e) => handleChangeText(e)}
          />
        </div>
        <div className={style.barrio}>
          <label className={style.barrioLabel} htmlFor="">
            Barrio
          </label>
          <input
            className={style.barrioInput}
            type="text"
            placeholder={site?.barrio}
            value={site?.barrio}
            name="barrio"
            onChange={(e) => handleChangeText(e)}
          />
        </div>
        <div className={style.domicilio}>
          <label className={style.domicilioLabel} htmlFor="">
            Domicilio
          </label>
          <input
            className={style.domicilioInput}
            type="text"
            placeholder={site?.domicilio}
            value={site?.domicilio}
            name="domicilio"
            onChange={(e) => handleChangeText(e)}
          />
        </div>
        <div className={style.tel}>
          <label className={style.telLabel} htmlFor="">
            Teléfono
          </label>
          <input
            className={style.telInput}
            type="text"
            placeholder={site?.tel}
            value={site?.tel}
            name="tel"
            onChange={(e) => handleChangeText(e)}
          />
        </div>
        <div className={style.about}>
          <label className={style.aboutLabel} htmlFor="">
            Sobre esta sede
          </label>
          <button
            onClick={(e) => modalOpenOrClose(e)}
            className={style.buttonContent}
          >
            {site && site?.content ? "Modificar" : "Crear"}
          </button>
        </div>
        {modal ? (
          <article className={style.modal}>
            <section className={style.modalContainer}>
              <div className={style.modalButtonContainer}>
                <button
                  className={style.openModal}
                  onClick={(e) => handleModal(e)}
                >
                  <ImageOutlined width="40" height="40" />
                </button>
              </div>
              <div
                className={
                  modalImage
                    ? style.modalImageContainer
                    : style.modalImageContainerOff
                }
              >
                <BackofficeModal />
              </div>
              <form className={style.formMarkdown}>
                <Editor
                  apiKey="slz93nsspb367zfebawu3ptagqrlbuyxfora657iog3xugbm"
                  textareaName="description"
                  initialValue={site && site?.content}
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  onDirty={() => setDirty(true)}
                  init={{
                    selector: "textarea.tinymce",
                    theme: "silver",
                    height: 500,
                    onchange_callback: "handleChange",
                    plugins: [
                      // FORMATO DEL MENU
                      "advlist autolink link image lists charmap preview hr anchor pagebreak",
                      "searchreplace wordcount visualblocks code visualchars nonbreaking",
                      "save table contextmenu directionality paste textcolor ",
                    ],
                    toolbar:
                      "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media| print preview fullpage | forecolor backcolor",
                    image_advtab: true,
                  }}
                />
              </form>
            </section>
            <div className={style.containerButtonGroup}>
              <button
                className="markdown-button"
                onClick={(e) => handleClose(e)}
              >
                Cancelar
              </button>
              <button
                className="modified-button"
                onClick={() => handleConfirm()}
                disabled={!dirty}
              >
                Modificar
              </button>
              <button
                className={save ? "saveButton" : "saveDisabled"}
                disabled={save ? false : true}
                onClick={() => handleSubmit()}
              >
                Guardar
              </button>
            </div>
          </article>
        ) : null}
        <div className={style.name}>
          <label className={style.nameLabel} htmlFor="">
            Días y horarios
          </label>
          <textarea
            className={style.diasHorariosInput}
            type="text"
            placeholder={site?.diasHorarios}
            value={site?.diasHorarios}
            name="diasHorarios"
            onChange={(e) => handleChangeText(e)}
          />
        </div>
        <div className={style.image}>
          <label className={style.imageLabel} htmlFor="">
            Imagen
          </label>
          <div className={style.imageContainer}>
            {site && site?.img ? null : (
              <ImageSearchIcon className="searchImage" onClick={() => open()} />
            )}
            {site && site?.img ? (
              <>
                <CancelRoundedIcon
                  onClick={() => deleteImg()}
                  className={style.deleteImg}
                />
                <img className={style.img} src={site && site?.img} alt="" />
              </>
            ) : null}
          </div>
          <input
            onChange={(e) => handleUpload(e)}
            className={style.inputImg}
            type="file"
            id="file"
            name="image"
          />
        </div>
        <div className={style.gallery}>
          <label className={style.galleryLabel} htmlFor="">
            Galería de imágenes
          </label>
          <div className={style.galleryContainer}>
            <div className={style.iconContainer}>
              <ImageSearchIcon
                className="searchImage"
                onClick={() => openGallery()}
              />
            </div>
            <div className={style.galleryItems}>
              {site &&
                site.galeria?.map((img, index) => (
                  <div key={index} className={style.imgCont}>
                    <CancelRoundedIcon
                      onClick={() => deleteImgGallery(img)}
                      className={style.deleteImg}
                    />
                    <img className={style.img} src={img} alt="" />
                  </div>
                ))}
            </div>
          </div>
          <input
            onChange={(e) => handleUpload(e)}
            className={style.inputImg}
            type="file"
            id="fileGallery"
            name="gallery"
          />
        </div>
        <div className={style.finishContainer}>
          <button
            type="submit"
            className={style.buttonContent}
            onClick={(e) => handleFinish(e)}
          >
            Finalizar
          </button>
        </div>
      </form>
    </div>
  );
};
