import { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logo from "./logo.png";

const styles = StyleSheet.create({
  page: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    backgroundColor: "#FFF",
    color: "#575757",
    paddingLeft: 15,
    paddingRight: 15,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    /* height: 70 */
  },
  text: {
    marginBottom: 10,
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: -0.32,
  },
  text1: {
    marginBottom: 10,
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: -0.32,
    width: "300px",
  },
  view: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    marginLeft: 10,
    marginTop: 20,
  },
  fast: {
    fontSize: 16,
    marginBottom: 10,
    color: "#002856",
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 30,
    marginBottom: 15,
  },
  img: {
    width: 154,
  },
  line: {
    width: "90%",
    height: 1,
    marginBottom: 15,
  },
  title: {
    marginBottom: 10,
    marginTop: 20,
    fontSize: 16,
    textAlign: "left",
    color: "#002856",
  },
});

export const RenderPDF = ({ documents, fast }) => {
  const [filter, setFilter] = useState("");
  const [others, setOthers] = useState("");

  const filterData = () => {
    setFilter(
      documents && documents?.some((e) => e?.fast === true) ? fast : null
    );

    setOthers(
      // eslint-disable-next-line no-prototype-builtins
      documents && documents?.filter((e) => !e?.hasOwnProperty("hours"))
    );
  };

  const removeTags = (html) => {
    let doc = new DOMParser().parseFromString(html, "text/html"),
      text = doc.body.textContent || "";
    // Limpiamos los espacios
    text = text.replace(/\s{2,}/g, " ");
    return text;
  };

  useEffect(() => {
    filterData();
  }, [fast, documents]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.logoContainer}>
          <Image style={{ width: 154 }} src={logo} alt="images" />
        </View>
        <View style={styles.line}></View>
        <View style={styles.view}>
          <Text style={styles.fast}>
            {filter && filter !== null ? "Ayuno requerido:" : null}
          </Text>
          {filter && filter
            ? filter?.map((text) => {
                return text?.hours ? (
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={styles.text1}>{text?.name}</Text>
                    <Text style={styles.text1}>{text?.hours}</Text>
                  </View>
                ) : text.name ? (
                  <Text style={styles.text}>{text?.name}</Text>
                ) : null;
              })
            : null}
          {others
            ? others?.map((text) => {
                return text.content ? (
                  <>
                    <Text style={styles.title}>{text?.name}</Text>
                    <Text style={styles.text}>{removeTags(text?.content)}</Text>
                  </>
                ) : null;
              })
            : null}
        </View>
      </Page>
    </Document>
  );
};
