import { SET_LOCATION } from "./constantes";

const setLocation = (payload) => {

    return{
        type: SET_LOCATION,
        payload
    }
};

export default setLocation;