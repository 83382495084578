import { useEffect, useState } from "react";
import CardGaleriaNews from "./CardGaleriaNews";
import { useNavigate } from "react-router-dom";
import style from "./GaleriaNews.module.css";

const GaleriaNews = ({ news }) => {
  const width = window.innerWidth;
  let navigate = useNavigate();
  let [currentNews, setCurrentNews] = useState();

  const resizeArray = () => {
    let aux = news && news?.slice(0, 3);
    if (width > 607 && width <= 791) {
      aux = news && news?.slice(0, 2);
    } else if (width <= 607) {
      aux = news && news?.slice(0, 1);
    }
    setCurrentNews(aux);
  };

  useEffect(() => {
    resizeArray();
  }, [news]);

  return (
    <div className={style.container__main}>
      <div className={style.container__title}>
        <h3>Notas relacionadas</h3>
      </div>
      <div className={style.container__content}>
        {currentNews &&
          currentNews.map((n) => (
            <CardGaleriaNews
              key={n.id}
              title={n.title}
              img={n.img}
              resume={n.resume}
              id={n.id}
            />
          ))}
      </div>
      <div
        className={style.container__btn}
        onClick={() => navigate("/noticias/Programa%20de%20buena%20salud")}
      >
        <span>Todas las notas</span>
      </div>
    </div>
  );
};

export default GaleriaNews;
