import { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Banner from "../Banner/Banner";
import flecha from "./felcha izquierda.svg";
import close from "./close.svg";
import sections from "./IndYPrep/sections.svg";
import style from "./Laboratories.module.css";
import IndYPrep from "./IndYPrep/IndYPrep";
import { useData } from "../../hooks/api";

export const Laboratories = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [labs, setLabs] = useState([]);
  const [show, setShow] = useState(false);
  const { name, section } = params;
  const [infoGral, setInfoGral] = useState("");

  const { data: laboratorios } = useData("laboratorios");
  const { data: preparationsLabs } = useData("preparationsLabs");

  useEffect(() => {
    if (laboratorios) {
      setLabs(laboratorios);
      filterInfo(laboratorios);
    }
    if (preparationsLabs) {
      const names = [];
      preparationsLabs.forEach((doc) => {
        doc.laboratories.forEach((r) => {
          if (!names.includes(r)) names.push(r);
        });
      });
    }
  }, [laboratorios, preparationsLabs]);

  const handleClose = () => {
    setShow(false);
  };
  const openSections = () => {
    setShow(true);
  };
  const filterInfo = (laboratorios) => {
    if (laboratorios) {
      const filteredInfo = laboratorios.filter(
        (e) => e.name === "Información general"
      );
      setInfoGral(filteredInfo);
    }
  };
  const createMarkup = (r) => {
    return { __html: r };
  };

  useEffect(() => {
    setShow(false);
  }, [section, name]);

  return (
    <div className={style.container}>
      <Banner
        title={`${
          name === "listado" ? "Estudios complementarios" : "Laboratorios"
        }`}
      />
      <div className={style.container__flecha_home}>
        <div className={style.container__flecha_home_content}>
          <img
            src={flecha}
            alt="Flecha"
            className={style.flecha__home}
            onClick={() => navigate(-1)}
          />
          <span onClick={() => navigate(-1)}>Volver</span>
        </div>
      </div>
      <div className={style.father}>
        <div
          className={`${style.columnaizq__container_main} ${
            !show ? style.columnaizq__noshow : ""
          }`}
        >
          <div className={style.sectionContainer}>
            <h2 className={style.columnaizq__title}>Secciones</h2>
            <img src={close} className={style.img} onClick={handleClose} />
          </div>
          {name === "listado" ? null : (
            <Link to="/laboratorios/Informacion%20general">
              <button
                className={
                  name === `Informacion general`
                    ? style.columnaizq__btn_active
                    : style.columnaizq__btn
                }
              >
                Información general
              </button>
            </Link>
          )}
          {name === "listado" ? (
            <Link to="/estudios/listado/Indicaciones%20y%20preparaciones">
              <button
                className={
                  section === `Indicaciones y preparaciones`
                    ? style.columnaizq__btn_active
                    : style.columnaizq__btn
                }
              >
                Indicaciones y preparaciones
              </button>
            </Link>
          ) : (
            <Link to="/laboratorios/Indicaciones%20y%20preparaciones">
              <button
                className={
                  name === `Indicaciones y preparaciones`
                    ? style.columnaizq__btn_active
                    : style.columnaizq__btn
                }
              >
                Indicaciones y preparaciones
              </button>
            </Link>
          )}
          <div>
            <h2 className={style.columnaizq__subtitle}>
              {name === "listado" ? "Especialidades" : "Nuestros laboratorios"}
            </h2>
            <div className={style.columnaizq__div}></div>
            <div>
              {name === "listado" ? (
                <>
                  <Link to={`/estudios/listado/Adultos`}>
                    <button
                      className={
                        section === `Adultos`
                          ? style.columnaizq__btn_active
                          : style.columnaizq__btn
                      }
                    >
                      Adultos
                    </button>
                  </Link>
                  <Link to={`/estudios/listado/Pediátricos`}>
                    <button
                      className={
                        section === `Pediátricos`
                          ? style.columnaizq__btn_active
                          : style.columnaizq__btn
                      }
                    >
                      Pediátricos
                    </button>
                  </Link>
                  <Link
                    to={`/especialidades/Diagnóstico%20por%20imágenes/Indicaciones%20y%20preparaciones/adultos/CvsvECgwinB4GaFnOCX5`}
                  >
                    <button className={style.columnaizq__btn}>
                      Diagnóstico por Imágenes
                    </button>
                  </Link>
                </>
              ) : (
                labs &&
                labs
                  .filter(
                    (lab) =>
                      lab.name !== "default" &&
                      lab.name !== "Información general"
                  )
                  .map((r, index) => (
                    <Link
                      key={index}
                      to={`/laboratorios/${r?.name}/Sobre%20el%20servicio/${r?.id}`}
                    >
                      <button
                        className={
                          section === `${r?.name}`
                            ? style.columnaizq__btn_active
                            : style.columnaizq__btn
                        }
                      >
                        {r?.name}
                      </button>
                    </Link>
                  ))
              )}
            </div>
          </div>
        </div>
        <div className={style.columnader__container_main}>
          <div className={style.columnader__container_content}>
            {name !== "Informacion general" ? (
              <IndYPrep />
            ) : (
              <>
                <div className={style.container__Infogral}>
                  <h1 className={style.title}>Información general</h1>
                  <img
                    className={style.flechaSection}
                    src={sections}
                    onClick={openSections}
                  />
                </div>
                <div
                  className={style.contentMarkdownContent}
                  dangerouslySetInnerHTML={createMarkup(
                    infoGral[0]?.content || ""
                  )}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
