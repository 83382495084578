import frame from "./Frame.svg";
import icon from "./IconoPrueba.svg";
import banner from './banner.png';
import style from "./Guide-Banner.module.css"

export const GuideBanner = ({ title, type, iconProp }) => {

  return (
    <div className={style.container__main}>
      <div
        style={{backgroundImage: `url(${banner})`}}
        alt="Background"
        className={style.imagen__background}
      ></div>
      <div className={style.filter}></div>
      <div className={style.container__content}>
        <div className={style.container__frame1}>
          <img src={frame} alt="Frame" />
        </div>
        <div className={style.container__title_image}>
          {title !== "Diagnóstico por imágenes" && <span>{type}</span>}
          <div className={style.titulo}>
            <img src={iconProp ? iconProp : icon} alt="Imagen de Prueba" />
            <h2 className={style.titleContain}>{title}</h2>
          </div>
        </div>
        <div className={style.container__frame2}>
          <img src={frame} alt="Frame" />
        </div>
      </div>
    </div>
  )
}
