import "@firebase/storage";
import { initializeApp } from "@firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCtXUsLm9hebD_SGne2CVwswe1ZwdKUyY8",
  authDomain: "vippinn-pwa.firebaseapp.com",
  projectId: "vippinn-pwa",
  storageBucket: "vippinn-pwa.appspot.com",
  messagingSenderId: "572144032336",
  appId: "1:572144032336:web:ba4a7a6b9fd72a547b4f55",
  measurementId: "G-VL85HL2M2S"
};

export const firebaseApp = initializeApp(firebaseConfig);
// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(firebaseApp);

export const db = getFirestore(firebaseApp);
export const storage = getStorage();
