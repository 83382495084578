import "./Backoffice-Carousel-Container.css";
import CarouselCard from "./CarouselCard/CarouselCard";
import { AddDeleteFiles } from "./Add-Delete-Files";
import { BackofficeNavImage } from "../Backoffice-Nav/Backoffice-Nav-Image";
import { useCarousel, useAddCarouselImage, useDeleteCarouselImage } from "../../../../hooks/api";

export const BackofficeCarousel = () => {
  const { data: images } = useCarousel();
  const addCarouselImage = useAddCarouselImage();
  const deleteCarouselImage = useDeleteCarouselImage();

  const handleUpload = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      addCarouselImage.mutate({ image: imageFile, mobile: false });
      e.target.value = null;
    }
  };

  const onDelete = async () => {
    images.map(async (e) => {
      await deleteCarouselImage.mutate(e.id);
    });
  };

  const open = () => {
    document.getElementById("file").click();
  };

  return (
    <div className="carousel-body">
      <div className="nav-container-back">
        <BackofficeNavImage />
      </div>
      <div className="addDelete">
        <AddDeleteFiles
          handleUpload={(e) => handleUpload(e)}
          onDelete={onDelete}
          open={open}
          images={images}
        />
      </div>
      <h3 className="h3-carousel">Vista previa</h3>
      <div className="images__container">
        {images?.map((image, index) => (
          <CarouselCard image={image} collec="carousel" key={index} />
        ))}
      </div>
    </div>
  );
};
