import { Route, Routes } from "react-router-dom";
import { Contact } from "../Views/Contact/Contact";
import { DigitalReport } from "../Views/DigitalReport/DigitalReport";
import { Institutional } from "../Views/Institutional/Institutional";
import { Specialties } from "../Views/Specialties/Specialties";
import TrabajaConNosotros from "../components/TrabajaConNosotros/TrabajaConNosotros";
import { Home } from "../Views/Home/Home";
import Template from "../Views/Template/Template.jsx";
import Specialty from "../components/Specialty/Specialty";
import { GeneralAreas } from "../components/GeneralAreas/GeneralAreas";
import { BackofficeGeneralAreas } from "../Views/Backoffice/Backoffice-Components/Backoffice-GeneralAreas/Backoffice-GeneralAreas";
import Noticias from "../components/Noticias/Noticias.jsx";
import { Laboratories } from "../components/Laboratories/Laboratories";
import { Units } from "../components/Units/Units";
import NoticiasDetails from "../components/NoticiasDetails/NoticiasDetails";
import Sedes from "../components/Sedes/Sedes";
import InvalidRoute from "../components/InvalidRoute/InvalidRoute";
import { MyPortal } from "../components/MyPortal/MyPortal";
import { Telemedicine } from "../components/Telemedicine/Telemedicine";
import { PatientGuide } from "../components/PatientGuide/PatientGuide";
import { Guides } from "../components/Guides/Guides";
import { BackofficeRouter } from './backoffice';

export const RootRouter = () => (
  <Routes>
    <Route path="/backoffice/*" element={<BackofficeRouter />} />
    <Route exact path="/" element={<Home />} />
    <Route path="/contacto" element={<Contact />} />
    <Route path="/informe-digital" element={<DigitalReport />} />
    <Route exact path="/institucional" element={<Institutional />} />
    <Route path="/especialidad" element={<Specialties />} />
    <Route path="/MiPortal" element={<MyPortal />} />
    <Route path="Telemedicina" element={<Telemedicine />} />
    <Route
      path="/search/:area/"
      element={
        <Template body={<Specialty direccion={window.location.pathname} />} />
      }
    />
    <Route
      path="/especialidades/"
      element={
        <Template body={<Specialty direccion={window.location.pathname} />} />
      }
    />
    <Route
      path="/especialidades/:name/:type"
      element={
        <Template body={<Specialty direccion={window.location.pathname} />} />
      }
    />
    <Route
      path="/especialidades/:name/:section/:type/:id"
      element={
        <Template body={<Specialty direccion={window.location.pathname} />} />
      }
    />
    <Route
      path="/especialidades/:name/:section/:type/"
      element={
        <Template body={<Specialty direccion={window.location.pathname} />} />
      }
    />
    <Route
      path="/general/:name"
      element={
        <Template
          body={<GeneralAreas direccion={window.location.pathname} />}
        />
      }
    />
    <Route
      path="/general/:name/:section"
      element={
        <Template
          body={<GeneralAreas direccion={window.location.pathname} />}
        />
      }
    />
    <Route
      path="/laboratorios"
      element={
        <Template
          body={<Laboratories direccion={window.location.pathname} />}
        />
      }
    />
    <Route
      path="/laboratorios/:name"
      element={
        <Template
          body={<Laboratories direccion={window.location.pathname} />}
        />
      }
    />
    <Route
      path="/laboratorios/:name/:section/:id"
      element={
        <Template body={<Specialty direccion={window.location.pathname} />} />
      }
    />
    <Route
      path="/laboratorios/:name/:section"
      element={
        <Template body={<Specialty direccion={window.location.pathname} />} />
      }
    />
    <Route
      path="/estudios/:name"
      element={<Template body={<Laboratories />} />}
    />
    <Route
      path="/estudios/:name/:section"
      element={<Template body={<Laboratories />} />}
    />
    <Route
      path="/unidadesAsistenciales/"
      element={
        <Template body={<Units direccion={window.location.pathname} />} />
      }
    />
    <Route
      path="/unidadesAsistenciales/:name"
      element={
        <Template body={<Units direccion={window.location.pathname} />} />
      }
    />
    <Route
      path="/unidadesAsistenciales/:name/:section"
      element={
        <Template body={<Units direccion={window.location.pathname} />} />
      }
    />
    <Route path="/prueba" element={<BackofficeGeneralAreas />} />
    <Route
      path="/noticias/:area"
      element={
        <Template body={<Noticias direccion={window.location.pathname} />} />
      }
    />
    <Route
      path="/noticiasDetails/:id"
      element={
        <Template
          body={<NoticiasDetails direccion={window.location.pathname} />}
        />
      }
    />
    <Route
      path="/sedes/:name"
      element={<Sedes direccion={window.location.pathname} />}
    />
    <Route
      exact
      path="/trabajaConNosotros"
      element={
        <Template
          body={<TrabajaConNosotros direccion={window.location.pathname} />}
        />
      }
    />
    <Route
      exact
      path="/guiaDelPaciente"
      element={
        <Template
          body={<PatientGuide direccion={window.location.pathname} />}
        />
      }
    />
    <Route
      exact
      path="/guiaDelPaciente/:name"
      element={
        <Template body={<Guides direccion={window.location.pathname} />} />
      }
    />
    <Route
      exact
      path="/guiaDelPaciente/:name/:id"
      element={
        <Template body={<Guides direccion={window.location.pathname} />} />
      }
    />
    <Route
      path="*"
      element={
        <Template
          body={<InvalidRoute direccion={window.location.pathname} />}
        />
      }
    />
  </Routes>
);
